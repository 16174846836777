export const logoPartnerList = [
    require('assets/images/logo-partner/ae-gaming.png'),
    require('assets/images/logo-partner/ae-loto.png'),
    require('assets/images/logo-partner/ae-sport.png'),
    require('assets/images/logo-partner/ae-sport+.png'),
    require('assets/images/logo-partner/ag-gaming.png'),
    require('assets/images/logo-partner/ameba.png'),
    require('assets/images/logo-partner/bbin.png'),
    require('assets/images/logo-partner/bti.png'),
    require('assets/images/logo-partner/cmd.png'),
    require('assets/images/logo-partner/cq9.png'),
    require('assets/images/logo-partner/deam-gaming.png'),
    require('assets/images/logo-partner/ebet.png'),
    require('assets/images/logo-partner/evolution.png'),
    require('assets/images/logo-partner/fc-chai.png'),
    require('assets/images/logo-partner/gameplay.png'),
    require('assets/images/logo-partner/gw.png'),
    require('assets/images/logo-partner/jdb.png'),
    require('assets/images/logo-partner/jili.png'),
    require('assets/images/logo-partner/ka-gaming.png'),
    require('assets/images/logo-partner/kingmaker.png'),
    require('assets/images/logo-partner/micro-gaming.png'),
    require('assets/images/logo-partner/ob.png'),
    require('assets/images/logo-partner/pg.png'),
    require('assets/images/logo-partner/v8.png'),
    require('assets/images/logo-partner/wm-casino.png'),
];
