import moment from 'moment';

export const convertSecondToTime = (totalSeconds: number) => {
    let hours: any = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    if (hours < 10) {
        hours = '0' + hours;
    }
    return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
};

export const numberWithCommas = (e: any) => {
    return e
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const timeOneMonth = 30 * 24 * 60 * 60 * 1000; // 30 days milisecond
export const convertTitleTimeAgo = (time: string | Date) => {
    if (new Date().getTime() - new Date(time).getTime() > timeOneMonth) {
        return moment(time).format('DD/MM/YYYY');
    } else {
        return moment(new Date(time)).fromNow();
    }
};
