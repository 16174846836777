import { StatsGameGroup } from 'models/statisticModel';

const gameListInterface: StatsGameGroup[] = [
    {
        id: 'xosotruyenthong',
        title: 'Xổ Số Truyền Thống',
        is3rdGame: false,
        gameGroup: [
            { id: 'mienbac', label: 'Miền Bắc', key: 'mienbac', groupType: 'lottery', gameId: 200 },
            { id: 'mienbac', label: 'Đặc Biệt 18h25', key: 'demienbac', groupType: 'lottery', gameId: 201 },
            { id: '', label: 'Tổng Miền Bắc', key: 'totalmienbac', groupType: 'lottery' },
            { id: 'mientrung', label: 'Thừa Thiên Huế', key: 'hue', groupType: 'lottery', gameId: 126 },
            { id: 'mientrung', label: 'Phú Yên', key: 'phuyen', groupType: 'lottery', gameId: 127 },
            { id: 'mientrung', label: 'Đắc Lắc', key: 'daclac', groupType: 'lottery', gameId: 128 },
            { id: 'mientrung', label: 'Quảng Nam', key: 'quangnam', groupType: 'lottery', gameId: 129 },
            { id: 'mientrung', label: 'Đà Nẵng', key: 'danang', groupType: 'lottery', gameId: 130 },
            { id: 'mientrung', label: 'Khánh Hòa', key: 'khanhhoa', groupType: 'lottery', gameId: 131 },
            { id: 'mientrung', label: 'Bình Định', key: 'binhdinh', groupType: 'lottery', gameId: 132 },
            { id: 'mientrung', label: 'Quảng Trị', key: 'quangtri', groupType: 'lottery', gameId: 133 },
            { id: 'mientrung', label: 'Quảng Bình', key: 'quangbinh', groupType: 'lottery', gameId: 134 },
            { id: 'mientrung', label: 'Gia Lai', key: 'gialai', groupType: 'lottery', gameId: 135 },
            { id: 'mientrung', label: 'Ninh Thuận', key: 'ninhthuan', groupType: 'lottery', gameId: 136 },
            { id: 'mientrung', label: 'Quảng Ngãi', key: 'quangngai', groupType: 'lottery', gameId: 137 },
            { id: 'mientrung', label: 'Đắc Nông', key: 'dacnong', groupType: 'lottery', gameId: 138 },
            { id: 'mientrung', label: 'Kon Tum', key: 'kontum', groupType: 'lottery', gameId: 139 },
            { id: '', label: 'Tổng Miền Trung', key: 'totalmientrung', groupType: 'lottery' },
            { id: 'miennam', label: 'Hồ Chí Minh', key: 'hcm', groupType: 'lottery', gameId: 105 },
            { id: 'miennam', label: 'Cà Mau', key: 'camau', groupType: 'lottery', gameId: 107 },
            { id: 'miennam', label: 'Đà Lạt', key: 'dalat', groupType: 'lottery', gameId: 125 },
            // { id: 'miennam', label: 'Bến Tre', key: 'bentre', groupType: 'lottery', gameId: 108},
            { id: 'miennam', label: 'Vũng Tàu', key: 'vungtau', groupType: 'lottery', gameId: 109 },
            { id: 'miennam', label: 'Bạc Liêu', key: 'baclieu', groupType: 'lottery', gameId: 110 },
            { id: 'miennam', label: 'Đồng Nai', key: 'dongnai', groupType: 'lottery', gameId: 111 },
            { id: 'miennam', label: 'Cần Thơ', key: 'cantho', groupType: 'lottery', gameId: 112 },
            { id: 'miennam', label: 'Sóc Trăng', key: 'soctrang', groupType: 'lottery', gameId: 113 },
            { id: 'miennam', label: 'Tây Ninh', key: 'tayninh', groupType: 'lottery', gameId: 114 },
            { id: 'miennam', label: 'An Giang', key: 'angiang', groupType: 'lottery', gameId: 115 },
            { id: 'miennam', label: 'Bình Thuận', key: 'binhthuan', groupType: 'lottery', gameId: 116 },
            { id: 'miennam', label: 'Vĩnh Long', key: 'vinglong', groupType: 'lottery', gameId: 117 },
            { id: 'miennam', label: 'Bình Dương', key: 'binhduong', groupType: 'lottery', gameId: 118 },
            { id: 'miennam', label: 'Trà Vinh', key: 'travinh', groupType: 'lottery', gameId: 119 },
            // { id: 'miennam', label: 'Long An', key: 'longan', groupType: 'lottery', gameId: 120},
            { id: 'miennam', label: 'Bình Phước', key: 'binhphuoc', groupType: 'lottery', gameId: 121 },
            { id: 'miennam', label: 'Hậu Giang', key: 'haugiang', groupType: 'lottery', gameId: 122 },
            { id: 'miennam', label: 'Tiền Giang', key: 'tiengiang', groupType: 'lottery', gameId: 123 },
            { id: 'miennam', label: 'Kiên Giang', key: 'kiengiang', groupType: 'lottery', gameId: 124 },
            { id: 'miennam', label: 'Đồng Tháp', key: 'dongthap', groupType: 'lottery', gameId: 106 },
            { id: '', label: 'Tổng Miền Nam', key: 'totalmiennam', groupType: 'lottery' },
        ],
    },
    {
        id: 'sieutocmienbac',
        title: 'Siêu Tốc Miền Bắc',
        is3rdGame: false,
        gameGroup: [
            { id: 'sieutocmienbac', label: 'Miền Bắc 1 Giây', key: 'mmc', groupType: 'fastlottery', gameId: 140 },
            { id: 'sieutocmienbac', label: 'Miền Bắc 45 Giây', key: 'xs45s', groupType: 'fastlottery', gameId: 141 },
            { id: 'sieutocmienbac', label: 'Miền Bắc 1 Phút', key: 'xs1m', groupType: 'fastlottery', gameId: 142 },
            { id: 'sieutocmienbac', label: 'Miền Bắc 1.5 Phút', key: 'xs1m30s', groupType: 'fastlottery', gameId: 143 },
            { id: 'sieutocmienbac', label: 'Miền Bắc 2 Phút', key: 'xs2m', groupType: 'fastlottery', gameId: 144 },
            { id: 'sieutocmienbac', label: 'Miền Bắc 5 Phút', key: 'xs5m', groupType: 'fastlottery', gameId: 145 },
            { id: '', label: 'Tổng', key: 'totalxosonhanh', groupType: 'fastlottery' },
        ],
    },
    {
        id: 'xosonhanh',
        title: 'Xổ Số Nhanh',
        is3rdGame: false,
        gameGroup: [
            { id: 'xosonhanh', label: 'Siêu Tốc 1 Giây', key: 'mmc', groupType: 'fastlottery', gameId: 50 },
            { id: 'xosonhanh', label: 'Siêu Tốc 45 Giây', key: 'xs45s', groupType: 'fastlottery', gameId: 100 },
            { id: 'xosonhanh', label: 'Siêu Tốc 1 Phút', key: 'xs1m', groupType: 'fastlottery', gameId: 101 },
            { id: 'xosonhanh', label: 'Siêu Tốc 1.5 Phút', key: 'xs1m30s', groupType: 'fastlottery', gameId: 102 },
            { id: 'xosonhanh', label: 'Siêu Tốc 2 Phút', key: 'xs2m', groupType: 'fastlottery', gameId: 103 },
            { id: 'xosonhanh', label: 'Siêu Tốc 5 Phút', key: 'xs5m', groupType: 'fastlottery', gameId: 104 },
            { id: '', label: 'Tổng', key: 'totalxosonhanh', groupType: 'fastlottery' },
        ],
    },
    // {
    //     id: 'dangian',
    //     title: 'Dân Gian',
    //     is3rdGame: false,
    //     gameGroup: [
    //         { id: 'dangian', label: 'Xóc Đĩa 1 Giây', key: 'xd1s', groupType: 'traditional' },
    //         { id: 'dangian', label: 'Xóc Đĩa 1 Phút', key: 'xd1m', groupType: 'traditional' },
    //         { id: 'dangian', label: 'Bầu Cua 1 Giây', key: 'bc1s', groupType: 'traditional' },
    //         { id: 'dangian', label: 'Bầu Cua 1 Phút', key: 'bc1m', groupType: 'traditional' },
    //         { id: '', label: 'Tổng', key: 'totaldangian', groupType: 'traditional' },
    //     ],
    // },
    // {
    //     id: 'minigame',
    //     title: 'Mini Game',
    //     is3rdGame: false,
    //     gameGroup: [
    //         { id: 'minigame', label: 'Tài Xỉu Cân Bảng', key: 'sicbo', groupType: 'minigame' },
    //         { id: 'minigame', label: 'Xóc Dĩa', key: 'xocdia', groupType: 'minigame' },
    //         { id: '', label: 'Tổng', key: 'totalminigame', groupType: 'minigame' },
    //     ],
    // },
];

export default gameListInterface;
