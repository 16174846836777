import { LotterySub, TypeInputLottery } from 'models/lotteryModel';
import React from 'react';
import PickLotteryInput from './PickLotteryInput';
import QuickPickLotteryInput from './QuickPickLotteryInput';
import TypeInLotteryInput from './TypeInLotteryInput';
interface Props {
    typeInputList: TypeInputLottery[];
    onChange: (inputData: TypeInputLottery, amount: number, isValid: boolean, inputType: 'pick' | 'quick-pick' | 'type-in') => void;
    subTypeBet: LotterySub;
    resetBet: () => void;
}

function LotteryTypeInput(props: Props) {
    const { typeInputList, onChange, subTypeBet, resetBet } = props;

    const handleChangePickInput = (inputData: TypeInputLottery, amount: number, isValid: boolean) => {
        onChange(inputData, amount, isValid, 'pick');
    };
    const handleChangeQuickPickInput = (inputData: TypeInputLottery, amount: number, isValid: boolean) => {
        onChange(inputData, amount, isValid, 'quick-pick');
    };

    const handleChangeTypeIn = (inputData: TypeInputLottery, amount: number, isValid: boolean) => {
        onChange(inputData, amount, isValid, 'type-in');
    };

    return (
        <>
            {typeInputList.map((item, index) => (
                <React.Fragment key={index}>
                    {item.active && item.typeIputID === 'pick' && <PickLotteryInput onChangePickInput={handleChangePickInput} pickInput={item} />}
                    {item.active && item.typeIputID === 'quick-pick' && <QuickPickLotteryInput subTypeBet={subTypeBet} onChangeQuickPick={handleChangeQuickPickInput} quickPickInput={item} />}
                    {item.active && item.typeIputID === 'type-in' && <TypeInLotteryInput subTypeBet={subTypeBet} onChangeTypeIn={handleChangeTypeIn} typeInInput={item} onResetBet={resetBet} />}
                </React.Fragment>
            ))}
        </>
    );
}

export default LotteryTypeInput;
