import lotteryApi from 'apis/lotteryApi';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';
import { LotteryGame } from 'models/lotteryListModel';
import queryString from 'query-string';
import { memo, useEffect, useState } from 'react';
import LotteryCard from './LotteryCard';
import styles from './lottery-home.style';
import { RootState, useAppSelector } from 'cache/store';
interface Props {
    lotteryData: LotteryGame[];
}
function FastLotteryHome(props: Props) {
    const { lotteryData } = props;
    const { triggerFastLottery } = useAppSelector((state: RootState) => state.config);
    const [lotteryList, setLotteryList] = useState<LotteryGame[]>(lotteryData);

    useEffect(() => {
        if (triggerFastLottery) {
            const listPromise: Promise<AxiosResponse<BaseResponse<any>>>[] = [];

            const listLotteryId = [...lotteryData].map((item) => item.lotteryID);

            listLotteryId.forEach((id) => {
                const promiseItem = lotteryApi.lotteryCurrentIssue({ lotteryid: id });
                listPromise.push(promiseItem);
            });
            Promise.all(listPromise)
                .then((listRes) =>
                    listRes.map((res) => {
                        const lotteryID = Number(queryString.parse(res.config.data).lotteryid);
                        return { ...res.data, lotteryID };
                    })
                )
                .then((listData) => {
                    const newListLottery = lotteryData.map((item) => {
                        const newLotteryItem = listData.find((data) => data.lotteryID === item.lotteryID);
                        if (newLotteryItem) return { ...item, ...newLotteryItem };
                        return item;
                    });
                    setLotteryList(newListLottery);
                })
                .catch((error) => console.log(error));
        }
    }, [triggerFastLottery]);

    return (
        <div className={styles.wrap}>
            {lotteryList.map((item) => (
                <LotteryCard data={item} key={item.lotteryID} />
            ))}
        </div>
    );
}

export default memo(FastLotteryHome);
