import { TypeInputLottery } from 'models/lotteryModel';
import styles from './lottery-type-input.style';
import classNames from 'classnames';
interface Props {
    pickInput: TypeInputLottery;
    onChangePickInput: (pickData: TypeInputLottery, amount: number, isValid: boolean) => void;
}
function PickLotteryInput(props: Props) {
    const { pickInput, onChangePickInput } = props;

    const genRangeBet = (range: string) => {
        const rangeNum: number[] = range.split('|').map((item) => parseInt(item, 10));
        const result: string[] = [];
        for (let num = rangeNum[0]; num <= rangeNum[1]; num++) {
            result.push(num.toString(10));
        }
        return result;
    };

    const selectPickSelect = (type: string, layoutIdx: number, rangeNumber: string[]) => {
        let rangeNumSelect = filterRangeNumber(type, rangeNumber);
        const newPickLayout = pickInput.layout.map((item, index) => {
            if (index === layoutIdx) {
                const newBtnList = pickInput.layout[layoutIdx].btns.map((btn) => {
                    if (btn.attr_name === type) {
                        if (btn.active) {
                            rangeNumSelect = [];
                            return { ...btn, active: false };
                        }
                        return { ...btn, active: true };
                    }
                    return { ...btn, active: false };
                });
                return {
                    ...item,
                    btns: newBtnList,
                    selectedNums: rangeNumSelect,
                    active: true,
                };
            }
            return item;
        });
        const newPickInput = { ...pickInput, layout: newPickLayout };
        checkValidPick(newPickInput);
    };

    const selectNumPick = (num: string, layoutIdx: number) => {
        const newPickLayout = pickInput.layout.map((item, index) => {
            if (index === layoutIdx) {
                let newSelectedNums = item.selectedNums;
                if (newSelectedNums.includes(num)) {
                    newSelectedNums = newSelectedNums.filter((numItem) => numItem !== num);
                } else {
                    newSelectedNums.push(num);
                }

                return {
                    ...item,
                    selectedNums: newSelectedNums,
                    active: true,
                };
            }
            return item;
        });
        const newPickInput = { ...pickInput, layout: newPickLayout };
        checkChangeSelectedNums(layoutIdx, newPickInput);
    };

    const checkChangeSelectedNums = (layoutIdx: number, newPickInput: TypeInputLottery) => {
        const arrayPick = [
            ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            ['5', '6', '7', '8', '9'],
            ['0', '1', '2', '3', '4'],
            ['1', '3', '5', '7', '9'],
            ['0', '2', '4', '6', '8'],
        ];
        let layout = newPickInput.layout[layoutIdx];
        const newBtnList = layout.btns.map((btn, index) => {
            return {
                ...btn,
                active: JSON.stringify(arrayPick[index]) === JSON.stringify(layout.selectedNums.sort()),
            };
        });
        layout = { ...layout, btns: newBtnList };
        newPickInput = {
            ...newPickInput,
            layout: newPickInput.layout.map((layoutItem, index) => {
                if (index === layoutIdx) {
                    return layout;
                }
                return layoutItem;
            }),
        };
        checkValidPick(newPickInput);
    };

    const filterRangeNumber = (type: string, rangeNumber: string[]) => {
        switch (type) {
            case 'all':
                return rangeNumber;
            case 'big':
                return rangeNumber.filter((num) => parseInt(num, 10) >= 5);
            case 'small':
                return rangeNumber.filter((num) => parseInt(num, 10) < 5);
            case 'odd':
                return rangeNumber.filter((num) => parseInt(num, 10) % 2 !== 0);
            case 'even':
                return rangeNumber.filter((num) => parseInt(num, 10) % 2 === 0);
            default:
                return [];
        }
    };

    const checkValidPick = (newPickInput: TypeInputLottery) => {
        //kiểm tra kiểu cược chọn đủ số
        let amount = 1;
        const isValidPick = newPickInput.layout.every((item) => {
            amount *= item.selectedNums.length;
            return item.selectedNums.length > 0;
        });

        onChangePickInput(newPickInput, amount, isValidPick);
    };
    return (
        <div className={styles.pickBet}>
            {pickInput.layout.map((rowBet, index) => (
                <div className={styles.pickRow} key={index}>
                    <div className={styles.pickLeft}>
                        <p>{rowBet.title}</p>
                        <div className={styles.pickNum}>
                            {genRangeBet(rowBet.no).map((num, idx) => (
                                <span key={idx} className={classNames(styles.numPickItem, { active: rowBet.selectedNums.includes(num) })} onClick={() => selectNumPick(num, index)}>
                                    {num}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className={styles.pickRight}>
                        {rowBet.btns.map((btn) => (
                            <button
                                type="button"
                                className={classNames(styles.btnPickQick, { active: btn.active })}
                                key={btn.attr_name}
                                onClick={() => selectPickSelect(btn.attr_name, index, genRangeBet(rowBet.no))}
                            >
                                {btn.title}
                            </button>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PickLotteryInput;
