import AccountPage from 'pages/account/Account';
import DepositPage from 'pages/deposit/DepositPage';
import DownloadApp from 'pages/download-app/DownloadApp';
import HelpPage from 'pages/help/HelpPage';
import HomePage from 'pages/homepage/HomePage';
import LobbyPage from 'pages/lobby/LobbyPage';
import LotteryPage from 'pages/lottery/LotteryPage';
import PromotionDetail from 'pages/promo-detail/PromotionDeail';
import PromotionPage from 'pages/promotion/PromotionPage';

interface MainRoutes {
    path: string;
    page: React.FC;
    isPrivate: boolean;
}

//public routes
const mainRoutes: MainRoutes[] = [
    {
        path: '/',
        page: HomePage,
        isPrivate: false,
    },
    {
        path: '/deposit',
        page: DepositPage,
        isPrivate: true,
    },
    {
        path: '/promotion',
        page: PromotionPage,
        isPrivate: false,
    },
    {
        path: '/promotion/:id',
        page: PromotionDetail,
        isPrivate: false,
    },
    {
        path: '/account',
        page: AccountPage,
        isPrivate: true,
    },
    {
        path: '/lottery',
        page: LotteryPage,
        isPrivate: true,
    },
    {
        path: '/help',
        page: HelpPage,
        isPrivate: false,
    },
    {
        path: '/download',
        page: DownloadApp,
        isPrivate: false,
    },
    {
        path: '/lobby',
        page: LobbyPage,
        isPrivate: false,
    },
];

export { mainRoutes };
