import { css } from '@emotion/css';
import { RootState, useAppSelector } from 'cache/store';
import alertMsg from 'components/alert-message/alert-message';
import IntroApp from 'components/intro-app/IntroApp';
import LiveCasinoSection from 'components/live-casino/LiveCasinoSection';
import Modal from 'components/modal/Modal';
import RuleApp from 'components/rule-app/RuleApp';
import FastTransferGame from 'components/transfer/FastTransferGame';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { Game3rdMenuItem, MenuHeaderItem } from 'models/commonModel';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function LobbyPage() {
    const { menuGame } = useAppSelector((state: RootState) => state.system);
    const { isLogged } = useAppSelector((state: RootState) => state.auth);
    const [searchParams] = useSearchParams();
    const type: string = searchParams.get('type') || 'LC'; //mặc định live casino
    const [lobbyActive, setLobbyActive] = useState<MenuHeaderItem>({} as MenuHeaderItem);
    const [gameTransferSelect, setGameTransferSelect] = useState<Game3rdMenuItem | null>(null);

    useEffect(() => {
        if (menuGame.length > 0) {
            let lobby = menuGame.filter((game) => game.child).find((item) => item.gametype === type);
            if (!lobby) {
                lobby = menuGame[0];
            }
            setLobbyActive(lobby);
        }
    }, [type, menuGame]);

    const selectGame = (game: Game3rdMenuItem) => {
        if (!isLogged) {
            alertMsg('Vui lòng đăng nhập');
            return;
        }
        setGameTransferSelect(game);
    };

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.bannerImg}>
                    {type === 'LC' && <img src={require('assets/images/bg/bg-casino.png')} alt="" />}
                    {type === 'SB' && <img src={require('assets/images/bg/bg-sport.png')} alt="" />}
                    {type === 'SL' && <img src={require('assets/images/bg/bg-slot.png')} alt="" />}
                    {type === 'CB' && <img src={require('assets/images/bg/bg-card.png')} alt="" />}
                    <div className={styles.titleGame}>
                        <h6>{lobbyActive.name}</h6>
                    </div>
                </div>
                <div className={styles.gameContent}>
                    <div className={globalStyle.container}>
                        {lobbyActive.child && lobbyActive.gametype && <LiveCasinoSection gametype={lobbyActive.gametype} gameList={lobbyActive.child} onSelect={selectGame} />}
                    </div>
                </div>
            </div>
            <div className={globalStyle.container}>
                <IntroApp />
                <div style={{ marginBottom: '24px' }}>
                    <RuleApp />
                </div>
            </div>

            {/* popup chuyển quỹ nhanh */}
            <Modal isShow={Boolean(gameTransferSelect)} onClose={() => setGameTransferSelect(null)} width="520px">
                {gameTransferSelect ? <FastTransferGame gameData={gameTransferSelect} onClose={() => setGameTransferSelect(null)} /> : <></>}
            </Modal>
        </>
    );
}

const styles = {
    wrap: css``,
    bannerImg: css`
        display: flex;
        position: relative;
        img {
            width: 100%;
        }
    `,
    titleGame: css`
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        h6 {
            position: relative;
            padding: 8px 12px;
            background: ${colors.bgDark};
            font-size: 28px;
            color: ${colors.white};
            &::before,
            &::after {
                content: '';
                position: absolute;
                height: 100%;
                width: 33px;
                bottom: 0;
                background: ${colors.bgDark};
                z-index: -1;
            }
            &::before {
                transform: skew(-29deg, 0);
                left: -15px;
            }
            &::after {
                transform: skew(29deg, 0);
                right: -15px;
            }
        }
    `,
    gameContent: css`
        margin: 24px 0;
    `,
};

export default LobbyPage;
