import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { useEffect, useState } from 'react';
let interValId: any;

interface Props {
    timeData: {
        openTime: string;
        endTime: string;
        serverTime: string;
    };
    noStyles?: boolean;
}

function LotteryButtonCountDown(props: Props) {
    const { timeData, noStyles } = props;

    const [timer, setTimer] = useState<{ h: string; m: string; s: string }>({ h: '00', m: '00', s: '00' });
    useEffect(() => {
        if (Object.keys(timeData).length > 0) {
            let pourTime: number = (+new Date(timeData.endTime.replace(/-/g, '/')) - +new Date(timeData.serverTime.replace(/-/g, '/'))) / 1000;
            interValId = setInterval(() => {
                pourTime--;
                if (pourTime >= 0) {
                    let hour: any = parseInt((pourTime / 3600).toString(), 10);
                    let minute: any = parseInt(((pourTime - hour * 3600) / 60).toString(), 10);
                    let second: any = parseInt((pourTime % 60).toString(), 10);
                    if (hour < 10) {
                        hour = '0' + hour;
                    }
                    if (minute < 10) {
                        minute = '0' + minute;
                    }
                    if (second < 10) {
                        second = '0' + second;
                    }

                    setTimer({
                        h: hour,
                        m: minute,
                        s: second,
                    });
                }
                if (pourTime < 0) {
                    clearInterval(interValId);
                }
            }, 1000);
        }
        return () => {
            clearTimeout(interValId);
        };
    }, [timeData]);

    return (
        <div className={!noStyles ? styles.wrap : styles.noStyles}>
            <p>{timer.h}</p>
            <span>:</span>
            <p>{timer.m}</p>
            <span>:</span>
            <p>{timer.s}</p>
        </div>
    );
}

const styles = {
    wrap: css`
        display: flex;
        align-items: center;
        gap: 16px;
        height: 50px;
        overflow: hidden;
        p,
        span {
            font-size: 32px;
            font-weight: 600;
        }
        span {
            padding-bottom: 8px;
        }
        p {
            padding: 6px;
            background: rgba(3, 10, 28, 0.5);
            border-radius: 8px;
            border: 1px solid ${colors.borderPrimary};
            min-width: 52px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `,
    noStyles: css`
        display: flex;
        align-items: center;
        gap: 2px;
    `,
};

export default LotteryButtonCountDown;
