import { useEffect, useState } from 'react';
import accountStyles from './account-option.style';
import { BankCardAddPayload, BankCardModel } from 'models/bankModel';
import userApi from 'apis/userApi';
import toast from 'react-hot-toast';
import moment from 'moment';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import bankItemBg from 'assets/images/bg/bank-card.jpg';
import { BiPlus } from 'react-icons/bi';
import classNames from 'classnames';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import AddBankPerson from 'components/add-bank/AddBank';

const bankListBackup = [
    'Ngân Hàng Á Châu (ACB)',
    'Agribank',
    'BIDV',
    'Đông Á',
    'EximBank',
    'Sacombank',
    'Techcombank',
    'Vietinbank',
    'Vietcombank',
    'VIB',
    'VPBank',
    'MaritimeBank',
    'SHB',
    'MBBank',
    'otherBank',
];

function BankAccount() {
    const [isCanAdd, setIsCanAdd] = useState(true);
    const [isCanLock, setIsCanLock] = useState(false);
    const [bankPersonList, setBankPersonList] = useState<BankCardModel[]>([]);

    const [loading, setLoading] = useState(false);
    const [showConfirmLock, setShowConfirmLock] = useState(false);
    const [showAddBank, setShowAddBank] = useState(false);
    const [bankList, setBankList] = useState<string[]>([]);
    useEffect(() => {
        const getBankPerson = async () => {
            try {
                const { data } = await userApi.getBankPersonList({});
                if (!data.msg) {
                    handleDataBank(data);
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getBankPerson();
    }, []);

    useEffect(() => {
        const getBankList = async () => {
            try {
                const { data } = await userApi.getBankList({});
                if (!data.msg) {
                    if (data.data.length > 0) {
                        setBankList(data.data);
                    } else {
                        setBankList(bankListBackup);
                    }
                } else {
                    toast.error(data.msg);
                    setBankList(bankListBackup);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getBankList();
    }, []);

    const handleDataBank = (data: any) => {
        let bankData = data.bankcardlist.map((item: any) => ({ ...item, bankcard: `****${item.bankcard.substr(-4)}` }));
        bankData = bankData.filter((bank: any) => bank.status === 0 || bank.status === 2);
        setBankPersonList(bankData);

        setIsCanAdd(data.bankcheck < 2 && data.banknumber < 5);
        setIsCanLock(data.bankcheck < 2);
    };

    const submitConfirmLockBank = async () => {
        setLoading(true);
        try {
            const { data } = await userApi.lockBankCardPerson({});
            if (!data.msg) {
                setShowConfirmLock(false);
                setIsCanLock(false);
                setIsCanAdd(false);
                toast.success('Khoá tài khoản thành công');
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const submitBankCardAdd = async (payload: BankCardAddPayload) => {
        setLoading(true);
        try {
            const { data } = await userApi.addBankcardCheckAdd(payload);
            if (!data.msg) {
                toast.success('Thêm ngân hàng thành công');
                setShowAddBank(false);
                handleDataBank(data);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={accountStyles.wrap}>
                <h6 className={accountStyles.title}>Tài Khoản Ngân Hàng</h6>
                <div className={styles.bankList}>
                    {bankPersonList.map((bank, index) => (
                        <div className={styles.bankItem} key={bank.id}>
                            <div className={styles.bankInner}>
                                <p className={styles.bankOrder}>{index + 1}</p>
                                <div className={classNames(styles.bankContent, { pending: bank.status === 2 })}>
                                    <div className={styles.bankItemRow}>
                                        <p>Ngân Hàng</p>
                                        <p>{bank.bankname}</p>
                                    </div>

                                    <div className={styles.bankItemRow}>
                                        <p>Số Tài Khoản</p>
                                        <p>{bank.bankcard}</p>
                                    </div>
                                    <div className={styles.bankItemRow}>
                                        <p>Ngày Thêm</p>
                                        <p>{moment(bank.savetime).format('DD/MM/yyyy HH:MM:ss')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {isCanAdd && (
                        <div className={styles.bankItem}>
                            <div className={styles.addBank} onClick={() => setShowAddBank(true)}>
                                <BiPlus className={styles.bankOrder} style={{ padding: '6px', marginRight: '8px' }} />
                                <p>Thêm Tài Khoản</p>
                            </div>
                        </div>
                    )}
                </div>
                <ol className={styles.ruleAddBank}>
                    <li>
                        Bạn có thể thêm tối đa <span>5</span> tài khoản ngân hàng. Hiện tại bạn đang có <span>{bankPersonList.length}</span> tài khoản.
                    </li>
                    <li>Từ tài khoản ngân hàng thứ 2 trở đi phải trùng tên chủ tài khoản với tài khoản đầu tiên.</li>
                    <li>Nếu bạn khoá danh sách tài khoản, bạn sẽ không thể thêm mới hoặc xoá tài khoản ngân hàng.</li>
                </ol>
                <p className={styles.note}>
                    <span>* Lưu ý:</span> Các tài khoản ngân hàng đang chờ kiểm tra sẽ được hiển thị màu đỏ. Quý khách vui lòng liên hệ CSKH để được hỗ trợ.
                </p>

                {isCanLock && (
                    <button type="button" className={`${accountStyles.btnSubmit} ${styles.btnLockAccount}`} onClick={() => setShowConfirmLock(true)}>
                        Khoá Tài Khoản
                    </button>
                )}
            </div>
            {/* confirm lock bank */}
            <Modal isShow={showConfirmLock} onClose={() => setShowConfirmLock(false)} width="400px">
                <div className="bg-popup-common">
                    <h6 className="title">Xác Nhận Khoá Tài Khoản</h6>
                    <p className="desc">Sau khi khoá tài khoản ngân hàng, bạn sẽ không thể thêm mới hoặc xoá tài khoản ngân hàng.</p>
                    <div className="action-group-modal">
                        <button type="button" className="btn-cancel" onClick={() => setShowConfirmLock(false)}>
                            Huỷ
                        </button>
                        <button type="button" className="btn-submit btn-primary" onClick={submitConfirmLockBank}>
                            Xác nhận
                        </button>
                    </div>
                </div>
            </Modal>

            {/* add bank */}
            <Modal isShow={showAddBank} onClose={() => setShowAddBank(false)} width="440px">
                <AddBankPerson bankList={bankList} onClose={() => setShowAddBank(false)} onSubmit={submitBankCardAdd} />
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    bankList: css`
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
        min-height: 121px;
    `,
    bankItem: css`
        flex: 0 0 calc(100% / 3);
        padding: 6px;
    `,
    bankInner: css`
        padding: 12px;
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        background: url(${bankItemBg}) no-repeat;
        background-size: cover;
        display: flex;
        gap: 8px;
    `,
    bankOrder: css`
        background: linear-gradient(180deg, #10acd3 0%, #461bf4 99.37%);
        font-weight: 500;
        width: 28px;
        height: 28px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    bankContent: css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
        &.pending {
            p {
                &:last-child {
                    color: ${colors.red};
                }
            }
        }
    `,
    bankItemRow: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 14px;
            &:last-child {
                color: ${colors.yellow};
            }
        }
    `,
    addBank: css`
        min-height: 109px;
        border: 2px dashed ${colors.primary};
        border-radius: 8px;
        padding: 12px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
    `,
    ruleAddBank: css`
        padding-left: 20px;
        font-size: 14px;
        margin-top: 20px;
        li {
            margin-bottom: 4px;
        }
        span {
            color: ${colors.red};
            font-weight: 600;
        }
    `,
    note: css`
        font-style: italic;
        font-size: 14px;
        margin-top: 12px;
        span {
            color: ${colors.red};
        }
    `,
    btnLockAccount: css`
        border: 1px solid ${colors.primary};
        color: ${colors.white};
        background: ${colors.bgTitle};
        margin-top: 30px;
        width: 340px;
        &:hover {
            background: ${colors.blackText};
        }
    `,
};

export default BankAccount;
