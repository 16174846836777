import { logoPartnerList } from 'constants/logoPartner';
import styles from './footer.styles';
import globalStyle from 'constants/global.style';
import { appName } from 'config/app.config';

const communityList = [
    {
        typeName: 'Facebook',
        name: 'Alobet88',
        icon: require('assets/images/icons/home/fb.png'),
    },
    {
        typeName: 'Youtube',
        name: 'Alobet88',
        icon: require('assets/images/icons/home/youtube.png'),
    },
    // {
    //     typeName: 'Telegram',
    //     name: 'Kênh ALOBET88',
    //     icon: require('assets/images/icons/home/telegram.png'),
    // },
    {
        typeName: 'CSKH Telegram',
        name: '@cskhalobet88',
        icon: require('assets/images/icons/home/telegram-group.png'),
    },
    // {
    //     typeName: 'Email',
    //     name: 'alobet88@gmail.com',
    //     icon: require('assets/images/icons/home/mail.png'),
    // },
];
function Footer() {
    const goToChannel = (item: any) => {
        if (item.name === '@cskhalobet88') {
            window.open(`https://t.me/CSKHalobet88`, '_blank');
        }
    };
    return (
        <div className={styles.wrap}>
            <div className={globalStyle.container}>
                <h6 className={styles.heading}>Nhà Cung Cấp</h6>
                <div className={styles.partnerList}>
                    {logoPartnerList.map((item, index) => (
                        <div className={styles.partnerItem} key={index}>
                            <img src={item} alt="" />
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.community}>
                <h6 className={`${styles.heading} ${styles.titleComunity}`}>Đồng Hành Cùng Chúng Tôi</h6>
                <div className={styles.communityList}>
                    {communityList.map((item) => (
                        <div className={`d-flex align-items-center ${styles.communityItem}`} key={item.typeName} onClick={() => goToChannel(item)}>
                            <img src={item.icon} alt="" />
                            <div className="">
                                <p>{item.typeName}</p>
                                <h6>{item.name}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={globalStyle.container}>
                <div className={styles.cer}>
                    <img src={require('assets/images/icons/home/cer-logo.png')} alt="" />
                </div>
                <p className={styles.cerDes}>
                    {window.origin} is licensed by E-Gambling Montenegro d.o.o. (Podgorica, Moskovska br. 65., reg. no. 50615951).E-Gambling Montenegro d.o.o. (Podgorica, Moskovska br. 65., reg. no.
                    50615951) hereby certifies that under concession (serial no. AA 001588, numeric no. 133-01/15 4A and 1ER) and approval (no. 02/01-118/4), organize and operate games of chance in
                    Montenegro on website {window.origin}
                </p>
            </div>
            <p className={styles.lisence}>
                {appName} đã được ủy quyền và giám sát bởi chính phủ Philippines PAGCOR từ ngày 02 tháng 02 năm 2022 © Bản quyền thuộc sử hữu của {appName}
            </p>
        </div>
    );
}

export default Footer;
