export const xdLabel: any = {
    '[Even]': 'Chẵn',
    '[even]': 'Chẵn',
    '[Odd]': 'Lẻ',
    '[odd]': 'Lẻ',
    '[1white3red]': '1 Trắng 3 Đỏ',
    '[3white1red]': '3 Trắng 1 Đỏ',
    '[White]': 'Trắng',
    '[white]': 'Trắng',
    '[Red]': 'Đỏ',
    '[red]': 'Đỏ',
};
export const baucuaLabel: any = {
    '[none]': 'none',
    '[nai]': 'Nai',
    '[cua]': 'Cua',
    '[ga]': 'Gà',
    '[ca]': 'Cá',
    '[bau]': 'Bầu',
    '[tom]': 'Tôm',
};
