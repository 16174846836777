import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import bgRegister from 'assets/images/bg/bg-register.jpg';
const registerStyle = {
    wrap: css`
        border-radius: 24px;
        overflow: hidden;
        display: flex;
    `,
    left: css`
        flex: 1;
        display: flex;
        flex-direction: column;
    `,
    logoPartner: css`
        display: flex;
        flex-wrap: wrap;

        background: ${colors.white};
        padding: 12px 30px;
    `,
    partnerItem: css`
        flex: 0 0 20%;
        max-width: 20%;
        padding: 4px 8px;
    `,
    itemInner: css`
        border: 1px solid #e0e0e0;
        padding: 2px 8px;
        border-radius: 4px;
        img {
            width: 100%;
            height: 27px;
            object-fit: contain;
        }
    `,
    right: css`
        flex: 0 0 450px;
        max-width: 450px;
        background: url(${bgRegister}) no-repeat;
        padding: 30px 45px;
    `,
    heading: css`
        display: flex;
        align-items: center;
    `,
    title: css`
        position: relative;
        display: flex;
        flex: 1;
        border: 1px solid ${colors.primary};
        border-radius: 8px;
    `,
    titleBg: css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    `,
    titlecontent: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        h6 {
            font-size: 20px;
            color: ${colors.white};
            font-weight: 500;
        }
        img {
            width: 108px;
        }
    `,
    btnClose: css`
        width: 40px;
        height: 40px;
        margin-left: 12px;
        cursor: pointer;
    `,
    hasAccount: css`
        display: flex;
        align-items: center;
        margin: 12px 0;
        padding-left: 12px;
        span {
            font-size: 14px;
            color: ${colors.white};
        }
        p {
            color: ${colors.yellow};
            font-weight: 600;
            padding: 4px 8px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    `,
    form: css`
        color: ${colors.white};
    `,
    inputWrap: css`
        position: relative;
        padding-bottom: 20px;
        label {
            font-size: 14px;
            display: block;
            margin-bottom: 6px;
            font-weight: 300;
        }
    `,
    input: css`
        width: 100%;
    `,

    errorInput: css`
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 100%;
        small {
            font-size: 12px;
            color: ${colors.red};
        }
    `,
    textTOF: css`
        font-size: 14px;
        font-weight: 300;
    `,

    togglePass: css`
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 42px;
        transform: translateY(-50%);
        img {
            width: 18px;
        }
    `,
};
export default registerStyle;
