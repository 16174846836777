import { css } from '@emotion/css';
import { RootState, useAppSelector } from 'cache/store';
import { colors } from 'constants/theme';
import { StatsGeneralResponse } from 'models/statisticModel';
import gameListInterface from '../stats-general.interface';

interface StastTable {
    gamelst: any;
    rebateList: any;
    newlist: any;
    miniGame: any;
}

interface Props {
    newReg: string | null;
    statsTableData: StastTable;
    statsData: StatsGeneralResponse;
}

function InfoStatsGeneral(props: Props) {
    const { newReg, statsTableData, statsData } = props;
    const { agentType } = useAppSelector((state: RootState) => state.auth);

    const calcWithdraw = () => {
        let wd = statsTableData.newlist.wd;
        if (statsTableData.newlist.adjmoney) {
            wd += statsTableData.newlist.adjmoney;
        }

        if (wd === undefined) {
            return '';
        }
        return new Intl.NumberFormat().format(wd);
    };

    const calcBetWin = (group: 'xosotruyenthong' | 'xosonhanh' | 'dangian' | 'minigame', isWin?: boolean) => {
        if (Object.keys(statsTableData.newlist).length > 0) {
            const gameSelect = gameListInterface.find((game) => game.id === group);
            if (gameSelect) {
                let totalGame = 0;
                gameSelect.gameGroup.forEach((item) => {
                    if (!item.key.includes('total')) {
                        if (group === 'minigame') {
                            totalGame += statsTableData.miniGame[!isWin ? item.key : `${item.key}win`];
                        } else {
                            totalGame += statsTableData.newlist[!isWin ? item.key : `${item.key}win`];
                        }
                    }
                });
                return new Intl.NumberFormat().format(totalGame);
            }
        } else {
            return '';
        }
    };

    const calcTotalBetWin = (type: 'bet' | 'win') => {
        const { gamelst, newlist, miniGame } = statsTableData;
        if (Object.keys(gamelst).length > 0 && Object.keys(newlist).length > 0 && Object.keys(miniGame).length > 0) {
            if (type === 'bet') {
                return new Intl.NumberFormat().format(newlist.tz + gamelst.total3rdpartygamebet + miniGame.tz);
            } else {
                return new Intl.NumberFormat().format(newlist.win + gamelst.total3rdpartygamewin + miniGame.win);
            }
        } else {
            return '';
        }
    };

    const calcTotalAllGame = () => {
        const { gamelst, newlist, miniGame } = statsTableData;
        let classString = 'text-success';
        if (Object.keys(gamelst).length > 0 && Object.keys(newlist).length > 0 && Object.keys(miniGame).length > 0) {
            const totalAllGame = newlist.win + newlist.fd + newlist.bonus + newlist.prothird + miniGame.win + gamelst.total3rdpartygamewin - newlist.tz - gamelst.total3rdpartygamebet - miniGame.tz;
            if (totalAllGame < 0) classString = 'text-error';
            return (
                <span className={classString} style={{ fontWeight: '500' }}>
                    {new Intl.NumberFormat().format(totalAllGame)}
                </span>
            );
        } else {
            return '';
        }
    };
    return (
        <div className={styles.wrap}>
            {agentType !== 2 && (
                <>
                    <div className={styles.group100}>
                        <div className={styles.group50}>
                            <p>Tổng số thành viên</p>
                            <p>{newReg}</p>
                        </div>
                        <div className={styles.group50}></div>
                    </div>
                    <div className={styles.group100}>
                        <div className={styles.group50}>
                            <p>Nạp tiền</p>
                            <p>{statsTableData.newlist.dp === undefined ? '' : new Intl.NumberFormat().format(statsTableData.newlist.dp)}</p>
                        </div>
                        <div className={styles.group50}>
                            <p>Rút tiền</p>
                            <p>{calcWithdraw()}</p>
                        </div>
                    </div>
                </>
            )}
            {agentType === 1 && (
                <>
                    <div className={styles.group100}>
                        <div className={styles.group50}>
                            <p>Nạp tiền cấp dưới</p>
                            <p>{statsTableData.newlist.dpxj === undefined ? '' : new Intl.NumberFormat().format(statsTableData.newlist.dpxj)}</p>
                        </div>
                        <div className={styles.group50}>
                            <p>Rút tiền cấp dưới</p>
                            <p>{statsTableData.newlist.wdchild === undefined ? '' : new Intl.NumberFormat().format(statsTableData.newlist.wdchild)}</p>
                        </div>
                    </div>
                </>
            )}
            {agentType === 2 && (
                <>
                    <div className={styles.group100}>
                        <div className={styles.group50}>
                            <p>Nạp tiền từ cấp trên</p>
                            <p>{statsTableData.newlist.dpxj === undefined ? '' : new Intl.NumberFormat().format(statsTableData.newlist.dpxj)}</p>
                        </div>
                        <div className={styles.group50}>
                            <p>Rút tiền từ cấp trên</p>
                            <p>{statsTableData.newlist.wdchild === undefined ? '' : new Intl.NumberFormat().format(statsTableData.newlist.wdchild)}</p>
                        </div>
                    </div>
                </>
            )}
            <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Cược xổ số truyền thống</p>
                    <p>{calcBetWin('xosotruyenthong')}</p>
                </div>
                <div className={styles.group50}>
                    <p>Trúng xổ số truyền thống</p>
                    <p>{calcBetWin('xosotruyenthong', true)}</p>
                </div>
            </div>
            <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Cược xổ số nhanh</p>
                    <p>{calcBetWin('xosonhanh')}</p>
                </div>
                <div className={styles.group50}>
                    <p>Trúng xổ số nhanh</p>
                    <p>{calcBetWin('xosonhanh', true)}</p>
                </div>
            </div>
            {/* <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Cược game Dân gian</p>
                    <p>{calcBetWin('dangian')}</p>
                </div>
                <div className={styles.group50}>
                    <p>Trúng game Dân gian</p>
                    <p>{calcBetWin('dangian', true)}</p>
                </div>
            </div>
            <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Cược Mini game</p>
                    <p>{calcBetWin('minigame')}</p>
                </div>
                <div className={styles.group50}>
                    <p>Trúng Mini game</p>
                    <p>{calcBetWin('minigame', true)}</p>
                </div>
            </div> */}
            <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Tổng đặt cược</p>
                    <p>{calcTotalBetWin('bet')}</p>
                </div>
                <div className={styles.group50}>
                    <p>Tổng trúng</p>
                    <p>{calcTotalBetWin('win')}</p>
                </div>
            </div>
            <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Chiết khấu</p>
                    <p>{statsTableData.newlist.fd === undefined ? '' : new Intl.NumberFormat().format(statsTableData.newlist.fd)}</p>
                </div>
                <div className={styles.group50}>
                    <p>Khuyến mãi</p>
                    <p>
                        {statsTableData.newlist.bonus === undefined && statsTableData.newlist.prothird === undefined
                            ? ''
                            : new Intl.NumberFormat().format(statsTableData.newlist.bonus + statsTableData.newlist.prothird)}
                    </p>
                </div>
            </div>
            <div className={styles.group100}>
                <div className={styles.group50}>
                    <p>Lợi nhuận ròng</p>
                    <p>{calcTotalAllGame()}</p>
                </div>
                <div className={styles.group50}>
                    <span className="text-error" style={{ fontSize: '14px' }}>
                        Lợi nhuận ròng = Trúng + Chiết khấu + Khuyến mãi - Cược.
                    </span>
                </div>
            </div>
        </div>
    );
}

const styles = {
    wrap: css`
        border: 1px solid ${colors.blue};
        border-top: none;
    `,
    group50: css`
        display: flex;
        flex: 0 0 50%;
        padding: 8px;
        border-left: 1px solid ${colors.borderTable};
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
        p {
            flex: 0 0 50%;
        }
    `,
    group100: css`
        display: flex;
        flex: 0 0 100%;
        border-bottom: 1px solid ${colors.borderTable};
    `,
};

export default InfoStatsGeneral;
