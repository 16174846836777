import { createSlice } from '@reduxjs/toolkit';
import { InfoAppModel, MessageAlertType } from 'models/commonModel';

interface StateProps {
    triggerFastLottery: boolean;
    alertMsg: {
        message: string | null;
        type: MessageAlertType;
    };
    infoApp: InfoAppModel;
}
export const configSlice = createSlice({
    name: 'config',
    initialState: {
        triggerFastLottery: false,
        alertMsg: {
            message: null,
            type: 'error',
        },
        infoApp: {} as InfoAppModel,
    } as StateProps,
    reducers: {
        toggleFastLotteryHome(state, action) {
            state.triggerFastLottery = action.payload;
        },
        toggleMessage(state, action) {
            state.alertMsg = action.payload;
        },
        cacheInfoApp(state, action) {
            state.infoApp = action.payload;
        },
    },
});

const { actions, reducer } = configSlice;

export const { toggleFastLotteryHome, toggleMessage, cacheInfoApp } = actions;
export default reducer;
