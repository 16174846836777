import { css } from '@emotion/css';
import { colors } from 'constants/theme';

interface Props {
    bg?: string;
    text: string;
    textSize?: string;
    textColor?: string;
    borderColor?: string;
    minWidth?: string;
    rounded?: boolean;
    bgHover?: string;
    weight?: string;
    className?: string;
    typeButton?: 'button' | 'submit' | 'reset' | undefined;
    width?: string;
    click?: () => void;
}

function Button(props: Props) {
    const { bg, click, text, textColor, borderColor, minWidth, rounded, bgHover, textSize, weight, typeButton, className, width } = props;
    const styles = css`
        text-align: center;
        font-size: ${textSize || ''};
        padding: 8px 16px;
        cursor: pointer;
        background: ${bg || colors.primary};
        color: ${textColor || colors.white};
        border: 1px solid ${borderColor};
        min-width: ${minWidth || 'unset'};
        border-radius: ${rounded ? '200px' : '8px'};
        transition: 0.25s;
        font-weight: ${weight || ''};

        &:hover {
            background: ${bgHover || bg || ''};
            border-color: ${bgHover || bg || 'inherit'};
        }
    `;

    const clickButton = () => {
        if (click) click();
    };

    return (
        <button type={typeButton || 'button'} className={`${styles} ${className || ''}`} style={{ width: width || '' }} onClick={clickButton}>
            {text}
        </button>
    );
}

export default Button;
