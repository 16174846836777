import commonApi from 'apis/commonApi';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import ToastRoot from 'components/ToastAlert';
import MessageAlertCustom from 'components/alert-message/MessageAlertCustom';
import SupportArea from 'components/support-area/SupportArea';
import vi from 'date-fns/locale/vi';
import { cacheInfoApp } from 'features/configSlice';
import MainLayout from 'layouts/MainLayout';
import Register from 'pages/reg/Register';
import { useEffect, useRef, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from 'routes/AuthRoute';
import { mainRoutes } from 'routes/routes';
import styles from './app.style';
import { isMobile } from 'react-device-detect';
import MiniGameIcon from 'components/minigame/MiniGameIcon';
import miniGameApi from 'apis/miniGameApi';
import toast from 'react-hot-toast';
import LoadingIcon from 'components/loading/LoadingIcon';
import { MiniGamePath, MiniGameType } from 'models/miniGameModel';
import alertMsg from 'components/alert-message/alert-message';
import actionMiniGame from 'utils/actionMinigame';
import Modal from 'components/modal/Modal';
import BoxTransferMiniGame from 'components/transfer/BoxTransferMiniGame';
import { Helmet } from 'react-helmet';
registerLocale('vi', vi);
const isLocal = process.env.REACT_APP_ENV_LOCAL;
function App() {
    const rootDivRef = useRef<HTMLDivElement>(null);
    const miniGameIframeRef = useRef<HTMLIFrameElement>(null);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const { isLogged } = useAppSelector((state: RootState) => state.auth);
    const [urlMiniGame, setUrlMiniGame] = useState('');
    const [showBoxTransfer, setShowBoxTransfer] = useState(false);

    useEffect(() => {
        /**
         * redirect if a mobile or tablet device
         */
        const redirectToWap = () => {
            if (isMobile) {
                // check src exist
                // const queryString = window.location.search;
                // const urlParams = new URLSearchParams(queryString);
                // const idFather = urlParams.get('id');

                // if (window.location.pathname.includes('/reg')) {
                //     const regId = window.location.href.split('/reg')[1];
                //     window.location.replace(`${window.location.protocol}//m.${window.location.host}/reg${regId}`);
                // } else if (idFather) {
                //     window.location.replace(`${window.location.protocol}//m.${window.location.host}/tabs/home?id=${idFather}`);
                // } else {
                // }
                window.location.replace(`${window.location.protocol}//m.${window.location.host}`);
            }
        };
        redirectToWap();
    }, []);

    useEffect(() => {
        //listener event when click button close minigame
        window.addEventListener('message', (event) => {
            // IMPORTANT: check the origin of the data!
            if (event.data.action === actionMiniGame.CLOSE_GAME) {
                setUrlMiniGame('');
            }

            if (event.data.action === actionMiniGame.OPEN_TRANSFER) {
                console.log(event.data);
                setShowBoxTransfer(true);
            }
        });
    }, []);

    useEffect(() => {
        const getInfoApp = async () => {
            try {
                const { data } = await commonApi.getVersionApp({});
                if (!data.msg) {
                    dispatch(cacheInfoApp(data));
                }
            } catch (error) {
                console.log(error);
            }
        };
        getInfoApp();
    }, []);

    const launchMiniGame = async (type: MiniGamePath, gameType: MiniGameType, cb: () => void) => {
        if (!isLogged) {
            alertMsg('Vui lòng đăng nhập');

            return;
        }
        setLoading(true);
        try {
            const { data } = await miniGameApi.launchGame({ gameType });
            if (!data.msg) {
                let url = `${process.env.REACT_APP_MINIGAME_URL}/${type}/?encrypted=${data.encrypted}`;
                // if (isLocal) {
                // } else {
                //     const origin = window.location.origin.replace('https://', 'https://albgame.');
                //     url = `${origin}/${type}/?encrypted=${data.encrypted}`;
                // }
                setUrlMiniGame(url);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            cb && cb();
        }
    };

    const handleTransferSucess = () => {
        if (miniGameIframeRef.current) {
            miniGameIframeRef.current.contentWindow?.postMessage({ action: actionMiniGame.LOAD_BALANCE }, '*');
        }
    };

    return (
        <>
            <div className={styles.root} ref={rootDivRef} id="appRoot">
                <Routes>
                    {/* main routes */}
                    {mainRoutes.map((route, index) => {
                        const Page = route.page;
                        return <Route key={index} path={route.path} element={<MainLayout>{route.isPrivate ? <PrivateRoute component={route.page} /> : <Page />}</MainLayout>} />;
                    })}
                    <Route path="/reg/:id" element={<Register />} />
                    <Route path="/reg" element={<Register />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>

                <MiniGameIcon onLaunchGame={launchMiniGame} />
                {/* support block */}
                <SupportArea ref={rootDivRef} />

                <div className={`${styles.miniGameIframe} ${urlMiniGame ? 'show' : ''}`}>{urlMiniGame && <iframe ref={miniGameIframeRef} src={urlMiniGame} title="minigame"></iframe>}</div>

                {/* popup chuyển quỹ nhanh */}
                <Modal isShow={showBoxTransfer} onClose={() => setShowBoxTransfer(false)} width="520px">
                    {showBoxTransfer ? <BoxTransferMiniGame onClose={() => setShowBoxTransfer(false)} onSuccess={handleTransferSucess} /> : <></>}
                </Modal>
            </div>
            {/* <Helmet>
                <title>My Title</title>
                <meta name="description" content="Helmet application" />
            </Helmet> */}
            <ToastRoot />
            <MessageAlertCustom />
            {loading && <LoadingIcon />}
        </>
    );
}

export default App;
