import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const accountHeaderStyles = {
    groupAction: css`
        position: absolute;
        top: calc(100% + 3px);
        left: -160px;
        z-index: 3;
        border-radius: 16px;
        box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        transition: 0.25s;
        transform: translateY(-1px);
    `,
    groupActionInner: css`
        background: ${colors.white};
        padding: 20px 24px;
        display: flex;
        gap: 12px;
    `,
    groupActionCol: css`
        width: 115px;

        h6 {
            font-size: 14px;
            margin-bottom: 12px;
        }
    `,
    groupActionList: css`
        display: flex;
        flex-direction: column;
        gap: 12px;
    `,
    accountActionItem: css`
        padding: 6px 2px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        transition: 0.25s;
        img {
            width: 20px;
        }
        p {
            font-size: 12px;
        }
        &:hover {
            background: #e9f7f3;
            border-color: ${colors.darkPrimary};
        }
    `,
};
export default accountHeaderStyles;
