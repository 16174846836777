import { css } from '@emotion/css';
import Button from 'components/button/Button';
import { animationShowHide, glassEffect } from 'constants/global.style';
import { colors } from 'constants/theme';
import Lottie from 'lottie-react';
import { Game3rdMenuItem } from 'models/commonModel';
import iconFire from 'assets/lottie/fire.json';
import React, { useEffect, useState } from 'react';
import LiveLabel from 'components/live-label/LiveLabel';
import moment from 'moment';
import imageDefault from 'assets/images/promo-default.png';

const cdnUrl = process.env.REACT_APP_CDN_URL;
let idInterval: any;
interface Props {
    gameList: Game3rdMenuItem[];
    onSelect: (game: Game3rdMenuItem) => void;
    gametype: string;
}

function LiveCasinoSection(props: Props) {
    const { gameList, onSelect, gametype } = props;
    const [showLive, setShowLive] = useState(false);
    useEffect(() => {
        idInterval = setInterval(() => {
            const date = moment(new Date()).format('DD/MM/yyyy HH:mm:ss');
            const day = moment(new Date()).format('DD/MM/yyyy');

            if (date > `${day} 15:00:00` && date < `${day} 23:59:59`) {
                setShowLive(true);
            } else {
                idInterval && clearInterval(idInterval);
                setShowLive(false);
            }
        }, 5000);

        return () => {
            idInterval && clearInterval(idInterval);
        };
    }, []);

    return (
        <div className={styles.gameWrap}>
            <div className={styles.gameRight}>
                {gameList.map((game, index) => (
                    <React.Fragment key={game.providercode}>
                        {game.providercode === 'TX' || game.providercode === 'XD' ? (
                            <div className={`${styles.gameItem} ${styles.ownGame}`} key={game.providercode}>
                                <img className="bg-game" src={`${cdnUrl}/image/game/web/background/${gametype}/${game.providercode}.png` || imageDefault} alt="" />
                                <div className="game-content">
                                    <div className={styles.logoOwnGame}>
                                        <img src={`${cdnUrl}/image/game/web/logo/${gametype}/${game.providercode}.png`} alt="" />
                                        <div className={styles.iconHot}>
                                            <Lottie className="icon" animationData={iconFire} loop={true} />
                                        </div>
                                    </div>
                                    <p className="game-game">{game.name}</p>
                                    <div className="btn-play">
                                        {game.providercode === 'XD' && (
                                            <>
                                                <Button text="CHƠI NGAY" bg="none" borderColor={colors.orange} bgHover={colors.orange} rounded click={() => onSelect(game)} />
                                                {showLive && <LiveLabel />}
                                            </>
                                        )}
                                        {game.providercode === 'TX' && <Button text="COMMING SOON" bg="none" borderColor={colors.orange} bgHover={colors.orange} rounded />}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`${styles.gameItem} ${styles.liveGameItem}`} key={game.providercode}>
                                <img className="bg-game" src={`${cdnUrl}/image/game/web/background/${gametype}/${index}.png` || imageDefault} alt="" />
                                <img className="logo-game" src={`${cdnUrl}/image/game/web/logo/${gametype}/${game.providercode}.png`} alt="" />
                                <div className={styles.btnPlayGame}>
                                    <Button text="CHƠI NGAY" bg="none" click={() => onSelect(game)} borderColor={colors.orange} rounded bgHover={colors.orange} textSize="12px" />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

const styles = {
    gameWrap: css`
        display: flex;
        gap: 28px;
    `,
    gameRight: css`
        display: flex;
        flex-wrap: wrap;
        row-gap: 12px;
        margin: 0 -12px;
        width: 100%;
    `,
    liveGameItem: css`
        flex: 0 0 25%;
        max-width: 25%;
        padding: 12px;
        padding-bottom: 0;
        .bg-game {
            width: 100%;
        }
    `,
    ownGame: css`
        flex: 0 0 50%;
        width: 50%;
        padding: 4px 12px;
        .bg-game {
            width: 100%;
        }
        .game-content {
            position: absolute;
            top: 32px;
            left: 12px;
            height: calc(100% - 48px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 16px;
            > img {
                width: 170px;
            }
        }
        .game-game {
            font-size: 28px;
            font-weight: 600;
            color: ${colors.white};
            margin-top: 8px;
        }
        .btn-play {
            margin-top: 40px;
            display: flex;
            align-items: center;
            gap: 16px;
        }
        &.active {
            .bg-game {
                animation: ${animationShowHide} 0.8s linear infinite;
            }
        }
    `,
    gameItem: css`
        position: relative;
        height: fit-content;
        transition: border-radius 650ms ease-in-out;
        overflow: hidden;
        .logo-game {
            position: absolute;
            left: 28px;
            top: 40px;
            width: 100px;
        }
        &::before {
            position: absolute;
            content: '';
            width: 130%;
            height: calc(100% - 15px);
            top: 15px;
            left: -10%;
            background: linear-gradient(120deg, transparent 0%, transparent 20%, rgba(255, 255, 255, 0.2) 21%, rgba(255, 255, 255, 0.5) 35%, transparent 36%);
            background-size: 160%;
            background-position: 120%;
            /* opacity: 0; */
            pointer-events: none;
            border-radius: 12px;
        }
        &:hover {
            &::before {
                cursor: pointer;
                animation: ${glassEffect} 650ms ease-in-out;
            }
        }
    `,
    btnPlayGame: css`
        position: absolute;
        left: 28px;
        bottom: 26px;
    `,
    logoOwnGame: css`
        display: flex;
        align-items: flex-end;
        gap: 12px;
        > img {
            width: 170px;
        }
    `,
    iconHot: css`
        width: 30px;
    `,
    labelHot: css`
        position: absolute;
    `,
};

export default LiveCasinoSection;
