import { css } from '@emotion/css';
import { RootState, useAppSelector } from 'cache/store';
import alertMsg from 'components/alert-message/alert-message';
import { lotteryHeaderMenu } from 'constants/common';
import { msgAuth } from 'constants/messageAlert';
import { colors } from 'constants/theme';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const dayWeekList = [
    { value: 1, label: 'THỨ 2' },
    { value: 2, label: 'THỨ 3' },
    { value: 3, label: 'THỨ 4' },
    { value: 4, label: 'THỨ 5' },
    { value: 5, label: 'THỨ 6' },
    { value: 6, label: 'THỨ 7' },
    { value: 0, label: 'CHỦ NHẬT' },
];

interface Props {
    onCloseMenu: (menuIndex: number, isHover: boolean) => void;
    menuIndex: number;
}

function HeaderMenuLottery(props: Props) {
    const { onCloseMenu, menuIndex } = props;
    const navigate = useNavigate();
    const { isLogged } = useAppSelector((state: RootState) => state.auth);
    const [currentDayMn, setCurrentDayMn] = useState(new Date().getDay());
    const [currentDayMt, setCurrentDayMt] = useState(new Date().getDay());

    const openGame = (ltId: number) => {
        if (!isLogged) {
            alertMsg(msgAuth.login);
            return;
        }
        onCloseMenu(menuIndex, false);
        navigate(`/lottery?ltId=${ltId}`);
    };

    return (
        <>
            {lotteryHeaderMenu.map((group, index) => (
                <div className={styles.wrapLottery} key={index}>
                    <div className={styles.heading}>
                        <h6 className={styles.title}>{group.title}</h6>
                        {group.isFilterDate && (
                            <>
                                {group.type === 'MN' && (
                                    <select value={currentDayMn} onChange={(e) => setCurrentDayMn(Number(e.target.value))}>
                                        {dayWeekList.map((day) => (
                                            <option value={day.value} key={day.value}>
                                                {day.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {group.type === 'MT' && (
                                    <select value={currentDayMt} onChange={(e) => setCurrentDayMt(Number(e.target.value))}>
                                        {dayWeekList.map((day) => (
                                            <option value={day.value} key={day.value}>
                                                {day.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </>
                        )}
                    </div>

                    {group.type === 'MN' && <LotteryGroup onSelectGame={openGame} listGame={group.child} currentDay={currentDayMn} />}
                    {group.type === 'MT' && <LotteryGroup onSelectGame={openGame} listGame={group.child} currentDay={currentDayMt} />}
                    {group.type !== 'MN' && group.type !== 'MT' && (
                        <ul className={styles.listGame}>
                            {group.child.map((item) => (
                                <li className={styles.gameItem} key={item.ltid} onClick={() => openGame(item.ltid)}>
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </>
    );
}

interface LotteryGroupProps {
    listGame: {
        label: string;
        ltid: number;
        openDay?: number;
    }[];
    currentDay: number;
    onSelectGame: (ltId: number) => void;
}

function LotteryGroup(props: LotteryGroupProps) {
    const { listGame, currentDay, onSelectGame } = props;
    return (
        <ul className={styles.listGame}>
            {listGame.map(
                (item) =>
                    currentDay === item.openDay && (
                        <li className={styles.gameItem} key={item.ltid} onClick={() => onSelectGame(item.ltid)}>
                            {item.label}
                        </li>
                    )
            )}
        </ul>
    );
}

const styles = {
    wrapLottery: css`
        flex: 1;
        color: ${colors.white};
        padding: 16px 0;
        &:nth-child(4),
        &:nth-child(5) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    `,
    heading: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        min-height: 28px;
        select {
            border: 1px solid ${colors.primary};
            border-radius: 8px;
            background: ${colors.bgTitle};
            font-size: 14px;
            padding: 4px;
        }
    `,
    title: css`
        text-transform: uppercase;
        color: ${colors.yellow};
    `,
    listGame: css`
        padding-left: 16px;
        list-style-type: disc;
    `,
    gameItem: css`
        margin-bottom: 8px;
        &:hover {
            color: #fecd2f;
        }
        &:last-child {
            margin-bottom: 0;
        }
    `,
};

export default HeaderMenuLottery;
