import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const checkboxStyles = {
    wrap: css`
        display: flex;
        align-items: center;
        cursor: pointer;
        input {
            display: none;
        }
        p {
            font-size: 14px;
            font-weight: 300;
            a {
                color: ${colors.yellow};
                font-weight: 400;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    `,
    dotChecked: css`
        background: ${colors.primary};
        width: 100%;
        height: 100%;
        border-radius: 24px;
        opacity: 0;
        transform: scale(0.7);
        transition: 0.25s;
    `,
    rounded: css`
        width: 24px;
        flex: 0 0 24px;
        height: 24px;
        padding: 3px;
        border-radius: 24px;
        border: 2px solid ${colors.gray};
        margin-right: 8px;
        &.active {
            border-color: ${colors.primary};
            > div {
                opacity: 1;
                transform: scale(1);
            }
        }
    `,
};
export default checkboxStyles;
