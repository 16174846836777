import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const footerStyles = {
    wrap: css`
        background: #022222;
    `,
    heading: css`
        text-align: center;
        color: #8b95b3;
        font-weight: 500;
        padding: 24px;
    `,
    partnerList: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    `,
    partnerItem: css`
        flex: 0 0 calc(100% / 9);
        max-width: calc(100% / 9);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            object-fit: contain;
        }
    `,
    community: css`
        padding-top: 8px;
        padding-bottom: 28px;
        border-top: 1px solid #1f3a43;
        border-bottom: 1px solid #1f3a43;
    `,
    titleComunity: css`
        padding: 16px 0;
    `,
    communityList: css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
    `,
    communityItem: css`
        background: #023131;
        border-radius: 4px;
        padding: 8px 12px;
        color: ${colors.white};
        cursor: pointer;
        img {
            width: 24px;
            margin-right: 16px;
        }
        p {
            font-size: 10px;
            color: #8b95b3;
        }
        h6 {
            font-size: 14px;
            font-weight: 500;
            margin-top: 4px;
        }
    `,
    cer: css`
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #1f3a43;
    `,
    cerDes: css`
        color: #8b95b3;
        font-size: 14px;
        width: 70%;
        margin: 0 auto;
        text-align: center;
        font-weight: 300;
        padding: 10px 0 20px;
    `,
    lisence: css`
        text-align: center;
        color: #8b95b3;
        background: #021818;
        padding: 12px 0;
        font-size: 14px;
    `,
};
export default footerStyles;
