import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { convertSecondToTime } from 'utils/time-utils';

interface Props {
    opentime: string;
    endtime: string;
    servertime: string;
    onFinish?: () => void;
    styleProps?: any;
}
function TimeCountDown(props: Props) {
    const { opentime, endtime, servertime, onFinish, styleProps } = props;
    const [timeCount, setTimeCount] = useState('00:00:00');
    const idInterValRef = useRef<any>(null);
    useEffect(() => {
        if (!endtime || !endtime) {
            return;
        }
        let pourTime: number = (+new Date(endtime.replace(/-/g, '/')) - +new Date(servertime.replace(/-/g, '/'))) / 1000;
        idInterValRef.current = setInterval(() => {
            pourTime--;
            const timeFormat = convertSecondToTime(pourTime);
            if (pourTime <= 0) {
                if (onFinish) onFinish();

                setTimeCount('00:00:00');
                clearInterval(idInterValRef.current);
                return;
            }
            setTimeCount(timeFormat);
        }, 1000);
        return () => {
            clearInterval(idInterValRef.current);
        };
    }, [endtime, servertime]);
    return <span style={styleProps}>{timeCount}</span>;
}

export default TimeCountDown;
