import { css, keyframes } from '@emotion/css';
import { colors } from './theme';

const globalStyle = {
    errorInput: css`
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 100%;
        small {
            font-size: 12px;
            color: ${colors.red};
        }
    `,
    togglePass: css`
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        img {
            width: 18px;
        }
    `,
    flexWidth: (width: number) => {
        return css`
            flex: 0 0 ${width}px !important;
        `;
    },
    flexPercent: (percent: number) => {
        return css`
            flex: 0 0 ${percent}% !important;
        `;
    },
    flexItems: (items: number) => {
        return css`
            flex: 0 0 calc(100% / ${items}) !important;
            max-width: calc(100% / ${items}) !important;
        `;
    },

    textGradient: (deg: string, color1: string, color2: string) => {
        return css`
            background: linear-gradient(${deg}, ${color1} 0%, ${color2} 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        `;
    },

    // modal
    bgPopup: (bgColor?: string) => {
        return css`
            border: 1px solid ${colors.darkPrimary};
            background: ${bgColor || colors['gradient-popup']};
            padding: 20px 16px;
            border-radius: 8px;
            backdrop-filter: blur(8px);
            color: ${colors.white};
            height: 100%;
            position: relative;
            .title {
                text-align: center;
                font-size: 18px;
                padding-bottom: 16px;
                width: 85%;
                margin: 0 auto;
            }
            .sub-title {
                font-size: 14px;
                margin: 20px 0;
                text-align: center;
            }
            .group-action {
                display: flex;
                justify-content: center;
                gap: 24px;
                margin-top: 30px;
                button {
                    padding: 10px 0;
                    border-radius: 8px;
                    flex: 0 0 calc(50% - 12px);
                    max-width: calc(50% - 12px);
                }
            }
            .btn-close {
                position: absolute;
                top: 16px;
                right: 16px;
                cursor: pointer;
            }
        `;
    },
    btnCopy: css`
        display: flex;
        width: 24px !important;
        padding: 4px;
        background: ${colors.primary};
        border-radius: 4px;
        cursor: pointer;

        img {
            width: 100%;
        }
    `,

    btnPrimary: css`
        padding: 14px 0;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        background: ${colors.darkPrimary};
        color: ${colors.white};
        width: 60%;
        margin: 0 auto;
        &:hover {
            background: ${colors.blue};
        }
        &:disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    `,
    btnPrimaryOutline: css`
        border-radius: 8px;
        background: none;
        border: 1px solid ${colors.darkPrimary};
        padding: 14px 0;
        font-weight: 500;
        cursor: pointer;
        width: 100%;
        color: ${colors.darkPrimary};

        /* &:hover {
            background: ${colors.primary};
        } */
    `,

    btnSecondary: css`
        padding: 14px 0;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        background: ${colors.secondary};
        color: ${colors.blackText};
        width: 100%;
        cursor: pointer;
        &:hover {
            background: #ffc107;
        }
        &:disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    `,

    btnError: css`
        padding: 14px 0;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        background: ${colors.red};
        color: ${colors.white};
        width: 100%;
        cursor: pointer;
        &:hover {
            background: #ff0051;
        }
        &:disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    `,

    inputCommon: css`
        background: rgba(0, 0, 0, 0.5);
        padding: 8px 12px;
        border: 1px solid #7f88c0;
        border-radius: 8px;
        color: ${colors.white};
        width: 100%;
        height: 100%;
        &::placeholder {
            font-size: 14px;
            color: #90949c;
            letter-spacing: 0;
        }
        &:focus {
            outline: none;
        }
    `,
    text: {
        error: css`
            color: ${colors.red};
        `,
        success: css`
            color: ${colors.success};
        `,
        blue: css`
            color: ${colors.blue};
        `,
        yellow: css`
            color: ${colors.yellow};
        `,
    },
    container: css`
        max-width: 1360px;
        margin: 0 auto;
    `,
};

export const rotateAnimation = keyframes`
  0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const zoomInZoomOut = keyframes`
    0%, 100% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
`;

export const coinRefresh = keyframes`
    0% {
        bottom: 0px;
    opacity: 0;
    }
    50% {
    bottom: 25px;
    opacity: 1;
}
100% {
    bottom: 0px;
    opacity: 0;
}
`;

export const textWave = keyframes`
    0%,
    100% {
        scale: 1.1;
        opacity: 1;
    }
    60% {
        scale: 0.8;
        opacity: 0.6;
    }
`;

export const glassEffect = keyframes`
  0%{
    background-position:120%;
    opacity: 1;
  }
  100%{
    background-position:-160%;
    opacity: 0;
  }
`;

export const showHideEffect = keyframes`
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.9);
    }
    100%{
        transform: scale(1);
    }
`;

export const lineAnimationSvg = keyframes`
    from {
    stroke-dashoffset: 0;
    }
  to {
    stroke-dashoffset: 1000;
  }
`;

export const animationShowHide = keyframes`
 0% {
    filter: drop-shadow(0 0 6px #038787);
}
  50% {
    filter: none;
  }
  100% {
    filter: drop-shadow(0 0 6px #038787);
}
`;

export default globalStyle;
