import { css } from '@emotion/css';
import { appName } from 'config/app.config';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { BankDepositModel } from 'models/bankModel';
import toast from 'react-hot-toast';
interface Props {
    depositSelect: BankDepositModel;
    codeTransfer: string;
    minMaxDeposit: { min: number; max: number };
}
function BankDeposit(props: Props) {
    const { depositSelect, codeTransfer, minMaxDeposit } = props;
    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('Sao chép thành công!');
    };
    return (
        <div className={styles.depositContainer}>
            <div className={styles.depositBankLeft}>
                <div>
                    <h6 className={styles.optionTitle}>Chuyển tiền đến tài khoản liên kết với {appName}</h6>
                    <div className={styles.bankInfo}>
                        <div className={styles.bankInfoItem}>
                            <p>Chủ tài khoản</p>
                            <div className={styles.bankInfoValue}>
                                <p>{depositSelect.showbankcard}</p>
                                <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(depositSelect.showbankcard)}>
                                    <img src={require('assets/images/icons/copy.png')} alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={styles.bankInfoItem}>
                            <p>Số tài khoản</p>
                            <div className={styles.bankInfoValue}>
                                <p>{depositSelect.bankcard}</p>
                                <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(depositSelect.bankcard)}>
                                    <img src={require('assets/images/icons/copy.png')} alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={styles.bankInfoItem}>
                            <p>Nội dung chuyển khoản</p>
                            <div className={styles.bankInfoValue}>
                                <p>{codeTransfer}</p>
                                <button type="button" className={`${globalStyle.btnCopy} btn-primary`} onClick={() => copyText(codeTransfer)}>
                                    <img src={require('assets/images/icons/copy.png')} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                    <h6 className={styles.optionTitle}>Hoặc quét mã QR CODE để nạp tiền</h6>
                    <div className={styles.qrDeposit}>
                        <p>Sử dụng ứng dụng ngân hàng thực hiện giao dịch. Vui lòng hướng camera vào QR code để lấy thông tin nạp tiền.</p>
                        {depositSelect.qrurl && (
                            <div className={styles.qrImgWrap}>
                                <img className={styles.qrImg} src={depositSelect.qrurl} alt="" />
                                <img className={styles.qrLogo} src={require('assets/images/logos/qr.png')} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.depositBankRight}>
                <div className={styles.rightSection}>
                    <h6>Hướng Dẫn</h6>
                    <ol>
                        <li>Quý khách vui lòng nạp tiền vào tài khoản trên đây.</li>
                        <li>
                            Tối thiểu 1 giao dịch <span>{new Intl.NumberFormat().format(minMaxDeposit.min)} VND</span>, Tối đa 1 giao dịch{' '}
                            <span>{new Intl.NumberFormat().format(minMaxDeposit.max)} VND</span>
                        </li>
                        <li>
                            Mã nạp tiền của quý khách là <span>{codeTransfer}</span>, quý khách vui lòng điền chính xác nội dung chuyển khoản là <span>{codeTransfer}</span> để được cập nhật tiền nạp
                            ngay lập tức.
                        </li>
                    </ol>
                </div>
                <div className={styles.rightSection}>
                    <h6>Lưu Ý</h6>
                    <ul>
                        <li>
                            Thẻ ngân hàng của chúng tôi sẽ thường xuyên thay đổi hoặc tạm ngừng dịch vụ bất cứ lúc nào, quý khách vui lòng kiểm tra tài khoản ngân hàng mới nhất trên trang trước mỗi
                            lần nạp tiền.
                        </li>
                        <li>Không thể chuyển tiền qua số tài khoản qua hình ảnh chụp lại hoặc lịch sử lưu người thụ hưởng.</li>
                        <li>Nếu như quý khách không thực hiện nạp tiền theo quy trình nạp tiền vào tài khoản ngân hàng mới nhất trên trang, chúng tôi sẽ hoàn toàn không chịu khách nhiệm xử lý.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const styles = {
    depositContainer: css`
        display: flex;
        margin-top: 8px;
        gap: 40px;
    `,
    depositBankLeft: css`
        flex: 0 0 400px;
    `,
    depositBankRight: css`
        padding: 16px;
        flex: 1;
        ol,
        ul {
            padding-left: 16px;
            font-size: 14px;
            li {
                line-height: 1.4;
                margin-bottom: 4px;
            }
            span {
                color: ${colors.yellow};
            }
        }
    `,
    rightSection: css`
        margin-bottom: 24px;
        h6 {
            font-weight: 400;
        }
    `,
    optionTitle: css`
        margin-bottom: 4px;
        font-weight: 400;
    `,
    bankInfo: css`
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: ${colors.drak50};
        padding: 12px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
    `,
    bankInfoItem: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    bankInfoValue: css`
        display: flex;
        align-items: center;
        gap: 8px;
    `,
    qrDeposit: css`
        background: ${colors.drak50};
        padding: 12px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
        display: flex;
        align-items: center;
        gap: 12px;
        p {
            font-size: 14px;
            color: #ccc;
        }
    `,
    textQrCode: css``,
    qrImgWrap: css`
        flex: 0 0 120px;
        height: 120px;
        position: relative;
    `,
    qrImg: css`
        width: 100%;
    `,
    qrLogo: css`
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;

        transform: translate(-50%, -50%);
    `,
};

export default BankDeposit;
