import { StatsGeneralCol } from 'models/statisticModel';
import React from 'react';
import styles from './stats-general-table.style';

interface Props {
    tableData: StatsGeneralCol[];
    checkDrawBorder: (item: StatsGeneralCol, index: number) => void;
    gameList: any;
}
function Stats3rdGameTalbe(props: Props) {
    const { tableData, checkDrawBorder, gameList } = props;

    const convertStastGame = (key: string, id: string) => {
        const keyGame3rd = `${key.toLowerCase()}${id.toLowerCase()}`;
        return `${formatNumberSeperate(gameList[keyGame3rd]) || 0} / ${formatNumberSeperate(gameList[`${keyGame3rd}win`]) || 0}`;
    };

    const getProfit3rdGame = (key: string, id: string) => {
        if (key === 'S6') key = 'S2';
        const keyGame3rd = `${key.toLowerCase()}${id.toLowerCase()}`;
        return formatNumberSeperate(gameList[`${keyGame3rd}win`] - gameList[keyGame3rd], true) || 0;
    };

    const formatNumberSeperate = (num: number | string, hightlight?: boolean) => {
        if (num || Number(num) === 0) {
            let classString = '';
            if (hightlight) {
                if (Number(num) < 0) {
                    classString = 'text-error';
                } else {
                    classString = 'text-success';
                }
                return <span className={classString}>{new Intl.NumberFormat().format(Number(num))}</span>;
            } else {
                return new Intl.NumberFormat().format(Number(num));
            }
        }
        return null;
    };
    const convertStatsTotal = (key: string, isProfit?: boolean) => {
        const keyGame = key.toLowerCase();
        if (isProfit) {
            return formatNumberSeperate(gameList[`${keyGame}win`] - gameList[keyGame], true) || 0;
        } else {
            return `${formatNumberSeperate(gameList[keyGame])} / ${formatNumberSeperate(gameList[`${keyGame}win`])}`;
        }
    };
    return (
        <>
            {tableData.map((item, index) => (
                <React.Fragment key={item.key}>
                    {!item.key.includes('total') ? (
                        <div className={`${styles.group50} ${checkDrawBorder(item, index)}`}>
                            <div className={`${styles.col} ${styles.flex25}`}>{item.label}</div>
                            <div className={`${styles.col} ${styles.flex50} text-center`}>{convertStastGame(item.key, item.id)}</div>
                            <div className={`${styles.col} ${styles.flex25} text-center`}>{getProfit3rdGame(item.key, item.id)}</div>
                        </div>
                    ) : (
                        <div className={styles.group100}>
                            <div className={styles.group50}>
                                <div className={`${styles.col} ${styles.flex25}`}>{item.label}</div>
                                <div className={`${styles.col} ${styles.flex50} text-center`}>{convertStatsTotal(item.key)}</div>
                                <div className={`${styles.col} ${styles.flex25} text-center`}>{convertStatsTotal(item.key, true)} </div>
                            </div>
                            <div className={styles.flex50}></div>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

export default Stats3rdGameTalbe;
