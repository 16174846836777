import accountBg from 'assets/images/bg/account.jpg';
import { colors } from 'constants/theme';
import { css } from '@emotion/css';

const helpPageStyles = {
    wrap: css`
        background: url(${accountBg}) no-repeat;
        background-size: cover;
        height: 100%;
        min-height: 70vh;
        padding: 24px 0;
    `,
    inner: css`
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        height: 100%;
    `,
    left: css`
        background: ${colors.white};
        padding: 20px 0 30px;
        flex: 0 0 225px;
    `,
    right: css`
        flex: 0 0 calc(100% - 225px);
        max-width: calc(100% - 225px);
        background: #222939;
        background-size: cover;
        padding: 16px;
        color: #e0e0e0;
        max-height: 80vh;
        overflow-y: auto;
        &::-webkit-scrollbar-thumb {
            background: ${colors.secondary};
            opacity: 0.6;
        }
        p {
            line-height: 1.4;
        }
    `,
    viewOption: css`
        padding: 8px 0 8px 24px;
        margin-bottom: 4px;
        cursor: pointer;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background: ${colors.primary};
            border-radius: 4px;
            opacity: 0;
        }

        &.active {
            background: #f0f7ff;
            color: ${colors.primary};
            &::before {
                opacity: 1;
            }
        }
    `,
    textTitle: css`
        color: ${colors.secondary};
        text-transform: uppercase;
    `,
    textLink: css`
        color: ${colors.primary};
    `,
    session: css`
        display: flex;
        flex-direction: column;
        padding-left: 12px;
        gap: 8px;
    `,
};
export default helpPageStyles;
