import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';
import { CreateBankOrder3rdPayload } from 'models/bankModel';
import { WithdrawApplyPayload } from 'models/walletModel';

const walletApi = {
    depositInit(payload: { flag?: string; origin?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'DepositBean';
        payload.origin = window.location.origin;
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    getBankDeposit3rd(bankCode: string): Promise<AxiosResponse<BaseResponse<any>>> {
        let payload: any = {};
        payload['flag'] = `ListBank_${bankCode}`;
        return axiosClient.post(serviceType.PAY_SERVICE, payload);
    },
    createOrderBank3rd(payload: CreateBankOrder3rdPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'CreateOrder';
        return axiosClient.post(serviceType.PAY_SERVICE, payload);
    },

    withdrawal(payload: { flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'WithdrawalBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    withdrawalApply(payload: WithdrawApplyPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'WithdrawalBean_apply';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    createEwalletOrder(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'CreateDepositOrder';
        return axiosClient.post(serviceType.PAY_SERVICE, payload);
    },
    getDetailEwalletOrder(payload: { id?: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'GetDepositDetail';
        return axiosClient.post(serviceType.PAY_SERVICE, payload);
    },
    cancelEwalletDeposit(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'CancelDeposit';
        return axiosClient.post(serviceType.PAY_SERVICE, payload);
    },
    completeEwalletDeposit(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'completeDeposit';
        return axiosClient.post(serviceType.PAY_SERVICE, payload);
    },
};
export default walletApi;
