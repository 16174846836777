import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const marqueeStyles = {
    wrap: css`
        padding: 12px 0;
    `,
    marqueeItem: css`
        margin: 0 40px;
        max-width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        font-size: 13px;
        color: #d3d4d7;
        &:hover {
            color: ${colors.secondary};
        }
    `,
};
export default marqueeStyles;
