import { css } from '@emotion/css';

const miniGameIconStyles = {
    wrapIcon: css`
        position: fixed;
        bottom: 24px;
        left: 24px;
        z-index: 10;
    `,
    iconItem: css`
        position: relative;
        width: 100px;
        cursor: pointer;
    `,
    bgOverlay: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        pointer-events: none;
        &.active {
            opacity: 1;
            pointer-events: initial;
        }
    `,
    wrapGameList: css`
        position: fixed;
        z-index: 12;
        left: -100px;
        bottom: -100px;
        transition: 0.4s;
        transform: scale(0.2);
        pointer-events: none;
        opacity: 0;
        &.show {
            left: calc(50% - 180px);
            bottom: calc(50% - 180px);
            transform: scale(1);
            opacity: 1;
            pointer-events: initial;
        }
    `,
    gameInnerList: css`
        width: 360px;
        height: 360px;
        background: rgba(255, 255, 255, 0.8);
        padding: 12px;
        border-radius: 16px;
        position: relative;
    `,
    gameItem: css`
        width: 100px;
        display: flex;
        position: absolute;
        opacity: 0.5;

        &.txcb {
            left: 50%;
            transform: translateX(-50%);
        }
        &.xd,
        &.bc {
            top: 50%;
            transform: translateY(-50%);
        }
        &.bc {
            right: 16px;
        }
        &.xs {
            bottom: 16px;
            left: 50%;
            transform: translateX(-50%);
        }
        &.ready {
            opacity: 1;
            cursor: pointer;
        }
    `,
};
export default miniGameIconStyles;
