import { FormValuesRegister, LoginPayload, RegisterPayload } from 'models/authModel';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styles from './register.style';
import CheckBox from 'components/checkbox/CheckBox';
import { msgAlertInput } from 'constants/messageAlert';
import authApi from 'apis/authApi';
import { DEVICE_INFO } from 'constants/common';
import { logoPartnerList } from 'constants/logoPartner';
import { cacheUserInfo, login, rememberAccountAction, updateAgentType } from 'features/authSlice';
import { useAppDispatch } from 'cache/store';
import { encode } from 'punycode';
import LoadingIcon from 'components/loading/LoadingIcon';
import { useNavigate, useParams } from 'react-router-dom';
import Fingerprint2 from 'fingerprintjs2';
import { appName, VERSION } from 'config/app.config';
import globalStyle from 'constants/global.style';

type InputId = 'un' | 'pwd' | 'zjpwd' | 'nickname' | 'phone' | 'email';
interface InputItem {
    id: InputId;
    label: string;
    holder: string;
    inputType: string;
    require: boolean;
    togglePass?: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
}
const listInput: InputItem[] = [
    {
        id: 'un',
        label: 'Tên Đăng Nhập',
        holder: msgAlertInput.username.invalid,
        inputType: 'text',
        require: true,
        pattern: {
            value: /^\S+$/,
            message: msgAlertInput.generic.invalid('Tên đăng nhập'),
        },

        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'nickname',
        label: 'Biệt Danh',
        holder: 'Nhập ký tự chữ và số',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'pwd',
        label: 'Mật Khẩu',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'phone',
        label: 'Số Điện Thoại',
        holder: 'Nhập vào số điện thoại',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 12,
            message: msgAlertInput.generic.maxLength(12),
        },
    },
    {
        id: 'zjpwd',
        label: 'Mật Khẩu Thanh Toán',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'email',
        label: 'Email (Không bắt buộc)',
        holder: 'Nhập đúng định dạng email',
        inputType: 'text',
        require: false,
    },
];

function Register() {
    const dispatch = useAppDispatch();
    let { id } = useParams();

    const [isCheckedTOF, setIsCheckedTOF] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassPay, setShowPassPay] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<FormValuesRegister>();

    const handleRegister: SubmitHandler<FormValuesRegister> = async (values) => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (values.email !== '' && !values.email.match(validRegex)) {
            toast.error('Vui lòng nhập đúng định dạng email');
            return;
        }
        if (!isCheckedTOF) {
            toast.error('Vui lòng chọn chấp nhận các điều khoản');
            return;
        }
        if (values.pwd === values.zjpwd) {
            setFocus('zjpwd');
            setError('zjpwd', {
                type: 'value',
                message: 'Mật khẩu thanh toán không được giống với đăng nhập',
            });
            return;
        }
        const payload = {
            ...values,
            loginname: values.un.toLowerCase(),
            email: values.email || `${values.un.toLowerCase().trim()}_noreply@gmail.com`,
            agentid: '10020', // prod
            id: id || '1635310', //id tkweb
        };
        onRegister(payload);
    };

    useEffect(() => {
        if ((window as any).requestIdleCallback) {
            requestIdleCallback(function () {
                Fingerprint2.get(function (components) {
                    getFingerPrint(components);
                });
            });
        } else {
            setTimeout(function () {
                Fingerprint2.get(function (components) {
                    getFingerPrint(components);
                });
            }, 500);
        }
    }, []);

    const getFingerPrint = (components: Fingerprint2.Component[]) => {
        const values = components.map((component) => {
            return component.value;
        });
        DEVICE_INFO.uuid = Fingerprint2.x64hash128(values.join(''), 88);
        DEVICE_INFO.timezone = values[9];
        DEVICE_INFO.platform = values[16];
        DEVICE_INFO.resolution = values[6].join('x');
    };

    const onRegister = async (values: RegisterPayload) => {
        try {
            const { data } = await authApi.signUp(values);
            if (!data.msg) {
                //thông báo đăng ký tài khoản thành công
                toast.success('Đăng ký tài khoản khoản thành công');

                //login account khi đăng ký tài khoản thành công
                const payloadLogin = {
                    username: values.un,
                    password: values.pwd,
                    version: VERSION,
                    di: DEVICE_INFO,
                };

                //call login from header component
                submitLogin(payloadLogin, true, false);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submitLogin = async (payload: LoginPayload, showAlertSuccess: boolean, rememberAccount?: boolean) => {
        const { username, password } = payload;
        try {
            const { data } = await authApi.login(payload);
            if (data.msg === '') {
                //change state logged in store "auth slice"
                dispatch(login(data.data.accessToken));

                if (showAlertSuccess) {
                    toast.success('Đăng nhập thành công!');
                }

                //cache userinfo to store "auth slice"
                dispatch(cacheUserInfo(data.user));

                //cache agent type account
                let agentTypeUser = null;
                if (data.user.usertype === -2) {
                    agentTypeUser = 1;
                } else if (data.user['param9'] && data.user['param9'] !== '') {
                    agentTypeUser = 2;
                }
                dispatch(updateAgentType(agentTypeUser));

                //cache remember account
                if (rememberAccount) {
                    const rememberData = {
                        account: encode(`${username}|${password}`),
                        isRemember: true,
                    };
                    dispatch(rememberAccountAction(rememberData));
                } else {
                    dispatch(rememberAccountAction({ isRemember: false, account: null }));
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                navigate('/');
                setLoading(false);
            }, 2000);
        }
    };

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.pageContent}>
                    <div className={styles.left}>
                        <img src={require('assets/images/bg/register.jpg')} alt="" />
                        <div className={styles.logoPartner}>
                            {logoPartnerList.map((item, index) => (
                                <div className={styles.partnerItem} key={index}>
                                    <div className={styles.itemInner}>
                                        <img src={item} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.contentform}>
                        <div className={styles.titleReg}>
                            <h6>ĐĂNG KÝ</h6>
                            <img src={require('assets/images/logos/logo.png')} alt="" onClick={() => navigate('/')} />
                        </div>
                        <form className={styles.form} onSubmit={handleSubmit(handleRegister)}>
                            {listInput.map((item, index) => (
                                <div className={styles.inputWrap} key={index}>
                                    <label htmlFor={item.id}>{item.label}</label>
                                    <input
                                        type={item.id === 'pwd' ? (!showPass ? item.inputType : 'text') : item.id === 'zjpwd' ? (!showPassPay ? item.inputType : 'text') : item.inputType}
                                        id={item.id}
                                        placeholder={item.holder}
                                        className={`input-common ${styles.input}`}
                                        {...register(item.id, {
                                            required: item.require,
                                            pattern: item.pattern,
                                            maxLength: item.maxLength,
                                            minLength: item.minLength,
                                        })}
                                    />
                                    {/* toggle password login */}
                                    {item.togglePass && item.id === 'pwd' && (
                                        <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                                            {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                        </div>
                                    )}

                                    {/* toggle password pay */}
                                    {item.togglePass && item.id === 'zjpwd' && (
                                        <div className={styles.togglePass} onClick={() => setShowPassPay(!showPassPay)}>
                                            {showPassPay ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                        </div>
                                    )}

                                    {/* base error  */}
                                    <div className={styles.errorInput}>{(errors as any)[item.id] && <small>{(errors as any)[item.id].message || 'Vui lòng nhập trường này!'}</small>}</div>
                                </div>
                            ))}
                            <CheckBox isChecked={isCheckedTOF} onToggleCheck={(value) => setIsCheckedTOF(value)}>
                                <p className={styles.textTOF}>
                                    Tôi đã đọc và đồng ý với các{' '}
                                    <a href="/help?view=1" target="_blank">
                                        Điều khoản và Điều kiện
                                    </a>{' '}
                                    của {appName}
                                </p>
                            </CheckBox>

                            <button type="submit" className={globalStyle.btnSecondary} style={{ marginTop: '24px' }}>
                                Đăng Ký
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default Register;
