import { RootState, useAppSelector } from 'cache/store';
import { Navigate } from 'react-router-dom';

interface Props {
    component: any;
}
const PrivateRoute = (props: Props) => {
    const { isLogged } = useAppSelector((state: RootState) => state.auth);
    const { component: Component } = props;

    return isLogged ? <Component {...props} /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
