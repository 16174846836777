export const colors = {
    primary: '#038787',
    blue: '#038787',
    lightBlue: '#E9F7F3',
    borderPrimary: '#7c94ea',
    secondary: '#feaa31',
    orange: '#ffad33',
    borderTable: '#2b3853',
    yellow: '#ffbf61',
    red: '#fc2569',
    gray: '#4e5461',
    white: '#fff',
    black: '#000',
    blackText: '#1a202c',
    success: '#0fa215',
    bgPopupDrak: '#222939',
    drak50: 'rgba(3, 10, 28, 0.5)',
    bgTitle: '#1e2c41',
    bgDark: '#082626',
    darkPrimary: '#038787',
    darkSecondary: '#022222',
    darkRed: '#FF1A0B',

    'gradient-primary': 'linear-gradient(192deg, #ffad33 9.53%, #de4300 132.11%)',
    'gradient-secondary': 'linear-gradient(180deg, #10acd3 0%, #461bf4 99.37%)',
    'gradient-popup': 'linear-gradient(269deg, rgba(67, 47, 92, 0.74) 23.94%, rgba(21, 41, 111, 0.68) 83.16%)',
    'gradient-1': 'linear-gradient(159deg, rgba(255,173,51,1) 0%, rgba(222,67,0,1) 100%)',
};
