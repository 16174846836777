import { css } from '@emotion/css';
import { lineAnimationSvg, showHideEffect } from 'constants/global.style';
import { colors } from 'constants/theme';

const headerMenuStyles = {
    wrap: css`
        border-top: 1px solid #262b40;
        min-height: 56px;
        position: relative;
    `,
    menuList: css`
        list-style-type: none;
        display: flex;
        gap: 16px;
    `,
    menuItem: css`
        cursor: pointer;
        &:first-child {
            p,
            a {
                padding-left: 0;
            }
        }
        &:hover,
        &.active {
            .label-hot {
                color: ${colors.white};
            }
            > h6,
            > a {
                color: ${colors.secondary};
                &::before {
                    opacity: 1;
                    transform: skew(-37deg, 0) translate(0, 0);
                }
            }
        }
    `,
    menuLink: css`
        /* font-weight: 600; */
        color: ${colors.white};
        text-transform: uppercase;
        padding: 18px 10px;
        display: block;
        text-decoration: none;
        position: relative;
        z-index: 1;
        overflow: hidden;

        text-align: center;
        min-width: 80px;
        font-weight: 400;
        &::before {
            content: '';
            position: absolute;
            left: calc(50% - 20px);
            top: 0;
            width: 40px;
            height: 100%;
            background: linear-gradient(195deg, #0e4141 10.36%, #021313 86.96%);
            z-index: -1;
            pointer-events: none;
            opacity: 0;
            transition: 0.25s;
            transform: skew(-37deg, 0) translate(-100%, -100%);
        }
        .label-hot {
            position: absolute;
            top: 4px;
            right: 0;
            display: block;
            background: ${colors.darkRed};
            font-size: 10px;
            font-weight: 600;
            padding: 2px 4px;
            border-radius: 4px;
            animation: ${showHideEffect} 1s ease-in-out infinite;
        }
    `,
    baseGameHover: css`
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(24px);
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 3;
        height: 0;
        overflow: hidden;

        transition: 0.25s;
    `,
    gameList: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        padding: 10px 0;
    `,
    gameItem: css`
        padding: 10px 20px;
        padding-top: 0;
        text-align: center;
        img {
            width: 120px;
            transition: 0.25s;
            /* opacity: 0.8; */
        }

        &:hover {
            img {
                filter: drop-shadow(0 0 0.75rem #fecd2f);
            }
        }
    `,
    gameMaintain: css`
        position: relative;
        pointer-events: initial;
        > img {
            filter: grayscale(90%);
        }
        .comming-soon {
            position: absolute;
            z-index: 1;
            font-weight: 600;
            color: ${colors.white};
            width: 70%;

            bottom: 22%;
            left: 15%;
            background: linear-gradient(193deg, rgba(0, 204, 255, 1) 0%, rgba(128, 83, 255, 1) 100%);
            padding: 4px 8px;
            border-radius: 8px;
            font-size: 14px;
            text-shadow: 1px 1px 2px #09062e;
        }
    `,
    gameName: css`
        text-align: center;
        color: ${colors.white};
        font-size: 14px;
    `,
    svgBorder: css`
        position: absolute;
        top: -1px;
        z-index: 2;
        pointer-events: none;
    `,
    lineSvg: css`
        stroke-dasharray: 600;
        stroke-width: 1px;
        fill: transparent;
        stroke: ${colors.darkPrimary};
        animation: ${lineAnimationSvg} 8s linear infinite;
    `,
};

export default headerMenuStyles;
