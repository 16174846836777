import moment from 'moment';
// import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './match-football.style';
import { useRef } from 'react';
import { SportMatchHotModel } from 'models/commonModel';
interface Props {
    sportList: SportMatchHotModel[];
}
function MatchFootball(props: Props) {
    const { sportList } = props;
    const btnPrevSlide = useRef<HTMLButtonElement>(null);
    const btnNextSlide = useRef<HTMLButtonElement>(null);
    const paginationRef = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.wrap}>
            {/* <button ref={btnPrevSlide} className={`${styles.btnPrev} ${styles.btnNavigate}`}>
                <GrFormPrevious size={48} />
            </button>
            <button ref={btnNextSlide} className={`${styles.btnNext} ${styles.btnNavigate}`}>
                <GrFormNext size={48} />
            </button> */}
            <img className={styles.sportBg} src={require('assets/images/match-sport/bg-sport.png')} alt="" />
            <img className={styles.playerSport} src={require('assets/images/match-sport/player.png')} alt="" />
            <div className={styles.sportList}>
                <div className="match-title">TRẬN ĐẤU HOT</div>
                {sportList.length > 0 ? (
                    <>
                        <Swiper
                            spaceBetween={8}
                            speed={600}
                            autoplay={{
                                delay: 5000,
                            }}
                            modules={[Navigation, Pagination, Autoplay]}
                            pagination={{ clickable: true, el: paginationRef.current }}
                            navigation={{
                                prevEl: btnPrevSlide.current,
                                nextEl: btnNextSlide.current,
                            }}
                        >
                            {sportList.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className={styles.matchItem}>
                                        <img className="bg-match" src={require('assets/images/match-sport/bg-match.png')} alt="" />
                                        <div className={styles.matchTeam}>
                                            <div className="team">
                                                <img src={item.team1_photo} alt="" />
                                                <p>{item.team1_name}</p>
                                            </div>
                                            <div className="time">
                                                <img src={require('assets/images/match-sport/vs.png')} alt="" />
                                                <p>{moment(item.startdate).format('HH:mm')}</p>
                                            </div>
                                            <div className="team">
                                                <img src={item.team2_photo} alt="" />
                                                <p>{item.team2_name}</p>
                                            </div>
                                        </div>
                                        <div className={styles.matchTime}>
                                            <h6>{moment(item.startdate).format('DD/MM/yyyy')}</h6>
                                            <p>{item.description}</p>
                                        </div>
                                        <div className={styles.rateBetInfo}>
                                            <div className="rate-item">
                                                <p>(+{item.team1_rate_bet})</p>
                                                <div className="rate-full">
                                                    <span>FT.HDP</span>
                                                    <span>{item.team1_rate_full}</span>
                                                </div>
                                            </div>
                                            <div className="rate-item">
                                                <p>(+{item.team2_rate_bet})</p>
                                                <div className="rate-full">
                                                    <span>FT.HDP</span>
                                                    <span>{item.team2_rate_full}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.btnBetNow}>
                                            <button type="button">CƯỢC NGAY</button>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div ref={paginationRef} className={styles.paginationMatch}></div>
                    </>
                ) : (
                    <div className={styles.noMatch}>
                        <p>Các trận cầu đỉnh cao đang được cập nhật</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MatchFootball;
