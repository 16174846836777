import { css } from '@emotion/css';
import { baucuaLabel, xdLabel } from 'constants/labelGame';
import { colors } from 'constants/theme';
import React from 'react';

interface Props {
    resultList: string[];
    gameType: 'XD' | 'BC';
}
function ResultDanGian(props: Props) {
    const { resultList, gameType } = props;

    const mapImgLabel = (value: any) => {
        if (gameType === 'BC') {
            const keyImg = Object.keys(baucuaLabel)[value].replace('[', '').replace(']', '');
            return <img className={styles.imgRs} src={require(`assets/images/dan-gian/baucua/mini-${keyImg}.png`)} alt="" title={keyImg} />;
        } else {
            return <div className={`dice dice-${value}`}></div>;
        }
    };

    return (
        <div className={styles.wrap}>
            {resultList.map((item, index) => (
                <React.Fragment key={index}>{mapImgLabel(item)}</React.Fragment>
            ))}
        </div>
    );
}

const styles = {
    wrap: css`
        display: flex;
        align-items: center;
        gap: 8px;
        .dice {
            width: 30px;
            height: 30px;
            border-radius: 30px;
        }
        .dice-1 {
            background: ${colors.red};
        }
        .dice-0 {
            background: ${colors.white};
        }
    `,
    imgRs: css`
        width: 36px;
        height: 36px;
        object-fit: contain;
    `,
};

export default ResultDanGian;
