import globalStyle from 'constants/global.style';
import styles from './promotion.styles';
import { useEffect, useState } from 'react';
import { PromoDetailResponse, PromoTab } from 'models/promotionModel';
import commonApi from 'apis/commonApi';
import classNames from 'classnames';
import imageDefault from 'assets/images/promo-default.png';
import { useNavigate } from 'react-router-dom';

const promoAll = {
    title: 'Tất cả',
    icon: '',
    groupId: -1,
    isActive: true,
    promoItem: [],
    disable: false,
    orderNumber: -1,
};
function PromotionPage() {
    const navigate = useNavigate();
    const [promoTabs, setPromoTabs] = useState<PromoTab[]>([promoAll]);
    const [promoTabActive, setPromoTabActive] = useState<PromoTab>(promoTabs[0]);
    const [promoListInit, setPromoListInit] = useState<PromoDetailResponse[]>([]);
    const [promoListActive, setPromoListActive] = useState<PromoDetailResponse[]>([]);

    useEffect(() => {
        const initMsgSystemBean = async () => {
            try {
                const { data } = await commonApi.getMsgSystemBean({});
                if (!data.msg) {
                    //add tab into promo tabs list
                    let promoTitles: PromoTab[] = data.promoTitles.sort((a: PromoTab, b: PromoTab) => a.orderNumber - b.orderNumber);
                    promoTitles = promoTitles.filter((item) => !item.disable);

                    const allPromoTab = [promoAll, ...promoTitles];
                    setPromoTabs(allPromoTab);
                    //lấy những item là tin khuyến mãi
                    const arrMsg: PromoDetailResponse[] = data.msglist;
                    const promoList = arrMsg.filter((item) => item.status === 0);

                    // thêm field tabMatch vào mỗi tin khuyến mãi
                    const newPromoList = promoList.map((item) => {
                        //kiểm tra có param 2
                        if (item.param2) {
                            return {
                                ...item,

                                //thêm mỗi tabMacth key = -1 map với tab "tất cả"
                                tabMatch: [...item.param2.split('|').map((id) => Number(id)), -1],
                            };
                        }
                        return item;
                    });
                    setPromoListInit(newPromoList);
                }
            } catch (error) {
                console.log(error);
            }
        };
        initMsgSystemBean();
    }, []);

    useEffect(() => {
        filterPromoActive(promoListInit);
    }, [promoTabActive, promoListInit]);

    const filterPromoActive = (promoList: PromoDetailResponse[]) => {
        const listPromoActive = promoList.filter((item) => {
            return item.tabMatch && item.tabMatch?.includes(Number(promoTabActive.groupId));
        });
        setPromoListActive(listPromoActive);
    };

    const switchTab = (tab: PromoTab) => {
        if (tab.groupId === promoTabActive.groupId) {
            return;
        }
        setPromoTabActive(tab);
    };

    return (
        <div className={styles.wrap}>
            <div className={globalStyle.container}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <img src={require('assets/images/icons/gift.png')} alt="" />
                        {/* <h6>KHUYẾN MÃI</h6> */}
                    </div>

                    <div className={styles.tabList}>
                        {promoTabs.map((tab, index) => (
                            <div className={classNames(styles.tabItem, { active: tab.groupId === promoTabActive.groupId })} key={index} onClick={() => switchTab(tab)}>
                                <span>{tab.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.promoContent}>
                    {promoListActive.length > 0 ? (
                        promoListActive.map((item) => (
                            <div className={styles.promoItem} key={item.id}>
                                <div className={styles.promoItemInner}>
                                    <div className="wrap-img">
                                        <img src={item.photourl || imageDefault} alt="" loading="lazy" onError={(e: any) => (e.target.src = imageDefault)} />
                                    </div>

                                    <button className="btn-detail" type="button" onClick={() => navigate(`/promotion/${item.id}`)}>
                                        <span>XEM CHI TIẾT</span>
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={styles.noPromo}>
                            <p>Hiện tại không có khuyến mãi nào dành cho bạn</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PromotionPage;
