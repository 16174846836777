import { PathTypeAccountInfo } from 'models/commonModel';
import AgentMember from 'pages/account/account-option/AgentMember';
import AgentRegister from 'pages/account/account-option/AgentRegister';
import AgentSign from 'pages/account/account-option/AgentSign';
import BankAccount from 'pages/account/account-option/BankAccount';
import FundBet from 'pages/account/account-option/FundBet';
import FundAccountTransfer from 'pages/account/account-option/FundAccountTransfer';
import GiftCodeAccount from 'pages/account/account-option/GiftCodeAccount';
import NotiftyAll from 'pages/account/account-option/NotifyAll';
import NotiftyInbox from 'pages/account/account-option/NotifyInbox';
import PersonalInfo from 'pages/account/account-option/PersonalInfo';
import PresentDetail from 'pages/account/account-option/PresentDetail';
import StatsAgency from 'pages/account/account-option/StatsAgency';
import StatsDaily from 'pages/account/account-option/StatsDaily';
import StatsGeneral from 'pages/account/account-option/StatsGeneral';
import WalletDepositHistory from 'pages/account/account-option/WalletDespositHis';
import DepositPage from 'pages/deposit/DepositPage';
import WithdrawPage from 'pages/withdraw/WithDraw';
import FundTransfer from 'pages/fund-transfer/FundTransfer';

interface AccountItem {
    label: string;
    path: PathTypeAccountInfo;
    icon: string;
}

interface GroupAccountItem {
    title: string;
    id: string;
    group: AccountItem[];
}

interface OptionAccountComponent {
    path: PathTypeAccountInfo;
    component: React.FC;
}

export const optionAccountMenu: GroupAccountItem[] = [
    {
        title: 'Tài Khoản',
        id: 'account',
        group: [
            {
                label: 'Thông tin cá nhân',
                path: 'personal-info',
                icon: require('assets/images/icons/account/account-1.png'),
            },
            {
                label: 'Tài khoản ngân hàng',
                path: 'bank-account',
                icon: require('assets/images/icons/account/account-2.png'),
            },

            {
                label: 'Quà tặng',
                path: 'gift-code',
                icon: require('assets/images/icons/account/account-3.png'),
            },
        ],
    },
    {
        title: 'Ví Tiền',
        id: 'wallet',

        group: [
            { label: 'Nạp Tiền', path: 'wl-depo', icon: require('assets/images/icons/account/wallet-1.png') },
            { label: 'Rút Tiền', path: 'wl-wdr', icon: require('assets/images/icons/account/wallet-2.png') },
            { label: 'Lịch sử Nạp/Rút', path: 'wl-depo-his', icon: require('assets/images/icons/account/wallet-1.png') },
            { label: 'Chuyển Quỹ', path: 'fund-transfer', icon: require('assets/images/icons/account/wallet-3.png') },
        ],
    },
    {
        title: 'Lịch Sử',
        id: 'history',

        group: [
            { label: 'Biến động số dư', path: 'fund-tran', icon: require('assets/images/icons/account/history-1.png') },
            { label: 'Chi tiết tặng quà', path: 'present-detail', icon: require('assets/images/icons/account/history-2.png') },
            { label: 'Lịch sử cược', path: 'fund-bet', icon: require('assets/images/icons/account/history-3.png') },
        ],
    },
    {
        title: 'Đại Lý',
        id: 'agency',
        group: [
            { label: 'Đăng ký thành viên', path: 'agent-sign', icon: require('assets/images/icons/account/agency-1.png') },
            { label: 'Cấp dưới', path: 'agent-member', icon: require('assets/images/icons/account/agency-2.png') },
            { label: 'Liên kết đăng ký', path: 'agent-reg', icon: require('assets/images/icons/account/agency-3.png') },
        ],
    },

    {
        title: 'Thông Báo',
        id: 'notify',

        group: [
            { label: 'Lịch sử Nạp/Rút', path: 'wl-depo-his', icon: require('assets/images/icons/account/notify-3.png') },
            { label: 'Tất cả thông báo', path: 'noti-all', icon: require('assets/images/icons/account/notify-1.png') },
            { label: 'Tin nhắn', path: 'noti-inbox', icon: require('assets/images/icons/account/notify-2.png') },
        ],
    },
    {
        title: 'Báo Cáo',
        id: 'report',

        group: [
            { label: 'Báo cáo tổng quan', path: 'stats-general', icon: require('assets/images/icons/account/report-1.png') },
            { label: 'Báo cáo đại lý', path: 'stats-wl', icon: require('assets/images/icons/account/report-2.png') },
            { label: 'Báo cáo hằng ngày', path: 'stats-daily', icon: require('assets/images/icons/account/report-3.png') },
        ],
    },
];

export const optionAccountComponent: OptionAccountComponent[] = [
    { path: 'personal-info', component: PersonalInfo },
    { path: 'bank-account', component: BankAccount },
    { path: 'gift-code', component: GiftCodeAccount },
    { path: 'agent-sign', component: AgentSign },
    { path: 'agent-member', component: AgentMember },
    { path: 'agent-reg', component: AgentRegister },
    { path: 'fund-tran', component: FundAccountTransfer },
    { path: 'present-detail', component: PresentDetail },
    { path: 'fund-bet', component: FundBet },
    { path: 'noti-all', component: NotiftyAll },
    { path: 'noti-inbox', component: NotiftyInbox },
    { path: 'stats-general', component: StatsGeneral },
    { path: 'stats-wl', component: StatsAgency },
    { path: 'stats-daily', component: StatsDaily },
    { path: 'wl-depo-his', component: WalletDepositHistory },
    { path: 'wl-depo', component: DepositPage },
    { path: 'wl-wdr', component: WithdrawPage },
    { path: 'fund-transfer', component: FundTransfer },
];
