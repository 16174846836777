import { useEffect, useState } from 'react';
import accountStyles from './account-option.style';
import commonApi from 'apis/commonApi';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { FaAngleRight } from 'react-icons/fa6';
import { rotateAnimation } from 'constants/global.style';
import classNames from 'classnames';
import Modal from 'components/modal/Modal';
import toast from 'react-hot-toast';
import { PromoDetailResponse } from 'models/promotionModel';
interface MsgDetail {
    id: number;
    title: string;
    content: string;
    [key: string]: any;
}

function NotiftyAll() {
    const [msgList, setMsgList] = useState<PromoDetailResponse[]>([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showPopupDetail, setShowPopupDetail] = useState(false);
    const [msgDetail, setMsgDetail] = useState<MsgDetail>({} as MsgDetail);

    useEffect(() => {
        initMsgSystemBean();
    }, []);

    const initMsgSystemBean = async (isRefresh?: boolean) => {
        if (isRefresh) {
            setIsRefreshing(true);
        }
        try {
            const { data } = await commonApi.getMsgSystemBean({});
            if (!data.msg) {
                const msgData: PromoDetailResponse[] = data.msglist.filter((item: PromoDetailResponse) => item.status === 1);
                const popupList: PromoDetailResponse[] = data.msglist.filter((item: PromoDetailResponse) => item.status === 2);
                if (popupList.length > 0) {
                    insertPopupIntoMsgList(msgData, popupList[0]);
                } else {
                    setMsgList(msgData);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsRefreshing(false);
        }
    };

    const insertPopupIntoMsgList = async (msgData: PromoDetailResponse[], popupItem: PromoDetailResponse) => {
        try {
            const { data } = await commonApi.sysMsgBeanInfo({ id: popupItem.id });
            if (!data.msg && !data.sysmsg.content.includes('popup')) {
                msgData = [...msgData, popupItem];
            }
            setMsgList(msgData);
        } catch (error) {
            console.log(error);
            setMsgList(msgData);
        }
    };

    const getMsgDetail = async (msgId: number) => {
        setShowPopupDetail(true);
        try {
            const { data } = await commonApi.sysMsgBeanInfo({ id: msgId });
            if (!data.msg) {
                setMsgDetail(data.sysmsg);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap} ${accountStyles.mw800}`}>
                <h6 className={accountStyles.title}>Thông Báo Chính Thức</h6>

                <div className="">
                    <button type="button" className={classNames(`${styles.btnRefresh} btn-primary`, { rotate: isRefreshing })} onClick={() => initMsgSystemBean(true)}>
                        <img src={require('assets/images/icons/refresh.png')} alt="" />
                        <span>Cập nhật</span>
                    </button>
                    <div className={styles.messageList}>
                        {msgList.map((item) => (
                            <div className={styles.messageItem} key={item.id} onClick={() => getMsgDetail(item.id)}>
                                <p>{item.title}</p>
                                <FaAngleRight size={20} color={colors.primary} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal isShow={showPopupDetail} onClose={() => setShowPopupDetail(false)}>
                <div className={`bg-popup-common ${styles.popup}`}>
                    <h2 className="title" style={{ marginBottom: '20px' }}>
                        {msgDetail.title}
                    </h2>
                    <div className={styles.popupContent} dangerouslySetInnerHTML={{ __html: msgDetail.content }} />

                    <div className="group-action">
                        <button
                            type="button"
                            className="btn-primary btn-close"
                            onClick={() => {
                                setShowPopupDetail(false);
                                setMsgDetail({} as MsgDetail);
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const styles = {
    btnRefresh: css`
        display: flex;
        align-items: center;
        margin-left: auto;
        width: fit-content !important;
        padding: 6px 12px;
        border-radius: 8px;
        span {
            margin-left: 4px;
            font-size: 14px;
        }
        img {
            width: 20px;
        }
        &.rotate {
            img {
                animation: ${rotateAnimation} 0.8s linear infinite;
            }
        }
    `,
    messageList: css`
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;
    `,
    messageItem: css`
        background: ${colors.drak50};
        border-radius: 8px;
        padding: 12px;
        padding-left: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
            p {
                color: ${colors.primary};
            }
            svg {
                transform: translateX(4px);
            }
        }
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            overflow: hidden;
            margin-right: 24px;
        }
        svg {
            transition: 0.25s;
        }
    `,
    popup: css`
        display: flex;
        flex-direction: column;
        min-height: 400px;
    `,
    popupContent: css`
        flex: 1;
    `,
};

export default NotiftyAll;
