import { IoMdClose } from 'react-icons/io';
import accountStyles from 'pages/account/account-option/account-option.style';
import globalStyle from 'constants/global.style';
import styles from './add-bank.style';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BankCardAddPayload } from 'models/bankModel';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import userApi from 'apis/userApi';
import LoadingIcon from 'components/loading/LoadingIcon';
import toast from 'react-hot-toast';
import { IoIosArrowDown } from 'react-icons/io';
import Modal from 'components/modal/Modal';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { FaCheck } from 'react-icons/fa6';
const locateBank = {
    nprovince: 'Việt Nam',
    ncity: 'Việt Nam',
};

interface Props {
    onSubmit: (bankPayload: BankCardAddPayload) => void;
    onClose: () => void;
    bankList: string[];
}
export default function AddBankPerson(props: Props) {
    const { onSubmit, onClose, bankList } = props;
    const [step, setStep] = useState<1 | 2>(1);
    const [bankName, setBankName] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [bankConfirmData, setBankConfirmData] = useState<BankCardAddPayload>({} as BankCardAddPayload);
    const [showBankList, setShowBankList] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<BankCardAddPayload>();

    const submitAddBankCheck: SubmitHandler<BankCardAddPayload> = async (values) => {
        const { nbankcard, bankcard2, nname } = values;
        if (!bankName) {
            toast.error('Vui lòng chọn ngân hàng');
            return;
        }

        if (nbankcard !== bankcard2) {
            setError('bankcard2', { message: 'Nhập lại số tài khoản không đúng' });
            return;
        }

        setLoading(true);
        const payload = {
            ...values,
            nname: nname.trim().toLocaleLowerCase(),
            ...locateBank,
            nbankname: bankName,
        };
        if (payload.nbranchotherbank) delete payload.nbranchotherbank;

        try {
            const { data } = await userApi.addBankcardCheck(payload);
            if (!data.msg) {
                setStep(2);
                setBankConfirmData(payload);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const changeAlias = (alias: string, removeNumber: boolean, typeInput: any) => {
        let str = alias;
        str = str.toUpperCase();
        if (removeNumber) {
            str = str.replace(/[0-9]/g, '');
        } else {
            str = str.replace(/ /g, '');
        }
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ắ|Ằ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, '');
        str = str.replace(/ + /g, '');
        if (alias !== '') {
            clearErrors(typeInput);
        }
        return str;
    };

    const handleSubmitAddBank = async () => {
        onSubmit(bankConfirmData);
    };

    const handleSelectBank = (bank: string) => {
        setBankName(bank);
        setShowBankList(false);
    };

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.heading}>
                    <h6>Thêm Ngân Hàng</h6>
                    <button type="button" onClick={onClose}>
                        <IoMdClose size={36} color="#20AEFF" />
                    </button>
                </div>
                <div className={styles.breadcrumb}>
                    <div className={classNames(`${styles.breadItem} ${styles.breadItem1}`, { active: step === 1 })}>
                        <p>Điền Thông Tin</p>
                    </div>
                    <div className={classNames(`${styles.breadItem} ${styles.breadItem2}`, { active: step === 2 })}>
                        <p>Xác Nhận</p>
                    </div>
                </div>
                {step === 1 && (
                    <form className={styles.formAddBank} onSubmit={handleSubmit(submitAddBankCheck)}>
                        <div className={`${accountStyles.groupInput} form-group-common`}>
                            <div className={`input-common ${styles.inputBank}`} onClick={() => setShowBankList(true)}>
                                <span>{bankName || 'Chọn ngân hàng'}</span>
                                <IoIosArrowDown size={20} />
                            </div>
                        </div>

                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Chi Nhánh</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('nbranchname', {
                                        required: true,
                                        maxLength: {
                                            value: 30,
                                            message: 'Tối thiểu 30 ký tự',
                                        },
                                    })}
                                    className={`input-common`}
                                    type="text"
                                    placeholder="Từ 2 đến 30 ký tự"
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['nbranchname'] && <small>{errors['nbranchname'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>

                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Tên Chủ Tài Khoản</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('nname', {
                                        required: true,
                                    })}
                                    className={`input-common`}
                                    type="text"
                                    placeholder="Từ 2 đến 30 ký tự"
                                    onChange={(e: any) => (e.target.value = changeAlias(e.target.value, true, 'nname'))}
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['nname'] && <small>{errors['nname'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>

                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Số Tài Khoản</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('nbankcard', {
                                        required: true,
                                    })}
                                    className={`input-common`}
                                    type="text"
                                    placeholder="Từ 1 đến 15 ký tự"
                                    onChange={(e: any) => (e.target.value = changeAlias(e.target.value, false, 'nbankcard'))}
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['nbankcard'] && <small>{errors['nbankcard'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>

                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Nhập Lại Số Tài Khoản</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('bankcard2', {
                                        required: true,
                                    })}
                                    className={`input-common`}
                                    type="text"
                                    placeholder="Xác minh lại tài khoản ngân hàng"
                                    onChange={(e: any) => (e.target.value = changeAlias(e.target.value, false, 'bankcard2'))}
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['bankcard2'] && <small>{errors['bankcard2'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>

                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Mật Khẩu Thanh Toán</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('checkpwd', {
                                        required: true,
                                    })}
                                    className={`input-common`}
                                    type="password"
                                    placeholder="Mật khẩu thanh toán"
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['checkpwd'] && <small>{errors['checkpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>

                        <button type="submit" className={`${accountStyles.btnSubmit} btn-primary`} style={{ marginTop: '12px' }}>
                            Bước Tiếp Theo
                        </button>
                    </form>
                )}
                {step === 2 && (
                    <div className="">
                        <p className={styles.noteConfirm}>Vui lòng xác nhận thông tin ngân hàng của bạn một lần nữa.</p>
                        <div className={styles.infoBankList}>
                            <div className={styles.infoBankItem}>
                                <p>Ngân Hàng</p>
                                <p>{bankConfirmData.nbankname}</p>
                            </div>
                            <div className={styles.infoBankItem}>
                                <p>Chi Nhánh</p>
                                <p>{bankConfirmData.nbranchname}</p>
                            </div>
                            <div className={styles.infoBankItem}>
                                <p>Tên Tài Khoản</p>
                                <p>{bankConfirmData.nname}</p>
                            </div>
                            <div className={styles.infoBankItem}>
                                <p>Số Tài Khoản</p>
                                <p>{bankConfirmData.nbankcard}</p>
                            </div>
                        </div>
                        <div className="action-group-modal">
                            <button type="button" className="btn-cancel" onClick={() => setStep(1)}>
                                Quay Lại
                            </button>
                            <button type="button" className="btn-submit btn-primary" onClick={handleSubmitAddBank}>
                                Xác nhận
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* show bank list */}
            <Modal isShow={showBankList} onClose={() => setShowBankList(false)} width="440px" closeClickBackdrop>
                <BankListSuggest bankSelect={bankName} bankData={bankList} onSelectBank={handleSelectBank} onClose={() => setShowBankList(false)} />
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

interface PropsBankList {
    bankData: string[];
    onSelectBank: (bank: string) => void;
    onClose: () => void;
    bankSelect: string;
}
const BankListSuggest = (props: PropsBankList) => {
    const { bankData, onSelectBank, onClose, bankSelect } = props;
    const [bankList, setBankList] = useState(bankData);
    const filterBank = (key: string) => {
        const bankMap = bankData.filter((bank) => bank.toLowerCase().includes(key));
        setBankList(bankMap);
    };
    return (
        <div className={stylesBankList.wrap}>
            <div className={stylesBankList.header}>
                <div className="header-title">
                    <h2>Chọn Ngân hàng</h2>
                    <button type="button" onClick={onClose}>
                        <IoMdClose size={36} color={colors.primary} />
                    </button>
                </div>
                <input type="text" placeholder="Tìm ngân hàng..." onChange={(e) => filterBank(e.target.value)} />
            </div>

            <div className={stylesBankList.bankContent}>
                {bankList.length > 0 ? (
                    bankList.map((bank, index) => (
                        <div className={`bank-item ${bankSelect === bank ? 'active' : ''}`} key={index} onClick={() => onSelectBank(bank)}>
                            <span>{bank}</span>
                            {bankSelect === bank && <FaCheck size={20} color={colors.primary} />}
                        </div>
                    ))
                ) : (
                    <div className={stylesBankList.noBank}>
                        <p>Không tìm thấy ngân hàng phù hợp</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const stylesBankList = {
    wrap: css`
        background: ${colors.white};
        border-radius: 16px;
        padding-bottom: 16px;
    `,
    bankContent: css`
        height: 400px;
        overflow-y: auto;
        padding-left: 16px;
        .bank-item {
            padding: 12px 0;
            padding-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #f1f1f1;
            color: ${colors.blackText};
            &.active {
                span {
                    color: ${colors.primary};
                }
            }
        }
    `,
    header: css`
        text-align: center;
        padding: 16px;
        background: #f1f1f1;
        border-radius: 16px 16px 0 0;
        .header-title {
            button {
                display: flex;
                position: absolute;

                right: 8px;
                cursor: pointer;
                top: 8px;
            }
        }
        h2 {
            font-size: 20px;
            padding-bottom: 16px;
        }
        input {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid ${colors.blackText};
            border-radius: 8px;
        }
    `,
    noBank: css`
        padding: 40px 0;
        text-align: center;
    `,
};
