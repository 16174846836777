import { css } from '@emotion/css';
import classNames from 'classnames';
import { lotteryHeaderMenu } from 'constants/common';
import { colors } from 'constants/theme';
import { LotteryMenuHeader } from 'models/commonModel';
import { useEffect, useState } from 'react';

interface Props {
    onSelectGame: (ltId: number) => void;
    lotteryIdActive: number;
}

function LotterySidebar(props: Props) {
    const { onSelectGame, lotteryIdActive } = props;
    const [lotteryMenu, setLotteryMenu] = useState<LotteryMenuHeader[]>([]);

    useEffect(() => {
        const newLotteryMenu = lotteryHeaderMenu.map((group) => {
            if (group.type === 'MN' || group.type === 'MT') {
                let title = 'Miền Nam Hôm Nay';
                const newChild = group.child.filter((game) => game.openDay === new Date().getDay());
                if (group.type === 'MT') {
                    title = 'Miền Trung Hôm Nay';
                }
                return {
                    ...group,
                    title,
                    child: newChild,
                };
            }
            return group;
        });
        setLotteryMenu(newLotteryMenu);
    }, []);
    return (
        <div className={styles.wrapSidebar}>
            {lotteryMenu.map((group, index) => (
                <div className={styles.groupGame} key={index}>
                    <div className={styles.headingGroup}>
                        <h6>{group.title}</h6>
                        {/* <IoIosArrowDown size={16} className="heading-icon" /> */}
                    </div>
                    <div className={styles.listGame}>
                        {group.child.map((game) => (
                            <div className={classNames(styles.gameItem, { active: game.ltid === lotteryIdActive })} key={game.ltid} onClick={() => onSelectGame(game.ltid)}>
                                <img src={require(`assets/images/icons-lt/${game.ltid}.png`)} alt="" />
                                <p>{game.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

const styles = {
    wrapSidebar: css`
        height: 100%;
    `,
    groupGame: css`
        margin-bottom: 8px;
    `,
    headingGroup: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        background: ${colors.darkPrimary};
        border-radius: 8px;
        h6 {
            padding: 8px;
            color: ${colors.white};
        }
    `,
    listGame: css``,
    gameItem: css`
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 8px;
        margin-bottom: 2px;
        &:hover,
        &.active {
            border-color: ${colors.darkPrimary};
            p {
                color: ${colors.darkPrimary};
            }
        }
        img {
            width: 30px;
        }
    `,
};

export default LotterySidebar;
