import Modal from './Modal';
interface Props {
    onClose: () => void;
    onSubmit: () => void;
    title: string;
    subTitle: string;
    isShow: boolean;
}
function ModalConfirm(props: Props) {
    const { onClose, onSubmit, title, subTitle, isShow } = props;
    return (
        <Modal onClose={onClose} width="400px" isShow={isShow} closeClickBackdrop>
            <div className="bg-popup-common">
                <h6 className="title">{title}</h6>
                <p className="sub-title">{subTitle}</p>
                <div className="action-group-modal">
                    <button type="button" className="btn-cancel" onClick={onClose}>
                        Huỷ
                    </button>
                    <button type="button" className="btn-submit" onClick={onSubmit}>
                        Xác Nhận
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalConfirm;
