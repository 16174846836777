import { LotteryList } from 'models/lotteryListModel';

export const LotteryMenu: LotteryList[] = [
    {
        titleRoot: 'XS Siêu Tốc',
        idRoot: 'fast-lt',
        thumbnail: 'fastlt.png',
        submenu: [
            {
                title: 'Siêu Tốc 1 Giây',
                idNameMenu: '1-second',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: true,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'MMC',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 50,
            },
            {
                title: 'Siêu Tốc 45 Giây',
                idNameMenu: '45-seconds',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'Jz45',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 100,
            },
            {
                title: 'Siêu Tốc 1 Phút',
                idNameMenu: '1-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'CSSSC',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 101,
            },
            {
                title: 'Siêu Tốc 1.5 Phút',
                idNameMenu: '1.5-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'PHP',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 102,
            },
            {
                title: 'Siêu Tốc 2 Phút',
                idNameMenu: '2-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'SSCBF',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 103,
            },
            {
                title: 'Siêu Tốc 5 Phút',
                idNameMenu: '5-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'CqSSC',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 104,
            },
        ],
    },
    {
        titleRoot: 'Siêu Tốc Miền Bắc',
        idRoot: 'fast-lt',
        thumbnail: 'fastlt.png',
        isNew: true,
        submenu: [
            {
                title: 'Miền Bắc 1 Giây',
                idNameMenu: '1-second',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: true,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'MMC',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 140,
            },
            {
                title: 'Miền Bắc 45 Giây',
                idNameMenu: '45-seconds',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'FASTMB',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 141,
            },
            {
                title: 'Miền Bắc 1 Phút',
                idNameMenu: '1-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'FASTMB',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 142,
            },
            {
                title: 'Miền Bắc 1.5 Phút',
                idNameMenu: '1.5-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'FASTMB',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 143,
            },
            {
                title: 'Miền Bắc 2 Phút',
                idNameMenu: '2-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'FASTMB',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 144,
            },
            {
                title: 'Miền Bắc 5 Phút',
                idNameMenu: '5-minutes',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'FASTMB',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 145,
            },
        ],
    },
    {
        titleRoot: 'Miền Nam',
        idRoot: 'mien-nam-lt',
        thumbnail: 'thailt.png',
        submenu: [
            {
                title: 'XS Hồ Chí Minh',
                idNameMenu: 'hcm-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 105,
                urlRefer: 'ho-chi-minh',
                hasRule1Percent: true,
            },
            // {
            //     title: 'XS Hồ Chí Minh VIP',
            //     idNameMenu: 'hcmvip-lt',
            //     defaultTime: '',
            //     defaultTimer: '',
            //     isOneSec: false,
            //     isMienBac: false,
            //     isPingPong: false,
            //     isLivestream: false,
            //     newUpdate: '',
            //     lotteryType: 'VN',
            //     endtime: '',
            //     servertime: '',
            //     openResult: false,
            //     lotteryID: 203,
            //     urlRefer: 'ho-chi-minh-vip',
            //     hasRule1Percent: true
            // },
            {
                title: 'XS Đồng Tháp',
                idNameMenu: 'dongthap-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 106,
                urlRefer: 'dong-thap',
                hasRule1Percent: true,
            },
            {
                title: 'XS Cà Mau',
                idNameMenu: 'camau-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 107,
                urlRefer: 'ca-mau',
                hasRule1Percent: true,
            },
            // {
            //     title: 'XS Bến Tre',
            //     idNameMenu: 'bentre-lt',
            //     defaultTime: '',
            //     defaultTimer: '',
            //     isOneSec: false,
            //     isMienBac: false,
            //     isPingPong: false,
            //     isLivestream: false,
            //     newUpdate: '',
            //     lotteryType: 'VN',
            //     endtime: '',
            //     servertime: '',
            //     openResult: false,
            //     lotteryID: 108,
            //     urlRefer: 'ben-tre'
            // },
            {
                title: 'XS Vũng Tàu',
                idNameMenu: 'vungtau-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 109,
                urlRefer: 'vung-tau',
                hasRule1Percent: true,
            },
            {
                title: 'XS Bạc Liêu',
                idNameMenu: 'baclieu-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 110,
                urlRefer: 'bac-lieu',
                hasRule1Percent: true,
            },
            {
                title: 'XS Đồng Nai',
                idNameMenu: 'dongnai-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 111,
                urlRefer: 'dong-nai',
                hasRule1Percent: true,
            },
            {
                title: 'XS Cần Thơ',
                idNameMenu: 'cantho-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 112,
                urlRefer: 'can-tho',
                hasRule1Percent: true,
            },
            {
                title: 'XS Sóc Trăng',
                idNameMenu: 'soctrang-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 113,
                urlRefer: 'soc-trang',
                hasRule1Percent: true,
            },
            {
                title: 'XS Tây Ninh',
                idNameMenu: 'tayninh-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 114,
                urlRefer: 'tay-ninh',
                hasRule1Percent: true,
            },
            {
                title: 'XS An Giang',
                idNameMenu: 'angiang-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 115,
                urlRefer: 'an-giang',
                hasRule1Percent: true,
            },
            {
                title: 'XS Bình Thuận',
                idNameMenu: 'binhthuan-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 116,
                urlRefer: 'binh-thuan',
                hasRule1Percent: true,
            },
            {
                title: 'XS Vĩnh Long',
                idNameMenu: 'vinhlong-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 117,
                urlRefer: 'vinh-long',
                hasRule1Percent: true,
            },
            {
                title: 'XS Bình Dương',
                idNameMenu: 'binhduong-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 118,
                urlRefer: 'binh-duong',
                hasRule1Percent: true,
            },
            {
                title: 'XS Trà Vinh',
                idNameMenu: 'travinh-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 119,
                urlRefer: 'tra-vinh',
                hasRule1Percent: true,
            },
            // {
            //     title: 'XS Long An',
            //     idNameMenu: 'longan-lt',
            //     defaultTime: '',
            //     defaultTimer: '',
            //     isOneSec: false,
            //     isMienBac: false,
            //     isPingPong: false,
            // isLivestream: false,
            //     newUpdate: '',
            //     lotteryType: 'VN',
            //     endtime: '',
            //     servertime: '',
            //     openResult: false,
            //     lotteryID: 120,
            //     urlRefer: 'long-an'
            // },
            {
                title: 'XS Hậu Giang',
                idNameMenu: 'haugiang-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 122,
                urlRefer: 'hau-giang',
                hasRule1Percent: true,
            },
            {
                title: 'XS Bình Phước',
                idNameMenu: 'binhphuoc-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 121,
                urlRefer: 'binh-phuoc',
                hasRule1Percent: true,
            },
            {
                title: 'XS Tiền Giang',
                idNameMenu: 'tiengiang-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 123,
                urlRefer: 'tien-giang',
                hasRule1Percent: true,
            },
            {
                title: 'XS Kiên Giang',
                idNameMenu: 'kiengiang-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 124,
                urlRefer: 'kien-giang',
                hasRule1Percent: true,
            },
            {
                title: 'XS Đà Lạt',
                idNameMenu: 'dalat-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 125,
                urlRefer: 'da-lat',
                hasRule1Percent: true,
            },
        ],
    },
    {
        titleRoot: 'Miền Trung',
        idRoot: 'mien-trung-lt',
        thumbnail: 'vnlt.png',
        isNew: false,
        submenu: [
            // {
            //     title: 'XS Đà Nẵng VIP',
            //     idNameMenu: 'danangvip-lt',
            //     defaultTime: '',
            //     defaultTimer: '',
            //     isOneSec: false,
            //     isMienBac: false,
            //     isPingPong: false,
            //     isLivestream: false,
            //     newUpdate: '',
            //     lotteryType: 'VN',
            //     endtime: '',
            //     servertime: '',
            //     openResult: false,
            //     lotteryID: 204,
            //     urlRefer: 'danang-vip',
            //     hasRule1Percent: true,
            //     isNew: true,
            // },
            {
                title: 'XS Thừa Thiên Huế',
                idNameMenu: 'tth-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 126,
                urlRefer: 'hue',
                hasRule1Percent: true,
            },
            {
                title: 'XS Phú Yên',
                idNameMenu: 'phuyen-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 127,
                urlRefer: 'phu-yen',
                hasRule1Percent: true,
            },
            {
                title: 'XS Đắk Lắk',
                idNameMenu: 'daklak-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 128,
                urlRefer: 'dac-lac',
                hasRule1Percent: true,
            },
            {
                title: 'XS Quảng Nam',
                idNameMenu: 'quangnam-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 129,
                urlRefer: 'quang-nam',
                hasRule1Percent: true,
            },
            {
                title: 'XS Đà Nẵng',
                idNameMenu: 'danang-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 130,
                urlRefer: 'da-nang',
                hasRule1Percent: true,
            },
            {
                title: 'XS Khánh Hoà',
                idNameMenu: 'khanhhoa-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 131,
                urlRefer: 'khanh-hoa',
                hasRule1Percent: true,
            },
            {
                title: 'XS Bình Định',
                idNameMenu: 'binhdinh-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 132,
                urlRefer: 'binh-dinh',
                hasRule1Percent: true,
            },
            {
                title: 'XS Quảng Trị',
                idNameMenu: 'quangtri-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 133,
                urlRefer: 'quang-tri',
                hasRule1Percent: true,
            },
            {
                title: 'XS Quảng Bình',
                idNameMenu: 'quangbinh-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 134,
                urlRefer: 'quang-binh',
                hasRule1Percent: true,
            },
            {
                title: 'XS Gia Lai',
                idNameMenu: 'gialai-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 135,
                urlRefer: 'gia-lai',
                hasRule1Percent: true,
            },
            {
                title: 'XS Ninh Thuận',
                idNameMenu: 'ninhthuan-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 136,
                urlRefer: 'ninh-thuan',
                hasRule1Percent: true,
            },
            {
                title: 'XS Quảng Ngãi',
                idNameMenu: 'quangngai-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 137,
                urlRefer: 'quang-ngai',
                hasRule1Percent: true,
            },
            {
                title: 'XS Đắc Nông',
                idNameMenu: 'dacnong-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 138,
                urlRefer: 'dac-nong',
                hasRule1Percent: true,
            },
            {
                title: 'XS Kon Tum',
                idNameMenu: 'kontum-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: false,
                isPingPong: false,
                isLivestream: false,
                newUpdate: '',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 139,
                urlRefer: 'kon-tum',
                hasRule1Percent: true,
            },
        ],
    },
    {
        titleRoot: 'Miền Bắc',
        idRoot: 'mien-bac-lt',
        thumbnail: 'vnlt.png',
        isNew: false,
        submenu: [
            {
                title: 'XS Miền Bắc',
                idNameMenu: 'mienbac-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: 'new',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 200,
                urlRefer: 'mien-bac',
                hasRule1Percent: true,
            },
            // {
            //     title: 'XS Hà Nội VIP',
            //     idNameMenu: 'hanoivip-lt',
            //     defaultTime: '',
            //     defaultTimer: '',
            //     isOneSec: false,
            //     isMienBac: true,
            //     isPingPong: false,
            //     isLivestream: false,
            //     newUpdate: 'new',
            //     lotteryType: 'VN',
            //     endtime: '',
            //     servertime: '',
            //     openResult: false,
            //     lotteryID: 202,
            //     urlRefer: 'hanoi-vip',
            //     hasRule1Percent: true
            // },
            {
                title: 'Đặc Biệt 18h25',
                idNameMenu: 'mbdb-lt',
                defaultTime: '',
                defaultTimer: '',
                isOneSec: false,
                isMienBac: true,
                isPingPong: false,
                isLivestream: false,
                newUpdate: 'new',
                lotteryType: 'VN',
                endtime: '',
                servertime: '',
                openResult: false,
                lotteryID: 201,
                urlRefer: 'mien-bac',
                hasRule1Percent: true,
            },
            // {
            //     title: "XS Việt Nam VIP",
            //     idNameMenu: "vietnamvip-lt",
            //     defaultTime: "",
            //     defaultTimer: "",
            //     isOneSec: false,
            //     isMienBac: true,
            //     isPingPong: false,
            //     isLivestream: false,
            //     newUpdate: "new",
            //     lotteryType: "VN",
            //     endtime: "",
            //     servertime: "",
            //     openResult: false,
            //     lotteryID: 206,
            //     urlRefer: "vietnam-vip",
            //     hasRule1Percent: true,
            //     isNew: false,
            // },
        ],
    },
];

export const LotteryFastMn: LotteryList = {
    titleRoot: 'XS Siêu Tốc Miền Nam',
    idRoot: 'fast-lt-mn',
    thumbnail: 'fastlt.png',
    submenu: [
        {
            title: 'Siêu Tốc 1 Giây',
            idNameMenu: '1-second',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: true,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'MMC',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 50,
        },
        {
            title: 'Siêu Tốc 45 Giây',
            idNameMenu: '45-seconds',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'Jz45',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 100,
        },
        {
            title: 'Siêu Tốc 1 Phút',
            idNameMenu: '1-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'CSSSC',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 101,
        },
        {
            title: 'Siêu Tốc 1.5 Phút',
            idNameMenu: '1.5-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'PHP',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 102,
        },
        {
            title: 'Siêu Tốc 2 Phút',
            idNameMenu: '2-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'SSCBF',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 103,
        },
        {
            title: 'Siêu Tốc 5 Phút',
            idNameMenu: '5-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'CqSSC',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 104,
        },
    ],
};
export const LotteryFastMb: LotteryList = {
    titleRoot: 'XS Siêu Tốc Miền Bắc',
    idRoot: 'fast-lt-mb',
    thumbnail: 'fastlt.png',
    submenu: [
        {
            title: 'Siêu Tốc 1 Giây',
            idNameMenu: '1-second',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: true,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'MMC',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 140,
        },
        {
            title: 'Siêu Tốc 45 Giây',
            idNameMenu: '45-seconds',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'Jz45',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 141,
        },
        {
            title: 'Siêu Tốc 1 Phút',
            idNameMenu: '1-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'CSSSC',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 142,
        },
        {
            title: 'Siêu Tốc 1.5 Phút',
            idNameMenu: '1.5-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'PHP',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 143,
        },
        {
            title: 'Siêu Tốc 2 Phút',
            idNameMenu: '2-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'SSCBF',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 144,
        },
        {
            title: 'Siêu Tốc 5 Phút',
            idNameMenu: '5-minutes',
            defaultTime: '',
            defaultTimer: '',
            isOneSec: false,
            isMienBac: false,
            isPingPong: false,
            isLivestream: false,
            newUpdate: '',
            lotteryType: 'CqSSC',
            endtime: '',
            servertime: '',
            openResult: false,
            lotteryID: 145,
        },
    ],
};
