import { createSlice } from '@reduxjs/toolkit';
import { MenuHeaderItem } from 'models/commonModel';
interface StateProps {
    menuGame: MenuHeaderItem[];
    msgUnread: number;
    showFirstNotice: boolean;
}
export const msgSystemSlice = createSlice({
    name: 'system',
    initialState: {
        menuGame: [],
        msgUnread: 0,
        showFirstNotice: false,
    } as StateProps,

    reducers: {
        cacheMenuGame(state, action) {
            state.menuGame = action.payload;
        },

        updateMessageUnread(state, action) {
            state.msgUnread = action.payload;
        },

        toggleShowFirstNotice(state, action) {
            state.showFirstNotice = action.payload;
        },
    },
});

const { actions, reducer } = msgSystemSlice;

export const { cacheMenuGame, updateMessageUnread, toggleShowFirstNotice } = actions;
export default reducer;
