import LoadingIcon from 'components/loading/LoadingIcon';
import accountStyles from './account-option.style';
import { useEffect, useRef, useState } from 'react';
import globalStyle from 'constants/global.style';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AgentSignPayload } from 'models/agentModel';
import { msgAlertInput } from 'constants/messageAlert';
import userApi from 'apis/userApi';
import toast from 'react-hot-toast';

function AgentSign() {
    const pointsscRef = useRef<number>(0);
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        setValue,
        formState: { errors },
    } = useForm<AgentSignPayload>();

    useEffect(() => {
        const agentSignInit = async () => {
            try {
                const { data } = await userApi.agentRegisterInit({});
                if (!data.msg) {
                    pointsscRef.current = data.pointssc;
                }
            } catch (error) {
                console.log(error);
            }
        };
        agentSignInit();
    }, []);

    const onSubmitAgentSign: SubmitHandler<AgentSignPayload> = async (values) => {
        const { pwd, pwdre } = values;
        if (pwd !== pwdre) {
            setError('pwdre', {
                message: msgAlertInput.prepassword.invalid,
            });
            setFocus('pwdre');
            return;
        }

        setLoading(true);
        const payload = {
            ...values,
            pointssc: pointsscRef.current,
        };
        try {
            const { data } = await userApi.agentRegisterSave(payload);
            if (!data.msg) {
                toast.success(`Đăng ký tài khoản ${values.loginname} thành công`);

                //reset form
                for (const key in values) {
                    setValue(key as any, '');
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className={`${accountStyles.wrap} ${accountStyles.mw800}`}>
                <h6 className={accountStyles.title}>Đăng Ký Thành Viên</h6>
                <form onSubmit={handleSubmit(onSubmitAgentSign)}>
                    <div className={accountStyles.flexbox}>
                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Tên Đăng Nhập</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    {...register('loginname', {
                                        required: true,
                                        maxLength: {
                                            value: 30,
                                            message: msgAlertInput.generic.maxLength(30),
                                        },
                                        pattern: {
                                            value: msgAlertInput.username.pattern,
                                            message: msgAlertInput.generic.noWhiteSpace,
                                        },
                                    })}
                                    className={`input-common`}
                                    type="text"
                                    placeholder={msgAlertInput.username.invalid}
                                />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['loginname'] && <small>{errors['loginname'].message || msgAlertInput.generic.require}</small>}</div>
                        </div>

                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Biệt Danh</label>
                            <div className={accountStyles.wrapInput}>
                                <input {...register('nickname', { required: true })} className={`input-common`} type="text" placeholder={msgAlertInput.nickname.invalid} />
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['nickname'] && <small>{errors['nickname'].message || msgAlertInput.generic.require}</small>}</div>
                        </div>

                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Mật Khẩu</label>
                            <div className={accountStyles.wrapInput}>
                                <input {...register('pwd', { required: true })} className={`input-common`} type={showPass ? 'tex' : 'password'} placeholder={msgAlertInput.password.invalid} />
                                <div className={globalStyle.togglePass} onClick={() => setShowPass(!showPass)}>
                                    {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['pwd'] && <small>{errors['pwd'].message || msgAlertInput.generic.require}</small>}</div>
                        </div>

                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Xác Nhận Mật Khẩu</label>
                            <div className={accountStyles.wrapInput}>
                                <input {...register('pwdre', { required: true })} className={`input-common`} type={showConfirmPass ? 'tex' : 'password'} placeholder={msgAlertInput.password.invalid} />
                                <div className={globalStyle.togglePass} onClick={() => setShowConfirmPass(!showConfirmPass)}>
                                    {showConfirmPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['pwdre'] && <small>{errors['pwdre'].message || msgAlertInput.generic.require}</small>}</div>
                        </div>
                    </div>

                    <div style={{ marginTop: '16px', width: 'calc(50% - 25px)' }}>
                        <button type="submit" className={`btn-primary ${accountStyles.btnSubmit}`}>
                            Tạo Tài Khoản
                        </button>
                    </div>
                </form>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default AgentSign;
