import { PromoDetailResponse } from 'models/promotionModel';
import styles from './marquee.style';
import Marquee from 'react-fast-marquee';
interface Props {
    dataList: PromoDetailResponse[];
    onShowDetail: (id: number) => void;
}
function MarqueeComponent(props: Props) {
    const { dataList, onShowDetail } = props;
    return (
        <div className={styles.wrap}>
            <Marquee pauseOnHover>
                {dataList.map((item) => (
                    <p className={styles.marqueeItem} key={item.id} onClick={() => onShowDetail(item.id)}>
                        {item.title}
                    </p>
                ))}
            </Marquee>
        </div>
    );
}

export default MarqueeComponent;
