import classNames from 'classnames';
import styles from './checkbox.style';
interface Props {
    isChecked: boolean;
    onToggleCheck: (value: boolean) => void;
    children: JSX.Element;
}

function CheckBox(props: Props) {
    const { isChecked, onToggleCheck, children } = props;
    return (
        <label className={styles.wrap} htmlFor="TOF">
            <input checked={isChecked} type="checkbox" id="TOF" onChange={(e) => onToggleCheck(e.target.checked)} />
            <div className={classNames(`${styles.rounded} `, { active: isChecked })}>
                <div className={styles.dotChecked} />
            </div>
            {children}
        </label>
    );
}

export default CheckBox;
