import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const switchButtonStyles = {
    wrap: css`
        display: flex;
        input:checked + label {
            background: ${colors.primary};
        }

        input:checked + label:after {
            left: calc(100% - 2px);
            background: ${colors.white};

            transform: translateX(-100%);
        }

        label:active:after {
            width: 30px;
        }
    `,
    inputCheckbox: css`
        height: 0;
        width: 0;
        visibility: hidden;
    `,
    labelCheckbox: css`
        cursor: pointer;
        text-indent: -9999px;
        width: 44px;
        height: 26px;
        background: #ccc;
        display: block;
        border-radius: 100px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 22px;
            height: 22px;
            background: ${colors.white};
            border-radius: 90px;
            transition: 0.3s;
        }
    `,
};
export default switchButtonStyles;
