import { css } from '@emotion/css';
import classNames from 'classnames';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { Bank3rdItem, BankDepositModel } from 'models/bankModel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import depositPageStyle from './deposit.styles';
import TagLabel from 'components/tag-label/TagLabel';
const cdnUrl = process.env.REACT_APP_CDN_URL;

const listAmountQuick = [
    {
        value: 200000,
        label: '200K',
    },
    {
        value: 500000,
        label: '500K',
    },
    {
        value: 1000000,
        label: '1TR',
    },
    {
        value: 2000000,
        label: '2TR',
    },
    {
        value: 5000000,
        label: '5TR',
    },
    {
        value: 10000000,
        label: '10TR',
    },
    {
        value: 20000000,
        label: '20TR',
    },
    {
        value: 50000000,
        label: '50TR',
    },
    {
        value: 100000000,
        label: '100TR',
    },
    {
        value: 300000000,
        label: '300TR',
    },
];

interface Props {
    bank3rdList: Bank3rdItem[];
    minMaxDeposit: { min: number; max: number };
    depositSelect: BankDepositModel;
    onSubmit3rd: (payload: { amount: number; bankCode: string }) => void;
}

function Bank3rdDeposit(props: Props) {
    const { bank3rdList, minMaxDeposit, onSubmit3rd, depositSelect } = props;
    const [bankSelect, setBankSelect] = useState<Bank3rdItem>({} as Bank3rdItem);
    const [amountDeposit, setAmountDeposit] = useState('');

    useEffect(() => {
        if (Object.keys(depositSelect).length > 0) {
            setBankSelect({} as Bank3rdItem);
        }
    }, [depositSelect]);

    const selectBank = (bankItem: Bank3rdItem) => {
        setBankSelect(bankItem);
    };

    const submitDeposit = async () => {
        if (Object.keys(bankSelect).length <= 0) {
            toast.error('Vui lòng chọn ngân hàng');
            return;
        }
        if (!amountDeposit) {
            toast.error('Vui lòng chọn số tiền muốn nạp');
            return;
        }
        const payload = {
            amount: Number(amountDeposit.replace(/[,]/g, '')),
            bankCode: bankSelect.code,
        };
        console.log(payload);
        onSubmit3rd(payload);
    };

    const mapIconBank = (bankIcon: string) => {
        if (bankIcon.includes('.jpg') || bankIcon.includes('.png')) {
            return `${cdnUrl}/bank-list/${bankIcon}`;
        } else {
            return `${cdnUrl}/bank-list/${bankIcon}.jpg`;
        }
    };

    return bank3rdList.length > 0 ? (
        <div className={styles.wrap3rd}>
            {bankSelect.name ? (
                <h6 className={depositPageStyle.titleBankGroup}>
                    2. Bạn đang chọn ngân hàng <span>{bankSelect.name}</span>
                </h6>
            ) : (
                <h6 className={depositPageStyle.titleBankGroup}>2 .Chọn ngân hàng thanh toán</h6>
            )}
            <div className={depositPageStyle.bankList}>
                {bank3rdList.map((bank, index) => (
                    <div className={classNames(depositPageStyle.bankItem, { active: bankSelect.code === bank.code })} key={index} onClick={() => selectBank(bank)}>
                        <div className={depositPageStyle.bankItemInner}>
                            {bank.icon.includes('http') ? (
                                <img className={`${depositPageStyle.bankIcon}`} src={bank.icon} alt="" />
                            ) : (
                                <img className={`${depositPageStyle.bankIcon}`} src={mapIconBank(bank.icon)} alt="" />
                            )}
                            {bankSelect.code === bank.code && <img className={depositPageStyle.iconBankSelect} src={require('assets/images/icons/tick-bank.png')} alt="" />}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.wrap}>
                <div className={styles.left}>
                    <h6 className={depositPageStyle.titleBankGroup}>3. Chọn số tiền nạp</h6>
                    <div className={styles.amountList}>
                        {listAmountQuick.map((item) => (
                            <div className={styles.amountItem} key={item.value}>
                                <p className={styles.amountItemInner} onClick={() => setAmountDeposit(String(item.value))}>
                                    {item.label}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.right}>
                    <h6 className={depositPageStyle.titleBankGroup}>Hoặc nhập số tiền nạp (VNĐ)</h6>
                    <NumericFormat className="input-common" thousandSeparator="," placeholder="Nhập số tiền..." value={amountDeposit} onChange={(e) => setAmountDeposit(e.target.value)} />
                    <div style={{ marginTop: '8px' }}>
                        <TagLabel label={`Tối thiểu ${new Intl.NumberFormat().format(minMaxDeposit.min)} - Tối đa ${new Intl.NumberFormat().format(minMaxDeposit.max)}`} />
                    </div>
                </div>
            </div>
            <div style={{ width: 'calc(60% - 50px)', marginTop: '30px' }}>
                <button type="button" className={globalStyle.btnPrimary} onClick={submitDeposit}>
                    Gửi Lệnh
                </button>
            </div>
        </div>
    ) : (
        <></>
    );
}

const styles = {
    wrap3rd: css`
        margin-top: 16px;
    `,
    wrap: css`
        display: flex;
        align-items: flex-start;
        gap: 50px;
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid ${colors.borderTable};
    `,
    left: css`
        flex: 0 0 calc(60% - 50px);
    `,
    amountList: css`
        display: flex;
        flex-wrap: wrap;
    `,
    amountItem: css`
        padding: 4px;
        flex: 0 0 20%;
        max-width: 20%;
    `,
    amountItemInner: css`
        padding: 8px 0;
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        cursor: pointer;
        text-align: center;

        &:active {
            background: ${colors.primary};
        }
    `,
    right: css`
        flex: 0 0 40%;
    `,
};

export default Bank3rdDeposit;
