import { css } from '@emotion/css';

const appStyles = {
    root: css`
        height: 100vh;
        overflow-y: auto;
    `,
    miniGameIframe: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 14;
        opacity: 0;
        pointer-events: none;
        transform: scale(0.7);
        transition: 0.25s;
        &.show {
            opacity: 1;
            pointer-events: initial;
            transform: scale(1);
        }
        iframe {
            width: inherit;
            height: inherit;
            overflow: hidden;
            border: none;
        }
    `,
};

export default appStyles;
