import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const introAppStyle = {
    intro: css`
        color: ${colors.white};
    `,
    introTitle: css`
        font-size: 18px;
        text-align: center;
        margin-bottom: 18px;
    `,
    introDesc: css`
        font-size: 14px;
    `,
};
export default introAppStyle;
