import { useEffect } from 'react';
import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';

const TOAST_LIMIT = 3;

function ToastAlert() {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    }, [toasts]);
    return (
        <Toaster
            toastOptions={{
                style: {
                    fontSize: '18px',
                },
                error: {
                    style: {
                        color: 'red',
                    },
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <>
                            {icon}
                            {message}
                            {t.type !== 'loading' && (
                                <button onClick={() => toast.dismiss(t.id)} style={{ cursor: 'pointer' }}>
                                    <IoMdClose size={24} />
                                </button>
                            )}
                        </>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
}

export default ToastAlert;
