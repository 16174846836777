import { LotteryGame } from 'models/lotteryListModel';
import styles from './lottery-home.style';
import NumberLottery from 'components/number-lottery/NumberLottery';
import TimeCountDown from 'components/Time-countdown/TimeCountDown';
import { colors } from 'constants/theme';
import Button from 'components/button/Button';
import lotteryApi from 'apis/lotteryApi';
import { useEffect, useRef, useState } from 'react';
import { RootState, useAppSelector } from 'cache/store';
import alertMsg from 'components/alert-message/alert-message';
import { msgAuth } from 'constants/messageAlert';
import { useNavigate } from 'react-router-dom';
import { scrollToTopPage } from 'utils/function';

let lotteryIdListIsLoaded: number[] = [];

interface Props {
    data: LotteryGame;
}
interface TimeCountDownProps {
    opentime: string;
    endtime: string;
    servertime: string;
}

function LotteryCard(props: Props) {
    const { data } = props;
    const navigate = useNavigate();
    const positionArea = useRef<number>(0);

    const { isLogged } = useAppSelector((state: RootState) => state.auth);
    const { triggerFastLottery } = useAppSelector((state: RootState) => state.config);

    const [lotteryData, setLotteryData] = useState<LotteryGame>(data);
    const [countDownTime, setCountDownTime] = useState<TimeCountDownProps>({} as TimeCountDownProps);
    const [issue, setIssue] = useState('');
    useEffect(() => {
        if (data.lotteryID && !lotteryIdListIsLoaded.includes(data.lotteryID) && triggerFastLottery) {
            lotteryIdListIsLoaded.push(data.lotteryID);
            getOpenWinHistoryRecord(data.lotteryID);
        }

        setCountDownTime({
            opentime: data.opentime,
            endtime: data.saleend,
            servertime: data.nowtime,
        });
        setIssue(data.issue);
    }, [data]);

    useEffect(() => {
        const rootEl = document.getElementById('appRoot');
        if (rootEl) {
            rootEl.addEventListener('scroll', listenerEventScroll);

            return () => {
                rootEl.removeEventListener('scroll', listenerEventScroll);
            };
        }
    }, []);

    const listenerEventScroll = (event: Event) => {
        var element: any = event.target;

        positionArea.current = element.scrollTop;
    };

    const getOpenWinHistoryRecord = async (lotteryid: number) => {
        const payload = {
            id: lotteryid,
            num: 1, // get 1 record
        };
        try {
            const { data } = await lotteryApi.lotteryWinOpenNumber(payload);
            if (!data.msg) {
                if (data.reslist.length > 0) {
                    const openwinNumber = data.reslist[0].winnumber.split(',')[0];
                    const newLotteryData = { ...lotteryData, openwin: openwinNumber.split(''), loading: false };
                    setLotteryData(newLotteryData);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getOpenWin = async (lotteryid: number, requestTimes: number) => {
        if (positionArea.current < 800) {
            return;
        }

        if (requestTimes === 5) {
            setLotteryData((data) => ({ ...data, loading: true }));
        }
        requestTimes--;
        if (requestTimes <= 0) {
            setLotteryData((data) => ({ ...data, loading: false }));
            return;
        }

        const payload = {
            issue,
            lotteryid,
        };
        try {
            const { data } = await lotteryApi.getOpenWinRecored(payload);
            if (!data.msg) {
                const newLotteryData = { ...lotteryData, openwin: data.code[0].split(''), loading: false };
                getCurrentLotteryIssue();
                setLotteryData(newLotteryData);
            } else {
                setTimeout(() => {
                    getOpenWin(lotteryid, requestTimes);
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getCurrentLotteryIssue = async () => {
        try {
            const { data } = await lotteryApi.lotteryCurrentIssue({ lotteryid: lotteryData.lotteryID });
            if (!data.msg) {
                setIssue(data.issue);
                setCountDownTime({
                    opentime: data.opentime,
                    endtime: data.saleend,
                    servertime: data.nowtime,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const selectGame = () => {
        if (!isLogged) {
            alertMsg(msgAuth.login);
            return;
        }
        scrollToTopPage();
        navigate(`/lottery?ltId=${lotteryData.lotteryID}`);
    };
    return (
        <div className={styles.card} key={lotteryData.lotteryID}>
            <div className={styles.top}>
                <img src={require(`assets/images/icons-lt/${lotteryData.lotteryID}.png`)} alt="" />
                <div className={styles.issue}>
                    <p>Lượt xổ: {issue}</p>
                    <h6>{lotteryData.title}</h6>
                </div>
            </div>
            <div className={styles.result}>
                {lotteryData.openwin ? lotteryData.openwin.map((num: string, index: number) => <NumberLottery value={Number(num)} isRun={lotteryData.loading} key={index} />) : <></>}
            </div>
            <div className={styles.bottom}>
                <TimeCountDown
                    opentime={countDownTime.opentime}
                    endtime={countDownTime.endtime}
                    servertime={countDownTime.servertime}
                    styleProps={{ fontSize: '14px' }}
                    onFinish={() => getOpenWin(lotteryData.lotteryID, 5)}
                />

                <Button text="CƯỢC NGAY" bg="none" click={selectGame} borderColor={colors.orange} rounded bgHover={colors.orange} textSize="12px" weight="500" />
            </div>
        </div>
    );
}

export default LotteryCard;
