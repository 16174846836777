import { MessageAlertType } from 'models/commonModel';
import store from 'cache/store';
import { toggleMessage } from 'features/configSlice';

const alertMsg = (message: string, type?: MessageAlertType) => {
    const payload = {
        message,
        type: type || 'error',
    };
    store.dispatch(toggleMessage(payload));
};
export default alertMsg;
