import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { NumHighlight } from 'models/lotteryModel';
import { useEffect, useState } from 'react';
import { getFormatHightLightLottery } from 'utils/function';

interface Props {
    resultList: string[];
    listNumHover?: string[];
    borderColor?: string;
    typeInputId: string;
}
function ResultLotteryMienNam(props: Props) {
    const { resultList, borderColor, typeInputId } = props;

    const [numList, setNumList] = useState<string[]>(resultList);
    const [formatData, setFormatData] = useState<NumHighlight>({} as NumHighlight);

    useEffect(() => {
        if (typeInputId) {
            const format = getFormatHightLightLottery(typeInputId, 'MN');
            setFormatData(format);
        }
    }, [typeInputId]);

    useEffect(() => {
        if (Object.keys(formatData).length > 0) {
            setNumList(resultList);
        }
    }, [formatData, resultList]);

    const mapHightLight = (index: number, rowIndex: number) => {
        if (Object.keys(formatData).length > 0) {
            if (formatData.row === String(rowIndex)) {
                return (
                    <>
                        <span>{numList[index].substring(0, Number(formatData.posStart))}</span>
                        <span className={styles.hightLight}>{numList[index].substring(Number(formatData.posStart), Number(formatData.posEnd))}</span>
                        <span>{numList[index].substring(Number(formatData.posEnd), numList[index].length)}</span>
                    </>
                );
            } else {
                if (formatData.row.split(',')[rowIndex] === String(rowIndex)) {
                    return (
                        <>
                            <span>{numList[index].substring(0, Number(formatData.posStart.split(',')[rowIndex]))}</span>
                            <span className={styles.hightLight}>{numList[index].substring(Number(formatData.posStart.split(',')[rowIndex]), Number(formatData.posEnd.split(',')[rowIndex]))}</span>
                            <span>{numList[index].substring(Number(formatData.posEnd.split(',')[rowIndex]), numList[index].length)}</span>
                        </>
                    );
                } else {
                    return <span>{numList[index]}</span>;
                }
            }
        }
        return <span>xxx</span>;
    };

    const mapHightLightLast = (index: number, rowIndex: number) => {
        if (Object.keys(formatData).length > 0) {
            if (formatData.row === String(rowIndex)) {
                return (
                    <>
                        <span>{numList[index].substring(0, Number(formatData.posStart))}</span>
                        <span className={styles.hightLight}>{numList[index].substring(Number(formatData.posStart), Number(formatData.posEnd))}</span>
                        <span>{numList[index].substring(Number(formatData.posEnd), numList[index].length)}</span>
                    </>
                );
            } else {
                if (formatData.row.split(',')[rowIndex] === String(rowIndex)) {
                    return (
                        <>
                            <span>{numList[index].substring(0, Number(formatData.posStart.split(',')[rowIndex]))}</span>
                            <span className={styles.hightLight}>{numList[index].substring(Number(formatData.posStart.split(',')[rowIndex]), Number(formatData.posEnd.split(',')[rowIndex]))}</span>
                            <span>{numList[index].substring(Number(formatData.posEnd.split(',')[rowIndex]), numList[index].length)}</span>
                        </>
                    );
                } else if (formatData.row.split(',')[1] === String(rowIndex)) {
                    return (
                        <>
                            <span>{numList[index].substring(0, Number(formatData.posStart.split(',')[1]))}</span>
                            <span className={styles.hightLight}>{numList[index].substring(Number(formatData.posStart.split(',')[1]), Number(formatData.posEnd.split(',')[1]))}</span>
                            <span>{numList[index].substring(Number(formatData.posEnd.split(',')[1]), numList[index].length)}</span>
                        </>
                    );
                } else {
                    return <span>{numList[index]}</span>;
                }
            }
        }
        return <span>xxx</span>;
    };

    return (
        <div className={styles.wrap} style={{ borderColor }}>
            <div className={styles.rows}>
                <p className={styles.label}>Giải ĐB</p>
                <p>{mapHightLight(0, 0)}</p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải nhất</p>
                <p>
                    <span>{mapHightLight(1, 1)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải nhì</p>
                <p>
                    <span>{mapHightLight(2, 2)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải ba</p>
                <p>
                    <span>{mapHightLight(3, 3)}</span> - <span>{mapHightLight(4, 3)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải tư</p>
                <p>
                    <span>{mapHightLight(5, 4)}</span> - <span>{mapHightLight(6, 4)}</span> - <span>{mapHightLight(7, 4)}</span> - <span>{mapHightLight(8, 4)}</span> -{' '}
                    <span>{mapHightLight(9, 4)}</span> - <span>{mapHightLight(10, 4)}</span> - <span>{mapHightLight(11, 4)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải năm</p>
                <p>
                    <span>{mapHightLight(12, 5)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải sáu</p>
                <p>
                    <span>{mapHightLight(13, 6)}</span> - <span>{mapHightLight(14, 6)}</span> - <span>{mapHightLight(15, 6)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải bảy</p>
                <p>
                    <span>{mapHightLightLast(16, 7)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải tám</p>
                <p>
                    <span>{mapHightLightLast(17, 8)}</span>
                </p>
            </div>
        </div>
    );
}

const styles = {
    wrap: css`
        border-radius: 8px;
        border: 1px solid ${colors.darkPrimary};
    `,
    rows: css`
        display: flex;
        border-bottom: 1px solid ${colors.borderTable};
        padding: 6px 12px;
        &:last-child {
            border-bottom: none;
        }
        p {
            text-align: center;
            &:last-child {
                flex: 1;
            }
        }
    `,
    label: css`
        flex: 0 0 100px;
        color: ${colors.yellow};
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${colors.borderTable};
    `,
    hightLight: css`
        color: ${colors.red};
        font-weight: 500;
    `,
};

export default ResultLotteryMienNam;
