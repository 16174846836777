import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const matchFootballStyle = {
    wrap: css`
        position: relative;
        height: 100%;
    `,
    btnNavigate: css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100px;
        z-index: 2;
        display: flex;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.9);
        &:disabled {
            opacity: 0.5;
            cursor: initial;
        }
        svg {
            polyline {
                stroke: ${colors.orange};
            }
        }
    `,
    btnPrev: css`
        left: -24px;
    `,
    btnNext: css`
        right: -24px;
    `,
    sportList: css`
        width: 50%;
        height: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .swiper {
            width: 100%;
        }
        .match-title {
            text-align: center;
            text-shadow: 1px 1px 2px #09062e;
            color: ${colors.white};
            font-weight: 700;
            background: #cf0306;
            border: 1px solid #ffafb0;
            border-radius: 8px;
            width: fit-content;
            padding: 4px 12px;
            margin: 0 auto;
            margin-bottom: 16px;
        }
    `,
    paginationMatch: css`
        position: absolute;
        bottom: 20px !important;
        left: 50% !important;
        text-align: center;
        transform: translateX(-50%);
        .swiper-pagination-bullet {
            border: 1px solid #b3b3b3;
            transition: 0.25s;
            opacity: 1;
            width: 12px;
            height: 12px;
            border-radius: 10px;
            margin: 0 4px;
        }
        .swiper-pagination-bullet-active {
            background: ${colors.primary};
            border-color: ${colors.primary};
        }
    `,
    sportBg: css`
        position: absolute;
    `,
    playerSport: css`
        position: absolute;

        right: 0;
        top: -100px;
    `,
    matchItem: css`
        position: relative;
        .bg-match {
            position: absolute;
            width: 100%;
            top: 20px;
        }
    `,
    matchTeam: css`
        display: flex;
        align-items: flex-end;
        position: relative;
        padding-left: 16px;
        gap: 16px;
        padding-bottom: 8px;
        .team {
            text-align: center;
            img {
                width: 80px;
                height: 80px;
                object-fit: contain;
            }
            p {
                color: ${colors.white};
            }
        }
        .time {
            text-align: center;
            padding-bottom: 8px;
            p {
                color: ${colors.white};
                font-size: 18px;
                margin-top: 8px;
            }
        }
    `,
    matchTime: css`
        text-align: center;
        padding: 8px 0;
        color: ${colors.white};
        p,
        h6 {
            text-shadow: 1px 1px 2px rgba(9, 6, 46, 0.67);
        }
        h6 {
            margin-bottom: 4px;
        }
    `,
    rateBetInfo: css`
        display: flex;
        padding-top: 8px;
        .rate-item {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: center;
            color: ${colors.white};

            .rate-full {
                margin: 4px auto 0;
                border: 1px solid #c0c9db;
                background: rgba(0, 0, 0, 0.28);
                width: fit-content;
                padding: 4px 16px;
                border-radius: 32px;
                display: flex;
                align-items: center;
                gap: 24px;
            }
        }
    `,
    btnBetNow: css`
        text-align: center;
        margin-top: 16px;
        margin-bottom: 8px;
        button {
            background: ${colors.white};
            color: #cf0306;
            border-radius: 8px;
            padding: 8px;
            width: 50%;
            font-weight: 600;
        }
    `,
    noMatch: css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        p {
            width: 80%;
            color: ${colors.white};
            text-align: center;
        }
    `,
};
export default matchFootballStyle;
