export class Base64Util {}

const PADCHAR = '=';
const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
export const encode = (s: string) => {
    s = String(s);

    let i: number;
    let b10: number;
    const x = [];
    const imax = s.length - (s.length % 3);

    if (s.length === 0) {
        return s;
    }

    for (i = 0; i < imax; i += 3) {
        b10 = (getByte(s, i) << 16) | (getByte(s, i + 1) << 8) | getByte(s, i + 2);
        x.push(ALPHA.charAt(b10 >> 18));
        x.push(ALPHA.charAt((b10 >> 12) & 63));
        x.push(ALPHA.charAt((b10 >> 6) & 63));
        x.push(ALPHA.charAt(b10 & 63));
    }

    switch (s.length - imax) {
        case 1:
            b10 = getByte(s, i) << 16;
            x.push(ALPHA.charAt(b10 >> 18) + ALPHA.charAt((b10 >> 12) & 63) + PADCHAR + PADCHAR);
            break;
        case 2:
            b10 = (getByte(s, i) << 16) | (getByte(s, i + 1) << 8);
            x.push(ALPHA.charAt(b10 >> 18) + ALPHA.charAt((b10 >> 12) & 63) + ALPHA.charAt((b10 >> 6) & 63) + PADCHAR);
            break;
    }

    return x.join('');
};

export const decode = (s: string) => {
    let pads = 0;
    let i = s.length;
    let b10 = s.length;
    let imax = s.length;
    const x = [];

    s = String(s);

    if (imax === 0) {
        return s;
    }

    if (s.charAt(imax - 1) === PADCHAR) {
        pads = 1;
        if (s.charAt(imax - 2) === PADCHAR) {
            pads = 2;
        }
        imax -= 4;
    }

    for (i = 0; i < imax; i += 4) {
        b10 = (getByte64(s, i) << 18) | (getByte64(s, i + 1) << 12) | (getByte64(s, i + 2) << 6) | getByte64(s, i + 3);
        x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 255, b10 & 255));
    }

    switch (pads) {
        case 1:
            b10 = (getByte64(s, i) << 18) | (getByte64(s, i + 1) << 12) | (getByte64(s, i + 2) << 6);
            x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 255));
            break;
        case 2:
            b10 = (getByte64(s, i) << 18) | (getByte64(s, i + 1) << 12);
            x.push(String.fromCharCode(b10 >> 16));
            break;
    }

    return x.join('');
};

const getByte = (s: string, i: number) => {
    const x = s.charCodeAt(i);
    return x;
};

const getByte64 = (s: string, i: number) => {
    const idx = ALPHA.indexOf(s.charAt(i));
    return idx;
};
