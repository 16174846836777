import { css } from '@emotion/css';
import userApi from 'apis/userApi';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import LoadingIcon from 'components/loading/LoadingIcon';
import { cacheUserInfo } from 'features/authSlice';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
const linkIframe = process.env.REACT_APP_VERIFY_PHONE_URL;

export default function PersonPhoneTab() {
    const dispatch = useAppDispatch();

    const { userInfo } = useAppSelector((state: RootState) => state.auth);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [loading, setLoading] = useState(false);
    const [iframLoaded, setIframLoaded] = useState(false);

    useEffect(() => {
        // nhan thong bao tu iframe
        window.addEventListener('message', eventOnMessageIframe);

        return () => {
            window.removeEventListener('message', eventOnMessageIframe);
        };
    }, []);

    useEffect(() => {
        if (userInfo.phone && iframLoaded) {
            const getConfigFirebase = async () => {
                try {
                    const { data } = await userApi.getFirebaseConfig({});
                    if (!data.msg) {
                        let dataVerifyPhone = {
                            action: 'INIT_VERIFY',
                            params: {
                                verifiedPhone: userInfo.verifiedphone, // flag check is verify phone
                                phoneNumber: userInfo.phone,
                                arrConfigFirebase: JSON.stringify(data['data'][0]),
                            },
                        };
                        if (iframeRef.current) {
                            iframeRef.current.contentWindow?.postMessage(dataVerifyPhone, '*');
                        }
                    } else {
                        toast.error(data.msg);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            getConfigFirebase();
        }
    }, [iframLoaded, userInfo]);

    const eventOnMessageIframe = (event: any) => {
        const data = event.data;
        if (data.action === 'CHECK_PHONE_NUMBER') {
            checkPhoneNumber(data.params.phoneNumber);
        } else if (data.action === 'TOKEN_VERIFY_DONE') {
            submitPhoneVerifyDone(data.params.phoneNumber, data.params.tokenFB);
        }
    };

    const checkPhoneNumber = async (phone: string) => {
        setLoading(true);
        try {
            const { data } = await userApi.phoneCheck({ phone });
            if (!data.msg) {
                if (iframeRef.current) {
                    iframeRef.current.contentWindow?.postMessage({ action: 'CHECK_PHONE_VALID' }, '*');
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const submitPhoneVerifyDone = async (phoneNumber: string, tokenFB: string) => {
        setLoading(true);
        try {
            const { data } = await userApi.confirmNumPhone({ phone: phoneNumber, firebaseId: tokenFB });
            if (!data.msg) {
                toast.success('Xác minh SĐT thành công!');
                //update user info cache
                const newUserInfo = { ...userInfo, verifiedphone: 1, phone: phoneNumber };
                dispatch(cacheUserInfo(newUserInfo));
                if (iframeRef.current) {
                    iframeRef.current.contentWindow?.postMessage({ action: 'SUCCESS_VERIFY' }, '*');
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={styles.iframeWrap}>
                <iframe src={linkIframe} title="alb88 verify phone" ref={iframeRef} onLoad={() => setIframLoaded(true)}></iframe>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    iframeWrap: css`
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        height: 100%;
        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    `,
};
