import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';

const bankApi = {
    getListBankQi(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'bankAvailable';
        return axiosClient.post(serviceType.BANK_SERVICE, payload);
    },
    createOrderBank(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'CreateDepositOrder';
        return axiosClient.post(serviceType.BANK_SERVICE, payload);
    },
    getDetailOrder(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload['flag'] = 'GetDepositDetail';
        return axiosClient.post(serviceType.BANK_SERVICE, payload);
    },
};
export default bankApi;
