import commonApi from 'apis/commonApi';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './promo-detail.styles';
import globalStyle from 'constants/global.style';
import { scrollToTopPage } from 'utils/function';
import { IoCaretBack } from 'react-icons/io5';
import { colors } from 'constants/theme';
interface PromoDetail {
    id: number;
    title: string;
    content: string;
    [key: string]: any;
}
function PromotionDetail() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [promoData, setPromoData] = useState<PromoDetail>({} as PromoDetail);
    useEffect(() => {
        if (id) {
            const getMsgDetail = async () => {
                try {
                    const { data } = await commonApi.sysMsgBeanInfo({ id: Number(id) });
                    if (!data.msg) {
                        setPromoData(data.sysmsg);
                        scrollToTopPage();
                    } else {
                        toast.error(data.msg);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            getMsgDetail();
        }
    }, [id]);

    return (
        <div className={styles.wrap}>
            <div className={globalStyle.container}>
                <div className={styles.content}>
                    <button type="button" className={styles.btnBack} onClick={() => navigate(-1)}>
                        <IoCaretBack size={32} color={colors.blackText} />
                    </button>
                    <div className={styles.contentInner} dangerouslySetInnerHTML={{ __html: promoData.content }}></div>
                </div>
            </div>
        </div>
    );
}

export default PromotionDetail;
