import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const layoutStyles = {
    wrap: css`
        display: flex;
        flex-direction: column;
        height: 100%;
    `,
    body: css`
        flex: 1;
        background: ${colors.bgDark};
    `,
};
export default layoutStyles;
