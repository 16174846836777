import { css } from '@emotion/css';
import classNames from 'classnames';
import ResultLotteryMienBac from 'components/result-game/ResultLotteryMienBac';
import ResultLotteryMienNam from 'components/result-game/ResultLotteryMienNam';
import TableLotteryDauDuoi from 'components/result-game/TableLotteryDauDuoi';
import { colors } from 'constants/theme';
import { IssueResultWinOpenNumber } from 'models/lotteryModel';
import { useEffect, useState } from 'react';
import { checkIsTypeLotteryMienBac, checkIsTypeLotteryMienNam } from 'utils/function';

interface Props {
    winNumberList: IssueResultWinOpenNumber[];
    ltId: number;
    typeInputId: string;
}
function LotteryWinNumber(props: Props) {
    const { winNumberList, ltId, typeInputId } = props;
    const [winNumberActive, setWinNumberActive] = useState<IssueResultWinOpenNumber>(winNumberList[0]);

    useEffect(() => {
        if (winNumberList.length > 0) {
            setWinNumberActive(winNumberList[0]);
        }
    }, [winNumberList]);

    const changeIssue = (issue: string) => {
        const resultSelect = winNumberList.find((item) => item.issue === issue);
        if (resultSelect) {
            setWinNumberActive(resultSelect);
        }
    };

    const hightLightNum = (numList: string[]) => {};

    return (
        <div className={styles.wrap}>
            <div className={styles.tableResult}>
                <h6 className="">Kết quả: {winNumberActive.issue}</h6>
                {checkIsTypeLotteryMienNam(ltId) && <ResultLotteryMienNam resultList={winNumberActive.code} borderColor={colors.darkPrimary} typeInputId={typeInputId} />}
                {checkIsTypeLotteryMienBac(ltId) && <ResultLotteryMienBac resultList={winNumberActive.code} borderColor={colors.darkPrimary} typeInputId={typeInputId} />}
            </div>
            <div style={{ fontSize: '13px' }}>
                <TableLotteryDauDuoi resultList={winNumberActive.code} methodid={117} borderColor={colors.darkPrimary} onHover={hightLightNum} />
            </div>
            <div className={styles.issueList}>
                {winNumberList.map((item, index) => (
                    <div className={classNames(styles.issueItem, { active: item.issue === winNumberActive.issue })} key={index} onClick={() => changeIssue(item.issue)}>
                        {item.issue}
                    </div>
                ))}
            </div>
        </div>
    );
}

const styles = {
    wrap: css`
        display: flex;
        gap: 6px;
    `,
    issueList: css`
        border: 1px solid ${colors.darkPrimary};
        border-radius: 8px;
        padding: 4px 2px;
        max-height: 330px;
        overflow-y: auto;
    `,
    issueItem: css`
        padding: 4px 8px;
        text-align: center;
        min-width: 180px;
        cursor: pointer;
        border-radius: 4px;
        margin: 2px 0;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${colors.borderTable};
        }
        &.active {
            background: ${colors.darkPrimary};
            color: ${colors.white};
        }
        &:last-child {
            border-bottom: none;
        }
    `,
    tableResult: css`
        display: flex;
        flex-direction: column;
        font-size: 14px;
        h6 {
            padding: 12px;
            /* background: #0e1d42; */
            text-align: center;
        }
    `,
};

export default LotteryWinNumber;
