import { colors } from 'constants/theme';
import styles from './update-user.style';
import { IoMdClose } from 'react-icons/io';
import { msgAlertInput } from 'constants/messageAlert';
import CheckBox from 'components/checkbox/CheckBox';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormValuesUpdateUser } from 'models/authModel';
import { useState } from 'react';
import toast from 'react-hot-toast';
import globalStyle from 'constants/global.style';
import { Link } from 'react-router-dom';
import userApi from 'apis/userApi';
import LoadingIcon from 'components/loading/LoadingIcon';
import { useAppDispatch } from 'cache/store';
import { cacheUserInfo, login, updateAgentType } from 'features/authSlice';
import { appName } from 'config/app.config';
type InputId = 'uPwd' | 'uCheckpwd' | 'uNickname' | 'uPhone' | 'uEmail';
interface InputItem {
    id: InputId;
    label: string;
    holder: string;
    inputType: string;
    require: boolean;
    togglePass?: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
}
const listInput: InputItem[] = [
    {
        id: 'uNickname',
        label: 'Biệt Danh',
        holder: 'Nhập ký tự chữ và số',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'uPwd',
        label: 'Mật Khẩu',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'uCheckpwd',
        label: 'Mật Khẩu Thanh Toán',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },

    {
        id: 'uPhone',
        label: 'Số Điện Thoại',
        holder: 'Nhập vào số điện thoại',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 10,
            message: msgAlertInput.generic.maxLength(10),
        },
        pattern: {
            value: /^[0-9]{6,20}$/,
            message: 'Số điện thoại không hợp lệ',
        },
    },
    {
        id: 'uEmail',
        label: 'Email (Không bắt buộc)',
        holder: 'Nhập đúng định dạng email',
        inputType: 'text',
        require: false,
    },
];
interface Props {
    onClose: () => void;
    loginName: string;
}
function UpdateUser(props: Props) {
    const { onClose, loginName } = props;
    const dispatch = useAppDispatch();
    const [isCheckedTOF, setIsCheckedTOF] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassPay, setShowPassPay] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        register,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<FormValuesUpdateUser>();

    const handleRegister: SubmitHandler<FormValuesUpdateUser> = async (values) => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (values.uEmail !== '' && !values.uEmail.match(validRegex)) {
            toast.error('Vui lòng nhập đúng định dạng email');
            return;
        }
        if (!isCheckedTOF) {
            toast.error('Vui lòng chọn chấp nhận các điều khoản');
            return;
        }
        if (values.uPwd === values.uCheckpwd) {
            setFocus('uCheckpwd');
            setError('uCheckpwd', {
                type: 'value',
                message: 'Mật khẩu thanh toán không được giống với đăng nhập',
            });
            return;
        }
        const payload = {
            ...values,
            uEmail: values.uEmail || `${loginName.toLowerCase().trim()}_noreply@gmail.com`,
        };
        setLoading(true);
        try {
            const { data } = await userApi.updateNewUserInfo(payload);
            if (!data.msg) {
                toast.success('Cập nhật thành công!');
                onClose();

                //change state logged in store "auth slice"
                dispatch(login(true));

                //cache userinfo to store "auth slice"
                dispatch(cacheUserInfo(data.user));

                //cache agent type account
                let agentTypeUser = null;
                if (data.user.usertype === -2) {
                    agentTypeUser = 1;
                } else if (data.user['param9'] && data.user['param9'] !== '') {
                    agentTypeUser = 2;
                }
                dispatch(updateAgentType(agentTypeUser));
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={styles.wrapTransfer}>
                <div className={styles.heading}>
                    <h6 className={styles.title}>Cập nhật tài khoản</h6>
                    <button type="button" className={styles.btnClose} onClick={onClose}>
                        <IoMdClose size={36} color={colors.primary} />
                    </button>
                </div>
                <div className="">
                    <h6 className={styles.gretting}>
                        Xin chào, <span>{loginName}</span>
                    </h6>
                    <p className={styles.desc}>Vui lòng cập nhật thông tin và thay đổi mật khẩu lần đăng nhập đầu tiên</p>
                    <form className={styles.form} onSubmit={handleSubmit(handleRegister)}>
                        {listInput.map((item, index) => (
                            <div className={styles.inputWrap} key={index}>
                                <label htmlFor={item.id}>{item.label}</label>
                                <input
                                    type={item.id === 'uPwd' ? (!showPass ? item.inputType : 'text') : item.id === 'uCheckpwd' ? (!showPassPay ? item.inputType : 'text') : item.inputType}
                                    id={item.id}
                                    placeholder={item.holder}
                                    className={`input-common ${styles.input}`}
                                    {...register(item.id, {
                                        required: item.require,
                                        pattern: item.pattern,
                                        maxLength: item.maxLength,
                                        minLength: item.minLength,
                                    })}
                                />
                                {/* toggle password login */}
                                {item.togglePass && item.id === 'uPwd' && (
                                    <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                                        {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                    </div>
                                )}

                                {/* toggle password pay */}
                                {item.togglePass && item.id === 'uCheckpwd' && (
                                    <div className={styles.togglePass} onClick={() => setShowPassPay(!showPassPay)}>
                                        {showPassPay ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                    </div>
                                )}

                                {/* base error  */}
                                <div className={styles.errorInput}>{(errors as any)[item.id] && <small>{(errors as any)[item.id].message || 'Vui lòng nhập trường này!'}</small>}</div>
                            </div>
                        ))}
                        <CheckBox isChecked={isCheckedTOF} onToggleCheck={(value) => setIsCheckedTOF(value)}>
                            <p className={styles.textTOF}>
                                Tôi đã đọc và đồng ý với các{' '}
                                <Link to="/help?view=1" target="_blank">
                                    Điều khoản và Điều kiện
                                </Link>{' '}
                                của {appName}
                            </p>
                        </CheckBox>

                        <div style={{ textAlign: 'center', marginTop: '24px' }}>
                            <button type="submit" className={globalStyle.btnPrimary}>
                                Cập Nhật
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default UpdateUser;
