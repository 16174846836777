import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import headingBg from 'assets/images/bg/title-register.jpg';

const accountOptionStyles = {
    wrap: css`
        padding: 20px;
        color: ${colors.white};
        display: flex;
        flex-direction: column;
        height: 100%;
    `,
    mw800: css`
        max-width: 800px;
    `,
    title: css`
        font-size: 20px;
        margin-bottom: 30px;
    `,
    label: css`
        display: block;
        font-size: 14px;
        margin-bottom: 6px;
    `,
    heading: css`
        background: url(${headingBg}) no-repeat;
        background-size: cover;
        width: 100%;
        border-radius: 8px;
        align-items: center;
        padding: 10px 12px;
        border: 1px solid ${colors.darkPrimary};
        p {
            font-size: 14px;
        }
        span {
            color: ${colors.yellow};
            font-weight: 600;
        }
    `,
    accountName: css`
        display: flex;
        align-items: center;
        p {
            margin-right: 12px;
        }
    `,
    groupInput: css`
        padding-bottom: 20px;
        position: relative;
        label {
            display: block;
            margin-bottom: 4px;
            padding-left: 2px;
        }
        input {
            width: 100%;
        }
    `,
    flexbox: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    `,
    btnCopy: css`
        display: flex;
        width: 24px !important;
        padding: 4px;
        background: ${colors.primary};
        border-radius: 4px;
        cursor: pointer;

        img {
            width: 100%;
        }
    `,
    flex50: css`
        flex: 0 0 calc(50% - 25px);
        max-width: calc(50% - 25px);
    `,
    flex33: css`
        flex: 0 0 calc(calc(100% / 3) - 12px);
    `,
    groupPass: css`
        padding-top: 16px;
        border-top: 1px solid #3d4056;
        align-items: flex-end;
        &:first-child {
            border-top: none;
        }
    `,
    btnSubmit: css`
        padding: 14px 0;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
    `,
    wrapInput: css`
        position: relative;
        height: 40px;
        input {
            height: 100%;
            padding: 0 12px;
        }
    `,
    btnUpdateInput: css`
        position: absolute;
        top: 6px;
        right: 10px;
        border-radius: 4px;
        font-size: 12px;
        color: ${colors.white};
        height: calc(100% - 12px);
        padding: 0 6px;
        border: 1px solid ${colors.primary};
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            background: ${colors.primary};
        }
    `,
    btnVerify: css`
        border: 1px solid ${colors.yellow};
        color: ${colors.yellow};
        &:hover {
            color: ${colors.blackText};
            background: ${colors.yellow};
        }
    `,
    verified: css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        display: flex;
        align-items: center;
        img {
            width: 16px;
        }
        span {
            font-size: 12px;
            margin-left: 4px;
            color: ${colors.success};
        }
    `,

    tabList: css`
        display: flex;
        border-bottom: 2px solid ${colors.darkPrimary};
    `,
    tabItem: css`
        padding: 12px 16px;
        cursor: pointer;
        min-width: 150px;
        text-align: center;
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
        &.active {
            background: ${colors.darkPrimary};
        }
    `,
};
export default accountOptionStyles;
