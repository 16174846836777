import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';
import { Game3rdTransferPayload, Game3rdLaunchGamePayload } from 'models/fundTransferModel';
const game3rdApi = {
    launchGame(payload: Game3rdLaunchGamePayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'Launch';
        return axiosClient.post(serviceType.GA_SERVICE, payload);
    },
    initTransfer(payload: { flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'Transfer_init';
        return axiosClient.post(serviceType.GA_SERVICE, payload);
    },
    checkBalance(payload: { providercode: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'Balance';
        return axiosClient.post(serviceType.GA_SERVICE, payload);
    },

    submitTransfer(payload: Game3rdTransferPayload): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'Transfer';
        return axiosClient.post(serviceType.GA_SERVICE, payload);
    },
};
export default game3rdApi;
