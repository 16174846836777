import globalStyle from 'constants/global.style';
import styles from './download-app.style';
import IntroApp from 'components/intro-app/IntroApp';
import RuleApp from 'components/rule-app/RuleApp';
import { RootState, useAppSelector } from 'cache/store';
import { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import logo from 'assets/images/logos/qr.png';
import { appName } from 'config/app.config';

function DownloadApp() {
    const { infoApp } = useAppSelector((state: RootState) => state.config);
    console.log(infoApp);
    const qrCodeAndroidRef = useRef<HTMLDivElement>(null);
    const qrCodeIOSRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (infoApp.androidURL) {
            generateQRCode(infoApp.androidURL, 'ANDROID');
        }
        if (infoApp.iosURLWEB) {
            generateQRCode(infoApp.iosURLWEB, 'IOS');
        }
    }, [infoApp]);

    const generateQRCode = async (link: string, platform: 'ANDROID' | 'IOS') => {
        let canvas = await QRCode.toCanvas(link, { errorCorrectionLevel: 'high', width: 160 });

        //adding a log at center
        const imgDim = { width: 30, height: 30 }; //logo dimention
        var context = canvas.getContext('2d');
        var imageObj = new Image();
        imageObj.src = logo;
        imageObj.onload = function () {
            if (context) {
                context.drawImage(imageObj, canvas.width / 2 - imgDim.width / 2, canvas.height / 2 - imgDim.height / 2, imgDim.width, imgDim.height);
            }
        };
        if (platform === 'ANDROID') {
            if (qrCodeAndroidRef.current) {
                qrCodeAndroidRef.current.appendChild(canvas);
            }
        }

        if (platform === 'IOS') {
            if (qrCodeIOSRef.current) {
                qrCodeIOSRef.current.appendChild(canvas);
            }
        }
    };

    return (
        <>
            <div className={styles.downloadPage}>
                <div className={globalStyle.container}>
                    <div className={styles.downloadContent}>
                        <div className={styles.downloadLeft}>
                            <img src={require('assets/images/bg/download-charater.png')} alt="" />
                        </div>
                        <div className={styles.downloadRight}>
                            <h2 className={globalStyle.textGradient('160deg', '#FAF1BD', '#CDB181')}>TẢI NGAY ỨNG DỤNG</h2>
                            <img className="logo" src={require('assets/images/logos/logo.png')} alt="" />
                            <p className="desc">Đăng ký ngay hôm nay để nhận vô vàn quà tặng, ưu đãi và thưởng miễn phí.</p>
                            <div className={styles.downloadOption}>
                                <div className={styles.optionContent}>
                                    <div className="app-item">
                                        <div className="qr-code" ref={qrCodeIOSRef}></div>
                                        <div className="icon-platform">
                                            <img src={require('assets/images/icons/ios-icon.png')} alt="" />
                                            <span>IOS</span>
                                        </div>
                                    </div>
                                    <div className="app-item">
                                        <div className="qr-code" ref={qrCodeAndroidRef}></div>
                                        <div className="icon-platform">
                                            <img src={require('assets/images/icons/android-icon.png')} alt="" />
                                            <span>Android</span>
                                        </div>
                                    </div>
                                </div>
                                <p className="download-text">
                                    {appName.toUpperCase()} là website cá cược trực tuyến hàng đầu Châu Á, cung cấp nhiều loại hình cá cược trực tuyến như: Thể thao, Sòng bài , Numbergame, Keno, Trò
                                    chơi.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={globalStyle.container}>
                <IntroApp />
                <div className={globalStyle.container} style={{ marginBottom: '24px' }}>
                    <RuleApp />
                </div>
            </div>
        </>
    );
}

export default DownloadApp;
