import { css } from '@emotion/css';
import classNames from 'classnames';
import { colors } from 'constants/theme';
import { useState } from 'react';

const tabGuide = [
    {
        label: 'Hướng Dẫn Nạp Tiền',
        value: 'DEPOSIT',
    },
    {
        label: 'Hướng Dẫn Rút Tiền',
        value: 'WITHDRAW',
    },
];

const tabDeposite = [
    {
        label: 'Chuyển Khoản Ngân Hàng',
        value: 'BANK',
    },
    {
        label: 'Chuyển Khoản Nhanh',
        value: 'QUICK',
    },
    {
        label: 'Ví Điện Tử',
        value: 'EWALLET',
    },
];

export default function Guide() {
    const [tabSelect, setTabSelect] = useState<'DEPOSIT' | 'WITHDRAW'>('DEPOSIT');
    const [depositeTab, setDepositeTab] = useState<'BANK' | 'QUICK' | 'EWALLET'>('BANK');
    const changeTabBank = (tab: any) => {
        if (tab === tabSelect) return;
        setTabSelect(tab);
    };
    return (
        <div className={styles.container}>
            <div className={styles.tabList}>
                {tabGuide.map((tab) => (
                    <div className={classNames(styles.tabItem, { active: tabSelect === tab.value })} key={tab.value} onClick={() => changeTabBank(tab.value)}>
                        {tab.label}
                    </div>
                ))}
            </div>

            {tabSelect === 'DEPOSIT' && (
                <div className={styles.guideWrap}>
                    <div className={styles.depositTabs}>
                        {tabDeposite.map((item) => (
                            <div className={`tab-item ${depositeTab === item.value ? 'active' : ''}`} key={item.value} onClick={() => setDepositeTab(item.value as any)}>
                                <p>{item.label}</p>
                            </div>
                        ))}
                    </div>

                    <div className={styles.depositContent}>
                        {depositeTab === 'BANK' && (
                            <div className="">
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/deposite/bank-1.jpg`)} alt="" />
                                </div>
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/deposite/bank-2.jpg`)} alt="" />
                                </div>
                            </div>
                        )}

                        {depositeTab === 'QUICK' && (
                            <div className="">
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/deposite/quick-1.jpg`)} alt="" />
                                </div>
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/deposite/quick-2.jpg`)} alt="" />
                                </div>
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/deposite/quick-3.jpg`)} alt="" />
                                </div>
                            </div>
                        )}

                        {depositeTab === 'EWALLET' && (
                            <div className="">
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/ewallet/ewallet-1.jpg`)} alt="" />
                                </div>
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/ewallet/ewallet-2.jpg`)} alt="" />
                                </div>
                                <div className="guide-item">
                                    <img src={require(`assets/images/guide/ewallet/ewallet-3.jpg`)} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {tabSelect === 'WITHDRAW' && (
                <div className={styles.guideWrap}>
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                        <div className="guide-item" key={item}>
                            <img src={require(`assets/images/guide/withdraw/${item}.jpg`)} alt="" />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

const styles = {
    container: css`
        color: ${colors.white};
    `,
    tabList: css`
        display: flex;
        border-bottom: 2px solid ${colors.darkPrimary};
    `,
    tabItem: css`
        padding: 12px 16px;
        cursor: pointer;
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
        &.active {
            background: ${colors.darkPrimary};
        }
    `,
    guideWrap: css`
        padding-top: 24px;
        .guide-item {
            padding: 12px;
            border: 1px solid #535353;
            text-align: center;
            margin-bottom: 16px;
            img {
                width: 80%;
            }
        }
    `,
    depositTabs: css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .tab-item {
            padding: 12px 16px;
            background: ${colors.black};
            cursor: pointer;
            border: 1px solid ${colors.black};
            &:hover {
                border-color: ${colors.secondary};
            }
            &.active {
                background: ${colors.secondary};
                p {
                    color: ${colors.blackText};
                }
            }
        }
    `,
    depositContent: css`
        margin-top: 24px;
    `,
};
