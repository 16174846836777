import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import cardBg from 'assets/images/bg/lottery-card.png';
const LotteryHomeStyle = {
    wrap: css`
        position: relative;
        display: flex;
        gap: 21px;
    `,
    card: css`
        flex: 1;
        padding: 12px;
        background: url(${cardBg}) no-repeat;
        background-size: cover;
        color: ${colors.white};
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid ${colors.darkPrimary};
    `,
    top: css`
        display: flex;
        align-items: center;
        img {
            width: 40px;
        }
    `,
    issue: css`
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
            font-size: 12px;
            color: ${colors.yellow};
        }
        h6 {
            font-weight: 500;
        }
    `,
    result: css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 18px 0;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            border: 1px solid #c7aa7f;
            font-size: 20px;
            font-weight: 600;
            overflow: hidden;
            position: relative;
        }
    `,
    bottom: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
    `,
    timer: css`
        font-size: 14px;
    `,
    btnNavigate: css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100px;
        z-index: 2;
        display: flex;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.9);
        &:disabled {
            opacity: 0.5;
            cursor: initial;
        }
        svg {
            width: 40px;
            height: 40px;
            polyline {
                stroke: ${colors.orange};
            }
        }
    `,
    btnPrev: css`
        left: -20px;
    `,
    btnNext: css`
        right: -20px;
    `,
};
export default LotteryHomeStyle;
