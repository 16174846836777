import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import moment from 'moment';
import { useEffect, useState } from 'react';

let idInterval: any;

const styles = css`
    color: ${colors.white};
    font-size: 13px;
    white-space: nowrap;
`;

function TimeNow() {
    const [timeNowText, setTimeNowText] = useState<string>('00/00/0000 00:00:00');
    useEffect(() => {
        idInterval = setInterval(() => {
            const date = moment(new Date()).format('DD/MM/yyyy HH:MM:ss');
            setTimeNowText(() => date);
        }, 1000);
        return () => {
            if (idInterval) clearInterval(idInterval);
        };
    }, []);

    return <p className={styles}>{timeNowText}</p>;
}

export default TimeNow;
