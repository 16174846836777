import { DEVICE_INFO } from 'constants/common';
import { FormValuesLogin, LoginPayload } from 'models/authModel';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styles from './auth-header.style';
import { VERSION } from 'config/app.config';
import globalStyle from 'constants/global.style';

type InputId = 'username' | 'password';
interface InputItem {
    id: InputId;
    label: string;
    holder: string;
    inputType: string;
    require: boolean;
    togglePass?: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
}
const listInput: InputItem[] = [
    {
        id: 'username',
        label: 'Tên Đăng Nhập',
        holder: 'Từ 5 đến 30 ký tự và không chứa khoảng trắng',
        inputType: 'text',
        require: true,
        pattern: {
            value: /^\S+$/,
            message: 'Tên đăng nhập không hợp lệ!',
        },

        maxLength: {
            value: 30,
            message: 'Tối đa 30 ký tự',
        },
    },
    {
        id: 'password',
        label: 'Mật Khẩu',
        holder: 'Từ 6 đến 30 ký tự chữ, số hoặc ký tự đặc biệt',
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: 'Tối đa 30 ký tự',
        },
    },
];

interface Props {
    onLogin: (payload: LoginPayload) => void;
}
function LoginForm(props: Props) {
    const { onLogin } = props;

    const [showPass, setShowPass] = useState(false);
    const {
        handleSubmit,
        register,

        formState: { errors },
    } = useForm<FormValuesLogin>();

    const handleLogin: SubmitHandler<FormValuesLogin> = async (values) => {
        const payload = {
            ...values,
            username: values.username.toLowerCase(),
            version: VERSION,
            di: DEVICE_INFO,
        };
        onLogin(payload);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit(handleLogin)}>
            {listInput.map((item, index) => (
                <div className={styles.inputWrap} key={index}>
                    <label htmlFor={item.id}>{item.label}</label>
                    <input
                        autoComplete="off"
                        type={item.id === 'password' ? (!showPass ? item.inputType : 'text') : item.inputType}
                        id={item.id}
                        placeholder={item.holder}
                        className={`input-common ${styles.input}`}
                        {...register(item.id, {
                            required: item.require,
                            pattern: item.pattern,
                            maxLength: item.maxLength,
                            minLength: item.minLength,
                        })}
                    />
                    {/* toggle password login */}
                    {item.togglePass && item.id === 'password' && (
                        <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                            {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    )}

                    {/* base error  */}
                    <div className={styles.errorInput}>{(errors as any)[item.id] && <small>{(errors as any)[item.id].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
            ))}

            <button type="submit" className={globalStyle.btnPrimary} style={{ marginTop: '24px' }}>
                Đăng Nhập
            </button>
        </form>
    );
}

export default LoginForm;
