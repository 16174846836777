import userApi from 'apis/userApi';
import { RootState, useAppSelector } from 'cache/store';
import DatePicker from 'components/date-picker/DatePicker';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import globalStyle from 'constants/global.style';
import { NewListStastRespone } from 'models/statisticModel';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import StatsAgencyDetail from './StatsAgencyDetail';
import accountStyles from './account-option.style';

const actionType = [
    { value: 0, label: 'Đặt cược từ lớn tới nhỏ' },
    { value: 1, label: 'Chiết khấu từ lớn tới nhỏ' },
    { value: 2, label: 'Trúng cược từ lớn tới nhỏ' },
    { value: 3, label: 'Khuyến mãi từ lớn tới nhỏ' },
    { value: 4, label: 'Nạp tiền từ lớn tới nhỏ' },
    { value: 5, label: 'Rút tiền từ lớn tới nhỏ' },
];

function StatsAgency() {
    const { agentType } = useAppSelector((state: RootState) => state.auth);

    const [startTime, setStartTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')}`));
    const [endTime, setEndTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')}`));
    const [orderSelect, setOrderSelect] = useState(0);
    const [loginname, setLoginName] = useState('');
    const [recordList, setRecordList] = useState<NewListStastRespone[] | null>(null);
    const [mainRecord, setMainRecord] = useState<NewListStastRespone | null>(null);
    const [totalRecord, setTotalRecord] = useState<NewListStastRespone | null>(null);
    const [loading, setLoading] = useState(false);

    const [recordDetail, setRecordDetail] = useState<any>(null);

    const searchStats = async () => {
        if (!startTime || !endTime) {
            toast.error('Ngày chọn không hợp lệ');
            return;
        }
        setLoading(true);
        const payload = {
            order: orderSelect,
            loginname,
            stime: moment(startTime).format('yyyy-MM-DD'),
            etime: moment(endTime).format('yyyy-MM-DD'),
        };
        try {
            const { data } = await userApi.reportWinLossSearch(payload);
            if (!data.msg) {
                setRecordList(data.reportmain);
                setMainRecord(data.ruitem);
                setTotalRecord(data.totallist);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <div className={accountStyles.mw800}>
                    <h6 className={accountStyles.title}>Báo Cáo Đại Lý</h6>
                    <div className={accountStyles.flexbox}>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Thời Gian Từ</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker dateSelect={startTime} onSelectDate={(date) => setStartTime(date)} showIcon maxDate={new Date()} />
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Đến</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker dateSelect={endTime} onSelectDate={(date) => setEndTime(date)} showIcon maxDate={new Date()} />
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Loại</label>
                            <div className={accountStyles.wrapInput}>
                                <select className="input-common" value={orderSelect} onChange={(e) => setOrderSelect(Number(e.target.value))}>
                                    {actionType.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Tên Tài Khoản</label>
                            <div className={accountStyles.wrapInput}>
                                <input value={loginname} onChange={(e) => setLoginName(e.target.value)} className="input-common" type="text" placeholder="Tên tài khoản" />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '8px', width: 'calc(50% - 25px)', marginBottom: '24px' }}>
                        <button type="button" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={searchStats}>
                            Tìm Kiếm
                        </button>
                    </div>
                </div>
                <div className="table-common table-col-center">
                    <div className="row">
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Hội viên</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Nạp tiền</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Rút tiền</div>
                        {agentType === 1 && (
                            <>
                                <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Nạp tiền cấp dưới</div>
                                <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Rút tiền cấp dưới</div>
                            </>
                        )}
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Cược</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Chiết khấu</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Trúng</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Khuyến mãi</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Lợi nhuận</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Xem</div>
                    </div>
                    {recordList ? (
                        <>
                            {mainRecord && (
                                <div className="row">
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{mainRecord.loginname}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.dp)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.wd + mainRecord.adjmoney)}</p>
                                    {agentType === 1 && (
                                        <>
                                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.sjdp)}</p>
                                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.wdagent)}</p>
                                        </>
                                    )}
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.tz)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.fd)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.win)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(mainRecord.bonus)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)} ${mainRecord.saletz >= 0 ? 'text-success' : 'text-error'}`}>
                                        {new Intl.NumberFormat().format(mainRecord.saletz)}
                                    </p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}></p>
                                </div>
                            )}
                            {recordList.map((item, index) => (
                                <div className="row" key={index}>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{item.loginname}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.dp)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.wd + item.adjmoney)}</p>
                                    {agentType === 1 && (
                                        <>
                                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.dpxj)}</p>
                                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.wdchild)}</p>
                                        </>
                                    )}
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.tz)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.fd)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.win)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.bonus)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)} ${item.saletz >= 0 ? 'text-success' : 'text-error'}`}>
                                        {new Intl.NumberFormat().format(item.saletz)}
                                    </p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>
                                        <span className="btn-detail" onClick={() => setRecordDetail(item)}>
                                            Chi tiết
                                        </span>
                                    </p>
                                </div>
                            ))}

                            {totalRecord && (
                                <div className="row">
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Tổng cộng</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.dp)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.wd + totalRecord.adjmoney)}</p>
                                    {agentType === 1 && (
                                        <>
                                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.sjdp)}</p>
                                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.wdagent)}</p>
                                        </>
                                    )}
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.tz)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.fd)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.win)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.bonus)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)} ${totalRecord.saletz >= 0 ? 'text-success' : 'text-error'}`}>
                                        {new Intl.NumberFormat().format(totalRecord.saletz)}
                                    </p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}></p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-data"></div>
                    )}
                </div>
            </div>
            <Modal isShow={Boolean(recordDetail)} onClose={() => setRecordDetail(null)} closeClickBackdrop>
                <StatsAgencyDetail statsData={recordDetail} onClose={() => setRecordDetail(null)} />
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

export default StatsAgency;
