import { LotteryInterface } from 'models/lotteryModel';

export const MienbacDBLotto: LotteryInterface[] = [
    {
        title: 'Đặc biệt 18h25',
        typeIputID: 'danh-de',
        classicStyle: false,
        active: true,
        subTabs: [
            {
                title: 'Đề đặc biệt 18h25',
                typeIputID: 'de-dac-biet',
                active: true,
                desc: 'Đánh 2 chữ số cuối trong giải đặc biệt',
                methodID: 1000,
                methodidInDB: 1000,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '0', posStart: '3', posEnd: '5' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    { title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false },
                                    { title: 'Tài', attr_name: 'big', cls: 'qselect', active: false },
                                    { title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false },
                                    { title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false },
                                    { title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    { title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false },
                                    { title: 'Tài', attr_name: 'big', cls: 'qselect', active: false },
                                    { title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false },
                                    { title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false },
                                    { title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [{ title: '00-99', attr_name: '00to99', cls: 'range-item', active: true }],
                                selectedNums: [],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \n Ví dụ: 23;82 hoặc 23 82',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    { title: 'Tải tập tin', attr_name: 'upload_file', cls: 'btn btn-primary', active: false },
                                    { title: 'Huỷ', attr_name: 'clear_upload', cls: 'btn btn-dark', active: false },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
