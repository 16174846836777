import { useState } from 'react';
import styles from './auth-header.style';
import { logoPartnerList } from 'constants/logoPartner';
import { IoMdClose } from 'react-icons/io';
import { LoginPayload, RegisterPayload } from 'models/authModel';
import LoadingIcon from 'components/loading/LoadingIcon';
import RegisterForm from './RegisterForm';
import authApi from 'apis/authApi';
import toast from 'react-hot-toast';
import { DEVICE_INFO } from 'constants/common';
import LoginForm from './LoginForm';
import { colors } from 'constants/theme';
import { VERSION } from 'config/app.config';
interface Props {
    onClose: () => void;
    onLogin: (payload: LoginPayload, showAlertSuccess: boolean, closeModal: boolean, rememberAccount?: boolean) => void;
}
function AuthHeader(props: Props) {
    const { onClose, onLogin } = props;

    const [modeAuth, setModeAuth] = useState<'LOGIN' | 'SIGNUP'>('SIGNUP');
    const [loading, setLoading] = useState(false);

    const handleRegister = async (values: RegisterPayload) => {
        setLoading(true);
        try {
            const { data } = await authApi.signUp(values);
            if (!data.msg) {
                //thông báo đăng ký tài khoản thành công
                toast.success('Đăng ký tài khoản khoản thành công');

                //đóng modal đăng ký
                onClose();

                //login account khi đăng ký tài khoản thành công
                const payloadLogin = {
                    username: values.un,
                    password: values.pwd,
                    version: VERSION,
                    di: DEVICE_INFO,
                };

                //call login from header component
                onLogin(payloadLogin, false, true);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = (payload: LoginPayload) => {
        onLogin(payload, true, true);
    };

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.left}>
                    <img src={require('assets/images/bg/register.jpg')} alt="" />
                    <div className={styles.logoPartner}>
                        {logoPartnerList.map((item, index) => (
                            <div className={styles.partnerItem} key={index}>
                                <div className={styles.itemInner}>
                                    <img src={item} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.heading}>
                        <div className={styles.title}>
                            <img className={styles.titleBg} src={require('assets/images/bg/title-register.jpg')} alt="" />
                            {modeAuth === 'SIGNUP' && (
                                <div className={styles.titlecontent}>
                                    <h6>ĐĂNG KÝ</h6>
                                    <img src={require('assets/images/logos/logo.png')} alt="" />
                                </div>
                            )}
                            {modeAuth === 'LOGIN' && (
                                <div className={styles.titlecontent}>
                                    <h6>ĐĂNG NHẬP</h6>
                                    <img src={require('assets/images/logos/logo.png')} alt="" />
                                </div>
                            )}
                        </div>
                        <button type="button" className={styles.btnClose} onClick={onClose}>
                            <IoMdClose size={40} color={colors.primary} />
                        </button>
                    </div>
                    {modeAuth === 'SIGNUP' && (
                        <div className={styles.hasAccount}>
                            <span>Đã có tài khoản?</span>
                            <p onClick={() => setModeAuth('LOGIN')}>Đăng Nhập</p>
                        </div>
                    )}
                    {modeAuth === 'LOGIN' && (
                        <div className={styles.hasAccount}>
                            <span>Chưa có tài khoản?</span>
                            <p onClick={() => setModeAuth('SIGNUP')}>Đăng Ký</p>
                        </div>
                    )}

                    {/* form register */}
                    <div style={{ display: modeAuth === 'SIGNUP' ? 'block' : 'none' }}>
                        <RegisterForm onRegister={handleRegister} />
                    </div>

                    {/* form login */}
                    <div style={{ display: modeAuth === 'LOGIN' ? 'block' : 'none' }}>
                        <LoginForm onLogin={handleLogin} />
                    </div>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default AuthHeader;
