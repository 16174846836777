import { css } from '@emotion/css';
import accountStyles from './account-option.style';
import { useState } from 'react';
import { msgAlertInput } from 'constants/messageAlert';
import { useForm } from 'react-hook-form';
import globalStyle from 'constants/global.style';
import DatePicker from 'components/date-picker/DatePicker';
import LoadingIcon from 'components/loading/LoadingIcon';
import moment from 'moment';
import { BonusReportSearchPayload, FundTransferItem } from 'models/fundTransferModel';
import userApi from 'apis/userApi';
import Pagination from 'components/pagination/Pagination';
import toast from 'react-hot-toast';
import { InfoPaginate } from 'models/commonModel';
import { scrollToTopPage } from 'utils/function';
const typelist = [
    {
        value: -2,
        label: 'Toàn bộ',
    },
    {
        value: 13,
        label: 'Tặng quà',
    },
    {
        value: 17,
        label: 'Nạp tiền từ cấp trên',
    },
    {
        value: 10,
        label: 'Nạp tiền cho cấp dưới',
    },
    {
        value: 53,
        label: 'Khuyến mãi của tôi',
    },
    {
        value: 54,
        label: 'Hoa hồng của tôi',
    },
    {
        value: 51,
        label: 'Hoa hồng cấp dưới',
    },
    {
        value: 19,
        label: 'Vòng quay may mắn',
    },
];
interface FormValues {
    loginname: string;
}

function PresentDetail() {
    const [startTime, setStartTime] = useState<Date | null>(new Date());
    const [endTime, setEndTime] = useState<Date | null>(new Date());
    const [typeSelectValue, setTypeselectValue] = useState(-2);
    const [loading, setLoading] = useState(false);
    const [loginname, setLoginname] = useState('');
    const [infoPage, setInfoPage] = useState<InfoPaginate | null>(null);
    const [bonusList, setBonusList] = useState<FundTransferItem[] | null>(null);
    const {
        formState: { errors },
        setError,
        clearErrors,
    } = useForm<FormValues>();

    const handleChangeLoginName = (value: string) => {
        if (!msgAlertInput.username.pattern.test(value) && value) {
            setError('loginname', {
                message: msgAlertInput.username.invalid,
            });
        } else {
            if (errors['loginname']) {
                clearErrors('loginname');
            }
        }
        setLoginname(value);
    };

    const searchBonusReport = async (page: number, isResetPaginate: boolean, type: 'btnSearch' | 'inputPage') => {
        if (isResetPaginate) {
            setInfoPage(null);
        }
        setLoading(true);
        let payload: BonusReportSearchPayload = {
            type: Number(typeSelectValue),
            loginname,
            stime: startTime ? moment(startTime).format('yyyy-MM-DD') : '',
            etime: endTime ? moment(endTime).format('yyyy-MM-DD') : '',
            pagesize: 10,
        };

        if (type === 'inputPage') {
            payload = {
                ...payload,
                curpage: infoPage?.curpage,
                num: page,
            };
        }
        try {
            const { data } = await userApi.bonusReportBean(payload, type);
            if (!data.msg) {
                setBonusList(data.reslist);
                const { curpage, totalpage, totalrecord } = data;
                setInfoPage({ curpage, totalpage, totalrecord });
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const mapLabelType = (type: number) => {
        const typeItem = typelist.find((list) => list.value === type);
        return typeItem?.label || '';
    };

    const handleChangePage = (page: number) => {
        searchBonusReport(page, false, 'inputPage');
        scrollToTopPage();
    };
    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Chi Tiết Quà Tặng</h6>
                <div className={accountStyles.flexbox}>
                    <div className={`${accountStyles.groupInput} ${styles.flex30}`}>
                        <label htmlFor="">Tên Đăng Nhập</label>
                        <div className={accountStyles.wrapInput}>
                            <input value={loginname} onChange={(e) => handleChangeLoginName(e.target.value)} className={`input-common`} type="text" placeholder={msgAlertInput.username.invalid} />
                        </div>
                        {/* base error  */}
                        <div className={globalStyle.errorInput}>{errors['loginname'] && <small>{errors['loginname'].message || msgAlertInput.generic.require}</small>}</div>
                    </div>
                    <div className={`${accountStyles.groupInput} ${styles.flex30}`}>
                        <label htmlFor="">Loại Hình</label>
                        <div className={accountStyles.wrapInput}>
                            <select className="input-common" value={typeSelectValue} onChange={(e) => setTypeselectValue(Number(e.target.value))}>
                                {typelist.map((item) => (
                                    <option value={item.value} key={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={`${styles.flex40} ${styles.groupAmount}`}>
                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Thời Gian Từ</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker dateSelect={startTime} onSelectDate={(date) => setStartTime(date)} showIcon />
                            </div>
                        </div>
                        <div className={accountStyles.groupInput}>
                            <label htmlFor="">Đến</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker dateSelect={endTime} onSelectDate={(date) => setEndTime(date)} showIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '10px', marginBottom: '30px', width: 'calc(calc(100% / 3) - 12px)' }}>
                    <button type="button" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={() => searchBonusReport(1, true, 'btnSearch')}>
                        Tìm Kiếm
                    </button>
                </div>
                <div className="table-common">
                    <div className="row">
                        <div className="title col" style={{ flex: '0 0 200px' }}>
                            Mã số biến động
                        </div>
                        <div className="title col">Thời Gian</div>
                        <div className="title col">Tên Tài Khoản</div>
                        <div className="title col">Loại Hình</div>
                        <div className="title col">Thu Nhập</div>
                        <div className="title col">Chi</div>
                        <div className="title col">Ghi Chú</div>
                    </div>
                    {bonusList ? (
                        <>
                            {bonusList.length > 0 ? (
                                bonusList.map((mem) => (
                                    <div className="row" key={mem.id}>
                                        <p className="col" style={{ flex: '0 0 200px' }}>
                                            {mem.id}
                                        </p>
                                        <p className="col">{moment(mem.inserttime).format('DD/MM/yyyy HH:mm')}</p>
                                        <p className="col">{mem.loginname}</p>
                                        <p className="col">{mapLabelType(mem.opttype)}</p>
                                        <p className="col text-success">{new Intl.NumberFormat().format(mem.income)}</p>
                                        <p className="col text-error">{new Intl.NumberFormat().format(mem.pay)}</p>
                                        <p className="col">{Number(mem.remark) === 0 ? '' : <span>{mem.remark.substring(0, 20)}...</span>}</p>
                                    </div>
                                ))
                            ) : (
                                <div className="no-data">
                                    <img src={require('assets/images/icons/no-data.png')} alt="" />
                                    <p>Không có dữ liệu</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-data"></div>
                    )}
                </div>
                {infoPage && (
                    <div className="pagination-common">
                        <div className=""></div>
                        <Pagination infoPage={infoPage} onChangePage={handleChangePage} showTotal />
                    </div>
                )}
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    groupAmount: css`
        display: flex;
        gap: 12px;
    `,

    flex30: css`
        flex: 0 0 calc(30% - 24px);
    `,
    flex40: css`
        flex: 0 0 calc(40% - 24px);
    `,
};

export default PresentDetail;
