import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const promotionHomeStyle = {
    wrap: css`
        display: flex;
    `,
    item: css`
        flex: 0 0 25%;
        max-width: 25%;
        padding: 8px;
    `,
    itemInner: css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 100%;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 12px ${colors.secondary};
        }
        .promo-name {
            cursor: pointer;
            color: ${colors.white};
            flex: 0 0 38px;
            font-size: 14px;
            &:hover {
                text-decoration: underline;
                color: ${colors.yellow};
            }
        }
    `,
    wrapImg: css`
        flex: 0 0 calc(100% - 38px);
        display: flex;
        img {
            width: 100%;
        }
    `,
};

export default promotionHomeStyle;
