import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import accountBg from 'assets/images/bg/account.jpg';
const bgTitleLinear = `linear-gradient(269deg, rgba(67, 47, 92, 0.66) 23.94%, rgba(21, 41, 111, 0.61) 83.16%), ${colors.bgTitle}`;
const lotteryPageStyles = {
    wrapPage: css`
        background: url(${accountBg}) no-repeat;
        background-size: cover;
        height: 100%;
        padding: 24px 0;
    `,
    wrapInner: css`
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        height: 100%;
        background: ${colors.white};
    `,
    sidebar: css`
        flex: 0 0 240px;
        max-width: 240px;
        border-right: 1px solid #dfeeff;
        padding: 24px 20px;
    `,
    betContainer: css`
        padding: 16px;
        flex: 1;
    `,
    resultSection: css`
        display: flex;
        gap: 8px;
        background: ${colors.darkSecondary};
        margin-bottom: 24px;
        border-radius: 8px;
        color: ${colors.white};
        padding: 8px;
    `,

    issueWrap: css`
        flex: 1;
        max-width: 400px;
        margin-left: auto;
        background: linear-gradient(65deg, rgba(9, 111, 112, 0.5) 15%, rgba(13, 49, 76, 1) 100%);
        border-radius: 6px;
        padding: 10px 8px;
        border: 1px solid ${colors.darkPrimary};
        display: flex;
        flex-direction: column;
    `,
    resultSpecial: css`
        text-align: center;
        border-bottom: 1px solid #7793a3;
        padding-top: 16px;
        p {
            font-size: 18px;
            &:first-child {
                span {
                    color: ${colors.yellow};
                    font-weight: 500;
                }
            }
        }
    `,
    specialLotteryHistory: css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 18px 0;

        span {
            width: 36px;
            height: 36px;
            line-height: 34px;
            text-align: center;
            border-radius: 36px;
            background: ${colors.white};
            border: 1px solid ${colors.borderPrimary};
            color: ${colors.blackText};
            font-size: 20px;
            font-weight: 600;
        }
    `,
    nextIssue: css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
    `,
    chanelLottery: css`
        font-size: 24px;
        color: ${colors.yellow};
        font-weight: 600;
        padding: 8px 0;
    `,
    timerCountLeft: css`
        margin-bottom: 8px;
    `,
    timerCountDown: css`
        display: flex;
        justify-content: center;
    `,
    betSection: css``,
    currentIssue: css`
        margin-top: 16px;
        span {
            color: ${colors.yellow};
            font-weight: 500;
        }
    `,

    tabTypeBetList: css`
        display: flex;
        background: ${colors.darkSecondary};
        gap: 8px;
        width: 100%;
        border-radius: 6px;
        padding-left: 16px;
    `,
    tabTypeItem: css`
        padding: 12px;

        text-align: center;
        color: ${colors.white};
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            width: 100%;
            height: calc(100% - 4px);
            background: ${colors.white};
            border-radius: 8px 8px 0 0;
            opacity: 0;
            pointer-events: none;
            z-index: 0;
        }
        &.active {
            span {
                color: ${colors.black};
            }
            &::before {
                opacity: 1;
            }
        }
        span {
            position: relative;
            z-index: 1;
            font-weight: 500;
        }
    `,
    subTabTypeBetList: css`
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
        p {
            margin-left: auto;
            font-size: 14px;
        }
    `,
    subTabTypeBetItem: css`
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid ${colors.darkPrimary};
        cursor: pointer;
        &.active {
            background: ${colors.darkPrimary};
            color: ${colors.white};
        }
    `,
    inputBetWrap: css`
        border-radius: 8px;
        border: 1px solid #bce1e1;
        padding: 12px 10px;
        margin-top: 16px;
        background: ${colors.lightBlue};
    `,
    tabInputBetList: css`
        display: flex;
        gap: 30px;
        border-bottom: 1px solid #bce1e1;
    `,
    tabInputBetItem: css`
        padding: 8px 4px;
        border-bottom: 4px solid transparent;
        cursor: pointer;
        &.active {
            border-color: ${colors.darkPrimary};
        }
    `,

    betTimesInput: css`
        display: flex;
        align-items: center;
        padding: 6px 8px;
        border-radius: 6px;
        border: 1px solid ${colors.darkPrimary};
        input {
            border: none;
            outline: none;
            width: 60px;
            text-align: center;
        }
        button {
            border: 1px solid ${colors.lightBlue};
            background: #efefef;
            border-radius: 4px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:active {
                background: ${colors.darkPrimary};
                svg {
                    color: ${colors.white};
                }
            }
            svg {
                color: ${colors.darkPrimary};
            }
        }
    `,
    betAction: css`
        padding-top: 12px;
    `,
    betMulti: css`
        display: flex;
        align-items: center;
        gap: 12px;
    `,
    betActionGroup: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        gap: 40px;
    `,
    betNotice: css`
        font-size: 12px;
        span {
            color: ${colors.red};
        }
    `,
    betActionBtn: css`
        display: flex;
        gap: 16px;
        flex: 0 0 40%;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            text-align: center;
        }
    `,
    betInfo: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            color: ${colors.blue};
            font-weight: 500;
        }
    `,
    betInfoLeft: css``,
    betInfoRight: css`
        label {
            font-size: 16px;
            display: inline-block;
            margin-right: 8px;
        }
        select {
            padding: 4px 8px;
            border-radius: 8px;
            background: ${colors.white};
            color: ${colors.black};
            border: 1px solid ${colors.lightBlue};
        }
    `,
    selectRateBet: css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 8px;
    `,

    wrapBet1kAction: css`
        display: flex;
        align-items: center;
        p {
            margin-left: 8px;
        }
    `,
    btnBetAll: css`
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 10px 0;
        width: 70%;
        margin-left: auto;
    `,
    countdownBtn: css`
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.3);
        padding: 4px 8px;
    `,
    totalMoneyBet: css`
        display: flex;
        align-items: center;
        span {
            font-size: 22px;
            font-weight: 500;
            margin-left: 4px;
            color: ${colors.blue};
        }
    `,
    noResult: css`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    `,
};
export default lotteryPageStyles;
