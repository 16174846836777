import { optionAccountMenu, optionAccountComponent } from 'constants/optionAccount';
import styles from './account.style';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { PathTypeAccountInfo } from 'models/commonModel';
import { IoIosArrowDown } from 'react-icons/io';
import globalStyle from 'constants/global.style';

const optionMenu = optionAccountMenu.map((item) => {
    if (item.id === 'notify') {
        const newGroup = item.group.filter((component) => component.path !== 'wl-depo-his');
        return { ...item, group: newGroup, isActive: false };
    }
    return { ...item, isActive: false };
});

function AccountPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const mode: any = searchParams.get('mode');

    const [modePage, setModePage] = useState<PathTypeAccountInfo>(mode || 'personal-info');
    const [accountOption, setAccountOption] = useState<any[]>(optionMenu);

    const changePageAccount = (path: PathTypeAccountInfo) => {
        setSearchParams({ mode: path });
        setModePage(path);
    };

    useEffect(() => {
        if (mode) {
            setModePage(mode);

            const newAccountOption = accountOption.map((item) => {
                const isPathMatchGroup = item.group.map((page: any) => page.path).includes(mode);
                if (isPathMatchGroup) return { ...item, isActive: true };
                return item;
            });
            setAccountOption(newAccountOption);
        } else {
            const newAccountOption = accountOption.map((item, index) => {
                if (index === 0) return { ...item, isActive: true };
                return item;
            });
            setAccountOption(newAccountOption);
        }
    }, [mode]);

    const toggleGroupAccount = (groupId: string) => {
        const newAccountOption = accountOption.map((item: any) => {
            if (item.id === groupId) return { ...item, isActive: !item.isActive };
            return item;
        });
        setAccountOption(newAccountOption);
    };

    return (
        <div className={styles.wrap}>
            <div className={globalStyle.container} style={{ height: '100%' }}>
                <div className={styles.inner}>
                    <div className={styles.left}>
                        {accountOption.map((section, index) => (
                            <div className={classNames(styles.group, { active: section.isActive })} key={section.id}>
                                <div className={styles.headingGroup} onClick={() => toggleGroupAccount(section.id)}>
                                    <div className={styles.groupTitle}>
                                        <p>{index + 1}</p>
                                        <h6 className={styles.title}>{section.title}</h6>
                                    </div>
                                    <IoIosArrowDown size={16} className="heading-icon" />
                                </div>
                                <div className={styles.optionList} style={{ height: section.isActive ? `${section.group.length * 38}px` : 0 }}>
                                    {section.group.map((item: any, index: number) => (
                                        <div className={classNames(`${styles.optionItem}`, { active: modePage === item.path })} key={index} onClick={() => changePageAccount(item.path)}>
                                            <img src={item.icon} alt="" />
                                            <p>{item.label}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.right}>
                        {optionAccountComponent.map((pageItem, index) => {
                            const Page = pageItem.component;
                            return <React.Fragment key={index}>{modePage === pageItem.path ? <Page /> : null}</React.Fragment>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountPage;
