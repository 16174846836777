import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { useEffect, useState } from 'react';

interface Props {
    value: number;
    isRun: boolean;
}

const heightRangeNum = 36;
let listIntervalId: number[] = [];

function NumberLottery(props: Props) {
    const { value, isRun } = props;
    const [valueRunInit, setValueRunInit] = useState(value);

    useEffect(() => {
        if (isRun) {
            const intervalId = setInterval(() => {
                setValueRunInit((value) => {
                    value = value + 1;
                    if (value > 9) value = 0;
                    return value;
                });
            }, 100);
            listIntervalId.push(intervalId as any);
        } else {
            listIntervalId.forEach((id) => clearInterval(id));
            listIntervalId = [];
        }

        return () => {
            listIntervalId.forEach((id) => clearInterval(id));
            listIntervalId = [];
        };
    }, [isRun]);

    useEffect(() => {
        if (!isRun) {
            setValueRunInit(value);
        }
    }, [value, isRun]);

    return <div className={styles.wrap}>{valueRunInit}</div>;
}

const styles = {
    wrap: css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: ${heightRangeNum}px;
        background: ${colors.white};
        color: ${colors.blackText};
        border-radius: 36px;
        font-size: 20px;
        font-weight: 600;
        overflow: hidden;
        position: relative;
    `,
};

export default NumberLottery;
