import { useEffect, useState } from 'react';
import accountStyles from './account-option.style';
import userApi from 'apis/userApi';
import { MessageItem } from 'models/notiModel';
import toast from 'react-hot-toast';
import { colors } from 'constants/theme';
import { css } from '@emotion/css';
import { rotateAnimation } from 'constants/global.style';
import classNames from 'classnames';
import Modal from 'components/modal/Modal';
import moment from 'moment';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import { updateMessageUnread } from 'features/msgSystemSlice';
import ModalConfirm from 'components/modal/ModalConfirm';

function NotiftyInbox() {
    const dispatch = useAppDispatch();
    const { msgUnread } = useAppSelector((state: RootState) => state.system);
    const [msgList, setMsgList] = useState<MessageItem[]>([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [msgDetail, setMsgDetail] = useState<MessageItem>({} as MessageItem);
    const [showPopupDetail, setShowPopupDetail] = useState(false);

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    useEffect(() => {
        getMsgData();
    }, []);

    const getMsgData = async (isRefresh?: boolean) => {
        if (isRefresh) {
            setIsRefreshing(true);
        }
        try {
            const { data } = await userApi.personMessageSearch({});
            if (!data.msg) {
                const msgData: MessageItem[] = data.reslist.filter((msg: MessageItem) => checkSender(msg.sender));

                setMsgList(msgData);

                const unreadMsgNum = msgData.filter((msg) => msg.viewtimes === 0);
                dispatch(updateMessageUnread(unreadMsgNum.length));
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsRefreshing(false);
        }
    };

    const checkSender = (sender: string) => {
        return sender.toUpperCase() === 'BỘ PHẬN CSKH' || sender.toUpperCase() === 'HỆ THỐNG';
    };

    const getMsgDetail = async (msgId: number) => {
        setShowPopupDetail(true);
        try {
            const { data } = await userApi.personMessageView({ id: msgId });
            if (!data.msg) {
                setMsgDetail(data.viewmsg);

                const newMsgList = msgList.map((item) => {
                    if (item.id === msgId) {
                        //trừ tin nhắn chưa đọc
                        if (item.viewtimes === 0) {
                            if (msgUnread > 0) {
                                dispatch(updateMessageUnread(msgUnread - 1));
                            }
                        }
                        return { ...item, viewtimes: 2 };
                    }
                    return item;
                });
                setMsgList(newMsgList);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const showPopupDeleteMSg = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setShowConfirmDelete(true);
    };

    const submitDeleteMsg = () => {};

    return (
        <>
            <div className={`${accountStyles.wrap} ${accountStyles.mw800}`}>
                <h6 className={accountStyles.title}>Tin Nhắn</h6>
                <div className="d-flex">
                    {msgList.length === 0 && <p>Không có tin nhắn nào!</p>}
                    <button type="button" className={classNames(`${styles.btnRefresh} btn-primary`, { rotate: isRefreshing })} onClick={() => getMsgData(true)}>
                        <img src={require('assets/images/icons/refresh.png')} alt="" />
                        <span>Cập nhật</span>
                    </button>
                </div>
                <div className={styles.messageList}>
                    {msgList.map((item) => (
                        <div className={classNames(styles.messageItem, { unread: item.viewtimes === 0 })} key={item.id} onClick={() => getMsgDetail(item.id)}>
                            <div className={`${styles.dotUnread} dot`} />
                            <div className={styles.msgContent}>
                                <div className={styles.msgCol}>
                                    <p className="title-msg">{item.title}</p>
                                    <p className="sender">{item.sender}</p>
                                </div>
                                <div className={styles.msgCol}>
                                    <button type="button" onClick={(e) => showPopupDeleteMSg(e)}>
                                        Xoá
                                    </button>
                                    <p className="time">{moment(item.sendtime).format('DD/MM/yyyy HH:mm:ss')}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal isShow={showPopupDetail} onClose={() => setShowPopupDetail(false)} width="500px" closeClickBackdrop>
                <div className={`bg-popup-common ${styles.popup}`}>
                    <h2 className="title" style={{ marginBottom: '20px' }}>
                        {msgDetail.title}
                    </h2>
                    <div className={styles.popupContent} dangerouslySetInnerHTML={{ __html: msgDetail.content }} />

                    <div className="group-action">
                        <button
                            type="button"
                            className="btn-primary btn-close"
                            onClick={() => {
                                setShowPopupDetail(false);
                                setMsgDetail({} as MessageItem);
                            }}
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </Modal>

            {/* modal confirm delete message */}
            <ModalConfirm isShow={showConfirmDelete} onClose={() => setShowConfirmDelete(false)} onSubmit={submitDeleteMsg} title="Xác Nhận" subTitle="Bạn có chắc chắn muốn xoá tin nhắn này?" />
        </>
    );
}

const styles = {
    btnRefresh: css`
        display: flex;
        align-items: center;
        margin-left: auto;
        width: fit-content !important;
        padding: 6px 12px;
        border-radius: 8px;
        span {
            margin-left: 4px;
            font-size: 14px;
        }
        img {
            width: 20px;
        }
        &.rotate {
            img {
                animation: ${rotateAnimation} 0.8s linear infinite;
            }
        }
    `,
    messageList: css`
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;
    `,
    messageItem: css`
        background: ${colors.drak50};
        border-radius: 8px;
        padding: 12px;
        padding-left: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        &:hover {
            .title-msg {
                color: ${colors.primary};
            }
            svg {
                transform: translateX(4px);
            }
        }
        .title-msg {
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            overflow: hidden;
            margin-right: 24px;
            font-weight: 400;
        }

        &.unread {
            .title-msg {
                font-weight: 500;
            }
            .dot {
                opacity: 1;
            }
        }
    `,
    dotUnread: css`
        opacity: 0;
        position: absolute;
        top: calc(50% - 3px);
        left: 12px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: ${colors.success};
    `,
    msgContent: css`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    msgCol: css`
        display: flex;
        flex-direction: column;
        gap: 4px;
        .sender,
        .time {
            color: #ccc;
            font-size: 14px;
        }
        button {
            margin-left: auto;
            color: ${colors.red};
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    `,
    popup: css`
        display: flex;
        flex-direction: column;
        min-height: 200px;
    `,
    popupContent: css`
        flex: 1;
    `,
};

export default NotiftyInbox;
