import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { NumHighlight } from 'models/lotteryModel';
import { useEffect, useState } from 'react';
import { getFormatHightLightLottery } from 'utils/function';
interface Props {
    resultList: string[];
    borderColor?: string;
    typeInputId: string;
}
function ResultLotteryMienBac(props: Props) {
    const { resultList, borderColor, typeInputId } = props;

    const [numList, setNumList] = useState<string[]>(resultList);
    const [formatData, setFormatData] = useState<NumHighlight>({} as NumHighlight);

    useEffect(() => {
        if (typeInputId) {
            let format = {} as NumHighlight;
            if (typeInputId === 'de-dac-biet') {
                // mien bac dac biet
                format = getFormatHightLightLottery(typeInputId, 'MBDB');
            } else {
                format = getFormatHightLightLottery(typeInputId, 'MB');
            }
            setFormatData(format);
        }
    }, [typeInputId]);

    useEffect(() => {
        if (Object.keys(formatData).length > 0) {
            setNumList(resultList);
        }
    }, [formatData, resultList]);

    const mapHightLight = (index: number, rowIndex: number) => {
        if (Object.keys(formatData).length > 0) {
            if (formatData.row === String(rowIndex)) {
                return (
                    <>
                        <span>{numList[index].substring(0, Number(formatData.posStart))}</span>
                        <span className={styles.hightLight}>{numList[index].substring(Number(formatData.posStart), Number(formatData.posEnd))}</span>
                        <span>{numList[index].substring(Number(formatData.posEnd), numList[index].length)}</span>
                    </>
                );
            } else {
                if (formatData.row.split(',')[rowIndex] === String(rowIndex)) {
                    return (
                        <>
                            <span>{numList[index] ? numList[index].substring(0, Number(formatData.posStart.split(',')[rowIndex])) : 'xx'}</span>
                            <span className={styles.hightLight}>
                                {numList[index] ? numList[index].substring(Number(formatData.posStart.split(',')[rowIndex]), Number(formatData.posEnd.split(',')[rowIndex])) : 'xx'}
                            </span>
                            <span>{numList[index] ? numList[index].substring(Number(formatData.posEnd.split(',')[rowIndex]), numList[index].length) : 'xx'}</span>
                        </>
                    );
                } else {
                    return <span>{numList[index]}</span>;
                }
            }
        }
        return <span>xxxxx</span>;
    };
    return (
        <div className={styles.wrap} style={{ borderColor }}>
            <div className={styles.rows}>
                <p className={styles.label}>Giải ĐB</p>
                <p>
                    <span>{mapHightLight(0, 0)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải nhất</p>
                <p>
                    <span>{mapHightLight(1, 1)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải nhì</p>
                <p>
                    <span>{mapHightLight(2, 2)}</span> - <span>{mapHightLight(3, 2)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải ba</p>
                <p>
                    <span>{mapHightLight(4, 3)}</span> - <span>{mapHightLight(5, 3)}</span> - <span>{mapHightLight(6, 3)}</span> - <span>{mapHightLight(7, 3)}</span> -{' '}
                    <span>{mapHightLight(8, 3)}</span> - <span>{mapHightLight(9, 3)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải tư</p>
                <p>
                    <span>{mapHightLight(10, 4)}</span> - <span>{mapHightLight(11, 4)}</span> - <span>{mapHightLight(12, 4)}</span> - <span>{mapHightLight(13, 4)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải năm</p>
                <p>
                    <span>{mapHightLight(14, 5)}</span> - <span>{mapHightLight(15, 5)}</span> - <span>{mapHightLight(16, 5)}</span> - <span>{mapHightLight(17, 5)}</span> -{' '}
                    <span>{mapHightLight(18, 5)}</span> - <span>{mapHightLight(19, 5)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải sáu</p>
                <p>
                    <span>{mapHightLight(20, 6)}</span> - <span>{mapHightLight(21, 6)}</span> - <span>{mapHightLight(22, 6)}</span>
                </p>
            </div>
            <div className={styles.rows}>
                <p className={styles.label}>Giải bảy</p>
                <p>
                    <span>{mapHightLight(23, 7)}</span> - <span>{mapHightLight(24, 7)}</span> - <span>{mapHightLight(25, 7)}</span> - <span>{mapHightLight(26, 7)}</span>
                </p>
            </div>
        </div>
    );
}
const styles = {
    wrap: css`
        border-radius: 8px;
        border: 1px solid ${colors.primary};
    `,
    rows: css`
        display: flex;
        border-bottom: 1px solid ${colors.borderTable};
        padding: 6px 12px;
        &:last-child {
            border-bottom: none;
        }
        p {
            text-align: center;
            :last-child {
                flex: 1;
            }
        }
    `,
    label: css`
        flex: 0 0 100px;
        color: ${colors.yellow};
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${colors.borderTable};
    `,
    hightLight: css`
        color: ${colors.red};
        font-weight: 500;
    `,
};
export default ResultLotteryMienBac;
