import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const addBankStyles = {
    wrap: css`
        background: ${colors.blackText};
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        padding: 20px;
        color: ${colors.white};
    `,
    heading: css`
        position: relative;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        h6 {
            font-size: 20px;
            font-weight: 500;
        }
        button {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    `,
    breadcrumb: css`
        display: flex;
        width: 100%;
        overflow: hidden;
        margin: 20px 0;
        justify-content: space-between;
        padding: 0 8px;
    `,
    breadItem: css`
        height: 48px;
        flex: 0 0 calc(50% - 12px);
        background: #32343b;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        p {
            padding-left: 16px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            border-left: 18px solid ${colors.blackText};
            border-top: 24px solid transparent;
            border-bottom: 24px solid transparent;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -18px;

            border-left: 18px solid #32343b;
            border-top: 24px solid transparent;
            border-bottom: 24px solid transparent;
        }
        &.active {
            background: ${colors.primary};
            &::after {
                border-left-color: ${colors.primary};
            }
        }
    `,
    breadItem1: css`
        z-index: 2;
    `,
    breadItem2: css`
        transform: translateX(-18px);
    `,
    noteConfirm: css`
        color: ${colors.yellow};
        font-size: 14px;
    `,
    infoBankList: css`
        margin-bottom: 22px;
        margin-top: 30px;
    `,
    infoBankItem: css`
        display: flex;
        align-items: center;
        gap: 12px;
        p {
            font-size: 14px;
            margin-bottom: 8px;
            &:first-child {
                flex: 0 0 30%;
            }
            &:last-child {
                flex: 1;
                padding: 12px;
                border-radius: 8px;
                background: ${colors.drak50};
                font-weight: 500;
            }
        }
    `,
    formAddBank: css`
        max-height: 60vh;
        overflow-y: auto;
        margin-right: -20px;
        padding-right: 20px;
        &::-webkit-scrollbar-thumb {
            background: #32343b;
        }
    `,

    inputBank: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
};
export default addBankStyles;
