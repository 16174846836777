import { LotteryInterface } from 'models/lotteryModel';

export const FastMienBacLotto: LotteryInterface[] = [
    {
        title: 'Bao Lô',
        typeIputID: 'bao-lo',
        classicStyle: false,
        active: true,
        // groupGames: {
        //   active: 0,
        //   list: [
        //     { key: 'lo2', title: 'Lô 2 Số', subTabIdxes: [0, 1, 2, 3] },
        //     { key: 'lo3', title: 'Lô 3 Số', subTabIdxes: [4] },
        //     { key: 'lo4', title: 'Lô 4 Số', subTabIdxes: [5] },
        //   ]
        // },
        subTabs: [
            {
                groupBy: 'lo2',
                title: 'Lô 2 Số',
                typeIputID: 'lo-2-so',
                active: true,
                desc: 'Đánh 2 chữ số cuối trong các giải',
                methodID: 14,
                methodidInDB: 14,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                rebate: '',
                number: 1,
                digits: '2,0',
                rule1Percent: 2,
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 10;20;30 hoặc 10 20 30',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                groupBy: 'lo2',
                title: 'Lô 2 Số Đầu',
                typeIputID: 'lo-2-so-dau',
                active: false,
                desc: 'Đánh 2 chữ số đầu tiên từ giải Đặc biệt đến giải Sáu',
                methodID: 200145,
                methodidInDB: 200145,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                rebate: '',
                number: 1,
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '0,1,2,3,4,5,6', posStart: '0,0,0,0,0,0,0', posEnd: '2,2,2,2,2,2,2' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    { title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false },
                                    { title: 'Tài', attr_name: 'big', cls: 'qselect', active: false },
                                    { title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false },
                                    { title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false },
                                    { title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    { title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false },
                                    { title: 'Tài', attr_name: 'big', cls: 'qselect', active: false },
                                    { title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false },
                                    { title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false },
                                    { title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [{ title: '00-99', attr_name: '00to99', cls: 'range-item', active: true }],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 10;20;30 hoặc 10 20 30',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    { title: 'Tải tập tin', attr_name: 'upload_file', cls: 'btn btn-primary', active: false },
                                    { title: 'Huỷ', attr_name: 'clear_upload', cls: 'btn btn-dark', active: false },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            // {
            //   groupBy: 'lo2',
            //   title: 'Lô 2 Số - 7 Lô Đầu',
            //   typeIputID: '2con7lodau',
            //   active: false,
            //   desc: 'Đánh 2 chữ số cuối trong các số  thứ #1, #2, #3, #4, #5, #6, #7',
            //   methodID: 20038,
            //   methodidInDB: 20038,
            //   dyprize: {point: '', prize: ''},
            //   payprizenum: 0,
            //   betBtnActive: false,
            //   isXienTruot: false,
            //   amount: 0,
            //   number: 1,
            //   rebate: '',
            //   digits: '2,0',
            //   rule1Percent: 2,
            //   numHlPos: [1,2,3,4,5,6,7],
            //   numHl: {row: '0,1,2,3,4,5,6,7', posStart: '3,3,3,3,2,2,1,0', posEnd: '5,5,5,5,4,4,3,2'},
            //   typeInput: [
            //     {
            //       type: 'digital',
            //       typeIputID: 'pick',
            //       title: 'Chọn số',
            //       active: true,
            //       numDisabled: false,
            //       layout: [
            //         {
            //           title: 'Chục',
            //           no: '0|9',
            //           selectedNums: [],
            //           btns: [
            //             {title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false},
            //             {title: 'Tài', attr_name: 'big', cls: 'qselect', active: false},
            //             {title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false},
            //             {title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false},
            //             {title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false},
            //           ]
            //         },
            //         {
            //           title: 'Đơn vị',
            //           no: '0|9',
            //           selectedNums: [],
            //           btns: [
            //             {title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false},
            //             {title: 'Tài', attr_name: 'big', cls: 'qselect', active: false},
            //             {title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false},
            //             {title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false},
            //             {title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false},
            //           ]
            //         }
            //       ]
            //     },
            //     {
            //       type: 'f-digital',
            //       typeIputID: 'quick-pick',
            //       title: 'Chọn số nhanh',
            //       active: false,
            //       numDisabled: false,
            //       layout: [
            //         {
            //           title: '00-99',
            //           no: '00|99',
            //           btns: [{title: '00-99', attr_name: '00to99', cls: 'range-item', active: true}],
            //           selectedNums: []
            //         }
            //       ],
            //       randomPickLayout: {
            //         layout: [
            //           {
            //             randomAmount: 10,
            //             title: '10 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 20,
            //             title: '20 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 30,
            //             title: '30 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 40,
            //             title: '40 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 50,
            //             title: '50 số',
            //             countNumAsOne: 1
            //           },
            //         ]
            //       }
            //     },
            //     {
            //       type: 'f-digital',
            //       typeIputID: 'type-in',
            //       title: 'Nhập số',
            //       active: false,
            //       numDisabled: false,
            //       layout: [
            //         {
            //           title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \n \
            //             Ví dụ: 10;20;30 hoặc 10 20 30',
            //           no: '',
            //           selectedNums: [],
            //           btns: [{title: 'Tải tập tin', attr_name: 'upload_file', cls: 'btn btn-primary', active: false},
            //             {title: 'Huỷ', attr_name: 'clear_upload', cls: 'btn btn-dark', active: false}]
            //         }
            //       ],
            //       randomPickLayout: {
            //         layout: [
            //           {
            //             randomAmount: 10,
            //             title: '10 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 20,
            //             title: '20 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 30,
            //             title: '30 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 40,
            //             title: '40 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 50,
            //             title: '50 số',
            //             countNumAsOne: 1
            //           },
            //         ],
            //         typeInOptConfig: {
            //           separateOperator: ';',
            //           randomFormat: 'xx'
            //         }
            //       }
            //     }
            //   ]
            // },
            // {
            //   groupBy: 'lo2',
            //   title: 'Lô 2 Số - 7 Lô Cuối',
            //   typeIputID: '2con7locuoi',
            //   active: false,
            //   desc: 'Đánh 2 chữ số cuối trong các số  thứ #21, #22, #23, #24, #25, #26, #27',
            //   methodID: 20040,
            //   methodidInDB: 20040,
            //   dyprize: {point: '', prize: ''},
            //   payprizenum: 0,
            //   betBtnActive: false,
            //   isXienTruot: false,
            //   amount: 0,
            //   number: 1,
            //   rebate: '',
            //   digits: '2,0',
            //   rule1Percent: 2,
            //   numHlPos: [21,22,23,24,25,26,27],
            //   numHl: {row: '0,1,2,3,4,5,6,7', posStart: '3,3,3,3,2,2,1,0', posEnd: '5,5,5,5,4,4,3,2'},
            //   typeInput: [
            //     {
            //       type: 'digital',
            //       typeIputID: 'pick',
            //       title: 'Chọn số',
            //       active: true,
            //       numDisabled: false,
            //       layout: [
            //         {
            //           title: 'Chục',
            //           no: '0|9',
            //           selectedNums: [],
            //           btns: [
            //             {title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false},
            //             {title: 'Tài', attr_name: 'big', cls: 'qselect', active: false},
            //             {title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false},
            //             {title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false},
            //             {title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false},
            //           ]
            //         },
            //         {
            //           title: 'Đơn vị',
            //           no: '0|9',
            //           selectedNums: [],
            //           btns: [
            //             {title: 'Tất cả', attr_name: 'all', cls: 'qselect', active: false},
            //             {title: 'Tài', attr_name: 'big', cls: 'qselect', active: false},
            //             {title: 'Xỉu', attr_name: 'small', cls: 'qselect', active: false},
            //             {title: 'Lẻ', attr_name: 'odd', cls: 'qselect', active: false},
            //             {title: 'Chẵn', attr_name: 'even', cls: 'qselect', active: false},
            //           ]
            //         }
            //       ]
            //     },
            //     {
            //       type: 'f-digital',
            //       typeIputID: 'quick-pick',
            //       title: 'Chọn số nhanh',
            //       active: false,
            //       numDisabled: false,
            //       layout: [
            //         {
            //           title: '00-99',
            //           no: '00|99',
            //           btns: [{title: '00-99', attr_name: '00to99', cls: 'range-item', active: true}],
            //           selectedNums: []
            //         }
            //       ],
            //       randomPickLayout: {
            //         layout: [
            //           {
            //             randomAmount: 10,
            //             title: '10 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 20,
            //             title: '20 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 30,
            //             title: '30 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 40,
            //             title: '40 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 50,
            //             title: '50 số',
            //             countNumAsOne: 1
            //           },
            //         ]
            //       }
            //     },
            //     {
            //       type: 'f-digital',
            //       typeIputID: 'type-in',
            //       title: 'Nhập số',
            //       active: false,
            //       numDisabled: false,
            //       layout: [
            //         {
            //           title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \n \
            //             Ví dụ: 10;20;30 hoặc 10 20 30',
            //           no: '',
            //           selectedNums: [],
            //           btns: [{title: 'Tải tập tin', attr_name: 'upload_file', cls: 'btn btn-primary', active: false},
            //             {title: 'Huỷ', attr_name: 'clear_upload', cls: 'btn btn-dark', active: false}]
            //         }
            //       ],
            //       randomPickLayout: {
            //         layout: [
            //           {
            //             randomAmount: 10,
            //             title: '10 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 20,
            //             title: '20 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 30,
            //             title: '30 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 40,
            //             title: '40 số',
            //             countNumAsOne: 1
            //           },
            //           {
            //             randomAmount: 50,
            //             title: '50 số',
            //             countNumAsOne: 1
            //           },
            //         ],
            //         typeInOptConfig: {
            //           separateOperator: ';',
            //           randomFormat: 'xx'
            //         }
            //       }
            //     }
            //   ]
            // },
            {
                groupBy: 'lo3',
                title: 'Lô 3 Số',
                typeIputID: 'lo-3-so',
                active: false,
                desc: 'Đánh 3 chữ số cuối trong các giải có 3 chữ số trở lên',
                methodID: 15,
                methodidInDB: 15,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '3,0',
                rule1Percent: 11,
                numHl: {
                    row: '0,1,2,3,4,5,6',
                    posStart: '2,2,2,2,1,1,0',
                    posEnd: '5,5,5,5,4,4,3',
                },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Trăm',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '000-099',
                                no: '000|099',
                                btns: [
                                    {
                                        title: '000-099',
                                        attr_name: '000to099',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '100-199',
                                no: '100|199',
                                btns: [
                                    {
                                        title: '100-199',
                                        attr_name: '100to199',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '200-299',
                                no: '200|299',
                                btns: [
                                    {
                                        title: '200-299',
                                        attr_name: '200to299',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '300-399',
                                no: '300|399',
                                btns: [
                                    {
                                        title: '300-399',
                                        attr_name: '300to399',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '400-499',
                                no: '400|499',
                                btns: [
                                    {
                                        title: '400-499',
                                        attr_name: '400to499',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '500-599',
                                no: '500|599',
                                btns: [
                                    {
                                        title: '500-599',
                                        attr_name: '500to599',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '600-699',
                                no: '600|699',
                                btns: [
                                    {
                                        title: '600-699',
                                        attr_name: '600to699',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '700-799',
                                no: '700|799',
                                btns: [
                                    {
                                        title: '700-799',
                                        attr_name: '700to799',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '800-899',
                                no: '800|899',
                                btns: [
                                    {
                                        title: '800-899',
                                        attr_name: '800to899',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '900-999',
                                no: '900|999',
                                btns: [
                                    {
                                        title: '900-999',
                                        attr_name: '900to999',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 234;821;193 hoặc 234 821 193',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xxx',
                            },
                        },
                    },
                ],
            },
            {
                groupBy: 'lo4',
                title: 'Lô 4 Số',
                typeIputID: 'lo-4-so',
                active: false,
                desc: 'Đánh 4 chữ số cuối trong các giải có 4 chữ số trở lên',
                methodID: 26,
                methodidInDB: 26,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '4,0',
                rule1Percent: 101,
                numHl: {
                    row: '0,1,2,3,4,5',
                    posStart: '1,1,1,1,0,0',
                    posEnd: '5,5,5,5,4,4',
                },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Nghìn',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Trăm',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \n \
                  Ví dụ: 2384;8210;1393 hoặc 2384 8210 1393',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xxxx',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        title: 'Lô Xiên',
        typeIputID: 'lo-xien',
        classicStyle: false,
        active: false,
        subTabs: [
            {
                title: 'Xiên 2',
                typeIputID: 'xien-2',
                active: true,
                desc: 'Xiên 2 của 2 chữ số cuối trong các giải',
                methodID: 16,
                methodidInDB: 16,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: true,
                amount: 0,
                rebate: '',
                number: 1,
                digits: '2,2',
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                                countNumAsOne: 2,
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 2,
                                    title: '1 số',
                                    countNumAsOne: 2,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , \nGiữa con số phân cách bởi & hoặc khoảng trắng \nVí dụ: 23&43;87&25 hoặc 23&43 87&25',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 1,
                                    title: '1 số',
                                    countNumAsOne: 2,
                                },
                                {
                                    randomAmount: 2,
                                    title: '2 số',
                                    countNumAsOne: 2,
                                },
                                {
                                    randomAmount: 3,
                                    title: '3 số',
                                    countNumAsOne: 2,
                                },
                                {
                                    randomAmount: 5,
                                    title: '5 số',
                                    countNumAsOne: 2,
                                },
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 2,
                                },
                            ],
                            typeInOptConfig: {
                                combineAsOneOperator: '&',
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Xiên 3',
                typeIputID: 'xien-3',
                active: false,
                desc: 'Xiên 3 của 2 chữ số cuối trong các giải',
                methodID: 17,
                methodidInDB: 17,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: true,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,3',
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                                countNumAsOne: 3,
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 3,
                                    title: '1 số',
                                    countNumAsOne: 3,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , \nGiữa con số phân cách bởi & hoặc khoảng trắng \nVí dụ: 23&82&13;36&10&25 hoặc 23&82&13 36&10&25',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 1,
                                    title: '1 số',
                                    countNumAsOne: 3,
                                },
                                {
                                    randomAmount: 2,
                                    title: '2 số',
                                    countNumAsOne: 3,
                                },
                                {
                                    randomAmount: 3,
                                    title: '3 số',
                                    countNumAsOne: 3,
                                },
                                {
                                    randomAmount: 5,
                                    title: '5 số',
                                    countNumAsOne: 3,
                                },
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 3,
                                },
                            ],
                            typeInOptConfig: {
                                combineAsOneOperator: '&',
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Xiên 4',
                typeIputID: 'xien-4',
                active: false,
                desc: 'Xiên 4 của 2 chữ số cuối trong các giải',
                methodID: 18,
                methodidInDB: 18,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: true,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,4',
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                                countNumAsOne: 4,
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 4,
                                    title: '1 số',
                                    countNumAsOne: 4,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , \nGiữa con số phân cách bởi & hoặc khoảng trắng \nVí dụ: 23&82&13&22;36&10&25&33 hoặc 23&82&13&22 36&10&25&33',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 1,
                                    title: '1 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 2,
                                    title: '2 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 3,
                                    title: '3 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 5,
                                    title: '5 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 4,
                                },
                            ],
                            typeInOptConfig: {
                                combineAsOneOperator: '&',
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        title: 'Đánh Đề',
        typeIputID: 'danh-de',
        classicStyle: false,
        active: false,
        subTabs: [
            {
                title: 'Đề Đặc Biệt',
                typeIputID: 'de-dac-biet',
                active: true,
                desc: 'Đánh 2 chữ số cuối trong giải đặc biệt',
                methodID: 6,
                methodidInDB: 6,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '0', posStart: '3', posEnd: '5' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 23;82 hoặc 23 82',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Đề Đầu Đặc Biệt',
                typeIputID: 'de-dau-dac-biet',
                active: false,
                desc: 'Đánh 2 chữ số đầu trong giải đặc biệt',
                methodID: 20036,
                methodidInDB: 20036,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '0', posStart: '0', posEnd: '2' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 23;82 hoặc 23 82',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Đề Giải 7',
                typeIputID: 'de-giai-7',
                active: false,
                desc: 'Đánh 2 chữ số cuối trong 4 giải của giải 7',
                methodID: 20035,
                methodidInDB: 20035,
                dyprize: { point: '', prize: '' },
                betBtnActive: false,
                payprizenum: 0,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '7', posStart: '0', posEnd: '2' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 23;82 hoặc 23 82',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Đề Giải Nhất',
                typeIputID: 'de-giai-nhat',
                active: false,
                desc: 'Đánh 2 chữ số cuối trong giải nhất',
                methodID: 20034,
                methodidInDB: 20034,
                dyprize: { point: '', prize: '' },
                betBtnActive: false,
                payprizenum: 0,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '1', posStart: '3', posEnd: '5' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 23;82 hoặc 23 82',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Đề Đầu Giải Nhất',
                typeIputID: 'de-dau-giai-nhat',
                active: false,
                desc: 'Đánh 2 chữ số đầu trong giải nhất',
                methodID: 20054,
                methodidInDB: 20054,
                dyprize: { point: '', prize: '' },
                betBtnActive: false,
                payprizenum: 0,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,0',
                rule1Percent: 2,
                numHl: { row: '1', posStart: '0', posEnd: '2' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 23;82 hoặc 23 82',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        title: 'Đầu Đuôi',
        typeIputID: 'dau-duoi',
        classicStyle: false,
        active: false,
        subTabs: [
            {
                title: 'Đầu',
                typeIputID: 'dau',
                active: true,
                desc: 'Đánh 1 chữ số hàng chục của giải đặc biệt',
                methodID: 20,
                methodidInDB: 20,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '1,0',
                numHl: { row: '0', posStart: '3', posEnd: '4' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                title: 'Đuôi',
                typeIputID: 'duoi',
                active: false,
                desc: 'Đánh 1 chữ số cuối của giải đặc biệt',
                methodID: 22,
                methodidInDB: 22,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '1,0',
                numHl: { row: '0', posStart: '4', posEnd: '5' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: '3 Càng',
        typeIputID: '3-cang',
        classicStyle: false,
        active: false,
        subTabs: [
            // {
            //     title: "3 Càng Đầu Đuôi",
            //     typeIputID: "3-cang-dau-duoi",
            //     active: true,
            //     desc: "Đánh 3 chữ số cuối của giải đặc biệt và giải sáu.",
            //     methodID: 20037,
            //     methodidInDB: 20037,
            //     dyprize: { point: "", prize: "" },
            //     payprizenum: 0,
            //     betBtnActive: false,
            //     isXienTruot: false,
            //     amount: 0,
            //     number: 1,
            //     rebate: "",
            //     digits: "3,0",
            //     rule1Percent: 11,
            //     numHl: {
            //         row: "0,1,2,3,4,5,6",
            //         posStart: "2,0,0,0,0,0,0",
            //         posEnd: "5,0,0,0,0,0,3",
            //     },
            //     typeInput: [
            //         {
            //             type: "digital",
            //             typeIputID: "pick",
            //             title: "Chọn số",
            //             active: true,
            //             numDisabled: false,
            //             layout: [
            //                 {
            //                     title: "Trăm",
            //                     no: "0|9",
            //                     selectedNums: [],
            //                     btns: [
            //                         {
            //                             title: "Tất cả",
            //                             attr_name: "all",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Tài",
            //                             attr_name: "big",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Xỉu",
            //                             attr_name: "small",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Lẻ",
            //                             attr_name: "odd",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Chẵn",
            //                             attr_name: "even",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                     ],
            //                 },
            //                 {
            //                     title: "Chục",
            //                     no: "0|9",
            //                     selectedNums: [],
            //                     btns: [
            //                         {
            //                             title: "Tất cả",
            //                             attr_name: "all",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Tài",
            //                             attr_name: "big",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Xỉu",
            //                             attr_name: "small",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Lẻ",
            //                             attr_name: "odd",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Chẵn",
            //                             attr_name: "even",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                     ],
            //                 },
            //                 {
            //                     title: "Đơn vị",
            //                     no: "0|9",
            //                     selectedNums: [],
            //                     btns: [
            //                         {
            //                             title: "Tất cả",
            //                             attr_name: "all",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Tài",
            //                             attr_name: "big",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Xỉu",
            //                             attr_name: "small",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Lẻ",
            //                             attr_name: "odd",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Chẵn",
            //                             attr_name: "even",
            //                             cls: "qselect",
            //                             active: false,
            //                         },
            //                     ],
            //                 },
            //             ],
            //         },
            //         {
            //             type: "f-digital",
            //             typeIputID: "quick-pick",
            //             title: "Chọn số nhanh",
            //             active: false,
            //             numDisabled: false,
            //             layout: [
            //                 {
            //                     title: "000-099",
            //                     no: "000|099",
            //                     btns: [
            //                         {
            //                             title: "000-099",
            //                             attr_name: "000to099",
            //                             cls: "range-item",
            //                             active: true,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "100-199",
            //                     no: "100|199",
            //                     btns: [
            //                         {
            //                             title: "100-199",
            //                             attr_name: "100to199",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "200-299",
            //                     no: "200|299",
            //                     btns: [
            //                         {
            //                             title: "200-299",
            //                             attr_name: "200to299",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "300-399",
            //                     no: "300|399",
            //                     btns: [
            //                         {
            //                             title: "300-399",
            //                             attr_name: "300to399",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "400-499",
            //                     no: "400|499",
            //                     btns: [
            //                         {
            //                             title: "400-499",
            //                             attr_name: "400to499",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "500-599",
            //                     no: "500|599",
            //                     btns: [
            //                         {
            //                             title: "500-599",
            //                             attr_name: "500to599",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "600-699",
            //                     no: "600|699",
            //                     btns: [
            //                         {
            //                             title: "600-699",
            //                             attr_name: "600to699",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "700-799",
            //                     no: "700|799",
            //                     btns: [
            //                         {
            //                             title: "700-799",
            //                             attr_name: "700to799",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "800-899",
            //                     no: "800|899",
            //                     btns: [
            //                         {
            //                             title: "800-899",
            //                             attr_name: "800to899",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //                 {
            //                     title: "900-999",
            //                     no: "900|999",
            //                     btns: [
            //                         {
            //                             title: "900-999",
            //                             attr_name: "900to999",
            //                             cls: "range-item",
            //                             active: false,
            //                         },
            //                     ],
            //                     selectedNums: [],
            //                 },
            //             ],
            //             randomPickLayout: {
            //                 layout: [
            //                     {
            //                         randomAmount: 10,
            //                         title: "10 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 20,
            //                         title: "20 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 30,
            //                         title: "30 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 40,
            //                         title: "40 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 50,
            //                         title: "50 số",
            //                         countNumAsOne: 1,
            //                     },
            //                 ],
            //             },
            //         },
            //         {
            //             type: "f-digital",
            //             typeIputID: "type-in",
            //             title: "Nhập số",
            //             active: false,
            //             numDisabled: false,
            //             layout: [
            //                 {
            //                     title: "Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 232;822 hoặc 232 822",
            //                     no: "",
            //                     selectedNums: [],
            //                     btns: [
            //                         {
            //                             title: "Tải tập tin",
            //                             attr_name: "upload_file",
            //                             cls: "btn btn-primary",
            //                             active: false,
            //                         },
            //                         {
            //                             title: "Huỷ",
            //                             attr_name: "clear_upload",
            //                             cls: "btn btn-dark",
            //                             active: false,
            //                         },
            //                     ],
            //                 },
            //             ],
            //             randomPickLayout: {
            //                 layout: [
            //                     {
            //                         randomAmount: 10,
            //                         title: "10 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 20,
            //                         title: "20 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 30,
            //                         title: "30 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 40,
            //                         title: "40 số",
            //                         countNumAsOne: 1,
            //                     },
            //                     {
            //                         randomAmount: 50,
            //                         title: "50 số",
            //                         countNumAsOne: 1,
            //                     },
            //                 ],
            //                 typeInOptConfig: {
            //                     separateOperator: ";",
            //                     randomFormat: "xxx",
            //                 },
            //             },
            //         },
            //     ],
            // },
            {
                title: '3 Càng Đặc Biệt',
                typeIputID: '3-cang-dac-biet',
                active: true,
                desc: 'Đánh 3 chữ số cuối của giải đặc biệt',
                methodID: 21,
                methodidInDB: 21,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '3,0',
                rule1Percent: 11,
                numHl: { row: '0', posStart: '2', posEnd: '5' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Trăm',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '000-099',
                                no: '000|099',
                                btns: [
                                    {
                                        title: '000-099',
                                        attr_name: '000to099',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '100-199',
                                no: '100|199',
                                btns: [
                                    {
                                        title: '100-199',
                                        attr_name: '100to199',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '200-299',
                                no: '200|299',
                                btns: [
                                    {
                                        title: '200-299',
                                        attr_name: '200to299',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '300-399',
                                no: '300|399',
                                btns: [
                                    {
                                        title: '300-399',
                                        attr_name: '300to399',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '400-499',
                                no: '400|499',
                                btns: [
                                    {
                                        title: '400-499',
                                        attr_name: '400to499',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '500-599',
                                no: '500|599',
                                btns: [
                                    {
                                        title: '500-599',
                                        attr_name: '500to599',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '600-699',
                                no: '600|699',
                                btns: [
                                    {
                                        title: '600-699',
                                        attr_name: '600to699',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '700-799',
                                no: '700|799',
                                btns: [
                                    {
                                        title: '700-799',
                                        attr_name: '700to799',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '800-899',
                                no: '800|899',
                                btns: [
                                    {
                                        title: '800-899',
                                        attr_name: '800to899',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '900-999',
                                no: '900|999',
                                btns: [
                                    {
                                        title: '900-999',
                                        attr_name: '900to999',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 232;822 hoặc 232 822',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xxx',
                            },
                        },
                    },
                ],
            },
            {
                title: '3 Càng Đầu',
                typeIputID: '3-cang-dau',
                active: false,
                desc: 'Đánh 3 chữ số cuối của giải sáu.',
                methodID: 20011,
                methodidInDB: 20011,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '3,0',
                rule1Percent: 11,
                numHl: {
                    row: '0,1,2,3,4,5,6',
                    posStart: '0,0,0,0,0,0,0',
                    posEnd: '0,0,0,0,0,0,3',
                },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Trăm',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '000-099',
                                no: '000|099',
                                btns: [
                                    {
                                        title: '000-099',
                                        attr_name: '000to099',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '100-199',
                                no: '100|199',
                                btns: [
                                    {
                                        title: '100-199',
                                        attr_name: '100to199',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '200-299',
                                no: '200|299',
                                btns: [
                                    {
                                        title: '200-299',
                                        attr_name: '200to299',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '300-399',
                                no: '300|399',
                                btns: [
                                    {
                                        title: '300-399',
                                        attr_name: '300to399',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '400-499',
                                no: '400|499',
                                btns: [
                                    {
                                        title: '400-499',
                                        attr_name: '400to499',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '500-599',
                                no: '500|599',
                                btns: [
                                    {
                                        title: '500-599',
                                        attr_name: '500to599',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '600-699',
                                no: '600|699',
                                btns: [
                                    {
                                        title: '600-699',
                                        attr_name: '600to699',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '700-799',
                                no: '700|799',
                                btns: [
                                    {
                                        title: '700-799',
                                        attr_name: '700to799',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '800-899',
                                no: '800|899',
                                btns: [
                                    {
                                        title: '800-899',
                                        attr_name: '800to899',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '900-999',
                                no: '900|999',
                                btns: [
                                    {
                                        title: '900-999',
                                        attr_name: '900to999',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 232;822 hoặc 232 822',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xxx',
                            },
                        },
                    },
                ],
            },
            {
                title: '3 Càng Giải Nhất',
                typeIputID: '3-cang-giai-nhat',
                active: false,
                desc: 'Đánh 3 chữ số cuối của giải nhất.',
                methodID: 20050,
                methodidInDB: 20050,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '3,0',
                rule1Percent: 11,
                numHl: {
                    row: '0,1,2,3,4,5,6',
                    posStart: '0,2,0,0,0,0,0',
                    posEnd: '0,5,0,0,0,0,0',
                },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Trăm',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: '000-099',
                                no: '000|099',
                                btns: [
                                    {
                                        title: '000-099',
                                        attr_name: '000to099',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '100-199',
                                no: '100|199',
                                btns: [
                                    {
                                        title: '100-199',
                                        attr_name: '100to199',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '200-299',
                                no: '200|299',
                                btns: [
                                    {
                                        title: '200-299',
                                        attr_name: '200to299',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '300-399',
                                no: '300|399',
                                btns: [
                                    {
                                        title: '300-399',
                                        attr_name: '300to399',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '400-499',
                                no: '400|499',
                                btns: [
                                    {
                                        title: '400-499',
                                        attr_name: '400to499',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '500-599',
                                no: '500|599',
                                btns: [
                                    {
                                        title: '500-599',
                                        attr_name: '500to599',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '600-699',
                                no: '600|699',
                                btns: [
                                    {
                                        title: '600-699',
                                        attr_name: '600to699',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '700-799',
                                no: '700|799',
                                btns: [
                                    {
                                        title: '700-799',
                                        attr_name: '700to799',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '800-899',
                                no: '800|899',
                                btns: [
                                    {
                                        title: '800-899',
                                        attr_name: '800to899',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                            {
                                title: '900-999',
                                no: '900|999',
                                btns: [
                                    {
                                        title: '900-999',
                                        attr_name: '900to999',
                                        cls: 'range-item',
                                        active: false,
                                    },
                                ],
                                selectedNums: [],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 232;822 hoặc 232 822',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xxx',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        title: '4 Càng',
        typeIputID: '4-cang',
        classicStyle: false,
        active: false,
        subTabs: [
            {
                title: '4 Càng Đặc Biệt',
                typeIputID: '4-cang-dac-biet',
                active: true,
                desc: 'So với 4 số cuối của giải đặc biệt',
                methodID: 23,
                methodidInDB: 23,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: false,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '4,0',
                rule1Percent: 101,
                numHl: { row: '0', posStart: '1', posEnd: '5' },
                typeInput: [
                    {
                        type: 'digital',
                        typeIputID: 'pick',
                        title: 'Chọn số',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Nghìn',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Trăm',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Chục',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                            {
                                title: 'Đơn vị',
                                no: '0|9',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tất cả',
                                        attr_name: 'all',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Tài',
                                        attr_name: 'big',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Xỉu',
                                        attr_name: 'small',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Lẻ',
                                        attr_name: 'odd',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                    {
                                        title: 'Chẵn',
                                        attr_name: 'even',
                                        cls: 'qselect',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: 2321;8221 hoặc 2321 8221',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 20,
                                    title: '20 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 30,
                                    title: '30 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 40,
                                    title: '40 số',
                                    countNumAsOne: 1,
                                },
                                {
                                    randomAmount: 50,
                                    title: '50 số',
                                    countNumAsOne: 1,
                                },
                            ],
                            typeInOptConfig: {
                                separateOperator: ';',
                                randomFormat: 'xxxx',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        title: 'Lô Trượt',
        typeIputID: 'lo-truot',
        classicStyle: false,
        active: false,
        subTabs: [
            {
                title: 'Trượt Xiên 4',
                typeIputID: 'truot-xien-4',
                active: true,
                desc: 'Trượt xiên 4 của 2 chữ số cuối trong các giải',
                methodID: 28,
                methodidInDB: 28,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: true,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,4',
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                                countNumAsOne: 4,
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 4,
                                    title: '1 số',
                                    countNumAsOne: 4,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , \nGiữa côn só phân cách bởi & hoặc khoảng trắng \nVí dụ: 12&34&56&78;68&23&45&79 hoặc 12&34&56&78 68&23&45&79',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 1,
                                    title: '1 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 2,
                                    title: '2 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 3,
                                    title: '3 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 5,
                                    title: '5 số',
                                    countNumAsOne: 4,
                                },
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 4,
                                },
                            ],
                            typeInOptConfig: {
                                combineAsOneOperator: '&',
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Trượt Xiên 8',
                typeIputID: 'truot-xien-8',
                active: false,
                desc: 'Trượt xiên 8 của 2 chữ số cuối trong các giải',
                methodID: 30,
                methodidInDB: 30,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: true,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,8',
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                                countNumAsOne: 8,
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 8,
                                    title: '1 số',
                                    countNumAsOne: 8,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , \nGiữa côn só phân cách bởi &  hoặc khoảng trắng \nVí dụ: 12&34&56&78&35&10&54&65;68&23&45&79&11&33&55&98 hoặc 12&34&56&78&35&10&54&65 68&23&45&79&11&33&55&98',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 1,
                                    title: '1 số',
                                    countNumAsOne: 8,
                                },
                                {
                                    randomAmount: 2,
                                    title: '2 số',
                                    countNumAsOne: 8,
                                },
                                {
                                    randomAmount: 3,
                                    title: '3 số',
                                    countNumAsOne: 8,
                                },
                                {
                                    randomAmount: 5,
                                    title: '5 số',
                                    countNumAsOne: 8,
                                },
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 8,
                                },
                            ],
                            typeInOptConfig: {
                                combineAsOneOperator: '&',
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
            {
                title: 'Trượt Xiên 10',
                typeIputID: 'truot-xien-10',
                active: false,
                desc: 'Trượt xiên 10 của 2 chữ số cuối trong các giải',
                methodID: 32,
                methodidInDB: 32,
                dyprize: { point: '', prize: '' },
                payprizenum: 0,
                betBtnActive: false,
                isXienTruot: true,
                amount: 0,
                number: 1,
                rebate: '',
                digits: '2,10',
                numHl: {
                    row: '0,1,2,3,4,5,6,7',
                    posStart: '3,3,3,3,2,2,1,0',
                    posEnd: '5,5,5,5,4,4,3,2',
                },
                typeInput: [
                    {
                        type: 'f-digital',
                        typeIputID: 'quick-pick',
                        title: 'Chọn số nhanh',
                        active: true,
                        numDisabled: false,
                        layout: [
                            {
                                title: '00-99',
                                no: '00|99',
                                btns: [
                                    {
                                        title: '00-99',
                                        attr_name: '00to99',
                                        cls: 'range-item',
                                        active: true,
                                    },
                                ],
                                selectedNums: [],
                                countNumAsOne: 10,
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 10,
                                    title: '1 số',
                                    countNumAsOne: 10,
                                },
                            ],
                        },
                    },
                    {
                        type: 'f-digital',
                        typeIputID: 'type-in',
                        title: 'Nhập số',
                        active: false,
                        numDisabled: false,
                        layout: [
                            {
                                title: 'Cách chơi: \nGiữa mỗi cược phân cách bởi dấu ; hoặc dấu , \nGiữa côn só phân cách bởi &  hoặc khoảng trắng \nVí dụ: 12&34&56&78&35&10&54&65&09;68&23&45&79&11&33&55&98&08 hoặc 12&34&56&78&35&10&54&65&09 68&23&45&79&11&33&55&98&08',
                                no: '',
                                selectedNums: [],
                                btns: [
                                    {
                                        title: 'Tải tập tin',
                                        attr_name: 'upload_file',
                                        cls: 'btn btn-primary',
                                        active: false,
                                    },
                                    {
                                        title: 'Huỷ',
                                        attr_name: 'clear_upload',
                                        cls: 'btn btn-dark',
                                        active: false,
                                    },
                                ],
                            },
                        ],
                        randomPickLayout: {
                            layout: [
                                {
                                    randomAmount: 1,
                                    title: '1 số',
                                    countNumAsOne: 10,
                                },
                                {
                                    randomAmount: 2,
                                    title: '2 số',
                                    countNumAsOne: 10,
                                },
                                {
                                    randomAmount: 3,
                                    title: '3 số',
                                    countNumAsOne: 10,
                                },
                                {
                                    randomAmount: 5,
                                    title: '5 số',
                                    countNumAsOne: 10,
                                },
                                {
                                    randomAmount: 10,
                                    title: '10 số',
                                    countNumAsOne: 10,
                                },
                            ],
                            typeInOptConfig: {
                                combineAsOneOperator: '&',
                                separateOperator: ';',
                                randomFormat: 'xx',
                            },
                        },
                    },
                ],
            },
        ],
    },
];
