import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './help-page.style';
import globalStyle from 'constants/global.style';
import classNames from 'classnames';
import { scrollToTopPage } from 'utils/function';
import { appName } from 'config/app.config';
import Guide from './Guide';

const viewList = [
    { id: 1, title: 'Quy Định Luật Lệ' },
    { id: 2, title: 'Trách Nhiệm Người Chơi' },
    { id: 3, title: 'Chính Sách Bảo Mật' },
    { id: 4, title: 'Tuyên Bố Bản Quyền' },
    { id: 5, title: 'Hướng Dẫn' },
];

function HelpPage() {
    const scrollContent = useRef<HTMLDivElement>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const view: string | null = searchParams.get('view');
    const [pageActive, setPageActive] = useState<number>(1);

    useEffect(() => {
        // scrollTop();
    }, []);

    useEffect(() => {
        if ([1, 2, 3, 4, 5].includes(Number(view))) {
            setPageActive(Number(view));
        } else {
            setPageActive(1);
        }
    }, [view]);

    const scrollTop = () => {
        if (scrollContent.current) {
            scrollContent.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const changeView = (page: number) => {
        setPageActive(page);
        setSearchParams({ view: String(page) });
        scrollTop();
    };

    return (
        <div className={styles.wrap}>
            <div className={globalStyle.container} style={{ height: '100%' }}>
                <div className={styles.inner}>
                    <div className={styles.left}>
                        {viewList.map((item) => (
                            <div className={classNames(styles.viewOption, { active: item.id === pageActive })} key={item.id} onClick={() => changeView(item.id)}>
                                {item.title}
                            </div>
                        ))}
                    </div>
                    <div className={styles.right} ref={scrollContent}>
                        {pageActive === 1 && (
                            <p>
                                Chào mừng thành viên đến với <strong>{appName}</strong>, một trong những nhà cái số một Đông Nam Á nói riêng và Châu Á nói chung, có sức ảnh hưởng không hề nhỏ trong
                                giới giải trí thể thao, casino, xổ số và <strong>{appName}</strong> cũng cung cấp các sản phẩm đa dạng trực tuyến đến người chơi từ các môn Thể Thao như bóng đá, bóng
                                rổ đến các trò casino trực tuyến, xổ số lô đề, keno, quay hủ và các trò chơi khác.
                                <br />
                                <br />
                                Thành viên cần chắc chắn và đảm bảo đã đọc và hiểu rõ các Quy Đinh và Điều Khoản này trước khi thành viên bắt đầu sử dụng tham gia bất kì sản phẩm, trò chơi nào trên
                                website <strong>{appName}</strong> của Chúng Tôi. Các Điều Khoản và Điều Kiện này bao gồm các thông tin quan trọng về việc thoả thuận pháp lý giữa Chúng Tôi và thành
                                viên, và cung cấp thông tin về những tài liệu khác thể hiện mối quan hệ giữa Chúng Tôi với thành viên.
                                <br />
                                <br />
                                <span className={styles.textTitle}>Thứ nhất: Các quy định về tài khoản</span>
                                <br />
                                <span className={styles.session}>
                                    <span>
                                        1. Khi người chơi đăng ký sử dụng <strong>{appName}</strong> đồng nghĩa với việc người chơi đã thực sự đọc, hiểu và đồng ý các quy định về điều khoản, định mức
                                        và các quy tắc trò chơi mà <strong>{appName}</strong> đưa ra và đồng ý chịu các trách nhiệm pháp lý tương đương.
                                    </span>
                                    <span>
                                        2. Nếu không đồng ý với các điều khoản và điều kiện tại <strong>{appName}</strong>, người chơi có thể ngừng sử dụng dịch vụ ngay lập tức.{' '}
                                    </span>
                                    <span>
                                        3. Khi tham gia giải trí với những dịch vụ của <strong>{appName}</strong> đồng nghĩa với việc người dùng đã hiểu và chấp nhận một điều rằng:{' '}
                                        <strong>{appName}</strong> cung cấp các trò chơi hợp lý, công bằng và trung thực. Do vậy, việc người chơi chiến thắng hoặc gặp rủi ro là chuyện bình thường và
                                        người chơi phải chấp nhận các nguy cơ đó.
                                    </span>
                                    <span>
                                        4. Người chơi phải cam kết rằng mọi thông tin người chơi cung cấp và sử dụng tại thời điểm đăng ký tài khoản như: Tên người chơi, địa chỉ email, số điện
                                        thoại,...là chính xác và đầy đủ. Đồng thời, các thông tin đăng ký tài khoản phải trùng khớp với thông tin trên căn cước công dân, tài khoản ngân hàng. Bởi nếu
                                        thông tin không nhất quán, người chơi sẽ mất quyền yêu cầu cung cấp một số dịch vụ từ <strong>{appName}</strong>.
                                    </span>
                                    <span>
                                        5. Để tránh tranh chấp: Khi tham gia vào các trò chơi của <strong>{appName}</strong>, người chơi nên chú ý theo dõi toàn bộ quá trình chơi hoặc trước khi kết
                                        thúc trò chơi, hãy kiểm tra thông tin tài khoản trò chơi, nếu phát hiện những trường hợp bất thường, hãy lập tức liên hệ với đội ngũ chăm sóc khách hàng để xác
                                        minh lại toàn bộ lịch sử truy cập và giao dịch trong hệ thống dữ liệu của <strong>{appName}</strong>.
                                    </span>
                                    <span>
                                        6. Nếu người chơi mở hoặc tìm cách mở nhiều tài khoản, vì bất cứ lý do gì, <strong>{appName}</strong> có thể ngăn chặn hoặc đóng bất kỳ tài khoản hoặc tất cả
                                        các tài khoản của bạn theo quyết định của <strong>{appName}</strong>.
                                    </span>
                                    <span>
                                        7. Nếu thấy số tiền nạp hiển thị không chính xác, người dùng có nghĩa vụ phải thông báo cho hệ thống chăm sóc khách hàng điều chỉnh, tuyệt đối không được sử
                                        dụng số tiền lỗi có liên quan. Bởi trong bất kỳ trường hợp nào, số tiền lỗi có liên quan được coi là số tiền không hợp lệ nên nếu người chơi sử dụng số tiền này
                                        để đặt cược thì tất cả các cược sẽ bị hủy và trả lại số tiền cho trang web.
                                    </span>
                                    <span>
                                        8. Đối với việc khách hàng tham gia khuyến mãi cho thành viên mới hoặc các khuyến mãi khác và đặt cược không hợp lệ. Nếu <strong>{appName}</strong> phát hiện ra
                                        thì có quyền tạm khóa hoặc đóng tài khoản và bộ phận có liên quan sẽ đưa ra hướng giải quyết cụ thể
                                    </span>
                                    <span>
                                        9. Nếu kết quả từ máy của người dùng không nhất quán với kết quả của máy chủ <strong>{appName}</strong> thì kết quả được hiển thị bởi máy chủ sẽ là quyết định
                                        cuối cùng. Cuối cùng, người chơi đã đồng ý tham gia trò chơi tức là đồng ý với các quy tắc, dịch vụ của <strong>{appName}</strong> đã được phổ biến.
                                    </span>
                                    <span>
                                        10. Người chơi phải đồng ý rằng một phần hay toàn bộ nội dung của thỏa thuận này là tạm thời và <strong>{appName}</strong> có quyền thay đổi, chỉnh sửa, thêm
                                        bớt bất kỳ lúc nào để phù hợp với chính sách hoạt động và chăm sóc khách hàng. Các quy tắc và điều khoản sử dụng mới nhất người chơi có thể truy cập thông qua
                                        trang web dưới liên kết, thỏa thuận người sử dụng.
                                    </span>
                                    <span>
                                        11. Trong bất kỳ trường hợp nào, quyết định cuối cùng đều thuộc về <strong>{appName}</strong>.
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ hai: Các quy định về tiền gửi</span>
                                <br />
                                <span className={styles.session}>
                                    <span>
                                        1. Nếu người chơi nạp tiền qua các ngân hàng trực tuyến một thời gian dài chúng tôi không nhận được. Xin hãy liên hệ ngay đến dịch vụ chăm sóc khách hàng trực
                                        tuyến 24/7 để hướng dẫn cách sử lý.{' '}
                                    </span>
                                    <span>
                                        2. Các thành viên gửi tiền qua bên thứ 3 hãy liên hệ với bộ phận chăm sóc khách hàng để thông báo số tiền và thời gian gửi tiền để chúng tôi có thể xác nhận
                                        càng sớm càng tốt.{' '}
                                    </span>
                                    <span>
                                        3. Nếu bạn sử dụng tiền gửi ngân hàng trực tuyến hoặc ngoại tuyến, <strong>{appName}</strong> sẽ kiểm tra hoạt động chính thức của trang web đó và xác nhận tài
                                        khoản tiền gửi. Nếu khoản tiền gửi được sử dụng tại thời điểm đó mà không có xác nhận gửi về thì <strong>{appName}</strong> sẽ ko chịu trách nhiệm về những tổn
                                        thất đó.{' '}
                                    </span>
                                    <span>4. Nếu người chơi muốn biết thêm các thông tin để gửi tiền, vui lòng liên hệ với bộ phận Chăm Sóc Khách Hàng để được hỗ trợ.</span>
                                    <span>
                                        5. Trong bất kỳ trường hợp nào, quyền quyết định cuối cùng đều thuộc về <strong>{appName}</strong>.
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ ba: Quy định rút tiền</span>
                                <br />
                                <span className={styles.session}>
                                    <span>1. Quý khách cần đặt cược số tiền lớn hơn hoặc bằng số tiền đã nạp. </span>
                                    <span>
                                        Ví dụ: Quý khách nạp với số tiền 500.000 VND, quý khách cần đặt cược tối thiểu 500.000 VND thì mới có thể rút tiền.Lưu ý các vé cược phải được tính thưởng thanh
                                        toán hoàn tất thì doanh thu cược mới tính hợp lệ.
                                    </span>
                                    <span>
                                        2. Mỗi lần rút tiền ít nhất là 200.000 VND, hạn mức rút tiền cao nhất trong 1 lần là 100.000.000 VND. 1 ngày có tối đa 5 lần rút. Để có thể rút nhiều hơn trong
                                        ngày phụ thuộc vào cấp độ VIP của bạn.
                                    </span>
                                    <span>3. Khách hàng VIP sẽ được hạn mức rút tiền cao hơn.</span>
                                    <span>
                                        4. <strong>{appName}</strong> hứa hẹn khách hàng sẽ rút tiền trong vòng 5 phút (Ngoại trừ các trường hợp mạng lưới ngân hàng phải bảo trì, mất kết nối)
                                    </span>
                                    <span>
                                        5. Trong tất cả các trường hợp, <strong>{appName}</strong> đều có quyền quyết định cuối cùng.
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ tư: Thay đổi và ngưng cung cấp dịch vụ</span>
                                <br />
                                <span className={styles.session}>
                                    <span>1. Dữ liệu đăng nhập của hội viên không chính xác</span>
                                    <span>2. Sử dụng thông tin của người khác để đăng ký dịch vụ này</span>
                                    <span>3. Vi phạm quy định về tính công bằng trò chơi</span>
                                    <span>
                                        4. Tham gia các loại trò chơi hoặc các trận đấu mà cố tình lợi dụng các lỗ hỏng của hệ thống hoặc lợi dụng các nhược điểm của trò chơi để gây ảnh hưởng đến kết
                                        quả
                                    </span>
                                    <span>
                                        5. Công ty chúng tôi không cho phép đặt cược không công bằng, đặt cược hai bên, không rủi ro. Nếu có phát hiện người chơi có vấn đề này, công ty chúng tôi sẽ
                                        ngưng quyền sử dụng của người chơi. Nếu có bất kỳ tranh luận vấn đề nào, chúng tôi có quyền quyết định cuối cùng. Ở bất kỳ tình huống nào, sẽ ngưng phục vụ hoặc
                                        là chấm dứt phục vụ đối với tài khoản hội viên để tránh gây nên vấn đề phát sinh thiệt hại, chúng tôi không chịu trách nhiệm.
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ năm: Tạm ngưng hoặc gián đoạn dịch vụ</span>
                                <br />
                                <span className={styles.session}>
                                    <span>1. Một trong các trường hợp sau đây, trang mạng sẽ không chịu bất cứ trách nhiệm bồi thường nào đối với người chơi:</span>
                                    <span style={{ paddingLeft: '12px' }}>
                                        - Trong trường hợp đang tiến hành di chuyển, thay thế hoặc bảo trì các thiết bị hệ thống có liên quan đến dịch vụ của chúng tôi.
                                        <br />
                                        - Bị mất mạng internet nhưng không do công ty chúng tôi gây ra mà dẫn đến tạm ngưng hoặc gián đoạn dịch vụ. <br />
                                        - Do các yếu tố đặc biệt bất khả kháng dẫn đến tạm ngưng hoặc gián đoạn dịch vụ.
                                        <br />
                                    </span>

                                    <span>
                                        2. Nếu công ty chúng tôi đối với hệ thống liên quan tiến hành di chuyển, thay đổi hoặc bảo trì, và cần tạm ngưng hoặc gián đoán phục vụ, chúng tôi sẽ gửi thông
                                        báo.
                                    </span>

                                    <span>
                                        3. Trường hợp tạm ngưng và gián đoạn dịch vụ có thể sẽ gây ra những bất tiện và tổn thất như mất dữ liệu, ảnh hướng đến kinh tế, thời gian của quý khách. Vì vậy
                                        thường ngày quý khách nên chọn những phương án và cách xử lý thích hợp nhằm đảm bảo được quyền lợi của chính mình.
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ sáu: Quy định vi phạm 1% số cược trong 1 đơn cược trong sổ xố</span>
                                <br />
                                <span className={styles.session}>
                                    <span>
                                        1. Trong một kỳ mở thưởng nếu có ít nhất 1 vé cược trong tổng số tất cả các vé cược có số cược từ dưới 1% tổng số cược của loại xổ số đó thì người chơi đã vi
                                        phạm 1% cược đơn. Tổng số tiền trả thưởng tối đa của tất cả các đơn cược trong kỳ mở thưởng đó là 200,000,000 VNĐ.
                                    </span>
                                    <span style={{ paddingLeft: '12px' }}>
                                        - Loại xổ số áp dụng: Xổ số truyền thống 3 miền (Bắc - Trung - Nam), xổ số VIP: Đề đầu - Đề Đặc biệt - Đề giải nhất - Đề giải 7 - Đề đầu đuôi - 3 Càng Đặc Biệt
                                        - 3 Càng Đầu - 3 Càng Đuôi - 3 Càng Đầu Đuôi - 4 Càng Đặc Biệt - Lô 2 Số - Lô 2 Số Đầu - Lô 3 Số - Lô 4 Số . Không áp dụng cho xổ số siêu tốc.
                                    </span>
                                    <span>2. Cách cược để không vi phạm 1% đơn cược:</span>
                                    <span style={{ paddingLeft: '12px' }}>
                                        * Đề đầu - Đề đặc biệt - Đề giải nhất - Đề giải 7 - Đề đầu đuôi - Lô 2 Số - Lô 2 Số Đầu : Đặt cược từ 2 số trở lên trong 1 đơn cược.
                                        <br />
                                        * 3 Càng Đặc Biệt - 3 Càng Đầu - 3 Càng Đầu Đuôi - Bao lô 3 số: Đặt cược từ 11 số trở lên trong 1 đơn cược.
                                        <br />
                                        * 4 Càng Đặc Biệt - Bao lô 4 số: Đặt cược từ 101 số trở lên trong 1 đơn cược.
                                        <br />
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ bảy: Giới hạn Kép Bằng trong sổ xố</span>
                                <br />
                                <span className={styles.session}>
                                    <span>- Đề Đặc Biệt và Đề đặc biệt 18h25 Miền Bắc: Đặt cược tối đa 2.000.000/1 số kép bằng (ví dụ: 00,11, 22, 33, 44, 55, 66, 77, 88, 99).</span>
                                    <span>- 3 Càng đặc biệt Miền Bắc: Đặt cược tối đa 200.000đ/1 số có 2 số cuối là kép bằng (Ví dụ: 011, 922, 433, 822,...)</span>
                                    <span>- 4 Càng đặc biệt Miền Bắc: Đặt cược tối đa 20.000đ/1 số có 2 số cuối là kép bằng (Ví dụ: 1011, 3922, 9833,5622,...)</span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>Thứ tám: Các quy định khuyến mãi</span>
                                <br />
                                <span className={styles.session}>
                                    <span>
                                        1. Mỗi người chơi, mỗi địa chỉ cư trú, mỗi địa chỉ email, mỗi số điện thoại, cùng một phương thức thanh toán (cùng thẻ ghi nợ/ thẻ tín dụng/ tài khoản ngân
                                        hàng) và địa chỉ IP chỉ được tham gia một lần khuyến mãi. Nếu thành viên cố tình tạo nhiều tài khoản lạm dụng khuyến mãi, Giải Trí <strong>{appName}</strong> có
                                        quyền huỷ bỏ hoặc thu hồi số tiền thưởng và lợi nhuận của thành viên.
                                    </span>
                                    <span>
                                        2. Tất cả các chương trình khuyến mãi tại Giải Trí <strong>{appName}</strong> đều dựa trên nhu cầu thực tế của người chơi. Nếu bất kỳ nhóm hoặc cá nhân nào có
                                        hành vi lạm dụng hoặc không trung thực khi tham gia khuyến mãi, lạm dụng khuyến mãi từ <strong>{appName}</strong>, Giải Trí <strong>{appName}</strong> có quyền
                                        huỷ bỏ tài khoản nhóm hoặc cá nhân và đóng băng số dư tài khoản khi vi phạm.
                                    </span>
                                    <span>
                                        3. Nếu thành viên có tranh chấp về sự kiện khuyến mãi, để đảm bảo quyền lợi của hai bên và tránh ảnh hưởng uy tín, <strong>{appName}</strong> có quyền yêu cầu
                                        thành viên cung cấp cho Chúng Tôi các tài liệu liên quan, hình ảnh trong các văn bản xác định (khuôn mặt của khách hàng phải được nhìn thấy rõ trong ảnh), việc
                                        không xuất trình các thông tin khi có yêu cầu có thể dẫn đến việc các khoản tiền thưởng khuyến mãi và tiền thắng cược sẽ bị thu hồi.
                                    </span>
                                    <span>
                                        4. Khi thành viên tham gia không tuân thủ đầy đủ hoặc vi phạm quy định, lạm dụng bất kỳ điều khoản nào về chiết khấu hoặc khuyến mãi của{' '}
                                        <strong>{appName}</strong>, <strong>{appName}</strong> phát hiện bất kỳ cá nhân hoặc nhóm thành viên nào có hành vi cược bất thường liên quan, tiền thưởng khi
                                        gửi tiền hoặc các hoạt động khuyến mãi khác mang lại lợi nhuận, Giải trí <strong>{appName}</strong> có quyền dừng, hủy bỏ khuyến mãi hoặc thu hồi lại tất cả
                                        tiền thưởng đã được kết toán cho đội hoặc cá nhân này. Đối với những trường hợp nghiêm trọng, <strong>{appName}</strong> sẽ đóng băng tài khoản vĩnh viễn mà
                                        không cần thông báo hoặc giải thích. Việc xác định như thế nào là lạm dụng tiền thưởng sẽ dựa trên việc kiểm tra chặt chẽ những bằng chứng rõ ràng về lạm dụng
                                        và gian lận tiền thưởng như sau:
                                    </span>
                                    <span style={{ paddingLeft: '12px' }}>
                                        + Cược 2 bên (cược đối nghịch). Ví dụ: Trong Baccarat cùng lúc cược nhà cái và nhà con, trong Roulette cược cùng lúc đỏ và đen…
                                        <br />
                                        + Gian lận có tổ chức, theo nhóm, sử dụng phần mềm đặt cược...
                                        <br />
                                        + Đặt cược tiền thưởng vào các trò chơi bị loại trừ
                                        <br />
                                        + Đặt cược gian lận doanh thu cược
                                        <br />
                                        + Một thành viên sử dụng nhiều tài khoản để giam gia cược
                                        <br />
                                        + Cược chéo tài khoản, cùng hoặc khác hệ thống
                                        <br />
                                        + Lạm dụng chênh lệch tỷ lệ kèo giữa các trang cá cược
                                        <br />+ Tiền thưởng chỉ được nhận một lần duy nhất (ví dụ khuyến mãi dành cho thành viên mới). <strong>{appName}</strong> có quyền chỉnh sửa, thay đổi hoặc hủy
                                        bỏ chương trình khuyến mãi hoặc một phần của chương trình bất kỳ thời điểm nào mà không cần báo trước. <br />+ Đối với sản phẩm Xổ Số
                                    </span>
                                    <span style={{ paddingLeft: '24px' }}>
                                        - 2D cược tối đa 60 số
                                        <br />
                                        - 3D cược tối đa 600 số
                                        <br />
                                        - 4D cược tối đa 5,500 số
                                        <br />
                                    </span>
                                    <span style={{ paddingLeft: '12px' }}>
                                        Thành viên cược quá số quy định hệ thống sẽ thu hồi tiền thắng dựa trên các vé cược sai quy định.
                                        <br />+ Cược trong trò Roulette chỉ được phép cược tối đa 30 số, cược quá số quy định hệ thống sẽ thu hồi tiền thắng cược.
                                    </span>
                                    <span>
                                        5. <strong>{appName}</strong> có quyền hạn chế hoặc tạm thời hạn chế bất kỳ thành viên tham gia Khuyến mãi nào khi phát hiện có dấu hiệu lạm dụng khuyến mãi.
                                        Trong trường hợp thành viên có hành vi vi phạm các điều kiện và điều khoản, quyết định của <strong>{appName}</strong> là quyết định cuối cùng và thành viên vi
                                        phạm không có quyền Khiếu Nại.
                                    </span>
                                    <span>
                                        6. Đối với các khuyến mãi hoàn trả, tất cả các cược hòa, cược hủy, cược 2 bên đều không được tính vào doanh thu cược để tính hoàn trả, chơi trò chơi 2 mặt trong
                                        sảnh slot sẽ không được tính hoàn trả (Trò chơi 2 mặt được hiển thị và hoạt động tại sảnh Slot nhưng hình thức tham gia lại mang tính chất Casino. Ví dụ: Sicbo,
                                        Tài Xỉu Kiểu Thái, Xóc Đĩa …)
                                    </span>
                                    <span>
                                        7. Tiền thưởng khuyến mãi có giới hạn cược ở từng loại sản phẩm không được tham gia cược ở sản phẩm khác trước khi thành viên hoàn thành số vòng cược yêu cầu
                                        tại khuyến mãi đó. Tiền thưởng khuyến mãi có quy định thời gian nhận thưởng, nếu quá thời gian nhận thưởng thành viên không đăng ký đồng nghĩa với việc từ chối
                                        khuyến mãi. Phần thưởng của bất kỳ khuyến mãi nào nếu không hoàn thành đúng quy định khuyến mãi hệ thống sẽ thu hồi tiền khuyến mãi và tiền thắng (nếu có).
                                    </span>
                                    <span>
                                        8. Tất cả thành viên tham gia các chương trình khuyến mãi phải tuân thủ các Quy Tắc & Điều Khoản <strong>{appName}</strong> và Các Quy Định Luật Lệ chung của{' '}
                                        <strong>{appName}</strong>.
                                    </span>
                                </span>
                            </p>
                        )}
                        {pageActive === 2 && (
                            <p>
                                <span className={styles.textTitle}>1. THỎA THUẬN SỬ DỤNG</span>
                                <br />
                                Đây là thỏa thuận pháp lý giữa Khách hàng với Nhà Cái <strong>{appName}</strong> quy định các điều khoản trong việc khách hàng sử dụng các sản phẩm, trò chơi thuộc
                                quyền sở hữu của
                                <strong>{appName}</strong>. Thỏa thuận này là hợp đồng điện tử giữa Hai Bên. Bằng cách nhấp chuột vào nút “Đồng ý” khi đăng ký tài khoản và tham gia các sản phẩm, trò
                                chơi tại trang web, ứng dụng phần mềm của <strong>{appName}</strong>, khách hàng đồng ý rằng các điều khoản này sẽ được áp dụng nếu khách hàng lựa chọn truy cập hoặc sử
                                dụng dịch vụ và thao tác nhấp chuột đăng ký tài khoản tại <strong>{appName}</strong> này tương đương với việc hai bên đã đồng ý với các điều khoản của thỏa thuận.
                                <br />
                                Thành viên đồng ý sử dụng <strong>{appName}</strong> và tuân thủ theo các quy định đưa ra và không vi phạm các quyền sử dụng, quyền riêng tư, vi phạm sự riêng tư, không
                                Tham gia vào việc quấy rối, đe dọa, gửi thư hàng loạt, hoặc phá hoại, không thực hiện gửi thư lan truyền, không đưa những nội dung sai sự thật hoặc không chính xác, giả
                                mạo thành viên khác, hoặc bất kỳ mục đích quảng cáo hay thương mại nào khác không được sự chấp thuận công khai của <strong>{appName}</strong>
                                <br />
                                <br />
                                <span className={styles.textTitle}>2. SỬ DỤNG TÀI KHOẢN</span>
                                <br />
                                Để sử dụng <strong>{appName}</strong>, đầu tiên thành viên cần đăng ký tài khoản với Chúng Tôi. Để truy cập vào bất kì trò chơi của Chúng Tôi từ tài khoản của thành
                                viên thông qua trang web{' '}
                                <a className={styles.textLink} href={window.origin}>
                                    {window.origin}
                                </a>
                                <br />
                                <span>
                                    <span style={{ fontSize: '20px' }}>❖</span> Khi mở tài khoản và tham gia tại <strong>{appName}</strong>, thành viên cần đảm bảo rằng:
                                </span>
                                <br />
                                <span style={{ paddingLeft: '24px', display: 'block' }}>
                                    - Thành viên hiểu rõ và chấp nhận các rủi ro trong các giao dịch cũng như các khoản thắng; các khoản thua khi tham gia và sử dụng các sản phẩm, dịch vụ, trò chơi và
                                    ứng dụng.
                                    <br />- Cần cung cấp các thông tin chi tiết cá nhân (bao gồm cả các thông tin chi tiết ngân hàng hay các phương thức thanh toán khác) cho <strong>{appName}</strong>
                                    .
                                    <br />
                                    - Thành viên chỉ được tạo và sử dụng một (01) tài khoản duy nhất để tham gia với chúng tôi, bất kì thành viên nào tạo trên hai (02) tài khoản trở lên hoặc cố tình
                                    hoặc sử dụng các thông tin liên hệ khác từ mối liên hệ, mối quan hệ quen biết để tạo thêm tài khoản thứ hai cho người khác sử dụng hay cho chính thành viên sử dụng
                                    đều sẽ được xem là vi phạm điều khoản sử dụng và tài khoản sẽ bị đóng.
                                    <br />
                                </span>
                                <br />
                                <span>
                                    <span style={{ fontSize: '20px' }}>❖</span> Nếu chúng tôi phát hiện ra thành viên tạo thêm một tại khoản khác tại <strong>{appName}</strong> thì:
                                </span>
                                <br />
                                <span style={{ paddingLeft: '24px', display: 'block' }}>
                                    - Tất cả các cược đang chờ xử lý sẽ bị hủy.
                                    <br />
                                    - Cược thắng sẽ bị tịch thu.
                                    <br />
                                    - Tài khoản sẽ bị đóng vĩnh viễn.
                                    <br />
                                    - Giao dịch tiền nạp gốc phát sinh sẽ được xem xét tùy trường hợp hoàn trả cho thành viên.
                                    <br />
                                </span>
                                <br />
                                <span>
                                    <span style={{ fontSize: '20px' }}>❖</span> Nếu Chúng Tôi kiểm tra và phát hiện thành viên đã vi phạm Điều khoản và Điều kiện hoặc tài khoản thành viên có những
                                    hoạt động bất thường, Chúng Tôi có toàn quyền quyết định đưa ra các quyết định sau:
                                </span>
                                <br />
                                <span style={{ paddingLeft: '24px', display: 'block' }}>
                                    - Đình chỉ hoặc chấm dứt sử dụng tài khoản của thành viên.
                                    <br />
                                    - Hạn chế thành viên rút tiền từ tài khoản.
                                    <br />
                                    - Không cho phép thành viên truy cập và sử dụng các sản phẩm, dịch vụ, trò chơi hay ứng dụng của chúng tôi.
                                    <br />
                                </span>
                                <br />
                                <span>
                                    <span style={{ fontSize: '20px' }}>❖</span> Là một Thành viên <strong>{appName}</strong>:
                                </span>
                                <br />
                                <span style={{ paddingLeft: '24px', display: 'block' }}>
                                    - Thành viên không được phép có hành động có tính chất làm mất uy tín, phỉ báng, bôi nhọ, đe dọa hoặc quấy rối <strong>{appName}</strong>, bất cứ Nhà cung cấp hoặc
                                    bất cứ bên nào khác có hoặc không có liên quan đến <strong>{appName}</strong>. <br />- Thành viên không được có hành động có tính chất bạo lực, hành hung hoặc sử
                                    dụng ngôn từ tục tĩu, bôi nhọ, phỉ báng đối với nhân viên <strong>{appName}</strong>. <br />
                                    - Thành viên không được cung cấp thông tin sai sự thật, gây nhầm lẫn, sử dụng từ ngữ không phù hợp với thuần phong mỹ tục.
                                    <br />- Thành viên không được sử dụng <strong>{appName}</strong> theo cách để cố ý tạo ra các tranh chấp, khiếu nại, đòi bồi thường.
                                    <br />- Thành viên cần đảm bảo tất cả thông tin cá nhân, thông tin cược và bất kì thông tin nào của thành viên tại {appName} đều phải tuyệt đối bảo mật và giữ kín.
                                    Nếu thành viên quên tên đăng nhập, mật khẩu, hoặc nghi ngờ tải khoản bị người khác truy cập sử dụng thì cần Liên Hệ ngay với chúng tôi để có thể kịp thời xử lý
                                    thông tin tài khoản của quý khách và thông tin mới có thể gửi qua cho thành viên qua Email hay Số Điện Thoại.
                                    <br />- Nếu <strong>{appName}</strong> kiểm tra và phát hiện giao dịch của thành viên do người khác thực hiện thì chúng tôi có quyền tùy ý quyết định khoản giao
                                    dịch phát sinh này và đưa ra quyết định hoàn toàn về tài khoản thành viên, cũng như hủy bỏ các vé cược thắng, hủy bỏ giao dịch rút tiền, tịch thu số tiền thưởng hay
                                    tiền hoàn trả , và xem xét tùy trường hợp hoàn trả lại tiền chuyển gốc phát sinh nếu có.Chúng tôi luôn có ưu đãi cho thành viên mới do đó quý khách sẽ không được
                                    nhận các khuyến mãi dành cho thành viên mới nếu chúng tôi kiểm tra và phát hiện thành viên đã có tài khoản tại <strong>{appName}</strong>. <br />
                                </span>
                                <br />
                                <span className={styles.textTitle}>3. NGHĨA VỤ CỦA BẠN KHI LÀ NGƯỜI CHƠI</span>
                                <br />
                                <span>
                                    <span style={{ fontSize: '20px' }}>❖</span> Bạn xin cam đoan và bảo đảm rằng:
                                </span>
                                <br />
                                <span style={{ paddingLeft: '24px', display: 'block' }}>
                                    - Bạn trên 18 tuổi hoặc cao hơn tuổi pháp định theo quy định áp dụng đối với Bạn, Bạn được phép tham gia vào các game đã cung cấp trên trang web. <br />
                                    - Nếu thành viên được chứng minh là chưa đủ tuổi, Chúng Tôi có quyền vô hiệu tất cả các giao dịch được thực hiện trong khi thành viên chưa đủ tuổi. Chúng Tôi có
                                    quyền đóng tài khoản của thành viên và tịch thu tất cả số dư nếu có.
                                    <br />
                                    - Bạn sẽ chỉ sử dụng trang web này và Tài khoản Thành viên của bạn và dành riêng cho mục đích tham gia chính của bạn vào các game và không cho bất kỳ hoạt động tài
                                    chính cũng như các hoạt động khác, việc tham gia của bạn vào các game là nghiêm túc trong khả năng không chuyên nghiệp cá nhân của bạn cho mục đích vui chơi và giải
                                    trí.
                                    <br />
                                    - Bạn tham gia vào các Game trên danh nghĩa của chính bạn và không đại diện cho bất kỳ người nào khác.
                                    <br />- Tất cả các thông tin mà bạn cung cấp cho <strong>{appName}</strong> trong thời hạn hiệu lực của thỏa thuận này là đúng, đầy đủ và chính xác.
                                    <br />- Bạn không nên tham gia vào bất kỳ sự gian lận, thông đồng, sửa chữa hoặc hoạt động trái quy định của trang liên quan đến bạn hoặc tham gia của bên thứ ba
                                    trong bất kỳ Game nào và sẽ không sử dụng bất kỳ phương pháp hay kỹ thuật hoặc các thiết bị phần cứng, phần mềm hỗ trợ cho sự tham gia của bạn trong bất kỳ các Game
                                    nào.
                                    <strong>{appName}</strong> có quyền huỷ bỏ hoặc đóng Tài khoản Thành viên của bạn hoặc làm mất hiệu lực tham gia của bạn trong một trò chơi nếu bạn thực hiện các
                                    hành vi trên. <br />- Phần mềm máy tính mà bạn dùng thuộc quyền sở hữu bởi <strong>{appName}</strong> hoặc bên thứ ba khác và được bảo vệ bởi luật bản quyền và luật
                                    sở hữu trí tuệ khác. Bạn chỉ có thể sử dụng phần mềm cho cá nhân của bạn, sử dụng giải trí riêng phù hợp với tất cả các quy tắc, theo các điều khoản và điều kiện
                                    được thành lập và phù hợp với tất cả các luật, quy tắc và quy định. <br />- Các game được chơi trên trang web nên được chơi theo cách thức tương tự như các game
                                    khác được thiết lập. Bạn phải lịch sự với các người chơi và đại diện của
                                    <strong>{appName}</strong> sẽ loại bỏ các bình luận thô lỗ hoặc khiêu dâm, bao gồm cả trong các phòng chat. <br />
                                </span>
                                <br />
                                <span className={styles.textTitle}>4. CHƠI CÓ TRÁCH NHIỆM</span>
                                <br />
                                <span style={{ paddingLeft: '24px' }}>
                                    Cá cược vừa mang tính giải trí vừa mang lại lợi nhuận. Nhưng chiến thắng 100% sẽ là điều không thể. Thua là một phần của trò chơi và bạn phải chuẩn bị tâm lý cho
                                    điều đó. Vì vậy, bạn nên cá cược có trách nhiệm và không nên mạo hiểm cược với số tiền mà bạn không thể có. Chúng tôi tại <strong>{appName}</strong> mong muốn tất
                                    cả các trải nghiệm của bạn là tích cực nhất có thể, ngay cả khi bạn thua. Do đó chúng tôi luôn làm việc chăm chỉ để giúp bạn điều khiển trò chơi theo cách riêng của
                                    bạn.
                                </span>
                            </p>
                        )}
                        {pageActive === 3 && (
                            <p>
                                <span className={styles.textTitle}>1. CHÍNH SÁCH CHUNG</span>
                                <br />
                                <span className={styles.session}>
                                    <span>
                                        - Bạn thừa nhận và đồng ý để <strong>{appName}</strong> xử lý dữ liệu cá nhân của bạn cho các mục đích của việc cho phép bạn truy cập và sử dụng trang web và để
                                        cho phép bạn tham gia vào trò chơi và cung cấp dịch vụ phụ trợ cho bạn. <strong>{appName}</strong> sẽ bảo vệ thông tin cá nhân của bạn và tôn trọng quyền riêng
                                        tư của bạn.
                                    </span>
                                    <span>
                                        - <strong>{appName}</strong> sẽ chỉ sử dụng dữ liệu cá nhân của bạn để cho phép bạn tham gia vào các Game và để thực hiện các hoạt động liên quan đến sự tham
                                        gia của bạn trong các Game
                                    </span>
                                    <span>
                                        - Dữ liệu cá nhân của bạn sẽ không được tiết lộ cho bên thứ ba, trừ khi việc tiết lộ đó là cần thiết để xử lý các yêu cầu của bạn, Hoặc như đối tác kinh doanh
                                        hoặc nhà cung cấp hoặc cung cấp dịch vụ của <strong>{appName}</strong> có thể chịu trách nhiệm cho các bộ phận nhất định trong các hoạt động tổng thể hoặc hoạt
                                        động của trang web, dữ liệu cá nhân có thể được tiết lộ cho họ. Bạn đồng ý với tất cả các tiết lộ như vậy.{' '}
                                    </span>
                                    <span>
                                        - Để cung cấp cho bạn dịch vụ hiệu quả, <strong>{appName}</strong> và các nhà cung cấp dịch vụ có thể yêu cầu chuyển dữ liệu cá nhân của bạn từ nước này sang
                                        nước khác trên toàn thế giới. Bạn đồng ý với việc chuyển giao dữ liệu cá nhân của bạn.{' '}
                                    </span>
                                    <span>
                                        - Bạn đồng ý để <strong>{appName}</strong> thông báo cho bạn bất cứ lúc nào về những thay đổi trên trang web, dịch vụ mới và chương trình khuyến mãi. Nếu bạn
                                        không muốn nhận thông tin tiếp thị trực tiếp Bạn có thể chọn không tham gia dịch vụ này. Bạn có thể chọn lại vào nhận thông tin quảng cáo bằng cách gửi email
                                        cho bộ phận hỗ trợ của chúng tôi.
                                    </span>
                                </span>
                                <br />
                                <span className={styles.textTitle}>2. BẢO MẬT THÔNG TIN</span>
                                <br />
                                <span className={styles.session}>
                                    <span>
                                        - Tên đăng nhập và mật khẩu được chọn khi thành viên đăng ký tài khoản không được tiết lộ cho bất kỳ bên thứ ba nào. Thành viên hoàn toàn chịu trách nhiệm về
                                        bảo mật tên tài khoản và mật khẩu của mình.
                                    </span>
                                    <span>
                                        - Thành viên đồng ý giữ bí mật tên tài khoản và mật khẩu của mình và không cho phép bất kỳ ai khác sử dụng nó. Mỗi người tự nhận dạng bằng cách nhập tên người
                                        dùng và mật khẩu chính xác sẽ được Chúng Tôi coi là chủ tài khoản hợp pháp và tất cả các giao dịch nơi bằng người dùng và mật khẩu đã được nhập chính xác sẽ
                                        được coi là hợp lệ. Trong mọi trường hợp, Chúng Tôi sẽ không chịu trách nhiệm cho bất kỳ tổn thất nào thành viên phải chịu do bất kỳ việc sử dụng trái phép hoặc
                                        sử dụng sai các chi tiết đăng nhập của thành viên. Nếu thành viên bị mất tên tài khoản hoặc mật khẩu, vui lòng Liên Hệ Với Chúng Tôi ngay lập tức.
                                    </span>
                                    <span>
                                        - <strong>{appName}</strong> có thể cung cấp thông tin tài khoản của thành viên
                                    </span>
                                    <span>- Cho các hoạt động quảng cáo và tiếp thị (bao gồm liên hệ với thành viên với các ưu đãi đặc biệt mà có thể thành viên quan tâm);</span>
                                    <span>
                                        - Cho đối tác đang sử dụng <strong>{appName}</strong> hoặc nhà cung cấp của <strong>{appName}</strong>.
                                    </span>
                                    <span>
                                        - Thành viên bên hiểu rằng tất cả việc sử dụng trang web của Chúng Tôi và email, tin nhắn và cuộc gọi đến giữa thành viên và Chúng Tôi sẽ được ghi lại. Những
                                        bản ghi chép này sẽ là tài sản của Chúng Tôi và có thể được sử dụng như là bằng chứng trong trường hợp có bất kì tranh chấp hoặc để cải thiện dịch vụ chăm sóc
                                        khách.
                                    </span>
                                </span>
                            </p>
                        )}
                        {pageActive === 4 && (
                            <p>
                                {/* Trang mạng cá cược <strong>{appName}</strong> của công ty chúng tôi hoạt động hợp pháp tại Philippines được giám sát bởi tổ chức Gaming Associates. */}
                                Sở hữu trí tuệ - <strong>{appName}</strong> là chủ sở hữu của:
                                <br />
                                - Tất cả các quyền bản quyền và các quyền liên quan bên trong và đối với trang web của Chúng Tôi, bao gồm trò chơi, ứng dụng, dịch vụ và các quyền trong cơ sở dữ liệu.
                                <br />- Tất cả các quyền nhãn hiệu thương mại, tất cả các sản phẩm, cho dù đã đăng ký hoặc chưa đăng ký, trong và đối với <strong>{appName}</strong> và logo
                                <strong>{appName}</strong>.<br />- <strong>{appName}</strong> là chủ sở hữu duy nhất thương hiệu <strong>{appName}</strong> và biểu tượng của <strong>{appName}</strong>
                                . Sử dụng trái phép các thương hiệu và biểu tượng của <strong>{appName}</strong> có thể bị truy tố. <br />-
                                <a className={styles.textLink} href={window.origin}>
                                    {window.origin}
                                </a>{' '}
                                là địa chỉ tài nguyên(URL) của <strong>{appName}</strong> ( bao gồm cả những tên miền phụ) và bạn không được sử dụng trái phép URL này trên một trang web khác hoặc trên
                                các nền tảng kỹ thuật số mà không được sự đồng ý trước bằng văn bản của chúng tôi. <br />- Thành viên sẽ hoàn toàn chịu trách nhiệm cho bất kỳ thiệt hại, chi phí hoặc
                                chi phí phát sinh từ hoặc liên quan đến hoa hồng, bất kì doanh thu của thành viên về bất kỳ việc sử dụng trái phép nào. Thành viên khi phát hiện người chơi khác vi truy
                                cập trái phép hay có hành vi gây thiệt hại tổn thất đến chính mình và
                                <strong>{appName}</strong> thì vui lòng Liên Hệ ngay với chung tôi để kịp thời xử lý theo các điều khoản thỏa thuận.
                                <br />- Các liên kết trang web và bất kỳ trang nào trong đó có thể không được đưa vào trong bất kỳ trang web khác mà không có sự chấp thuận trước bằng văn bản của
                                <strong>{appName}</strong>.
                                <br />- Bạn đồng ý không sử dụng bất kỳ thiết bị tự động hoặc thủ công để theo dõi các trang web <strong>{appName}</strong> hoặc bất kỳ nội dung trong đó. Sử dụng trái
                                phép hoặc sao chép lại có thể bị truy tố.
                                <br />
                            </p>
                        )}

                        {pageActive === 5 && <Guide />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HelpPage;
