import styles from './minigame-icon.style';
import Lottie from 'lottie-react';
import iconMiniGame from 'assets/lottie/iconMiniGame.json';
import txIcon from 'assets/lottie/taixiu.json';
import xdIcon from 'assets/lottie/xocdia.json';
import bcIcon from 'assets/lottie/baucua.json';
import xsIcon from 'assets/lottie/xoso.json';
import { useRef, useState } from 'react';
import { MiniGamePath, MiniGameType } from 'models/miniGameModel';
import toast from 'react-hot-toast';

interface GameItem {
    id: MiniGamePath;
    source: any;
    isReady: boolean;
    gameType: MiniGameType;
}

const miniGameList: GameItem[] = [
    {
        id: 'txcb',
        gameType: 'SICBO',
        source: txIcon,
        isReady: true,
    },
    {
        id: 'xd',
        source: xdIcon,
        isReady: false,
        gameType: 'XOCDIA',
    },
    {
        id: 'bc',
        source: bcIcon,
        isReady: true,
        gameType: 'BAUCUA',
    },
    {
        id: 'xs',
        source: xsIcon,
        isReady: false,
        gameType: 'XS',
    },
];

interface Props {
    onLaunchGame: (type: MiniGamePath, gameType: MiniGameType, cb: () => void) => void;
}

export default function MiniGameIcon(props: Props) {
    const { onLaunchGame } = props;

    const [showGameList, setShowGameList] = useState(false);

    const openGameList = () => {
        setShowGameList(true);
    };

    const openGame = (path: MiniGamePath, gameType: MiniGameType, isReady: boolean) => {
        if (isReady) {
            onLaunchGame(path, gameType, () => {
                setShowGameList(false);
            });
        } else {
            toast.error('Game sắp ra mắt');
        }
    };
    return (
        <>
            <div className={styles.wrapIcon}>
                <div className={styles.iconItem} onClick={openGameList}>
                    <Lottie className="icon" animationData={iconMiniGame} loop={true} />
                </div>
            </div>
            <div className={`${styles.wrapGameList} ${showGameList ? 'show' : ''}`}>
                <div className={styles.gameInnerList}>
                    {miniGameList.map((game) => (
                        <Lottie
                            key={game.id}
                            className={`${styles.gameItem} ${game.id} ${game.isReady ? 'ready' : ''}`}
                            animationData={game.source}
                            loop={game.isReady}
                            onClick={() => openGame(game.id, game.gameType, game.isReady)}
                        />
                    ))}
                </div>
            </div>

            <div className={`${styles.bgOverlay} ${showGameList ? 'active' : ''}`} onClick={() => setShowGameList(false)} />
        </>
    );
}
