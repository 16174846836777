import { css } from '@emotion/css';
import userApi from 'apis/userApi';
import logo from 'assets/images/logos/qr.png';
import { colors } from 'constants/theme';
import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import accountStyles from './account-option.style';
import { AgentRegLinkPayload } from 'models/agentModel';
function AgentRegister() {
    const qrCodeRef = useRef<HTMLDivElement>(null);
    const [qrCode, setQrCode] = useState<HTMLCanvasElement | null>(null);
    const [regId, setRegId] = useState('');
    const [regLink, setRegLink] = useState('');

    useEffect(() => {
        initAgentReg();
    }, []);

    const initAgentReg = async () => {
        try {
            const { data } = await userApi.agentUserAutoRegList({});
            if (!data.msg) {
                const qrCodeData = data.reslist[0];

                if (qrCodeData.sign) {
                    const linkReg = new URL(qrCodeData.sign);
                    const regIdData = linkReg.pathname.replace('/', '');
                    // const newLink = getFullLinkUrl() + '/reg/' + regIdData;

                    setRegId(regIdData);
                    setRegLink(qrCodeData.sign);
                    generateQRCode(data.reslist[0].sign);
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const generateQRCode = async (link: string) => {
        let canvas = await QRCode.toCanvas(link, { errorCorrectionLevel: 'high', width: 160 });

        //adding a log at center
        const imgDim = { width: 30, height: 30 }; //logo dimention
        var context = canvas.getContext('2d');
        var imageObj = new Image();
        imageObj.src = logo;
        imageObj.onload = function () {
            if (context) {
                context.drawImage(imageObj, canvas.width / 2 - imgDim.width / 2, canvas.height / 2 - imgDim.height / 2, imgDim.width, imgDim.height);
            }
        };
        setQrCode(canvas);
        if (qrCodeRef.current) {
            qrCodeRef.current.appendChild(canvas);
        }
    };

    const downloadQrCode = async () => {
        if (qrCode) {
            const imageSrc = qrCode.toDataURL('image/jpeg');
            const response = await fetch(imageSrc);

            const blobImage = await response.blob();

            const href = URL.createObjectURL(blobImage);

            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = 'qrcode';

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        }
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('Sao chép thành công!');
    };

    const createAgentLink = async () => {
        const payload: AgentRegLinkPayload = { rpointssc: 0, rpoint115: 0, rpointdp: 0 };

        try {
            const { data } = await userApi.agentCreateLink(payload);
            if (!data.msg) {
                toast.success('Tạo liên kết thành công');
                initAgentReg();
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap} ${accountStyles.mw800}`}>
                <h6 className={accountStyles.title}>Liên Kết Đăng Ký</h6>

                {/* block has qrcode */}
                <div style={{ display: qrCode ? 'block' : 'none' }}>
                    <div className={styles.qrcodeWrap}>
                        <div ref={qrCodeRef} className={styles.qrcodeIcon}></div>
                        <div className={styles.ruleQr}>
                            <ul>
                                <li>
                                    Mỗi hội viên chỉ có <strong>1</strong> liên kết đăng ký.
                                </li>
                                <li>Tải xuống mã QR hoặc sao chép liên kết để gửi cho cấp dưới của bạn đăng ký.</li>
                            </ul>
                            <button type="button" className={`btn-primary ${accountStyles.btnSubmit}`} style={{ width: '160px', marginTop: '16px', padding: '10px' }} onClick={downloadQrCode}>
                                Tải ảnh QR
                            </button>
                        </div>
                    </div>
                    <div className={`${accountStyles.heading} ${accountStyles.flexbox}`} style={{ marginTop: '20px' }}>
                        <div className={`${accountStyles.accountName} ${accountStyles.flex50}`}>
                            <p>
                                Link đại lý: <span>{regLink}</span>
                            </p>
                            <button type="button" className={`${accountStyles.btnCopy} btn-primary`} onClick={() => copyText(regLink)}>
                                <img src={require('assets/images/icons/copy.png')} alt="" />
                            </button>
                        </div>
                        <div className={`${accountStyles.accountName} ${accountStyles.flex50}`}>
                            <p>
                                Mã đại lý: <span>{regId}</span>
                            </p>
                            <button type="button" className={`${accountStyles.btnCopy} btn-primary`} onClick={() => copyText(regId)}>
                                <img src={require('assets/images/icons/copy.png')} alt="" />
                            </button>
                        </div>
                    </div>
                </div>

                {/* block no qrcode */}
                <div style={{ display: qrCode ? 'none' : 'block' }}>
                    <ol className={styles.generateQr}>
                        <li>
                            Mỗi hội viên có <span>[1]</span> liên kết đăng ký.
                        </li>
                        <li>
                            Sau khi thiết lập liên kết đăng ký, nhấn vào <span>[Sao chép]</span> hoặc <span>[Truy cập]</span> để truy cập vào liên kết.
                        </li>
                    </ol>
                    <div style={{ marginTop: '28px', width: '400px' }}>
                        <button type="button" className={`${accountStyles.btnSubmit} btn-primary`} onClick={createAgentLink}>
                            Tạo Liên Kết
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = {
    qrcodeWrap: css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 30px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
    `,
    qrcodeIcon: css`
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        flex: 0 0 160px;
        max-width: 160px;
    `,
    ruleQr: css`
        ul {
            padding-left: 32px;
        }
        li {
            margin-bottom: 4px;
            font-size: 15px;
        }
    `,
    generateQr: css`
        padding-left: 32px;
        li {
            margin-bottom: 4px;
        }
        span {
            color: ${colors.red};
            font-weight: 500;
        }
    `,
};

export default AgentRegister;
