import { scrollToTopPage } from 'utils/function';
import styles from './promotion-home.style';
import { useNavigate } from 'react-router-dom';
import imageDefault from 'assets/images/promo-default.png';

interface Props {
    promoList: any[];
}
function PromotionHome(props: Props) {
    const { promoList } = props;
    const navigate = useNavigate();
    const goToPromoDetail = (id: number) => {
        navigate(`/promotion/${id}`);
        scrollToTopPage();
    };
    return (
        <div className={styles.wrap}>
            {promoList.map((item) => (
                <div className={styles.item} key={item.id}>
                    <div className={styles.itemInner} onClick={() => goToPromoDetail(item.id)}>
                        <div className={styles.wrapImg}>
                            <img src={item.photourl || imageDefault} alt="" />
                        </div>
                        {/* <p className="promo-name" onClick={() => goToPromoDetail(item.id)}>
                            {item.title}
                        </p> */}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PromotionHome;
