import { useEffect, useState } from 'react';
import accountStyles from './account-option.style';
import { FundTransSearchPayload, FundTransferItem, FundTransferType } from 'models/fundTransferModel';
import userApi from 'apis/userApi';
import { css } from '@emotion/css';
import DatePicker from 'components/date-picker/DatePicker';
import moment from 'moment';
import LoadingIcon from 'components/loading/LoadingIcon';
import toast from 'react-hot-toast';
import Modal from 'components/modal/Modal';
import BetRecord from 'components/bet-record/BetRecord';

function FundAccountTransfer() {
    const [fundTypeList, setFundTypeList] = useState<FundTransferType[]>([]);
    const [fundTypeSelect, setFunTypeSelect] = useState<FundTransferType>({} as FundTransferType);
    const [startTime, setStartTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')} 00:00:00`));
    const [endTime, setEndTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')} 23:59:59`));
    const [accountList, setAccountList] = useState<FundTransferItem[] | null>(null);
    const [betRecordId, setBetRecordId] = useState<string | null>(null);

    const [loading, setLoading] = useState(false);

    //mã số cược
    const [wid, setWid] = useState('');

    //mã số biến động
    const [fundId, setfundId] = useState('');

    useEffect(() => {
        const initFundTran = async () => {
            try {
                const { data } = await userApi.initAgentZbBean({});
                if (!data.msg) {
                    setFundTypeList(data.typelist);
                    if (data.typelist.length > 0) {
                        setFunTypeSelect(data.typelist[0]);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        initFundTran();
    }, []);

    const handleChangeFundType = (values: string) => {
        const fundSelect = fundTypeList.find((item) => item.value === values);
        if (fundSelect) {
            setFunTypeSelect(fundSelect);
        }
    };

    const searchFund = async () => {
        const payload: FundTransSearchPayload = {
            type: fundTypeSelect.value,
            starttime: startTime ? moment(startTime).format('yyyy-MM-DD HH:mm:ss') : '',
            endtime: endTime ? moment(endTime).format('yyyy-MM-DD HH:mm:ss') : '',
            wid,
            id: fundId,
        };
        setLoading(true);
        try {
            const { data } = await userApi.balanceChangeSearch(payload);
            if (!data.msg) {
                const accountData = data.reslist.map((item: FundTransferItem) => ({
                    ...item,
                    showDetail: [1, 5, 8, 9, 830, 850, 890, 13, 3001, 3002, 3003, 4001, 4002].includes(item.opttype),
                    isNote: Number(item.opttype) === 13 && item.remark.trim() !== '',
                }));
                setAccountList(accountData);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelBet = (recordId: string) => {};

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Biến Động Số Dư</h6>
                <div className={accountStyles.flexbox}>
                    <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                        <label htmlFor="">Loại Biến Động Số Dư</label>
                        <div className={accountStyles.wrapInput}>
                            <select className="input-common" value={fundTypeSelect.value} onChange={(e) => handleChangeFundType(e.target.value)}>
                                {fundTypeList.map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={`${styles.groupTime} ${accountStyles.flex50}`}>
                        <div className={accountStyles.groupInput} style={{ flex: 1 }}>
                            <label htmlFor="">Thời Gian Từ</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker showTime dateSelect={startTime} onSelectDate={(date) => setStartTime(date)} showIcon />
                            </div>
                        </div>
                        <div className={accountStyles.groupInput} style={{ flex: 1 }}>
                            <label htmlFor="">Đến</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker showTime dateSelect={endTime} onSelectDate={(date) => setEndTime(date)} showIcon />
                            </div>
                        </div>
                    </div>

                    <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                        <label htmlFor="">Mã Số Cược</label>
                        <div className={accountStyles.wrapInput}>
                            <input value={wid} onChange={(e) => setWid(e.target.value)} className={`input-common`} type="text" placeholder="Mã số cược" />
                        </div>
                    </div>
                    <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                        <label htmlFor="">Mã Số Biến Động</label>
                        <div className={accountStyles.wrapInput}>
                            <input value={fundId} onChange={(e) => setfundId(e.target.value)} className={`input-common`} type="text" placeholder="Mã số biến động" />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '10px', marginBottom: '30px', width: 'calc(calc(100% / 3) - 12px)' }}>
                    <button type="submit" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={searchFund}>
                        Tìm Kiếm
                    </button>
                </div>
                <div className="table-common">
                    <div className="row">
                        <div className="title col" style={{ flex: '0 0 200px' }}>
                            Mã số biến động
                        </div>
                        <div className="title col">Thành viên</div>
                        <div className="title col">Thời gian</div>
                        <div className="title col">Loại</div>
                        <div className="title col">Thu nhập</div>
                        <div className="title col">Chi</div>
                        <div className="title col">Số dư trước</div>
                        <div className="title col">Số dư sau</div>
                        <div className="title col">Chi tiết</div>
                    </div>
                    {accountList ? (
                        <>
                            {accountList.length > 0 ? (
                                accountList.map((mem) => (
                                    <div className="row" key={mem.id}>
                                        <p className="col" style={{ flex: '0 0 200px' }}>
                                            {mem.id}
                                        </p>
                                        <p className="col">{mem.loginname}</p>
                                        <p className="col">{moment(mem.inserttime).format('DD/MM/yyyy HH:mm')}</p>
                                        <p className="col">{mem.opttypename}</p>
                                        <p className="col text-success">{new Intl.NumberFormat().format(mem.income)}</p>
                                        <p className="col text-error">{new Intl.NumberFormat().format(mem.pay)}</p>
                                        <p className="col">{new Intl.NumberFormat().format(Number(mem.beforebalance))}</p>
                                        <p className="col">{new Intl.NumberFormat().format(Number(mem.afterbalance))}</p>
                                        <p className="col">
                                            {mem.showDetail && (
                                                <span className="btn-detail" style={{ padding: '4px 0', width: '100%', display: 'block' }} onClick={() => setBetRecordId(mem.wid)}>
                                                    Xem
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <div className="no-data">
                                    <img src={require('assets/images/icons/no-data.png')} alt="" />
                                    <p>Không có dữ liệu</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-data"></div>
                    )}
                </div>
            </div>
            <Modal isShow={Boolean(betRecordId)} onClose={() => setBetRecordId(null)} closeClickBackdrop width="60%" maxWidth="900px">
                <BetRecord onClose={() => setBetRecordId(null)} betRecordId={betRecordId} onCancelBet={handleCancelBet} />
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    groupTime: css`
        display: flex;
        gap: 16px;
    `,
};

export default FundAccountTransfer;
