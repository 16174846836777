import { css } from '@emotion/css';
import bgDownload from 'assets/images/bg/download-bg.jpg';
import { colors } from 'constants/theme';

const downloadAppStyle = {
    downloadPage: css`
        background: url(${bgDownload}) no-repeat;
        margin-bottom: 24px;
    `,
    downloadContent: css`
        display: flex;
        justify-content: space-between;
    `,
    downloadLeft: css`
        display: flex;
        img {
            width: 100%;
            object-fit: contain;
        }
    `,
    downloadRight: css`
        text-align: center;
        flex: 0 0 450px;
        max-width: 450px;
        margin: auto 0;
        > h2 {
            font-size: 32px;
            font-weight: 600;
        }
        .logo {
            margin: 16px 0;
        }
        .desc {
            color: ${colors.white};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 16px;
        }
    `,
    downloadOption: css`
        background: linear-gradient(180deg, rgba(2, 193, 196, 1) 0%, rgba(13, 38, 67, 1) 100%);
        border-radius: 16px;
        padding: 24px;
        .download-text {
            font-size: 14px;
            font-weight: 400;
            color: ${colors.white};
        }
    `,
    optionContent: css`
        display: flex;
        .app-item {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: center;
        }
        .qr-code {
            canvas {
                border-radius: 8px;
            }
        }
        .icon-platform {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(30, 44, 65, 0.15);
            border-radius: 24px;
            width: fit-content;
            min-width: 120px;
            padding: 4px 12px;
            margin: 12px auto;
            img {
                width: 24px;
            }
            span {
                color: ${colors.white};
            }
        }
    `,
};
export default downloadAppStyle;
