import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const stastGeneralTableStyle = {
    group50: css`
        display: flex;
        flex: 0 0 50%;
        border-bottom: 1px solid ${colors.borderTable};

        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
    `,
    group100: css`
        display: flex;
        flex: 0 0 100%;
        border-bottom: 1px solid ${colors.blue};
        &:last-child {
            border-bottom: none;
        }
        > div {
            background: rgba(0, 0, 0, 0.5);
            border: none;
        }
    `,
    borderRight: css`
        border-right: 1px solid ${colors.blue};
    `,
    borderNone: css`
        border-right: none !important;
    `,

    col: css`
        padding: 12px 8px;
        font-size: 14px;
    `,
    flex25: css`
        flex: 0 0 25%;
        max-width: 25%;
    `,
    flex50: css`
        flex: 0 0 50%;
        max-width: 50%;
    `,
};

export default stastGeneralTableStyle;
