import styles from './lottery-home.style';
import Button from 'components/button/Button';
import { colors } from 'constants/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { memo, useRef } from 'react';
import { Navigation } from 'swiper/modules';
import TimeCountDown from 'components/Time-countdown/TimeCountDown';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppSelector } from 'cache/store';
import alertMsg from 'components/alert-message/alert-message';
import { msgAuth } from 'constants/messageAlert';
import { scrollToTopPage } from 'utils/function';
interface Props {
    lotteryList: any[];
}
function TodayLotteryHome(props: Props) {
    const { lotteryList } = props;
    const navigate = useNavigate();
    const { isLogged } = useAppSelector((state: RootState) => state.auth);

    const btnPrevSlide = useRef<HTMLButtonElement>(null);
    const btnNextSlide = useRef<HTMLButtonElement>(null);

    const selectGame = (ltId: number) => {
        if (!isLogged) {
            alertMsg(msgAuth.login);
            return;
        }
        scrollToTopPage();
        navigate(`/lottery?ltId=${ltId}`);
    };
    return (
        <div className={styles.wrap}>
            <button ref={btnPrevSlide} className={`${styles.btnPrev} ${styles.btnNavigate}`}>
                <GrFormPrevious size={48} />
            </button>
            <button ref={btnNextSlide} className={`${styles.btnNext} ${styles.btnNavigate}`}>
                <GrFormNext size={48} />
            </button>
            <Swiper
                spaceBetween={21}
                slidesPerView={4}
                modules={[Navigation]}
                navigation={{
                    prevEl: btnPrevSlide.current,
                    nextEl: btnNextSlide.current,
                }}
            >
                {lotteryList.map((item) => (
                    <SwiperSlide key={item.lotteryid}>
                        <div className={styles.card}>
                            <div className={styles.top}>
                                <img src={require(`assets/images/icons-lt/${item.lotteryid}.png`)} alt="" />
                                <div className={styles.issue}>
                                    <p>Lượt xổ: {item.issue}</p>
                                    <h6>{item.lotteryname}</h6>
                                </div>
                            </div>
                            <div className={styles.result}>
                                {item.listJackpost.length > 0 ? (
                                    item.listJackpost.map((num: number, index: number) => <div key={index}>{num}</div>)
                                ) : (
                                    // list jackpost null
                                    <>
                                        <div>?</div>
                                        <div>?</div>
                                        <div>?</div>
                                        <div>?</div>
                                        <div>?</div>
                                    </>
                                )}
                            </div>
                            <div className={styles.bottom}>
                                <TimeCountDown opentime={item.opentime} endtime={item.endtime} servertime={item.servertime} styleProps={{ fontSize: '14px' }} />
                                <Button text="CƯỢC NGAY" bg="none" click={() => selectGame(item.lotteryid)} borderColor={colors.orange} rounded bgHover={colors.orange} textSize="12px" weight="500" />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default memo(TodayLotteryHome);
