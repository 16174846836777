import ReactDatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import { rangeYear } from 'utils/function';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const months = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];

interface Props {
    showTime?: boolean;
    showSecond?: boolean;
    onSelectDate: (date: Date) => void;
    dateSelect: Date | null;
    holder?: string;
    yearEnd?: number;
    showIcon?: boolean;
    minDate?: Date;
    maxDate?: Date;
}

function DatePicker(props: Props) {
    const { showTime, onSelectDate, dateSelect, holder, yearEnd, showIcon, minDate, maxDate, showSecond } = props;
    const years = rangeYear(yearEnd || 2010, getYear(new Date()) + 1);

    return (
        <div className={styles.wrapDatePicker}>
            <ReactDatePicker
                locale="vi"
                className="input-common"
                showTimeInput={showTime}
                dateFormat={showSecond ? 'dd/MM/yyyy HH:mm:ss' : showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                timeFormat="p"
                minDate={minDate}
                maxDate={maxDate}
                timeIntervals={15}
                timeInputLabel="Thời gian:"
                placeholderText={holder || 'Chọn ngày'}
                formatWeekDay={(nameOfDay) => {
                    if (nameOfDay.includes('Chủ Nhật')) return (nameOfDay = 'CN');
                    else {
                        return nameOfDay.replace('Thứ ', '');
                    }
                }}
                renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                    <div className={styles.header}>
                        <button className={styles.btnHeader} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            <GrFormPrevious size={28} />
                        </button>
                        <select className={styles.selectHeader} value={getYear(date)} onChange={({ target: { value } }: any) => changeYear(value)}>
                            {years.map((option: any) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select className={styles.selectHeader} value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <button className={styles.btnHeader} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            <GrFormNext size={28} />
                        </button>
                    </div>
                )}
                selected={dateSelect}
                onChange={(date: Date) => onSelectDate(date)}
            />
            {showIcon && <img className={styles.icon} src={require('assets/images/icons/calendar.png')} alt="" />}
        </div>
    );
}

const styles = {
    wrapDatePicker: css`
        position: relative;
        height: 100%;
    `,
    icon: css`
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        width: 20px;
        pointer-events: none;
    `,
    header: css`
        display: flex;
        gap: 4px;
        margin-bottom: 12px;
    `,
    selectHeader: css`
        flex: 1;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        background: ${colors.white};
        color: ${colors.blackText};
        font-size: 14px;
        font-weight: 500;
        &:focus {
            outline: none;
        }
    `,
    btnHeader: css`
        display: flex;
        cursor: pointer;
    `,
};

export default DatePicker;
