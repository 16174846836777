import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const lotteryTypeInputStyles = {
    wrap: css``,
    pickBet: css``,
    pickRow: css`
        display: flex;
        align-items: center;
        padding: 8px 0;
    `,
    pickLeft: css`
        display: flex;
        align-items: center;
        flex: 1;

        p {
            flex: 0 0 60px;
        }
    `,
    pickNum: css`
        display: flex;
        gap: 8px;
    `,
    numPickItem: css`
        width: 36px;
        height: 36px;
        border-radius: 36px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${colors.white};
        border: 1px solid #bce1e1;
        cursor: pointer;
        font-weight: 600;

        &.active {
            background: ${colors.darkPrimary};
            border-color: ${colors.darkPrimary};
            color: ${colors.white};
        }
        &:hover {
            border-color: ${colors.darkPrimary};
        }
    `,
    pickRight: css`
        display: flex;
        gap: 6px;
    `,
    btnPickQick: css`
        font-size: 14px;
        padding: 4px 0;
        min-width: 52px;
        border: 1px solid #bce1e1;
        border-radius: 6px;
        cursor: pointer;
        &.active {
            background: ${colors.darkPrimary};
            color: ${colors.white};
        }
    `,
};
export default lotteryTypeInputStyles;
