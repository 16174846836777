import { css } from '@emotion/css';
import PopupTooltip from 'components/tooltip-popup/PopupTooltip';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { LotteryTicket } from 'models/lotteryModel';
import { formatCodeString } from 'utils/function';
const bgTitleLinear = `linear-gradient(269deg, rgba(67, 47, 92, 0.66) 23.94%, rgba(21, 41, 111, 0.61) 83.16%), ${colors.bgTitle}`;

interface Props {
    ticketList: LotteryTicket[];
    onRemoveTicket: (tickets: LotteryTicket[]) => void;
}
function LotteryTicketsTable(props: Props) {
    const { ticketList, onRemoveTicket } = props;

    const removeTicket = (idx: number) => {
        const newTicketList = ticketList.filter((_, index) => index !== idx);
        onRemoveTicket(newTicketList);
    };

    const removeAllTicket = () => {
        if (ticketList.length === 0) {
            return;
        }
        onRemoveTicket([]);
    };
    return (
        <section className={styles.wrap}>
            <div className={styles.heading}>
                <h4>
                    Nội Dung Cược
                    {ticketList.length > 0 && <span className={styles.ticketLabel}>{ticketList.length} vé</span>}
                </h4>
                <button type="button" className={styles.btnClearAll} onClick={removeAllTicket}>
                    Xoá Tất Cả
                </button>
            </div>
            <div className={styles.table}>
                <div className={`${styles.row} ${styles.rowHeader}`}>
                    <div className={`${styles.col} ${styles.title}`}>Tên cược</div>
                    <div className={`${styles.col} ${styles.title}`} style={{ flex: '0 0 200px' }}>
                        Số cược
                    </div>
                    <div className={`${styles.col} ${styles.title}`}>Tổng cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Cấp số nhân</div>
                    <div className={`${styles.col} ${styles.title}`}>Tiền cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Tiền thắng/1 lần</div>
                    <div className={`${styles.col} ${styles.title}`}>Tỉ lệ</div>
                    <div className={`${styles.col} ${styles.title}`} style={{ flex: '0 0 80px' }}></div>
                </div>
                {ticketList.length > 0 ? (
                    ticketList.map((item, index) => (
                        <div className={styles.row} key={index}>
                            <p className={`${styles.col} ${globalStyle.text.blue}`} style={{ fontWeight: '600' }}>
                                {item.trTitle} - {item.stTitle}
                            </p>
                            <div className={`${styles.col} ${styles.codesBet}`} style={{ flex: '0 0 200px' }}>
                                <PopupTooltip content={formatCodeString(item.codes)} idRef={`ticket_${index}`} />
                            </div>
                            <p className={styles.col}>{item.total}</p>
                            <p className={styles.col}>{item.times}</p>
                            <p className={`${styles.col} ${globalStyle.text.blue}`} style={{ fontWeight: '600' }}>
                                {new Intl.NumberFormat().format(item.money * item.times)}
                            </p>
                            <p className={styles.col}>{new Intl.NumberFormat().format(Number(item.dyprize.prize) * item.times * 1000)}</p>
                            <p className={styles.col}>{item.dyprize.prize}</p>
                            <div className={styles.col} style={{ flex: '0 0 80px' }}>
                                <button type="button" className={styles.btnRemoveTicket} onClick={() => removeTicket(index)}>
                                    <img src={require('assets/images/icons/close-lt.png')} alt="" />
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.noData}>
                        {/* <img src={require('assets/images/icons/no-data.png')} alt="" /> */}
                        <p>Chưa có vé cược</p>
                    </div>
                )}
            </div>
        </section>
    );
}

const styles = {
    wrap: css`
        padding-top: 16px;
    `,
    heading: css`
        background: ${colors.darkSecondary};
        color: ${colors.white};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 16px;
        border-radius: 8px;
        margin-bottom: 16px;
    `,
    ticketLabel: css`
        background: ${colors.red};
        border-radius: 40px;
        font-size: 14px;
        padding: 4px 8px;
        display: inline-block;
        margin-left: 6px;
    `,
    btnClearAll: css`
        border: 1px solid ${colors.lightBlue};
        border-radius: 8px;
        padding: 6px;
        font-size: 14px;
        color: ${colors.white};
    `,
    table: css`
        border: 1px solid ${colors.lightBlue};
        border-radius: 8px;
        overflow: hidden;
        min-height: 150px;
        &.table-col-center {
            .col {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    `,
    codesBet: css`
        max-width: 200px;
    `,
    row: css`
        display: flex;
        border-bottom: 1px solid ${colors.lightBlue};
        &:first-child {
            border-bottom-color: ${colors.lightBlue};
        }
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background: ${colors.lightBlue};
        }
    `,
    rowHeader: css`
        border-bottom: 1px solid ${colors.darkPrimary};
    `,
    col: css`
        flex: 1;
        padding: 12px 8px;
        text-align: center;
        font-size: 14px;
    `,
    title: css`
        font-weight: 600;
        background: ${colors.lightBlue};
        font-size: 16px !important;
    `,
    noData: css`
        text-align: center;
        padding: 30px;
        p {
            margin-top: 8px;
            font-size: 14px;
        }
    `,
    btnRemoveTicket: css`
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img {
            height: 16px;
        }
    `,
};

export default LotteryTicketsTable;
