import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';

const miniGameApi = {
    launchGame(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'launchMiniGame';
        return axiosClient.post(serviceType.MINIGAME_SERVICE, payload);
    },
    checkBalance(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'balanceMiniGame';
        return axiosClient.post(serviceType.MINIGAME_SERVICE, payload);
    },
    submitTransfer(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'transferMiniGame';
        return axiosClient.post(serviceType.MINIGAME_SERVICE, payload);
    },
};
export default miniGameApi;
