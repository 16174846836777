import { css } from '@emotion/css';
import { rotateAnimation, textWave } from 'constants/global.style';
import { colors } from 'constants/theme';
import { Game3rdTransferModel } from 'models/fundTransferModel';
import { useState } from 'react';

interface Props {
    gameData: Game3rdTransferModel;
    onLoadBalance: (gameData: Game3rdTransferModel, isReload: boolean, cb?: () => void) => void;
}
export function Game3rdTransfer(props: Props) {
    const { gameData, onLoadBalance } = props;

    const reloadBalance = (e: any) => {
        const elTarget = e.target as HTMLElement;
        const buttonEl = elTarget.closest('button');

        if (buttonEl) {
            buttonEl.classList.add('rotating');
        }
        onLoadBalance(gameData, true, () => {
            if (buttonEl) {
                buttonEl.classList.remove('rotating');
            }
        });
    };

    return (
        <div className={styles.gameItem}>
            <button type="button" className={styles.btnRefresh} onClick={(e) => reloadBalance(e)}>
                <img src={require('assets/images/icons/refresh.png')} alt="" />
            </button>
            <p className={styles.gameName}>{gameData.balancename.includes(',') ? gameData.balancename.replace(',', ' + ') : gameData.balancename}</p>
            {gameData.msg ? (
                <p className={styles.msgAlert}>{gameData.msg}</p>
            ) : (
                <div className={styles.balance}>
                    {gameData.loading ? (
                        <div className={styles.loading}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    ) : (
                        new Intl.NumberFormat().format(Number(gameData.balance))
                    )}
                </div>
            )}
        </div>
    );
}

export function MiniGameTransfer(props: Props) {
    const { gameData, onLoadBalance } = props;
    const [loading, setLoading] = useState(false);
    const reloadBalance = (e: any) => {
        const elTarget = e.target as HTMLElement;
        const buttonEl = elTarget.closest('button');
        setLoading(true);
        if (buttonEl) {
            buttonEl.classList.add('rotating');
        }
        onLoadBalance(gameData, true, () => {
            if (buttonEl) {
                buttonEl.classList.remove('rotating');
                setLoading(false);
            }
        });
    };
    return (
        <div className={styles.gameItem}>
            <button type="button" className={styles.btnRefresh} onClick={(e) => reloadBalance(e)}>
                <img src={require('assets/images/icons/refresh.png')} alt="" />
            </button>
            <p className={styles.gameName}>MiniGame</p>
            {gameData.msg ? (
                <p className={styles.msgAlert}>{gameData.msg}</p>
            ) : (
                <div className={styles.balance}>
                    {loading ? (
                        <div className={styles.loading}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    ) : gameData.balance ? (
                        new Intl.NumberFormat().format(Number(gameData.balance))
                    ) : (
                        0
                    )}
                </div>
            )}
        </div>
    );
}

const styles = {
    gameItem: css`
        border-radius: 8px;
        border: 1px solid #7f88c0;
        padding: 6px 12px;
        background: ${colors.drak50};
        position: relative;
        p {
            font-size: 14px;
        }
    `,
    btnRefresh: css`
        position: absolute;
        top: 2px;
        right: 2px;
        display: flex;
        padding: 4px;
        cursor: pointer;
        &.rotating {
            pointer-events: none;
            animation: ${rotateAnimation} 0.8s linear infinite;
        }
        img {
            width: 18px;
            opacity: 0.8;
        }
    `,
    gameName: css`
        margin-bottom: 8px;
    `,
    msgAlert: css`
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: ${colors.red};
        height: 19px;
    `,
    balance: css`
        color: ${colors.yellow};
    `,
    loading: css`
        display: flex;
        align-items: center;
        height: 19px;
        span {
            width: 6px;
            height: 6px;
            background: ${colors.yellow};
            border-radius: 8px;
            animation: ${textWave} 1s ease-in-out infinite;
            display: inline-block;
            margin-left: 2px;
            &:nth-child(2) {
                animation-delay: 0.1s;
            }
            &:nth-child(3) {
                animation-delay: 0.2s;
            }
        }
    `,
};
