import DatePicker from 'components/date-picker/DatePicker';
import accountStyles from './account-option.style';
import { useEffect, useState } from 'react';
import moment from 'moment';
import userApi from 'apis/userApi';
import { InfoPaginate } from 'models/commonModel';
import Pagination from 'components/pagination/Pagination';
import LoadingIcon from 'components/loading/LoadingIcon';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import { HistoryBetPayload } from 'models/fundTransferModel';
import Modal from 'components/modal/Modal';
import BetRecord from 'components/bet-record/BetRecord';
import { scrollToTopPage } from 'utils/function';
import lotteryApi from 'apis/lotteryApi';

interface StatusItem {
    description: string;
    disabled: boolean;
    escape: boolean;
    label: string;
    noSelectionOption: boolean;
    value: string;
}

const timeOptionBet = [
    {
        value: '0',
        label: 'Thời gian cược',
    },
    {
        value: '1',
        label: 'Thời gian trả thưởng',
    },
];

function FundBet() {
    const [startTime, setStartTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')} 00:00:00`));
    const [endTime, setEndTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')} 23:59:59`));

    const [statusList, setStatusList] = useState<StatusItem[]>([]);
    const [statusSelect, setStatusSelect] = useState('');

    const [timeSelect, setTimeSelect] = useState('0');

    const [gameList, setGameList] = useState<StatusItem[]>([]);
    const [gameValueSelect, setGameValueSelect] = useState('-1');
    const [infoPage, setInfoPage] = useState<InfoPaginate | null>(null);
    const [recordList, setRecordList] = useState<any[] | null>(null);

    const [betId, setBetId] = useState('');
    const [loading, setLoading] = useState(false);

    const [totalBetWin, setTotalBetWin] = useState<{ tbet: number; twin: number }>({ tbet: 0, twin: 0 });
    const [betRecordId, setBetRecordId] = useState<string | null>(null);

    useEffect(() => {
        const getStatusList = async () => {
            try {
                const { data } = await userApi.gameRecordInit({});
                if (!data.msg) {
                    //remove 5:huy nuôi số, 9:nổ hũ
                    const statusData: any[] = data.statuslist.filter((item: StatusItem) => ![5, 9].includes(Number(item.value)));
                    setStatusList(statusData);

                    //remove 300:txcb, 301:tren duoi, 303:cao rua v1, 22:livestream 2p, tai xiu, xoc dia, bau cua
                    const gameData = data.lotterylist.filter((item: StatusItem) => ![300, 301, 303, 22, 90, 91, 92, 93, 80, 60].includes(Number(item.value)));
                    setGameList(gameData);
                    if (statusData.length > 0) {
                        setStatusSelect(statusData[0].value);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getStatusList();
    }, []);

    const handleChangePage = (page: number) => {
        searchRecordBet(page, false, 'inputPage');
        scrollToTopPage();
    };

    const searchRecordBet = async (page: number, isResetPaginate: boolean, type: 'mobileSearch' | 'inputPage') => {
        if (isResetPaginate) {
            setInfoPage(null);
        }
        setLoading(true);
        let payload: HistoryBetPayload = {
            status: statusSelect,
            betid: betId,
            starttime: startTime ? moment(startTime).format('yyyy-MM-DD HH:mm:ss') : '',
            endtime: endTime ? moment(endTime).format('yyyy-MM-DD HH:mm:ss') : '',
            lotteryid: Number(gameValueSelect),
            pagesize: 10,
            type: timeSelect,
        };

        if (type === 'inputPage') {
            payload = {
                ...payload,
                curpage: infoPage?.curpage,
                num: page,
            };
        }
        try {
            const { data } = await userApi.gameRecordSearch(payload, type);
            if (!data.msg) {
                setRecordList(data.reslist);
                const { curpage, totalpage, totalrecord } = data;
                setInfoPage({ curpage, totalpage, totalrecord });

                setTotalBetWin({ tbet: data.tamount, twin: data.twin });
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelBet = async (recordId: string) => {
        setLoading(true);
        try {
            const { data } = await lotteryApi.lotteryCancelBet({ id: recordId });
            if (!data.msg) {
                toast.success('Huỷ cược thành công');
                setBetRecordId(null);
                searchRecordBet(infoPage ? infoPage.curpage : 1, false, 'mobileSearch');
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <div className={accountStyles.mw800}>
                    <h6 className={accountStyles.title}>Lịch Sử Cược</h6>
                    <div className={accountStyles.flexbox}>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Thời Gian Từ</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker maxDate={new Date()} showTime dateSelect={startTime} onSelectDate={(date) => setStartTime(date)} showIcon />
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Đến</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker maxDate={new Date()} showTime dateSelect={endTime} onSelectDate={(date) => setEndTime(date)} showIcon />
                            </div>
                        </div>

                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Trạng Thái</label>
                            <div className={accountStyles.wrapInput}>
                                <select className="input-common" value={statusSelect} onChange={(e) => setStatusSelect(e.target.value)}>
                                    {statusList.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Mã Số Cược</label>
                            <div className={accountStyles.wrapInput}>
                                <input value={betId} onChange={(e) => setBetId(e.target.value)} className={`input-common`} type="text" placeholder="Mã số cược" />
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Loại Trò Chơi</label>
                            <div className={accountStyles.wrapInput}>
                                <select className="input-common" value={gameValueSelect} onChange={(e) => setGameValueSelect(e.target.value)}>
                                    {gameList.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Thời Gian</label>
                            <div className={accountStyles.wrapInput}>
                                <select className="input-common" value={timeSelect} onChange={(e) => setTimeSelect(e.target.value)}>
                                    {timeOptionBet.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '8px', width: 'calc(50% - 25px)', marginBottom: '30px' }}>
                        <button type="submit" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={() => searchRecordBet(1, true, 'mobileSearch')}>
                            Tìm Kiếm
                        </button>
                    </div>
                </div>
                <div className="table-common">
                    <div className="row">
                        <div className="title col">Hội viên</div>
                        <div className="title col">Loại trò chơi</div>
                        <div className="title col">Kiểu chơi</div>
                        <div className="title col">Thời gian</div>
                        <div className="title col">Lượt xổ</div>
                        <div className="title col">Cược</div>
                        <div className="title col">Trúng</div>
                        <div className="title col">Trạng thái</div>
                        <div className="title col">Chi tiết</div>
                    </div>
                    {recordList ? (
                        <>
                            {recordList.length > 0 ? (
                                recordList.map((item) => (
                                    <div className="row" key={item.id}>
                                        <p className="col">{item.loginname}</p>
                                        <p className="col">{item.lotteryname}</p>
                                        <p className="col">{![60, 80, 90, 91, 92, 93, 300, 301].includes(item.lotteryid) ? item.methodname : '-'}</p>
                                        <p className="col">{moment(item.bettime).format('DD/MM/yyyy HH:mm')}</p>
                                        <p className="col">{item.issue}</p>
                                        <p className="col">{new Intl.NumberFormat().format(item.amount)}</p>
                                        <p className="col text-success">{new Intl.NumberFormat().format(item.bonus)}</p>
                                        <p className={classNames('col', { 'text-success': item.status === 4 }, { 'text-error': [1, 3, 6].includes(item.status) })}>{item.statusname}</p>
                                        <p className="col">
                                            <span className="btn-detail" style={{ padding: '4px 0', width: '100%', display: 'block' }} onClick={() => setBetRecordId(item.id)}>
                                                Xem
                                            </span>
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <div className="no-data">
                                    <img src={require('assets/images/icons/no-data.png')} alt="" />
                                    <p>Không có dữ liệu</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-data"></div>
                    )}
                </div>
                {infoPage && infoPage.totalpage > 1 && (
                    <div className="pagination-common">
                        <div className="total">
                            {totalBetWin.tbet > 0 && (
                                <>
                                    <p>
                                        Cược: <span className="text-white">{new Intl.NumberFormat().format(totalBetWin.tbet)}</span>
                                    </p>
                                    <p className="line">|</p>
                                    <p>
                                        Thắng: <span className="text-success">{new Intl.NumberFormat().format(totalBetWin.twin)}</span>
                                    </p>
                                </>
                            )}
                        </div>
                        <Pagination infoPage={infoPage} onChangePage={handleChangePage} showTotal />
                    </div>
                )}
            </div>
            <Modal isShow={Boolean(betRecordId)} onClose={() => setBetRecordId(null)} closeClickBackdrop width="60%" maxWidth="900px">
                <BetRecord onClose={() => setBetRecordId(null)} betRecordId={betRecordId} onCancelBet={handleCancelBet} />
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

export default FundBet;
