import { DeviceInfo, LotteryMenuHeader } from 'models/commonModel';

export const DEVICE_INFO: DeviceInfo = {
    uuid: '',
    timezone: '',
    platform: '',
    resolution: '',
};

export const ltOpenDay = [
    '202,203,204,200,206,123,124,125,139,131,201',
    '202,203,204,200,206,105,106,107,126,127,201',
    '202,203,204,200,206,109,110,128,129,201', // 108
    '202,203,204,200,206,111,112,113,130,131,201',
    '202,203,204,200,206,114,115,116,132,133,134,201',
    '202,203,204,200,206,117,118,119,135,136,201',
    '202,203,204,200,206,105,121,122,137,138,130,201', // 120
];

export const lotteryHeaderMenu: LotteryMenuHeader[] = [
    {
        title: 'Xổ số siêu tốc',
        isFilterDate: false,
        type: 'FAST',
        child: [
            { ltid: 50, label: 'Siêu Tốc 1 Giây' },
            { ltid: 100, label: 'Siêu Tốc 45 Giây' },
            { ltid: 101, label: 'Siêu Tốc 1 Phút' },
            { ltid: 102, label: 'Siêu Tốc 1.5 Phút' },
            { ltid: 103, label: 'Siêu Tốc 2 Phút' },
            { ltid: 104, label: 'Siêu Tốc 5 Phút' },
        ],
    },
    {
        title: 'Siêu tốc miền Bắc',
        isFilterDate: false,
        type: 'FAST',
        child: [
            { ltid: 140, label: 'Miền Bắc 1 Giây' },
            { ltid: 141, label: 'Miền Bắc 45 Giây' },
            { ltid: 142, label: 'Miền Bắc 1 Phút' },
            { ltid: 143, label: 'Miền Bắc 1.5 Phút' },
            { ltid: 144, label: 'Miền Bắc 2 Phút' },
            { ltid: 145, label: 'Miền Bắc 5 Phút' },
        ],
    },
    {
        title: 'Xổ số miền Bắc',
        isFilterDate: false,
        type: 'MB',
        child: [
            { ltid: 200, label: 'Xổ Số Miền Bắc' },
            { ltid: 201, label: 'Đặc Biệt 18h25' },
        ],
    },
    {
        title: 'Xổ số miền Nam',
        isFilterDate: true,
        type: 'MN',
        child: [
            { label: 'Xổ Số Tiền Giang', ltid: 123, openDay: 0 },
            { label: 'Xổ Số Kiên Giang', ltid: 124, openDay: 0 },
            { label: 'Xổ Số Đà Lạt', ltid: 125, openDay: 0 },
            { label: 'Xổ Số Hồ Chí Minh', ltid: 105, openDay: 1 },
            { label: 'Xổ Số Đồng Tháp', ltid: 106, openDay: 1 },
            { label: 'Xổ Số Cà Mau', ltid: 107, openDay: 1 },
            { label: 'Xổ Số Vũng Tàu', ltid: 109, openDay: 2 },
            { label: 'Xổ Số Bạc Liêu', ltid: 110, openDay: 2 },
            { label: 'Xổ Số Đồng Nai', ltid: 111, openDay: 3 },
            { label: 'Xổ Số Cần Thơ', ltid: 112, openDay: 3 },
            { label: 'Xổ Số Sóc Trăng', ltid: 113, openDay: 3 },
            { label: 'Xổ Số Tây Ninh', ltid: 114, openDay: 4 },
            { label: 'Xổ Số An Giang', ltid: 115, openDay: 4 },
            { label: 'Xổ Số Bình Thuận', ltid: 116, openDay: 4 },
            { label: 'Xổ Số Vĩnh Long', ltid: 117, openDay: 5 },
            { label: 'Xổ Số Bình Dương', ltid: 118, openDay: 5 },
            { label: 'Xổ Số Trà Vinh', ltid: 119, openDay: 5 },
            { label: 'Xổ Số Hồ Chí Minh', ltid: 105, openDay: 6 },
            { label: 'Xổ Số Bình Phước', ltid: 121, openDay: 6 },
            { label: 'Xổ Số Hậu Giang', ltid: 122, openDay: 6 },
        ],
    },
    {
        title: 'Xổ số miền Trung',
        isFilterDate: true,
        type: 'MT',
        child: [
            { label: 'Xổ Số Huế', ltid: 126, openDay: 0 },
            { label: 'Xổ Số Kon Tum', ltid: 139, openDay: 0 },
            { label: 'Xổ Số Khánh Hoà', ltid: 131, openDay: 0 },

            { label: 'Xổ Số Huế', ltid: 126, openDay: 1 },
            { label: 'Xổ Số Phú Yên', ltid: 127, openDay: 1 },

            { label: 'Xổ Số Đắk Lắk', ltid: 128, openDay: 2 },
            { label: 'Xổ Số Quảng Nam', ltid: 129, openDay: 2 },

            { label: 'Xổ Số Đà Nẵng', ltid: 130, openDay: 3 },
            { label: 'Xổ Số Khánh Hoà', ltid: 131, openDay: 3 },

            { label: 'Xổ Số Bình Định', ltid: 132, openDay: 4 },
            { label: 'Xổ Số Quảng Trị', ltid: 133, openDay: 4 },
            { label: 'Xổ Số Quảng Bình', ltid: 134, openDay: 4 },

            { label: 'Xổ Số Gia Lai', ltid: 135, openDay: 5 },
            { label: 'Xổ Số Ninh Thuận', ltid: 136, openDay: 5 },

            { label: 'Xổ Số Quảng Ngãi', ltid: 137, openDay: 6 },
            { label: 'Xổ Số Đắc Nông', ltid: 138, openDay: 6 },
            { label: 'Xổ Số Đà Nẵng', ltid: 130, openDay: 6 },
        ],
    },
];
