import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import React, { useEffect, useState } from 'react';
import { cloneObject } from 'utils/function';

let interfaceTable: string[][] = [[], [], [], [], [], [], [], [], [], []];
interface Props {
    resultList: string[];
    methodid: number;
    onHover?: (list: string[]) => void;
    onBlur?: (list: string[]) => void;
    borderColor?: string;
}

const checkIs2SoDau = (methodId: number) => {
    return methodId.toString().slice(-3) === '145' || methodId.toString().slice(-3) === '146'; // lo 2 so dau vs lo 2 so dau 1k
};

function TableLotteryDauDuoi(props: Props) {
    const { resultList, methodid, onHover, borderColor } = props;
    const [tableList, setTableList] = useState<string[][]>([]);
    useEffect(() => {
        let tmpList: string[] = [];
        resultList.forEach((num) => {
            if (checkIs2SoDau(methodid)) {
                if (num.length > 2) {
                    tmpList.push(num.slice(0, 1));
                }
            } else {
                tmpList.push(num.slice(-2));
            }
        });
        let newTableList = cloneObject(interfaceTable);

        tmpList.forEach((item) => {
            const dauDuoi = item.split('');
            newTableList[Number(dauDuoi[0])].push(dauDuoi[1]);
        });
        setTableList(newTableList);
        return () => {
            interfaceTable = [[], [], [], [], [], [], [], [], [], []];
            tmpList = [];
            newTableList = [];
        };
    }, [resultList, methodid]);

    const handleHover = (dau: number, listDuoi: string[]) => {
        const listNumHover = listDuoi.map((duoiItem) => `${dau}${duoiItem}`);
        if (onHover) {
            onHover(listNumHover);
        }
    };
    const handleBlur = (dau: number, listDuoi: string[]) => {
        const listNumHover = listDuoi.map((duoiItem) => `${dau}${duoiItem}`);
        // console.log(listNumHover);
    };

    return (
        <div className={styles.wrap} style={{ borderColor }}>
            <div className={`${styles.row} ${styles.rowHeading}`}>
                <p className={`${styles.label} ${styles.title}`}>Đầu</p>
                <p className={styles.title}>Đuôi</p>
            </div>
            {tableList.map((group, index) => (
                <div className={styles.row} key={index} onMouseEnter={() => handleHover(index, group)} onMouseLeave={() => handleBlur(index, group)}>
                    <p className={styles.label}>{index}</p>
                    <p>{group.join(',')}</p>
                </div>
            ))}
        </div>
    );
}

const styles = {
    wrap: css`
        border-radius: 8px;
        border: 1px solid ${colors.primary};
        min-width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    row: css`
        display: flex;
        border-bottom: 1px solid ${colors.borderTable};
        padding: 6px 12px;
        &:last-child {
            border-bottom: none;
        }
        p {
            text-align: center;
            &:last-child {
                flex: 1;
            }
        }
    `,
    rowHeading: css`
        background: rgba(0, 0, 0, 0.3);
        flex: 1;
        border-radius: 8px 8px 0 0;
    `,
    label: css`
        flex: 0 0 50px;
        border-right: 1px solid ${colors.borderTable};
    `,
    title: css`
        color: ${colors.yellow};
        display: flex;
        align-items: center;
        justify-content: center;
    `,
};

export default TableLotteryDauDuoi;
