import accountStyles from './account-option.style';
import { useState } from 'react';
import LoadingIcon from 'components/loading/LoadingIcon';
import userApi from 'apis/userApi';
import toast from 'react-hot-toast';

function GiftCodeAccount() {
    const [giftCode, setGiftCode] = useState('');
    const [loading, setLoading] = useState(false);
    const submitGiftCode = async () => {
        if (!giftCode) {
            toast.error('Mã quà tặng không hợp lệ');
            return;
        }
        setLoading(true);
        try {
            const { data } = await userApi.sendGiftCode({ giftcode: giftCode });
            if (data) {
                if (data.errorCode === 0) {
                    toast.success(data.message);
                    setGiftCode('');
                } else {
                    toast.error(data.message);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className={accountStyles.wrap}>
                <div style={{ maxWidth: '350px' }}>
                    <h6 className={accountStyles.title}>Quà Tặng</h6>
                    <div style={{ marginBottom: '8px' }} className={accountStyles.groupInput}>
                        <label className={accountStyles.label} htmlFor="giftCode">
                            Mã Quà Tặng
                        </label>
                        <input id="giftCode" type="text" className="input-common" placeholder="Mã quà tặng" onChange={(e) => setGiftCode(e.target.value)} />
                    </div>
                    <button type="button" className={`${accountStyles.btnSubmit} btn-primary`} style={{ width: '100%' }} onClick={submitGiftCode}>
                        Gửi
                    </button>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

export default GiftCodeAccount;
