import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState } from 'react';
import { RootState, useAppSelector } from 'cache/store';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { IoMdClose } from 'react-icons/io';
import authApi from 'apis/authApi';

const TIMEOUTALERT = 2000;
let idTimeOut: any;
interface Props {
    closeClickBackdrop?: boolean;
}
function MessageAlertCustom(props: Props) {
    const { closeClickBackdrop } = props;
    const { alertMsg } = useAppSelector((state: RootState) => state.config);

    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        if (alertMsg.message) {
            setIsShow(true);
            if (alertMsg.type !== 'expired') {
                idTimeOut = setTimeout(() => {
                    setIsShow(false);
                }, TIMEOUTALERT);
            }
        }
        return () => {
            idTimeOut && clearTimeout(idTimeOut);
        };
    }, [alertMsg]);

    const handleClickBackDrop = () => {
        if (closeClickBackdrop) {
            idTimeOut && clearTimeout(idTimeOut);
            setIsShow(false);
        }
    };

    const handleClosePopup = () => {
        setIsShow(false);
        if (alertMsg.type === 'expired') {
            authApi.logoutByToken();
            // window.location.href = '/';
        }
    };

    return (
        <>
            {ReactDOM.createPortal(
                <CSSTransition mountOnEnter unmountOnExit in={isShow} timeout={{ enter: 300, exit: 200 }} classNames="modal">
                    <div className={styles.modalWrap}>
                        <div className={styles.modalInner}>
                            <div className={styles.modalContent}>
                                <div className={styles.lineAlertType} />

                                <div className={styles.content}>
                                    <h6>THÔNG BÁO</h6>
                                    <p>{alertMsg.message}</p>
                                </div>
                                <button type="button" className={styles.btnClose} onClick={handleClosePopup}>
                                    <IoMdClose size={24} />
                                </button>
                            </div>
                            <div className={styles.outsideArea} onClick={handleClickBackDrop} />
                        </div>
                    </div>
                </CSSTransition>,
                document.body
            )}
            {ReactDOM.createPortal(isShow ? <div className={styles.backdrop}></div> : <></>, document.body)}
        </>
    );
}

const styles = {
    modalWrap: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 15;
    `,
    backdrop: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        z-index: 8;
    `,
    modalInner: css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    modalContent: css`
        /* width: 50%; */

        overflow: hidden;
        position: relative;
        z-index: 1;
        background: ${colors.white};
        padding: 12px;
        border-radius: 8px;
    `,
    content: css`
        padding-left: 24px;
        width: 400px;

        h6 {
            margin-bottom: 8px;
            color: ${colors.red};
        }
    `,
    btnClose: css`
        position: absolute;
        top: 8px;
        right: 8px;
    `,
    lineAlertType: css`
        width: 4px;
        height: calc(100% - 12px);
        background: ${colors.red};
        border-radius: 8px;
        position: absolute;
        left: 8px;
        top: 6px;
    `,
    outsideArea: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    `,
};

export default MessageAlertCustom;
