import { css } from '@emotion/css';
import routeActiveBg from 'assets/images/bg/title-register.jpg';
import { colors } from 'constants/theme';

const fundTransferStyle = {
    wrap: css`
        display: flex;
        gap: 50px;
    `,
    left: css`
        flex: 0 0 400px;
    `,
    right: css`
        flex: 1;
        padding-top: 21px;
    `,
    balanceMain: css`
        display: flex;
        gap: 40px;
        background: url(${routeActiveBg}) no-repeat;
        background-size: cover;
        padding: 10px 12px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
        p {
            flex: 1;
        }
        span {
            &:last-child {
                color: ${colors.yellow};
                margin-left: 4px;
            }
        }
    `,
    gameWrap: css`
        padding: 20px 16px;
        border: 1px solid ${colors.borderPrimary};
        border-radius: 8px;
        margin-top: 12px;
        background: ${colors['gradient-popup']};
    `,
    gameGroup: css`
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    `,
    gameList: css`
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;
    `,
    gameGroupTitle: css`
        margin-bottom: 4px;
        font-weight: 400;
    `,
    gameItem: css`
        padding: 4px;
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
    `,
    transferNotice: css`
        font-size: 14px;
        p {
            &:last-child {
                position: relative;
                padding-left: 12px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    border-radius: 5px;
                    transform: translateY(-50%);
                    background: ${colors.white};
                }
            }
        }
    `,
    tabTransfer: css`
        display: flex;
        gap: 8px;
        margin-top: 24px;
        .tab-item {
            padding: 4px 8px 8px;
            cursor: pointer;
            background: ${colors.blackText};
            border-radius: 4px;
            &.active {
                background: ${colors.primary};
            }
        }
    `,
};
export default fundTransferStyle;
