import ReactDOM from 'react-dom';
import styles from './modal.style';
import { CSSTransition } from 'react-transition-group';

interface Props {
    isShow: boolean;
    children: JSX.Element;
    closeClickBackdrop?: boolean;
    width?: string;
    maxWidth?: string;
    backdropColor?: string;
    onClose: () => void;
}

function Modal(props: Props) {
    const { isShow, children, closeClickBackdrop, onClose, width, backdropColor, maxWidth } = props;

    const handleClickBackDrop = () => {
        if (closeClickBackdrop) {
            onClose();
        }
    };
    return (
        <>
            {ReactDOM.createPortal(
                <CSSTransition mountOnEnter unmountOnExit in={isShow} timeout={{ enter: 300, exit: 200 }} classNames="modal">
                    <div className={styles.modalWrap}>
                        <div className={styles.modalInner}>
                            <div className={styles.modalContent} style={{ width, maxWidth }}>
                                {children}
                            </div>
                            <div className={styles.outsideArea} onClick={handleClickBackDrop} />
                        </div>
                    </div>
                </CSSTransition>,
                document.body
            )}
            {ReactDOM.createPortal(isShow ? <div style={{ background: backdropColor }} className={styles.backdrop}></div> : <></>, document.body)}
        </>
    );
}

export default Modal;
