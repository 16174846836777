import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { useNavigate } from 'react-router-dom';

function RuleApp() {
    const navigate = useNavigate();
    return (
        <div className={styles.about}>
            <div className={styles.aboutItem} onClick={() => navigate('/help?view=1')}>
                Quy Định Luật Lệ
            </div>
            <div className={styles.aboutItem} onClick={() => navigate('/help?view=2')}>
                Trách Nhiệm Người Chơi
            </div>
            <div className={styles.aboutItem} onClick={() => navigate('/help?view=3')}>
                Chính Sách Bảo Mật
            </div>
            <div className={styles.aboutItem} onClick={() => navigate('/help?view=4')}>
                Tuyên Bố Bản Quyền
            </div>
            <div className={styles.aboutItem} onClick={() => navigate('/help?view=5')}>
                Hướng Dẫn
            </div>
        </div>
    );
}

const styles = {
    about: css`
        margin-top: 24px;
        display: flex;
        justify-content: center;
        gap: 46px;
        background: #023131;
        border-radius: 8px;
    `,
    aboutItem: css`
        padding: 14px;
        font-weight: 600;
        transition: 0.25s;
        cursor: pointer;
        color: #ffbf61;
        &:hover {
            color: #ff9900;
        }
    `,
};

export default RuleApp;
