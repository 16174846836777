import { BankWithdrawResponse } from 'models/bankModel';
import accountStyles from '../account/account-option/account-option.style';
import { useEffect, useState } from 'react';
import walletApi from 'apis/walletApi';
import toast from 'react-hot-toast';
import globalStyle from 'constants/global.style';
import { NumericFormat } from 'react-number-format';
import TagLabel from 'components/tag-label/TagLabel';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import moment from 'moment';
import userApi from 'apis/userApi';
import { WalletHistoryPayload, WithdrawApplyPayload } from 'models/walletModel';
import { subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { removeSeperateNumber } from 'utils/function';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import useBalance from 'hooks/useBalance';

const wdrStatus = ['Đã gửi', 'Đang xử lý', 'Chờ duyệt', '', 'Thành công', 'Thất bại'];

interface WithdrawInfo {
    wmin: number;
    wmax: number;
    wdhours: number;
    mrtxcs: number;
    wtimes: number;
}

function WithdrawPage() {
    const navigate = useNavigate();
    const [bankList, setBankList] = useState<BankWithdrawResponse[]>([]);
    const [bankIdSelect, setBankIdSelect] = useState<number>(0);
    const [amountWithdraw, setAmountWithdraw] = useState('');
    const [withdrawInfo, setWithdrawInfo] = useState<WithdrawInfo>({ wmin: 50000, wmax: 100000000, wdhours: 6, mrtxcs: 5, wtimes: 0 });
    const [withdrawHistory, setWithdrawHistory] = useState<any[]>([]);
    const [passPay, setPassPay] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAddBank, setShowAddBank] = useState(false);

    const loadBalanceMain = useBalance();

    useEffect(() => {
        const getBankList = async () => {
            try {
                const { data } = await walletApi.withdrawal({});
                if (!data.msg) {
                    const { wmin, wmax, mrtxcs, wdhours, wtimes } = data;
                    setWithdrawInfo({ wmin, wmax, mrtxcs, wdhours, wtimes });
                    setBankList(data.wlist);

                    if (data.wlist.length > 0) {
                        setBankIdSelect(data.wlist[0].id);
                        getHistoryWithdraw();
                    }
                } else {
                    toast.error(data.msg);
                }

                if (data.wlist.length === 0) {
                    setShowAddBank(true);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getBankList();
    }, []);

    const getHistoryWithdraw = async () => {
        const startDate = moment(subDays(new Date(), 30)).format('yyyy-MM-DD');
        let payload: WalletHistoryPayload = {
            stime: `${startDate} 00:00:00`,
            etime: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
        };
        try {
            const { data } = await userApi.withdrawalSearch(payload, 'btnSearch');
            if (!data.msg) {
                setWithdrawHistory(data.reslist.slice(0, 10));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submitWithdraw = async () => {
        if (withdrawInfo.mrtxcs === withdrawInfo.wtimes) {
            toast.error('Đã quá giới hạn rút tiền trong một ngày');
            return;
        }
        if (!amountWithdraw || Number(amountWithdraw) === 0) {
            toast.error('Vui lòng nhập số tiền');
            return;
        }
        if (Number(removeSeperateNumber(amountWithdraw)) < withdrawInfo.wmin) {
            toast.error(`Số tiền rút tối thiểu là ${new Intl.NumberFormat().format(withdrawInfo.wmin)}`);
            return;
        }
        if (Number(removeSeperateNumber(amountWithdraw)) > withdrawInfo.wmax) {
            toast.error(`Số tiền rút tối đa là ${new Intl.NumberFormat().format(withdrawInfo.wmax)}`);
            return;
        }
        if (!passPay) {
            toast.error('Vui lòng nhập mật khẩu thanh toán');
            return;
        }
        setLoading(true);
        const payload: WithdrawApplyPayload = {
            cardid: bankIdSelect,
            checkpwd: passPay,
            wamount: removeSeperateNumber(amountWithdraw),
        };
        try {
            const { data } = await walletApi.withdrawalApply(payload);
            if (!data.msg) {
                const { mrtxcs, wdhours, wtimes } = data;
                const newWithdrawInfo = { ...withdrawInfo, mrtxcs, wdhours, wtimes };
                setWithdrawInfo(newWithdrawInfo);
                toast.success('Gửi yêu cầu rút tiền thành công');
                loadBalanceMain();
                setPassPay('');
                setAmountWithdraw('');

                setTimeout(() => {
                    getHistoryWithdraw();
                }, 1000);
            } else {
                loadBalanceMain();
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Rút Tiền</h6>
                {showAddBank ? (
                    <div>
                        <p>Bạn chưa có thẻ ngân hàng nào liên kết với tài khoản</p>
                        <button type="button" className={globalStyle.btnPrimary} style={{ width: '300px', marginTop: '8px' }} onClick={() => navigate('/account?mode=bank-account')}>
                            Thêm Thẻ Ngân Hàng
                        </button>
                    </div>
                ) : (
                    <>
                        <div className={accountStyles.mw800} style={{ marginBottom: '30px' }}>
                            <div className={accountStyles.flexbox} style={{ alignItems: 'center' }}>
                                <div className={accountStyles.flex50}>
                                    <div className={accountStyles.groupInput}>
                                        <label htmlFor="">Ngân Hàng Nhận Tiền</label>
                                        <div className={accountStyles.wrapInput}>
                                            <select className={globalStyle.inputCommon} value={bankIdSelect} onChange={(e) => setBankIdSelect(Number(e.target.value))}>
                                                {bankList.map((item) => (
                                                    <option value={item.id} key={item.id}>
                                                        {item.bankname} || Số cuối tài khoản {item.bankcard.slice(-4)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={accountStyles.groupInput}>
                                        <label htmlFor="">Số Tiền Rút</label>
                                        <div className={accountStyles.wrapInput}>
                                            <NumericFormat
                                                className={globalStyle.inputCommon}
                                                thousandSeparator=","
                                                placeholder="Nhập số tiền..."
                                                value={amountWithdraw}
                                                onChange={(e) => setAmountWithdraw(e.target.value)}
                                            />
                                        </div>
                                        <TagLabel label={`Tối thiểu ${new Intl.NumberFormat().format(withdrawInfo.wmin)} - Tối đa ${new Intl.NumberFormat().format(withdrawInfo.wmax)}`} />
                                    </div>
                                    <div className={accountStyles.groupInput}>
                                        <label htmlFor="">Mật Khẩu Thanh Toán</label>
                                        <div className={accountStyles.wrapInput}>
                                            <input className={globalStyle.inputCommon} type="password" placeholder="Mật khẩu thanh toán" value={passPay} onChange={(e) => setPassPay(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={accountStyles.flex50}>
                                    <ul className={styles.listGuide}>
                                        {withdrawInfo.wtimes > 0 && (
                                            <li>
                                                Rút tiền 24/7. Mỗi ngày chỉ rút tối đa <span>({withdrawInfo.mrtxcs})</span> lần. Bạn đã yêu cầu thành công <span>({withdrawInfo.wtimes}) </span> lần
                                                trong ngày hôm nay.
                                            </li>
                                        )}
                                        {withdrawInfo.wtimes === 0 && (
                                            <li>
                                                Rút tiền 24/7. Mỗi ngày chỉ rút tối đa <span>({withdrawInfo.mrtxcs})</span> lần. Bạn chưa có yêu cầu rút tiền nào trong ngày hôm nay.
                                            </li>
                                        )}
                                        <li>
                                            Tài khoản ngân hàng mới cần thêm <span>{withdrawInfo.wdhours}</span> tiếng trở lên mới có thể rút tiền.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div style={{ width: 'calc(50% - 25px)', marginTop: '16px' }}>
                                <button type="button" className={globalStyle.btnPrimary} onClick={submitWithdraw}>
                                    Gửi Lệnh
                                </button>
                            </div>
                        </div>

                        {/* history withdraw table */}
                        <div className="table-common table-col-center">
                            <div className="row">
                                <p className="title col">STT</p>
                                <p className="title col">Mã Số</p>
                                <p className="title col">Giá Trị</p>
                                <p className="title col">Thời Gian</p>
                                <p className="title col">Trạng Thái</p>
                                <p className="title col">Ghi Chú</p>
                            </div>
                            {withdrawHistory.length > 0 ? (
                                withdrawHistory.map((item, index) => (
                                    <div className="row" key={item.id}>
                                        <p className="col">{index + 1}</p>
                                        <p className="col">{item.id}</p>
                                        <p className="col">{new Intl.NumberFormat().format(item.amount)}</p>
                                        <p className="col">{moment(item.applytime).format('DD/MM/yyyy HH:mm')}</p>
                                        <p className={classNames('col', { 'text-error': [0, 1, 2, 5].includes(item.status) }, { 'text-success': item.status === 4 })}>{wdrStatus[item.status]}</p>
                                        <p className="col">{item.remark}</p>
                                    </div>
                                ))
                            ) : (
                                <div className="no-data">
                                    <img src={require('assets/images/icons/no-data.png')} alt="" />
                                    <p>Không có dữ liệu</p>
                                </div>
                            )}
                        </div>
                        <p className={styles.noticeHistory}>
                            Danh sách hiển thị 10 lệnh rút trong 30 ngày gần nhất. Xem tất cả tại <span onClick={() => navigate('/account?mode=wl-depo-his&type=1')}>Lịch sử rút tiền</span>
                        </p>
                    </>
                )}
            </div>

            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    listGuide: css`
        padding-left: 40px;
        li {
            margin-bottom: 8px;
        }
        span {
            color: ${colors.yellow};
            font-weight: 500;
        }
    `,
    noticeHistory: css`
        text-align: center;
        margin-top: 8px;
        font-size: 14px;
        span {
            color: ${colors.yellow};
            text-decoration: underline;
            cursor: pointer;
        }
    `,
};

export default WithdrawPage;
