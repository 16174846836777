import authApi from 'apis/authApi';
import { useAppDispatch } from 'cache/store';
import { cacheUserInfo } from 'features/authSlice';

export default function useBalance() {
    const dispatch = useAppDispatch();

    const loadBalance = async () => {
        try {
            const { data } = await authApi.getUserInfo();
            if (!data.errorCode) {
                dispatch(cacheUserInfo(data));
            }
        } catch (error) {
            console.log(error);
        }
    };

    return loadBalance;
}
