import { css } from '@emotion/css';
import classNames from 'classnames';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { LotterySub, RandomPickOption, RandomPickOptionTypeIn, TypeInputLottery } from 'models/lotteryModel';
import { useEffect, useRef, useState } from 'react';
import { checkValidTypeNum } from '../lotteryFunc';
import LoadingIcon from 'components/loading/LoadingIcon';

let intervalId: any;

interface Props {
    typeInInput: TypeInputLottery;
    onChangeTypeIn: (pickData: TypeInputLottery, amount: number, isValid: boolean) => void;
    subTypeBet: LotterySub;
    onResetBet: () => void;
}
function TypeInLotteryInput(props: Props) {
    const { typeInInput, onChangeTypeIn, subTypeBet, onResetBet } = props;

    const areaInputNumRef = useRef<HTMLTextAreaElement>(null);
    const [selectedRandom, setSelectedRandom] = useState(0);
    const [numInputValue, setNumInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (typeInInput.layout[0].selectedNums.length === 0) {
            setNumInputValue('');
            setSelectedRandom(0);
        }
    }, [typeInInput]);

    const randomQuickNum = (layout: RandomPickOption) => {
        setSelectedRandom(layout.randomAmount);

        if (typeInInput?.randomPickLayout?.typeInOptConfig) {
            generateNum(layout, typeInInput.randomPickLayout.typeInOptConfig);
        }
    };

    const changeInputNum = (value: string) => {
        const { isValid, slNums, amount } = checkValidTypeNum(value, subTypeBet.isXienTruot, subTypeBet.digits);
        const newTypeInInput = {
            ...typeInInput,
            layout: [{ ...typeInInput.layout[0], selectedNums: [slNums] }],
        };
        setNumInputValue(slNums);
        onChangeTypeIn(newTypeInInput, amount, isValid);
    };

    const handleResetBet = () => {
        setNumInputValue('');
        setSelectedRandom(0);
        if (areaInputNumRef.current) {
            areaInputNumRef.current.value = '';
        }
        onResetBet();
    };

    const generateNum = (layout: RandomPickOption, config: RandomPickOptionTypeIn) => {
        setLoading(true);
        const randomWholeNumberLength = config.randomFormat.length;
        let randomResults: string[] = [];
        let randomResultsCheckExisted: string[] = [];
        let randomSingleNums: number[] = [];
        let countAsOneGroupNumbers: string[] = [];

        intervalId = setInterval(() => {
            if (randomResults.length < layout.randomAmount) {
                const singleNum = Math.floor(Math.random() * 9) + 1;
                randomSingleNums.push(singleNum);
                if (randomSingleNums.length === randomWholeNumberLength) {
                    let wholeNumber = randomSingleNums.join('');
                    if (countAsOneGroupNumbers.indexOf(wholeNumber) < 0) {
                        randomSingleNums = [];
                        countAsOneGroupNumbers.push(wholeNumber);
                    } else {
                        randomSingleNums = [];
                    }
                }

                if (countAsOneGroupNumbers.length === layout.countNumAsOne) {
                    let countAsOneGroupNumbersStr = countAsOneGroupNumbers.join();
                    //kiểm tra số random đã tồn tại
                    if (randomResultsCheckExisted.includes(countAsOneGroupNumbersStr)) {
                        countAsOneGroupNumbers = [];
                    } else {
                        randomResultsCheckExisted.push(countAsOneGroupNumbersStr);
                        let finalOne = countAsOneGroupNumbers.join(config.combineAsOneOperator ? config.combineAsOneOperator : '');
                        if (finalOne) {
                            randomResults.push(finalOne);
                            countAsOneGroupNumbers = [];
                        }
                        if (randomResults.length === layout.randomAmount) {
                            changeInputNum(randomResults.join(config.separateOperator));
                        }
                    }
                }
            } else {
                intervalId && clearInterval(intervalId);
                setLoading(false);
            }
        }, 1);
    };

    const openFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const reader = new FileReader();
            const file = event.target.files[0];
            reader.readAsText(file, 'UTF-8');

            reader.onload = (fre: any) => {
                const resultNums = fre.target.result.replace(/\r/g, '').replace(/\n/g, '');
                changeInputNum(resultNums);
                event.target.value = '';
            };
        }
    };

    return (
        <>
            <div className={styles.wrap}>
                <div className={styles.quickTabRandom}>
                    <div className={styles.quickTabList}>
                        <p className={styles.quickTabTitle}>Ngẫu nhiên</p>
                        {typeInInput.randomPickLayout &&
                            typeInInput.randomPickLayout.layout.map((item) => (
                                <button
                                    type="button"
                                    className={classNames(styles.quickTabItem, { active: item.randomAmount === selectedRandom })}
                                    key={item.randomAmount}
                                    onClick={() => randomQuickNum(item)}
                                >
                                    {item.title}
                                </button>
                            ))}
                    </div>

                    <button type="button" className={styles.btnClearRandom} onClick={handleResetBet}>
                        Xoá
                    </button>
                </div>
                <div className={styles.typeinWrap}>
                    <textarea
                        className={styles.typeinArea}
                        rows={8}
                        placeholder={typeInInput.layout[0].title}
                        value={numInputValue}
                        onChange={(e) => changeInputNum(e.target.value)}
                        ref={areaInputNumRef}
                    />
                    <div className={styles.typeinAction}>
                        <input type="file" autoComplete="off" id="typeInputFile" style={{ display: 'none' }} accept="text/plain" onChange={(e) => openFile(e)} />
                        <label htmlFor="typeInputFile" className={` ${styles.btnUploadFile} ${globalStyle.btnPrimary}`}>
                            <span>Tải tập tin</span>
                            <span>(.txt, .csv)</span>
                        </label>
                        <button type="button" className={globalStyle.btnPrimaryOutline} onClick={handleResetBet}>
                            Huỷ
                        </button>
                    </div>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    wrap: css`
        padding-top: 16px;
    `,
    quickTabRandom: css`
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #bce1e1;
    `,
    quickTabTitle: css`
        padding: 12px;
    `,
    quickTabList: css`
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        gap: 12px;
        padding-right: 6px;
    `,
    quickTabItem: css`
        padding: 6px 8px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #bce1e1;
        &.active {
            background: ${colors.primary};
            color: ${colors.white};
        }
    `,

    btnClearRandom: css`
        border: 1px solid ${colors.red};
        padding: 6px 8px;

        border-radius: 8px;
        margin-left: 12px;
        color: ${colors.red};
        cursor: pointer;
        font-size: 14px;
        &:active {
            background: ${colors.red};
            color: ${colors.white};
        }
    `,
    btnUploadFile: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;
        font-size: 16px;
        padding: 8px 0 !important;
        width: 100%;
    `,
    typeinWrap: css`
        display: flex;
        gap: 16px;
    `,
    typeinArea: css`
        border-radius: 8px;
        padding: 8px 16px;
        border: 1px solid #bce1e1;
        resize: none;
        flex: 1;
        font-size: 14px;
        &::placeholder {
            color: #90949c;
        }
        &:focus {
            outline: none;
        }
    `,
    typeinAction: css`
        flex: 0 0 200px;
    `,
};

export default TypeInLotteryInput;
