import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { useEffect, useState } from 'react';

let idInterval: any;

export default function LiveLabel() {
    const [toogleLive, setToogleLive] = useState(false);
    useEffect(() => {
        idInterval = setInterval(() => {
            setToogleLive((value) => !value);
        }, 600);

        return () => {
            idInterval && clearInterval(idInterval);
        };
    }, []);
    return (
        <div className={`${styles.liveLabel} ${toogleLive ? 'active' : ''}`}>
            <div className="dot-live"></div>
            <p>LIVE</p>
        </div>
    );
}

const styles = {
    liveLabel: css`
        display: flex;
        align-items: center;
        gap: 6px;
        background: #ff1a0b;
        padding: 2px 6px;

        border-radius: 8px;
        border: 2px solid #ff1a0b;
        .dot-live {
            width: 12px;
            height: 12px;

            border-radius: 12px;
            background: ${colors.white};
        }
        p {
            font-size: 22px;
            line-height: 22px;
            color: ${colors.white};
        }
        &.active {
            background: ${colors.white};
            p {
                color: #ff1a0b;
            }
            .dot-live {
                background: #ff1a0b;
            }
        }
    `,
};
