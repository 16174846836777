import { optionAccountMenu } from 'constants/optionAccount';
import styles from './account-header.style';
import { PathTypeAccountInfo } from 'models/commonModel';
import { useNavigate } from 'react-router-dom';
interface Props {
    show: boolean;
    onHide: () => void;
}

// ẩn chức năng ví tiền
const accountMenu = optionAccountMenu.filter((group) => group.id !== 'wallet');

function AccountHeader(props: Props) {
    const { show, onHide } = props;
    const navigate = useNavigate();

    const selectAccountInfo = (path: PathTypeAccountInfo) => {
        onHide();
        navigate(`/account?mode=${path}`);
    };

    return (
        <div className={styles.groupAction} style={{ height: show ? '250px' : 0 }}>
            <div className={styles.groupActionInner}>
                {accountMenu.map((col) => (
                    <div className={styles.groupActionCol} key={col.id}>
                        <h6 style={{ opacity: col.id === 'notify' ? '0' : '1' }}>{col.title}</h6>
                        <div className={styles.groupActionList}>
                            {col.group.map((item, index) => (
                                <div className={styles.accountActionItem} key={index} onClick={() => selectAccountInfo(item.path)}>
                                    <img src={item.icon} alt="" />
                                    <p>{item.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AccountHeader;
