import { css } from '@emotion/css';
import { colors } from 'constants/theme';

interface Props {
    label: string;
}
function TagLabel(props: Props) {
    const { label } = props;
    return (
        <div className={styles.wrap}>
            <p className={styles.label}>{label}</p>
        </div>
    );
}

const styles = {
    wrap: css`
        background: ${colors.bgTitle};
        border-radius: 8px;
        padding: 8px;
    `,
    label: css`
        font-size: 12px;
        color: ${colors.primary};
    `,
};

export default TagLabel;
