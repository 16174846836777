import Header from 'components/header/Header';
import styles from './layout.style';
import Footer from 'components/footer/Footer';
import { useEffect } from 'react';
import commonApi from 'apis/commonApi';
import { MenuHeaderItem } from 'models/commonModel';
import { useAppDispatch } from 'cache/store';
import { cacheMenuGame } from 'features/msgSystemSlice';

interface Props {
    children: JSX.Element;
}

const menuInit: MenuHeaderItem[] = [
    {
        name: 'Trang Chủ',
        child: null,
        isMain: true,
        path: '/',
    },
];

function MainLayout(props: Props) {
    const { children } = props;
    const dispatch = useAppDispatch();
    useEffect(() => {
        const initMenuGame = async () => {
            try {
                const { data } = await commonApi.initMenuHeader({});
                if (!data.msg) {
                    const newMenuGame = [
                        ...menuInit,
                        ...data.menuList,
                        {
                            name: 'Khuyến Mãi',
                            child: null,
                            isMain: true,
                            path: '/promotion',
                        },
                    ];
                    dispatch(cacheMenuGame(newMenuGame));
                }
            } catch (error) {
                console.log(error);
            }
        };
        initMenuGame();
    }, []);
    return (
        <div className={styles.wrap}>
            <Header />

            {/* route */}
            <div className={styles.body}>{children}</div>
            <Footer />
        </div>
    );
}

export default MainLayout;
