import { SubmitHandler, useForm } from 'react-hook-form';
import styles from '../account-option.style';
import globalStyle from 'constants/global.style';
import { useState } from 'react';
import userApi from 'apis/userApi';
import toast from 'react-hot-toast';
import LoadingIcon from 'components/loading/LoadingIcon';
import { msgAlertInput } from 'constants/messageAlert';
interface FormValuesAccount {
    oldpwd: string;
    newpwd: string;
    newpwd2: string;
    oldcheckpwd: string;
    newcheckpwd: string;
    newcheckpwd2: string;
}
interface PassUpdate {
    order: number;
    value: string;
    key: string;
    valid: boolean;
}

const validateList = {
    maxLength: {
        value: 30,
        message: msgAlertInput.generic.maxLength(30),
    },
    minLength: {
        value: 6,
        message: msgAlertInput.generic.minLength(6),
    },
};

export default function PersonPWTab() {
    const [showOldPwd, setShowOldPwd] = useState(false);
    const [showNewpwd, setShowNewpwd] = useState(false);
    const [showNewpwd2, setShowNewpwd2] = useState(false);
    const [showOldpaypwd, setShowOldpaypwd] = useState(false);
    const [showNewpaypwd, setShowNewpaypwd] = useState(false);
    const [showNewpaypwd2, setShowNewpaypwd2] = useState(false);

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        setValue,
        formState: { errors },
    } = useForm<FormValuesAccount>();

    const onEditAccount: SubmitHandler<FormValuesAccount> = (values, e) => {
        const { newcheckpwd, newcheckpwd2, newpwd, newpwd2, oldcheckpwd, oldpwd } = values;
        const passlogin = [
            { order: 1, value: oldpwd, key: 'oldpwd', valid: false },
            { order: 2, value: newpwd, key: 'newpwd', valid: false },
            { order: 3, value: newpwd2, key: 'newpwd2', valid: false },
        ];
        const passPayment = [
            { order: 1, value: oldcheckpwd, key: 'oldcheckpwd', valid: false },
            { order: 2, value: newcheckpwd, key: 'newcheckpwd', valid: false },
            { order: 3, value: newcheckpwd2, key: 'newcheckpwd2', valid: false },
        ];

        if (e?.target.id === 'updatePass') {
            submitUpdatePass(passlogin, 'PersonPwdBean_loginPWD');
        }
        if (e?.target.id === 'updatePassPay') {
            submitUpdatePass(passPayment, 'PersonPwdBean_checkPWD');
        }
    };

    const submitUpdatePass = async (listPass: PassUpdate[], typePass: 'PersonPwdBean_loginPWD' | 'PersonPwdBean_checkPWD') => {
        const isValidPass = checkValidPass(listPass);
        if (!isValidPass) return;

        setLoading(true);
        let passObj: { [key: string]: string } = {};
        listPass.forEach((passItem) => {
            passObj = { ...passObj, [passItem.key]: passItem.value };
        });
        const payload = {
            ...passObj,
            flag: typePass,
        };
        try {
            const { data } = await userApi.editPassWord(payload);
            if (!data.msg) {
                let msgAlert = 'Cập nhật mật khẩu thành công!';
                if (typePass === 'PersonPwdBean_checkPWD') {
                    msgAlert = 'Cập nhật mật khẩu thanh toán thành công!';
                }
                toast.success(msgAlert);
                //reset input
                listPass.forEach((passItem: any) => {
                    setValue(passItem.key, '');
                });
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const checkValidPass = (listPass: PassUpdate[]) => {
        listPass = listPass.map((item) => {
            if (!item.value) {
                setError(item.key as any, {
                    message: msgAlertInput.generic.require,
                });
                return { ...item, valid: false };
            } else if (item.value.trim().length < 6) {
                setError(item.key as any, {
                    message: msgAlertInput.generic.minLength(6),
                });
                return { ...item, valid: false };
            } else if (item.value.trim().length > 30) {
                setError(item.key as any, {
                    message: msgAlertInput.generic.maxLength(30),
                });
                return { ...item, valid: false };
            }
            return { ...item, valid: true };
        });

        const isValidValue = listPass.every((item) => item.valid);
        if (!isValidValue) {
            return false;
        }

        const isMatchNewPass = listPass[1].value === listPass[2].value;
        if (!isMatchNewPass) {
            setError(listPass[2].key as any, {
                message: msgAlertInput.prepassword.invalid,
            });
            return false;
        }

        return true;
    };
    return (
        <>
            <div className={`${styles.flexbox} ${styles.groupPass}`}>
                <div className={`${styles.groupInput} ${styles.flex33}`}>
                    <label htmlFor="">Mật Khẩu Đăng Nhập</label>
                    <div className={styles.wrapInput}>
                        <input {...register('oldpwd')} className={`input-common`} type={showOldPwd ? 'tex' : 'password'} placeholder="Mật khẩu cũ" />
                        <div className={globalStyle.togglePass} onClick={() => setShowOldPwd(!showOldPwd)}>
                            {showOldPwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    </div>
                    {/* base error  */}
                    <div className={globalStyle.errorInput}>{errors['oldpwd'] && <small>{errors['oldpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
                <div className={`${styles.groupInput} ${styles.flex33}`}>
                    <div className={styles.wrapInput}>
                        <input {...register('newpwd')} className={`input-common`} type={showNewpwd ? 'tex' : 'password'} placeholder="Mật khẩu mới" />
                        <div className={globalStyle.togglePass} onClick={() => setShowNewpwd(!showNewpwd)}>
                            {showNewpwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    </div>
                    {/* base error  */}
                    <div className={globalStyle.errorInput}>{errors['newpwd'] && <small>{errors['newpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
                <div className={`${styles.groupInput} ${styles.flex33}`}>
                    <div className={styles.wrapInput}>
                        <input {...register('newpwd2')} className={`input-common`} type={showNewpwd2 ? 'tex' : 'password'} placeholder="Nhập lại mật khẩu mới" />
                        <div className={globalStyle.togglePass} onClick={() => setShowNewpwd2(!showNewpwd2)}>
                            {showNewpwd2 ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    </div>
                    {/* base error  */}
                    <div className={globalStyle.errorInput}>{errors['newpwd2'] && <small>{errors['newpwd2'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
            </div>
            <div style={{ width: '250px', margin: '0 auto', padding: '16px 0' }}>
                <button type="button" id="updatePass" className={`btn-secondary ${styles.btnSubmit}`} onClick={handleSubmit((data, e) => onEditAccount(data, e))}>
                    Cập Nhật
                </button>
            </div>
            <div className={`${styles.flexbox} ${styles.groupPass}`}>
                <div className={`${styles.groupInput} ${styles.flex33}`}>
                    <label htmlFor="">Mật Khẩu Thanh Toán</label>
                    <div className={styles.wrapInput}>
                        <input {...register('oldcheckpwd')} className={`input-common`} type={showOldpaypwd ? 'tex' : 'password'} placeholder="Mật khẩu cũ" />
                        <div className={globalStyle.togglePass} onClick={() => setShowOldpaypwd(!showOldpaypwd)}>
                            {showOldpaypwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    </div>
                    {/* base error  */}
                    <div className={globalStyle.errorInput}>{errors['oldcheckpwd'] && <small>{errors['oldcheckpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
                <div className={`${styles.groupInput} ${styles.flex33}`}>
                    <div className={styles.wrapInput}>
                        <input {...register('newcheckpwd')} className={`input-common`} type={showNewpaypwd ? 'tex' : 'password'} placeholder="Mật khẩu mới" />
                        <div className={globalStyle.togglePass} onClick={() => setShowNewpaypwd(!showNewpaypwd)}>
                            {showNewpaypwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    </div>
                    {/* base error  */}
                    <div className={globalStyle.errorInput}>{errors['newcheckpwd'] && <small>{errors['newcheckpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
                <div className={`${styles.groupInput} ${styles.flex33}`}>
                    <div className={styles.wrapInput}>
                        <input {...register('newcheckpwd2')} className={`input-common`} type={showNewpaypwd2 ? 'tex' : 'password'} placeholder="Nhập lại mật khẩu mới" />
                        <div className={globalStyle.togglePass} onClick={() => setShowNewpaypwd2(!showNewpaypwd2)}>
                            {showNewpaypwd2 ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    </div>
                    {/* base error  */}
                    <div className={globalStyle.errorInput}>{errors['newcheckpwd2'] && <small>{errors['newcheckpwd2'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
            </div>
            <div style={{ width: '250px', margin: '0 auto', padding: '16px 0' }}>
                <button type="button" id="updatePassPay" className={`btn-secondary ${styles.btnSubmit}`} onClick={handleSubmit((data, e) => onEditAccount(data, e))}>
                    Cập Nhật
                </button>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}
