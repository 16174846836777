import globalStyle from 'constants/global.style';
import styles from './intro-app.style';
import { appName } from 'config/app.config';
function IntroApp() {
    return (
        <section className={styles.intro}>
            <div className={globalStyle.container}>
                <h4 className={styles.introTitle}>{appName.toUpperCase()} - Nhà cái uy tín hàng đầu châu Á</h4>
                <p className={styles.introDesc}>
                    {appName.toUpperCase()} là website cá cược trực tuyến hàng đầu Châu Á, cung cấp nhiều loại hình cá cược trực tuyến như: Thể thao, Sòng bài , Numbergame, Keno, Trò chơi... Chúng tôi
                    có trụ sở chính tại Philippines và được cấp giấy phép hoạt động hợp pháp bởi tổ chức CEZA & First Cagayan (http://www.firstcagayan.com), Ủy ban Giám sát Cờ bạc CEZA & First Cagayan
                    có tiêu chuẩn cao và cũng được biết đến với một hệ thống quản lý mạnh mẽ. Phương châm kinh doanh của {appName.toUpperCase()} là cung cấp các dịch vụ và sản phẩm cá cược Thể Thao
                    Giải Trí trực tuyến tốt nhất, không ngừng nâng cao nhằm đa dạng hóa sản phẩm làm hài lòng khách hàng, lợi ích của Khách hàng luôn được chúng tôi đặt lên hàng đầu.
                </p>
            </div>
        </section>
    );
}

export default IntroApp;
