import DatePicker from 'components/date-picker/DatePicker';
import accountStyles from './account-option.style';
import moment from 'moment';
import { useState } from 'react';
import { subDays } from 'date-fns';
import { RecordDepositHis, RecordWithdrawHis, WalletHistoryPayload } from 'models/walletModel';
import userApi from 'apis/userApi';
import toast from 'react-hot-toast';
import globalStyle from 'constants/global.style';
import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import classNames from 'classnames';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import { InfoPaginate } from 'models/commonModel';
import Pagination from 'components/pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import { scrollToTopPage } from 'utils/function';

const actionList: { label: string; value: '0' | '1' }[] = [
    {
        label: 'Nạp tiền',
        value: '0',
    },
    {
        label: 'Rút tiền',
        value: '1',
    },
];

const stateDeposit: any = {
    '-1': 'Đang chờ nôp tiền',
    '0': 'Đang xử lý',
    '1': 'Đang chờ xử lý',
    '4': 'Thành công',
    '5': 'Từ chối',
    '-3': 'Quá hạn',
    '-2': 'Đang chờ nạp tiền',
    '-5': 'Hủy',
};

const typeDeposit: any = {
    '1': 'Chuyển khoản ngân hàng',
    '2': 'Online ATM NganLuong',
    '3': 'eWallet NganLuong',
    '4': 'Online ATM Help2Pay',
    '5': 'Cardpay NganLuong',
};

const wdrStatus = ['Đã gửi', 'Đang xử lý', 'Chờ duyệt', '', 'Thành công', 'Thất bại'];

function WalletDepositHistory() {
    const [searchParams] = useSearchParams();
    const type: any = searchParams.get('type');

    const [startTime, setStartTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')} 00:00:00`));
    const [endTime, setEndTime] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')} 23:59:59`));
    const [billId, setBillId] = useState('');
    const [actionSelect, setActionSelect] = useState<'0' | '1'>(type ? type : '0'); //0: nạp tiền, 1: rút tiền

    const [recordDespositList, setRecordDepositList] = useState<RecordDepositHis[] | null>(null);
    const [recordWithdrawList, setRecordWithdrawList] = useState<RecordWithdrawHis[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [detailDeposit, setDetailDeposit] = useState<string | null>(null);
    const [infoPage, setInfoPage] = useState<InfoPaginate | null>(null);

    const searchHistoryWallet = (page: number, isResetPaginate: boolean, type: 'btnSearch' | 'inputPage') => {
        let payload: WalletHistoryPayload = {
            stime: startTime ? moment(startTime).format('yyyy-MM-DD HH:mm:ss') : moment(subDays(new Date(), 35)).format('yyyy-MM-DD HH:mm:ss'),
            etime: endTime ? moment(endTime).format('yyyy-MM-DD HH:mm:ss') : moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
            id: billId,
        };
        if (isResetPaginate) {
            setInfoPage(null);
        }
        if (type === 'inputPage') {
            payload = {
                ...payload,
                curpage: infoPage?.curpage,
                num: page,
            };
        }

        if (actionSelect === '0') {
            getHistoryDeposti(payload, type);
        } else {
            getHistoryWithdraw(payload, type);
        }
    };

    const getHistoryDeposti = async (payload: WalletHistoryPayload, type: 'btnSearch' | 'inputPage') => {
        setLoading(true);
        try {
            const { data } = await userApi.depositSearch(payload, type);
            if (!data.msg) {
                setRecordDepositList(data.reslist);
                const { curpage, totalpage, totalrecord } = data;
                setInfoPage({ curpage, totalpage, totalrecord });
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getHistoryWithdraw = async (payload: WalletHistoryPayload, type: 'btnSearch' | 'inputPage') => {
        setLoading(true);
        try {
            const { data } = await userApi.withdrawalSearch(payload, type);
            if (!data.msg) {
                setRecordWithdrawList(data.reslist);
                const { curpage, totalpage, totalrecord } = data;
                setInfoPage({ curpage, totalpage, totalrecord });
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeWalletAction = (value: any) => {
        setActionSelect(value);
        setRecordDepositList(null);
        setRecordWithdrawList(null);
        setBillId('');
        setInfoPage(null);
    };

    const handleChangePage = (page: number) => {
        searchHistoryWallet(page, false, 'inputPage');
        scrollToTopPage();
    };

    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <div className={accountStyles.mw800}>
                    <h6 className={accountStyles.title}>Lịch Sử {actionSelect === '0' ? 'Nạp Tiền' : 'Rút Tiền'}</h6>
                    <div className={accountStyles.flexbox}>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Loại</label>
                            <div className={accountStyles.wrapInput}>
                                <select className="input-common" value={actionSelect} onChange={(e) => handleChangeWalletAction(e.target.value)}>
                                    {actionList.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className={`${accountStyles.groupInput} ${accountStyles.flex50}`}>
                            <label htmlFor="">Mã số {actionSelect === '0' ? 'nạp tiền' : 'rút tiền'}</label>
                            <div className={accountStyles.wrapInput}>
                                <input
                                    value={billId}
                                    onChange={(e) => setBillId(e.target.value)}
                                    className={`input-common`}
                                    type="text"
                                    placeholder={`Mã số ${actionSelect === '0' ? 'nạp tiền' : 'rút tiền'}`}
                                />
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Thời Gian Từ</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker showTime showSecond dateSelect={startTime} onSelectDate={(date) => setStartTime(date)} showIcon minDate={subDays(new Date(), 35)} maxDate={new Date()} />
                            </div>
                        </div>
                        <div className={`${accountStyles.flex50} ${accountStyles.groupInput}`}>
                            <label htmlFor="">Đến</label>
                            <div className={accountStyles.wrapInput}>
                                <DatePicker showTime showSecond dateSelect={endTime} onSelectDate={(date) => setEndTime(date)} showIcon minDate={subDays(new Date(), 35)} maxDate={new Date()} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '8px', width: 'calc(50% - 25px)', marginBottom: '24px' }}>
                        <button type="button" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={() => searchHistoryWallet(1, true, 'btnSearch')}>
                            Tìm Kiếm
                        </button>
                    </div>
                </div>
                <p className={styles.notice}>
                    <span>Lưu ý: </span> Chỉ hiển thị lịch sử giao dịch trong 35 ngày gần nhất để đảm bảo thông tin của Quý khách.
                </p>
                {actionSelect === '0' && (
                    <div className="table-common">
                        <div className="row">
                            <div className="title col">STT</div>
                            <div className={`title col ${globalStyle.flexWidth(200)}`}>Loại</div>
                            <div className={`title col ${globalStyle.flexWidth(220)}`}>Mã số</div>
                            <div className="title col">Giá trị</div>
                            <div className="title col">Thời gian</div>
                            <div className="title col" style={{ flex: '0 0 100px' }}>
                                Ghi chú
                            </div>
                            <div className="title col">Trạng thái</div>
                        </div>
                        {recordDespositList ? (
                            <>
                                {recordDespositList.length > 0 ? (
                                    recordDespositList.map((item, index) => (
                                        <div className="row" key={item.id}>
                                            <p className="col">{index + 1}</p>
                                            <p className={`col ${globalStyle.flexWidth(200)}`}>{typeDeposit[item.type]}</p>
                                            <p className={`col ${globalStyle.flexWidth(220)}`}>{item.id}</p>
                                            <p className="col">{new Intl.NumberFormat().format(item.amount)}</p>
                                            <p className="col">{moment(item.savetime).format('DD/MM/yyyy, HH:mm')}</p>
                                            <p className="col" style={{ flex: '0 0 100px' }}>
                                                <span className={styles.btnDetail} title="Xem chi tiết" onClick={() => setDetailDeposit(item.remark)}>
                                                    Xem
                                                </span>
                                            </p>
                                            <p className={classNames('col', { 'text-error': [1, 2, 3].includes(item.status) }, { 'text-success': item.status === 4 })}>{stateDeposit[item.status]}</p>
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-data">
                                        <img src={require('assets/images/icons/no-data.png')} alt="" />
                                        <p>Không có dữ liệu</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="no-data"></div>
                        )}
                    </div>
                )}
                {actionSelect === '1' && (
                    <div className="table-common table-col-center">
                        <div className="row">
                            <div className="title col" style={{ flex: '0 0 100px' }}>
                                STT
                            </div>
                            <div className={`title col ${globalStyle.flexWidth(220)}`}>Mã số</div>
                            <div className="title col">Giá trị</div>
                            <div className="title col">Thời gian</div>

                            <div className="title col">Trạng thái</div>
                            <div className="title col">Ghi chú</div>
                        </div>
                        {recordWithdrawList ? (
                            <>
                                {recordWithdrawList.length > 0 ? (
                                    recordWithdrawList.map((item, index) => (
                                        <div className="row" key={item.id}>
                                            <p className="col" style={{ flex: '0 0 100px' }}>
                                                {index + 1}
                                            </p>
                                            <p className={`col ${globalStyle.flexWidth(220)}`}>{item.id}</p>
                                            <p className="col">{new Intl.NumberFormat().format(item.amount)}</p>
                                            <p className="col">{moment(item.applytime).format('DD/MM/yyyy, HH:mm')}</p>

                                            <p className={classNames('col', { 'text-error': [0, 1, 2, 5].includes(item.status) }, { 'text-success': item.status === 4 })}>{wdrStatus[item.status]}</p>
                                            <p className="col" dangerouslySetInnerHTML={{ __html: item.remark }}></p>
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-data">
                                        <img src={require('assets/images/icons/no-data.png')} alt="" />
                                        <p>Không có dữ liệu</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="no-data"></div>
                        )}
                    </div>
                )}
                {infoPage && infoPage.totalpage > 1 && (
                    <div className="pagination-common">
                        <Pagination infoPage={infoPage} onChangePage={handleChangePage} showTotal />
                    </div>
                )}
            </div>
            <Modal isShow={Boolean(detailDeposit)} onClose={() => setDetailDeposit(null)} width="400px">
                <div className="bg-popup-common">
                    <p>{detailDeposit}</p>
                    <div className="group-action">
                        <button type="button" className="btn-primary" onClick={() => setDetailDeposit(null)}>
                            Đóng
                        </button>
                    </div>
                </div>
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    btnDetail: css`
        color: ${colors.blue};
        font-weight: 500;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    `,
    notice: css`
        font-style: italic;
        font-size: 14px;
        span {
            color: ${colors.red};
        }
        margin-bottom: 8px;
    `,
};

export default WalletDepositHistory;
