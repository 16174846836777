import { css } from '@emotion/css';

const modalStyles = {
    modalWrap: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 16;
    `,
    backdrop: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        z-index: 8;
    `,
    modalInner: css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    modalContent: css`
        width: 50%;

        overflow: hidden;
        position: relative;
        z-index: 1;
    `,
    outsideArea: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    `,
};
export default modalStyles;
