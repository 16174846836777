import { css } from '@emotion/css';
import classNames from 'classnames';
import Pagination from 'components/pagination/Pagination';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { InfoPaginate } from 'models/commonModel';
import { LotteryRecord } from 'models/lotteryModel';
import moment from 'moment';
import { HiOutlineRefresh } from 'react-icons/hi';
import { formatCodeString } from 'utils/function';
const bgTitleLinear = `linear-gradient(269deg, rgba(67, 47, 92, 0.66) 23.94%, rgba(21, 41, 111, 0.61) 83.16%), ${colors.bgTitle}`;

interface Props {
    recordList: LotteryRecord[];
    infoPage: InfoPaginate | null;
    onChangePage: (page: number) => void;
    onShowDetailRecord: (recordId: string) => void;
}
function LotteryHistoryTable(props: Props) {
    const { recordList, infoPage, onChangePage, onShowDetailRecord } = props;

    const handleChangePage = (page: number) => {
        onChangePage(page);
    };

    const refreshPage = () => {
        if (infoPage) {
            onChangePage(infoPage.curpage);
        } else {
            onChangePage(1);
        }
    };

    return (
        <section className={styles.wrap}>
            <div className={styles.heading}>
                <h4>Lịch sử cược</h4>
                <button type="button" className={styles.btnClearAll} onClick={refreshPage}>
                    <HiOutlineRefresh size={20} />
                </button>
            </div>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.title}`}>Lượt xổ</div>
                    <div className={`${styles.col} ${styles.title}`}>Xổ số</div>
                    <div className={`${styles.col} ${styles.title}`}>Kiểu cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Thời gian cược</div>
                    <div className={`${styles.col} ${styles.title} ${styles.codesBet}`}>Số cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Tiền cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Tình trạng</div>
                </div>
                {recordList.length > 0 ? (
                    recordList.map((item, index) => (
                        <div className={styles.row} key={index} onClick={() => onShowDetailRecord(item.id)}>
                            <p className={`${styles.col}`}>{item.issue}</p>
                            <p className={`${styles.col} ${globalStyle.text.blue}`}>{item.lotteryName}</p>
                            <p className={`${styles.col}`}>{item.lotteryMethod}</p>
                            <p className={`${styles.col}`}>{moment(item.bettime).format('DD/MM/yyyy HH:mm:ss')}</p>
                            <p className={`${styles.col} ${styles.codesBet}`}>
                                <span>{formatCodeString(item.codes)}</span>
                            </p>
                            <p className={styles.col}> {new Intl.NumberFormat().format(item.amount)}</p>
                            <p className={classNames(styles.col, item.status === 'Trúng' && globalStyle.text.success, item.status === 'Trượt' && globalStyle.text.error)}>{item.status}</p>
                        </div>
                    ))
                ) : (
                    <div className={styles.noData}>
                        {/* <img src={require('assets/images/icons/no-data.png')} alt="" /> */}
                        <p>Không có dữ liệu</p>
                    </div>
                )}
            </div>
            {infoPage && infoPage.totalpage > 1 && (
                <div className="pagination-common">
                    <Pagination isLightThem infoPage={infoPage} onChangePage={handleChangePage} showTotal />
                </div>
            )}
        </section>
    );
}

const styles = {
    wrap: css`
        padding-top: 16px;
    `,
    heading: css`
        background: ${colors.darkSecondary};

        color: ${colors.white};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 16px;
        border-radius: 8px;
        margin-bottom: 16px;
    `,
    ticketLabel: css`
        background: ${colors.red};
        border-radius: 40px;
        font-size: 14px;
        padding: 4px 8px;
        display: inline-block;
        margin-left: 6px;
    `,
    btnClearAll: css`
        border: 1px solid ${colors.lightBlue};
        border-radius: 8px;
        padding: 6px;
        font-size: 14px;
        color: ${colors.white};
        display: flex;
    `,
    table: css`
        border: 1px solid ${colors.lightBlue};
        border-radius: 8px;
        overflow: hidden;
        min-height: 150px;
        &.table-col-center {
            .col {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    `,
    codesBet: css`
        max-width: 200px;
        flex: 0 0 200px;
        span {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* max line number */
            -webkit-box-orient: vertical;
        }
    `,
    row: css`
        display: flex;
        border-bottom: 1px solid ${colors.lightBlue};
        cursor: pointer;
        &:first-child {
            border-bottom-color: ${colors.lightBlue};
        }
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background: ${colors.lightBlue};
        }
    `,
    col: css`
        flex: 1;
        padding: 12px 8px;
        text-align: center;
        font-size: 14px;
    `,
    title: css`
        font-weight: 600;
        background: ${colors.lightBlue};
    `,
    noData: css`
        text-align: center;
        padding: 30px;
        p {
            margin-top: 8px;
            font-size: 14px;
        }
    `,
    btnRemoveTicket: css`
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        img {
            height: 16px;
        }
    `,
};

export default LotteryHistoryTable;
