import { css } from '@emotion/css';
import globalStyle, { zoomInZoomOut } from 'constants/global.style';
import { colors } from 'constants/theme';

interface Props {
    validDualList: any[];
    onClose: () => void;
}
function ErrorDualNumLottery(props: Props) {
    const { validDualList, onClose } = props;
    return (
        <div className={styles.wrap}>
            <h3 className={styles.title}>Thông Báo</h3>
            <p className={styles.subTitle}>
                Bạn vượt quá số điểm cho phép đánh <span>mỗi số kép bằng</span>
                <i>(số kép bằng là những số có 2 chữ số cuối giống nhau VD: 00, 11, 22, 155, 066, 1699, 1300...). Chỉ áp dụng cho giải:</i>
            </p>
            <ul className={styles.listRule}>
                <li>
                    Đề Miền Bắc ĐB, Đề Miền Bắc ĐB 18h25 - <span className={globalStyle.text.error}>2000</span> điểm
                </li>
                <li>
                    3 Càng Miền Bắc - <span className={globalStyle.text.error}>200</span> điểm
                </li>
                <li>
                    4 Càng Miền Bắc - <span className={globalStyle.text.error}>20</span> điểm
                </li>
            </ul>
            <div className={styles.listBetData}>
                {validDualList.map((item: any, index: number) => (
                    <div className={styles.betItem} key={index}>
                        <p className={styles.numbet}>
                            Số cược: <span className={!item.isValid ? styles.errorText : ''}>{item.numberBet}</span>
                        </p>
                        <div className={styles.betItemInfo}>
                            <p>
                                Đã cược <span>{item.betted.value}</span>/<span>{item.betted.max}</span>
                            </p>
                            <p>
                                Cược thêm tối đa <span>{item.recentValid}</span> điểm
                                <span></span>
                            </p>
                            <p>
                                Đang cược <span className={globalStyle.text.error}>{item.bettingNum}</span> điểm
                            </p>
                        </div>
                        <p className={styles.issue}>Lượt sổ {item.issue}</p>
                    </div>
                ))}
            </div>
            <div className={styles.actionGroup}>
                <button type="button" className={globalStyle.btnPrimary} onClick={onClose}>
                    Đóng
                </button>
            </div>
        </div>
    );
}

const styles = {
    wrap: css`
        padding: 20px 16px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
        background: #0f1524;
        color: ${colors.white};
    `,
    title: css`
        text-align: center;
        font-size: 22px;
    `,
    subTitle: css`
        font-size: 14px;
        margin: 20px 0;
    `,
    listRule: css`
        padding-left: 24px;
        margin-bottom: 20px;
    `,
    actionGroup: css`
        display: flex;
        gap: 16px;
        width: 40%;
        margin: 0 auto;
        margin-top: 20px;
    `,
    listBetData: css`
        border: 1px dashed #ccc;
        border-radius: 8px;
        overflow: hidden;
    `,
    betItem: css`
        border-bottom: 1px dashed #ccc;
        display: flex;
        align-items: center;
        gap: 24px;
        position: relative;
        &:last-child {
            border-bottom: none;
        }
    `,
    issue: css`
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        padding: 4px;
    `,
    betItemInfo: css`
        padding: 6px 12px;
        p {
            line-height: 1.4;
            font-size: 14px;
        }
    `,
    numbet: css`
        align-self: stretch;
        flex: 0 0 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        span {
            margin-left: 2px;
            font-weight: 500;
            font-size: 18px;
        }
    `,
    errorText: css`
        color: ${colors.red};
        animation: ${zoomInZoomOut} 1s infinite linear;
    `,
};

export default ErrorDualNumLottery;
