import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const loginHeaderStyle = {
    right: css`
        display: flex;
    `,
    formLogin: css`
        display: flex;
        gap: 10px;
    `,
    bgForm: css`
        background: ${colors.bgTitle};
        padding: 2px 4px;
        border-radius: 12px;
    `,
    inputWrap: css`
        display: flex;
        align-items: center;
        background-color: ${colors.white};
        padding: 0 8px;
        border-radius: 8px;
    `,
    input: css`
        border: none;
        background: none;
        margin-left: 8px;
        padding: 8px 0;
        width: 130px;
        height: 32px;
        font-size: 14px;
        &:placeholder-shown {
            font-size: 12px;
            transform: translateY(1px);
        }
    `,

    icon: css`
        height: 16px;
    `,
    remember: css`
        display: flex;
        align-items: center;
        label {
            margin-left: 3px;
            font-size: 12px;
        }
    `,
    forgetPw: css`
        color: ${colors.white};
        font-size: 12px;
        cursor: pointer;
        padding-right: 8px;
        margin-left: 4px;
        &:hover {
            text-decoration: underline;
        }
    `,
    togglePw: css`
        cursor: pointer;
        display: flex;
        height: 100%;
        align-items: center;
        img {
            width: 18px;
        }
    `,
    btnGroup: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 20px;
        button {
            height: 36px;
        }
    `,
};
export default loginHeaderStyle;
