import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { useEffect, useState } from 'react';
import gameListInterface from '../stats-general.interface';
import { StatsGameGroup } from 'models/statisticModel';
import globalStyle from 'constants/global.style';
interface Props {
    statsData: any;
    onClose: () => void;
}
function StatsAgencyDetail(props: Props) {
    const { statsData, onClose } = props;
    const [groupGame, setGroupGame] = useState<StatsGameGroup[]>([]);

    useEffect(() => {
        let newGroupGame = gameListInterface.map((item) => {
            return {
                ...item,
                gameGroup: item.gameGroup.filter((game) => !game.key.includes('total')),
            };
        });
        newGroupGame = newGroupGame.filter((item) => item.id !== 'minigame' && item.id !== 'dangian');
        setGroupGame(newGroupGame);
    }, []);
    return (
        <div className={globalStyle.bgPopup(colors.bgPopupDrak)}>
            <div className={styles.wrapPopup}>
                <h5 style={{ marginBottom: '12px' }}>{statsData?.loginname} (Cược / Thắng)</h5>
                <div className={styles.popupContent}>
                    {groupGame.map((group) => (
                        <div className={styles.groupPopup} key={group.id}>
                            <h6>{group.title}</h6>
                            <div className={styles.groupGame}>
                                {group.gameGroup.map((game) => (
                                    <div className={styles.colGame} key={game.key}>
                                        <p>{game.label}</p>
                                        {!statsData ? (
                                            <p></p>
                                        ) : (
                                            <p className={statsData[game.key] > statsData[`${game.key}win`] ? 'text-error' : 'text-success'}>
                                                {new Intl.NumberFormat().format(statsData[game.key])} / {new Intl.NumberFormat().format(statsData[`${game.key}win`])}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className={`${styles.groupGame} ${styles.groupTotal}`}>
                        <div className={styles.colGame}>
                            <p>Tổng Đặt Cược</p>
                            <p>{statsData ? new Intl.NumberFormat().format(statsData.tz) : ''}</p>
                        </div>
                        <div className={styles.colGame}>
                            <p>Tổng Trúng</p>
                            <p>{statsData ? new Intl.NumberFormat().format(statsData.win) : ''}</p>
                        </div>
                    </div>
                </div>
                <button type="button" className={`btn-primary ${styles.btnClose}`} onClick={onClose}>
                    Đóng
                </button>
            </div>
        </div>
    );
}

const styles = {
    wrapPopup: css`
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    groupPopup: css`
        background: rgba(0, 0, 0, 0.5);
        margin-bottom: 12px;
        padding: 8px;
        border-radius: 8px;
        h6 {
            text-align: center;
            padding-bottom: 8px;
            border-bottom: 1px solid ${colors.primary};
        }
    `,
    popupContent: css`
        flex: 1;
        overflow-y: auto;
        max-height: 70vh;
        overflow-y: auto;
        margin-right: -8px;
    `,
    groupGame: css`
        display: flex;
        flex-wrap: wrap;
    `,
    colGame: css`
        flex: 0 0 50%;
        display: flex;
        border-bottom: 1px solid ${colors.borderTable};
        p {
            padding: 8px;
            font-size: 14px;
            &:first-child {
                flex: 0 0 50%;
            }
            &:last-child {
                flex: 0 0 50%;
            }
        }
    `,
    btnClose: css`
        padding: 8px 0;
        border-radius: 8px;
        width: 300px !important;
        margin: 0 auto;
        margin-top: 12px;
    `,
    groupTotal: css`
        background: rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        > div {
            border-bottom: none;
        }
        p {
            font-size: 16px;
            &:first-child {
                color: ${colors.yellow};
            }
        }
    `,
};

export default StatsAgencyDetail;
