import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './pagination.style';
import { InfoPaginate } from 'models/commonModel';

interface Props {
    infoPage: InfoPaginate | null;
    onChangePage: (page: number) => void;
    showTotal?: boolean;
    isLightThem?: boolean;
}

const Pagination = (props: Props) => {
    const { infoPage, onChangePage, showTotal, isLightThem } = props;

    const [rangeDisplayPage, setRangeDisplayPage] = useState(5);

    const changeViewPagination = () => {
        if (window.innerWidth < 767) {
            setRangeDisplayPage(3);
        } else setRangeDisplayPage(5);
    };

    useEffect(() => {
        if (window.innerWidth < 575) {
            setRangeDisplayPage(3);
        }
        return () => window.removeEventListener('resize', changeViewPagination);
    }, []);

    window.addEventListener('resize', changeViewPagination);

    const handleChangePage = ({ selected }: { selected: number }) => {
        if (onChangePage) onChangePage(selected + 1);
    };
    return (
        <div className={`${styles.wrap} ${isLightThem && styles.lightNum}`}>
            <div className="">
                <ReactPaginate
                    previousLabel={false}
                    nextLabel={false}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={rangeDisplayPage}
                    onPageChange={handleChangePage}
                    pageCount={!infoPage ? 1 : infoPage.totalpage}
                />
            </div>
            {showTotal && <div className={styles.totalRecord}>Tổng số: {infoPage ? new Intl.NumberFormat().format(infoPage.totalrecord) : 0}</div>}
        </div>
    );
};

export default Pagination;
