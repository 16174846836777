const serviceType = {
    USER_SERVICE: '/UserService.aspx',
    MINIGAME_SERVICE: '/MiniGameService.aspx',
    PAY_SERVICE: '/PayService.aspx',
    MOBILE_SERVICE: '/MobileService.aspx',
    GA_SERVICE: '/GaService.aspx',
    LOTTERY_SERVICE: '/LotteryService.aspx',
    XDSERVICE: '/XDService.aspx',
    BCSERVICE: '/BCService.aspx',
    TXSERVICE: '/GameService.aspx',
    TX1M_SERVICE: '/K31fGameService.aspx',
    REGISTER_SERVICE: '/RegisterService.aspx',
    BANK_SERVICE: '/BankService.aspx',
};

export default serviceType;
