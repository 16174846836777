import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const promoDetailStyles = {
    wrap: css`
        min-height: 50vh;
        padding: 24px 0;
    `,
    content: css`
        background: ${colors.white};
        padding: 16px;
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
        position: relative;
    `,
    btnBack: css`
        border: 2px solid ${colors.gray};
        display: flex;
        padding: 6px;
        border-radius: 40px;
    `,
    contentInner: css`
        width: 90%;
        margin: 0 auto;
    `,
};
export default promoDetailStyles;
