import { css } from '@emotion/css';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { LotteryTicket, TicketConfirmLotteryData } from 'models/lotteryModel';
import moment from 'moment';
import bgRegister from 'assets/images/bg/title-register.jpg';
import { useEffect, useState } from 'react';
import PopupTooltip from 'components/tooltip-popup/PopupTooltip';
import { formatCodeString } from 'utils/function';

interface Props {
    ticketData: TicketConfirmLotteryData;
    ticketList: LotteryTicket[];
    onSubmit: () => void;
    onClose: () => void;
}
function ConfirmBetLottery(props: Props) {
    const { onClose, onSubmit, ticketData, ticketList } = props;
    const [ticketInfo, setTicketInfo] = useState(ticketData);
    useEffect(() => {
        setTicketInfo(ticketData);
    }, [ticketData, ticketList]);

    return (
        <div className={styles.wrap}>
            <div className={styles.heading}>
                <h3>{ticketInfo.title}</h3>
                <p>
                    Thời gian cược: <span>{moment(ticketInfo.time).format('HH:mm DD/MM/yyyy')}</span>
                    {/* Thời gian cược: <span>{moment(new Date()).format('HH:ss DD/MM/yyyy')}</span> */}
                </p>
                <p>
                    Tổng tiền: <span>{new Intl.NumberFormat().format(ticketList.reduce((acc, item) => acc + item.money * item.times, 0))}</span>
                </p>
            </div>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.title}`}>Tên cược</div>
                    <div className={`${styles.col} ${styles.title}`} style={{ flex: '0 0 200px' }}>
                        Số cược
                    </div>
                    <div className={`${styles.col} ${styles.title}`}>Tổng cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Cấp số nhân</div>
                    <div className={`${styles.col} ${styles.title}`}>Tiền cược</div>
                    <div className={`${styles.col} ${styles.title}`}>Tiền thắng/1 lần</div>
                    <div className={`${styles.col} ${styles.title}`}>Tỉ lệ</div>
                </div>
                <div className={styles.tableBody}>
                    {ticketList.map((item, index) => (
                        <div className={styles.row} key={index}>
                            <p className={`${styles.col} ${globalStyle.text.yellow}`} style={{ fontWeight: '500' }}>
                                {item.trTitle} - {item.stTitle}
                            </p>
                            <div className={`${styles.col} ${styles.codesBet}`} style={{ flex: '0 0 200px' }}>
                                <PopupTooltip content={formatCodeString(item.codes)} idRef={`confirm_${index}`} />
                            </div>
                            <p className={styles.col}>{item.total}</p>
                            <p className={styles.col}>{item.times}</p>
                            <p className={`${styles.col} ${globalStyle.text.yellow}`} style={{ fontWeight: '500' }}>
                                {new Intl.NumberFormat().format(item.money * item.times)}
                            </p>
                            <p className={styles.col}>{new Intl.NumberFormat().format(Number(item.dyprize.prize) * item.times * 1000)}</p>
                            <p className={styles.col}>{item.dyprize.prize}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.actionGroup}>
                <button type="button" className={`${globalStyle.btnPrimaryOutline}`} onClick={onClose}>
                    Huỷ
                </button>
                <button type="button" className={globalStyle.btnPrimary} onClick={onSubmit}>
                    Xác Nhận
                </button>
            </div>
        </div>
    );
}

const styles = {
    wrap: css`
        padding: 20px 16px;
        border-radius: 8px;
        border: 1px solid ${colors.borderPrimary};
        background: #0f1524;
    `,
    heading: css`
        color: ${colors.white};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 24px;
        border-radius: 8px;
        margin-bottom: 16px;
        background: black;
        /* background: url(${bgRegister}) no-repeat;
        background-size: cover; */
        border: 1px solid ${colors.borderPrimary};

        h3 {
            font-size: 24px;
        }
        p {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        span {
            font-size: 22px;
            color: ${colors.yellow};
            font-weight: 500;
        }
    `,

    table: css`
        border: 1px solid ${colors.primary};
        border-radius: 8px;
        overflow: hidden;
        min-height: 150px;
        color: ${colors.white};
        &.table-col-center {
            .col {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    `,
    codesBet: css`
        word-wrap: break-word;
        max-width: 200px;
    `,
    row: css`
        display: flex;
        border-bottom: 1px solid ${colors.borderTable};
        align-items: center;
        &:first-child {
            border-bottom-color: ${colors.borderTable};
        }
        &:last-child {
            border-bottom: none;
        }
    `,
    col: css`
        flex: 1;
        padding: 12px 8px;
        text-align: center;
        font-size: 14px;
    `,
    title: css`
        font-weight: 600;
        background: rgba(0, 0, 0, 0.3);
    `,
    actionGroup: css`
        display: flex;
        gap: 16px;
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        button {
            &:first-child {
                flex: 0 0 30%;
            }
        }
    `,
    tableBody: css`
        max-height: 500px;
        overflow-y: auto;
    `,
};

export default ConfirmBetLottery;
