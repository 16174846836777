import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';

const commonApi = {
    initMenuHeader(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'initmain';
        return axiosClient.post(serviceType.MOBILE_SERVICE, payload);
    },
    getVersionApp(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'getVersion1';
        return axiosClient.post(serviceType.MOBILE_SERVICE, payload);
    },
    getMsgSystemBean(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'SysMsgBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    sysMsgBeanInfo(payload: { id: number; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'SysMsgBean_showmsg';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
};
export default commonApi;
