import styles from './switch-button.style';
interface Props {
    isChecked: boolean;
    onToggle: (isChecked: boolean) => void;
}
function SwitchButton(props: Props) {
    const { isChecked, onToggle } = props;

    return (
        <div className={styles.wrap}>
            <input className={styles.inputCheckbox} type="checkbox" id="switchButton" checked={isChecked} onChange={(e) => onToggle(e.target.checked)} />
            <label className={styles.labelCheckbox} htmlFor="switchButton">
                Toggle
            </label>
        </div>
    );
}

export default SwitchButton;
