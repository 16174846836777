import { css } from '@emotion/css';
import { colors } from 'constants/theme';
import { Tooltip } from 'react-tooltip';

interface Props {
    content: string;
    idRef: string;
}
function PopupTooltip(props: Props) {
    const { content, idRef } = props;

    return content.length > 200 ? (
        <>
            <span className={styles.text} data-tooltip-id={idRef}>
                {content}
            </span>
            <Tooltip id={idRef} className={styles.tooltip}>
                <p className={styles.tooltipContent}>{content}</p>
            </Tooltip>
        </>
    ) : (
        <span className={styles.text}>{content}</span>
    );
}

const styles = {
    text: css`
        word-wrap: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* max line number */
        -webkit-box-orient: vertical;
    `,
    tooltip: css`
        background: ${colors.black};
        opacity: 1 !important;
        border-radius: 8px !important;
    `,
    tooltipContent: css`
        max-width: 400px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
    `,
};

export default PopupTooltip;
