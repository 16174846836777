import { css } from '@emotion/css';
import userApi from 'apis/userApi';
import { RootState, useAppSelector } from 'cache/store';
import DatePicker from 'components/date-picker/DatePicker';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { NewListStastRespone } from 'models/statisticModel';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import StatsAgencyDetail from './StatsAgencyDetail';
import accountStyles from './account-option.style';

interface TotalRecord extends NewListStastRespone {
    sumDpChild: number;
    sumWdChild: number;
}

const actionType = [
    { value: 0, label: 'Lợi nhuận từ lớn đến nhỏ' },
    { value: 1, label: 'Tổn thất từ lớn tới nhỏ' },
    { value: 2, label: 'Đặt cược từ lớn tới nhỏ' },
    { value: 3, label: 'Trúng cược từ lớn tới nhỏ' },
    { value: 4, label: 'Khuyến mãi từ lớn tới nhỏ' },
    { value: 5, label: 'Nạp tiền từ lớn tới nhỏ' },
    { value: 6, label: 'Rút tiền từ lớn tới nhỏ' },
    { value: 7, label: 'Chiết khấu từ lớn tới nhỏ' },
];

function StatsDaily() {
    const { agentType } = useAppSelector((state: RootState) => state.auth);

    const [dateSelect, setDateSelect] = useState<Date | null>(new Date(`${moment(new Date()).format('yyyy-MM-DD')}`));
    const [loginname, setLoginName] = useState('');
    const [orderSelect, setOrderSelect] = useState(0);

    const [recordList, setRecordList] = useState<NewListStastRespone[] | null>(null);
    const [totalRecord, setTotalRecord] = useState<TotalRecord>({} as TotalRecord);
    const [recordDetail, setRecordDetail] = useState<any>(null);

    const [loading, setLoading] = useState(false);

    const searchStatsDaily = async () => {
        setLoading(true);
        const payload = {
            order: orderSelect,
            dates: moment(dateSelect).format('yyyy-MM-DD'),
            loginname,
            agentname: loginname,
        };
        try {
            const { data } = await userApi.reportWinLoss2List(payload);
            if (!data.msg || data.msg === 'Không có dữ liệu') {
                setRecordList(data.reslist);
                if (data.reslist.length > 0) {
                    initTotalRecord(data.reslist);
                }
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const initTotalRecord = (list: NewListStastRespone[]) => {
        let totalRecordObj: any = {
            dp: 0,
            wd: 0,
            sumDpChild: 0,
            sumWdChild: 0,
            tz: 0,
            fd: 0,
            win: 0,
            bonus: 0,
        };
        list.forEach((item) => {
            totalRecordObj.dp += item.dp;
            totalRecordObj.wd += item.wd;
            totalRecordObj.sumDpChild += item.dpxj || 0;
            totalRecordObj.sumWdChild += item.wdchild || 0;
            totalRecordObj.tz += item.tz;
            totalRecordObj.fd += item.fd;
            totalRecordObj.win += item.win;
            totalRecordObj.bonus += item.bonus;
        });
        setTotalRecord(totalRecordObj);
    };

    const calcProfit = (total: any) => {
        return total.win + total.fd + total.bonus - total.tz;
    };
    return (
        <>
            <div className={`${accountStyles.wrap}`}>
                <h6 className={accountStyles.title}>Báo Cáo Hằng Ngày</h6>
                <div className={accountStyles.flexbox}>
                    <div className={`${accountStyles.flex33} ${accountStyles.groupInput}`}>
                        <label htmlFor="">Thời Gian Từ</label>
                        <div className={accountStyles.wrapInput}>
                            <DatePicker dateSelect={dateSelect} onSelectDate={(date) => setDateSelect(date)} showIcon maxDate={new Date()} />
                        </div>
                    </div>
                    <div className={`${accountStyles.flex33} ${accountStyles.groupInput}`}>
                        <label htmlFor="">Loại</label>
                        <div className={accountStyles.wrapInput}>
                            <select className="input-common" value={orderSelect} onChange={(e) => setOrderSelect(Number(e.target.value))}>
                                {actionType.map((item) => (
                                    <option value={item.value} key={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={`${accountStyles.groupInput} ${accountStyles.flex33}`}>
                        <label htmlFor="">Tên Tài Khoản</label>
                        <div className={accountStyles.wrapInput}>
                            <input value={loginname} onChange={(e) => setLoginName(e.target.value)} className="input-common" type="text" placeholder="Tên tài khoản" />
                        </div>
                    </div>
                    <div className={accountStyles.flex33} style={{ marginTop: '8px', marginBottom: '24px' }}>
                        <button type="button" className={`btn-primary ${accountStyles.btnSubmit}`} onClick={searchStatsDaily}>
                            Tìm Kiếm
                        </button>
                    </div>
                </div>
                <div className="table-common">
                    <div className="row">
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Hội viên</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Nạp tiền</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Rút tiền</div>
                        {agentType === 1 && (
                            <>
                                <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Nạp tiền cấp dưới</div>
                                <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Rút tiền cấp dưới</div>
                            </>
                        )}
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Cược</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Chiết khấu</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Trúng</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Khuyến mãi</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Lợi nhuận</div>
                        <div className={`title col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Xem</div>
                    </div>
                    {recordList ? (
                        <>
                            {recordList.length > 0 ? (
                                recordList.map((item, index) => (
                                    <div className="row" key={index}>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{item.loginname}</p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.dp)}</p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.wd)}</p>
                                        {agentType === 1 && (
                                            <>
                                                <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.dpxj)}</p>
                                                <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.wdchild)}</p>
                                            </>
                                        )}
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.tz)}</p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.fd)}</p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.win)}</p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(item.bonus)}</p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)} ${calcProfit(item) >= 0 ? 'text-success' : 'text-error'}`}>
                                            {new Intl.NumberFormat().format(calcProfit(item))}
                                        </p>
                                        <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>
                                            <span className="btn-detail" onClick={() => setRecordDetail(item)}>
                                                Chi tiết
                                            </span>
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <div className="no-data">
                                    <img src={require('assets/images/icons/no-data.png')} alt="" />
                                    <p>Không có dữ liệu</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="no-data"></div>
                    )}

                    {recordList && recordList.length > 0 && (
                        <div className="row">
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>Tổng cộng</p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.dp)}</p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.wd)}</p>
                            {agentType === 1 && (
                                <>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.sumDpChild)}</p>
                                    <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.sumWdChild)}</p>
                                </>
                            )}
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.tz)}</p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.fd)}</p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.win)}</p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}>{new Intl.NumberFormat().format(totalRecord.bonus)}</p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)} ${calcProfit(totalRecord) >= 0 ? 'text-success' : 'text-error'}`}>
                                {new Intl.NumberFormat().format(calcProfit(totalRecord))}
                            </p>
                            <p className={`col ${globalStyle.flexItems(agentType === 1 ? 11 : 9)}`}></p>
                        </div>
                    )}
                </div>
            </div>
            <Modal isShow={Boolean(recordDetail)} onClose={() => setRecordDetail(null)} closeClickBackdrop>
                <StatsAgencyDetail statsData={recordDetail} onClose={() => setRecordDetail(null)} />
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

export default StatsDaily;
