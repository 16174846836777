import { css } from '@emotion/css';
import accountBg from 'assets/images/bg/account.jpg';
import routeActiveBg from 'assets/images/bg/title-register.jpg';
import { colors } from 'constants/theme';
const accountStyles = {
    wrap: css`
        background: url(${accountBg}) no-repeat;
        background-size: cover;
        height: 100%;
        min-height: 70vh;
        padding: 24px 0;
    `,
    inner: css`
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        height: 100%;
    `,
    left: css`
        background: ${colors.white};
        padding: 20px 24px 30px;
        flex: 0 0 250px;
    `,
    group: css`
        margin-bottom: 8px;
        .heading-icon {
            transition: 0.25s;
            color: white;
        }
        &.active {
            .heading-icon {
                transform: rotate(180deg);
            }
        }
    `,
    groupTitle: css`
        display: flex;
        align-items: center;

        p {
            padding: 8px;
            background: ${colors.secondary};
            border-radius: 8px;
            color: ${colors.white};
            font-weight: 500;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -15px;
            font-size: 14px;
        }
    `,
    headingGroup: css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 8px 6px 0;
        margin-right: -8px;
        cursor: pointer;
        background: ${colors.darkSecondary};
    `,
    title: css`
        padding-left: 6px;
        color: ${colors.white};
    `,
    optionList: css`
        margin-bottom: 8px;
        margin-top: 4px;
        height: 0;
        overflow: hidden;
        transition: 0.25s;
    `,
    optionItem: css`
        display: flex;
        align-items: center;
        padding: 6px 8px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: 0.25s;
        img {
            width: 24px;
            margin-right: 6px;
        }
        p {
            flex: 1;
            font-size: 16px;
            color: #040404;
        }
        &:hover {
            border-color: ${colors.primary};
            p {
                color: ${colors.primary};
            }
        }
        &.active {
            background: url(${routeActiveBg}) no-repeat;
            background-size: cover;
            border-color: ${colors.darkPrimary};

            p {
                color: ${colors.white};
                font-weight: 500;
            }
        }
    `,
    right: css`
        flex: 0 0 calc(100% - 250px);
        max-width: calc(100% - 250px);
        background: ${colors.darkSecondary};
        background-size: cover;
    `,
};
export default accountStyles;
