import serviceType from 'constants/serviceType';
import axiosClient from './aixosClient';
import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';

const lotteryApi = {
    lotteryOffical4DBean(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'Official4DBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    lotteryCurrentIssue(payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'read';
        return axiosClient.post(serviceType.LOTTERY_SERVICE, payload);
    },
    getOpenWinRecored(payload: { issue: string; lotteryid: number; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'gethistory';
        return axiosClient.post(serviceType.LOTTERY_SERVICE, payload);
    },
    lotteryWinOpenNumber(payload: { id: number; num: number; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'UIWinOpenNumberBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
    lotteryMMCWinOpenNumber(payload: { lotteryid: number; num: number; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'mmcwnum';
        return axiosClient.post(serviceType.LOTTERY_SERVICE, payload);
    },
    lotteryRecordBeanInfo(payload: { id: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'BetRecordBean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    lotteryCancelBet(payload: { id: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'GameRecordBean_cancelBet';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    lotteryInit(payload: { lotteryId: number; flag: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        if (payload.flag.search('FASTMB') > -1) {
            payload.flag = 'VNBean';
        } else {
            payload.flag = payload.flag.search('VN') > -1 ? payload.flag + 'BeanV2' : payload.flag + 'Bean';
        }
        // payload.flag = payload.flag.search('VN') > -1 ? payload.flag + 'BeanV2' : payload.flag + 'Bean';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },

    lotteryCheckDualNumber(payload: { id: number; issue: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'checkDualNumber';
        return axiosClient.post(serviceType.LOTTERY_SERVICE, payload);
    },
    submitMultiple(payload: any, isFastLT?: boolean): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = `${isFastLT ? 'save' : 'multilbets'}`;
        return axiosClient.post(serviceType.LOTTERY_SERVICE, payload);
    },

    lotteryRecordBean(payload: { lotteryid: number; num?: number; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'GameRecordBean_btnSearch';
        return axiosClient.post(serviceType.USER_SERVICE, payload);
    },
};
export default lotteryApi;
