import { css } from '@emotion/css';
import lotteryApi from 'apis/lotteryApi';
import globalStyle from 'constants/global.style';
import { colors } from 'constants/theme';
import { BetRecordDetailResponse } from 'models/lotteryModel';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import { xdLabel, baucuaLabel } from 'constants/labelGame';
import ResultDanGian from 'components/result-game/ResultDanGian';
import { checkIsTypeLotteryMienBac, checkIsTypeLotteryMienNam } from 'utils/function';
import ResultLotteryMienBac from 'components/result-game/ResultLotteryMienBac';
import ResultLotteryMienNam from 'components/result-game/ResultLotteryMienNam';
import TableLotteryDauDuoi from 'components/result-game/TableLotteryDauDuoi';
import classNames from 'classnames';
import { MiennamLotto } from 'interfaces/miennam-lotto';
import { MienbacLotto } from 'interfaces/mienbac-lotto';
interface Props {
    onClose: () => void;
    betRecordId: string | null;
    onCancelBet: (recordId: string) => void;
}

const listIdTranditionGame = [90, 91, 92, 93]; //xoc dia 1s, 1p, bau cua 1s,1p

function BetRecord(props: Props) {
    const { onClose, betRecordId, onCancelBet } = props;
    const [betRecord, setBetRecord] = useState<BetRecordDetailResponse>({ winnumber: [] as string[] } as BetRecordDetailResponse);
    const [typeInputId, setTypeInputId] = useState('');
    useEffect(() => {
        if (betRecordId) {
            const getBetRecordDetail = async () => {
                try {
                    const { data } = await lotteryApi.lotteryRecordBeanInfo({ id: betRecordId });
                    if (!data.msg) {
                        const betInfo = {
                            ...data.betinfo,
                            winnumber: data.betinfo.winnumber ? data.betinfo.winnumber.split(',').filter((item: any) => item) : [],
                        };
                        setBetRecord(betInfo);
                    } else {
                        toast.error(data.msg);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            getBetRecordDetail();
        }
    }, [betRecordId]);

    useEffect(() => {
        if (betRecord.methodid && betRecord.lotteryid) {
            // mieen bac dac biet
            if (betRecord.methodid === 1000) {
                setTypeInputId('de-dac-biet');
            } else {
                if (checkIsTypeLotteryMienNam(betRecord.lotteryid)) {
                    getTypeInputLottery('MN', betRecord.methodid);
                }
                if (checkIsTypeLotteryMienBac(betRecord.lotteryid)) {
                    getTypeInputLottery('MB', betRecord.methodid);
                }
            }
        }
    }, [betRecord]);

    const getTypeInputLottery = (lotteryChanel: 'MB' | 'MN', methodid: number) => {
        let lotteryMenu = MiennamLotto;
        if (lotteryChanel === 'MB') {
            lotteryMenu = MienbacLotto;
        }

        lotteryMenu.forEach((item) => {
            if (item.subTabs) {
                item.subTabs.forEach((tab) => {
                    if (tab.methodID === methodid) {
                        setTypeInputId(tab.typeIputID);
                    }
                });
            }
        });
    };

    const cancelBet = () => {
        if (betRecordId) onCancelBet(betRecordId);
    };

    const mapBetSelect = () => {
        if (!listIdTranditionGame.includes(betRecord.lotteryid)) {
            return transformCodes(betRecord.codes);
        } else {
            //game dan gian xoc dia, bau cua
            return (
                <div className="">
                    {betRecord.codes &&
                        betRecord.codes.split('&&').map((item, index) => {
                            let typeBetGame = baucuaLabel;
                            //game dan gian xoc dia
                            if ([90, 91].includes(betRecord.lotteryid)) {
                                typeBetGame = xdLabel;
                            }

                            return item ? (
                                <p key={index}>
                                    {typeBetGame[item.split('Bet')[0]]}
                                    {item.split('Bet')[1].replace('Prize', 'Thắng')}
                                </p>
                            ) : (
                                ''
                            );
                        })}
                </div>
            );
        }
    };

    const transformCodes = (value: string) => {
        if (!value) return '';
        if (value.indexOf('&') > -1) {
            return value.replace(/&/g, ',');
        }
        return value;
    };

    return (
        <div className={`${globalStyle.bgPopup(colors.bgPopupDrak)} ${styles.wrap}`}>
            <h6 className="title">Chi Tiết Vé Cược</h6>
            <button type="button" onClick={onClose} className="btn-close">
                <IoMdClose size={40} color={colors.primary} />
            </button>
            <div className={styles.betContent}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Thành viên</p>
                        <p>{betRecord.loginname}</p>
                    </div>
                    <div className={styles.col}>
                        <p>Trạng thái</p>
                        <p
                            className={classNames(
                                { 'text-secondary': betRecord.status === 1 },
                                { 'text-success': betRecord.status === 4 },
                                { 'text-error': betRecord.status === 3 || betRecord.status === 6 }
                            )}
                        >
                            {betRecord.statusname}
                        </p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Mã số cược</p>
                        <p>{betRecord.id}</p>
                    </div>
                    <div className={styles.col}>
                        <p>Thời gian cược</p>
                        <p>{moment(betRecord.bettime).format('DD/MM/yyyy HH:mm:ss')}</p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Loại</p>
                        <p>{betRecord.lotteryname}</p>
                    </div>
                    <div className={styles.col}>
                        <p>Tỷ lệ chiết khấu</p>
                        <p>{betRecord.rebate === 1 ? '' : betRecord.rebate * 100}%</p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Lượt xổ</p>
                        <p>{betRecord.issue}</p>
                    </div>
                    <div className={styles.col}>
                        <p>Tổng số mua</p>
                        <p>{betRecord.betnumber}</p>
                    </div>
                </div>
                {!listIdTranditionGame.includes(betRecord.lotteryid) && (
                    <>
                        <div className={`${styles.row} ${styles.rowResult}`}>
                            <p>Tóm tắt nội dung</p>
                            {betRecord.betcontent && (
                                <>
                                    {betRecord.betcontent.search(']') !== -1 ? (
                                        <div className={styles.resultContent}>
                                            {betRecord.betcontent.split(']')[0]}]{betRecord.betcontent.split(']')[1].substring(0, 16)} {betRecord.betcontent.split(']')[1].length > 16 ? '...' : ''}
                                        </div>
                                    ) : (
                                        <div className={styles.resultContent}>{betRecord.betcontent}</div>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}

                <div className={`${styles.row} ${styles.rowResult}`}>
                    <p>Chi tiết chọn</p>
                    <div className={styles.betSelectWrap}>
                        <span>{mapBetSelect()}</span>
                    </div>
                </div>

                <div className={`${styles.row} ${styles.rowResult}`}>
                    <p>Kết quả</p>
                    <div className={styles.resultContent}>
                        {betRecord.winnumber.length > 0 && (
                            <>
                                {betRecord.lotteryid === 90 && <ResultDanGian gameType="XD" resultList={betRecord.winnumber[0].split('|')} />}
                                {betRecord.lotteryid === 91 && <ResultDanGian gameType="XD" resultList={betRecord.winnumber[0].split('')} />}
                                {betRecord.lotteryid === 92 && <ResultDanGian gameType="BC" resultList={betRecord.winnumber[0].split('|')} />}
                                {betRecord.lotteryid === 93 && <ResultDanGian gameType="BC" resultList={betRecord.winnumber[0].split('')} />}
                                {checkIsTypeLotteryMienNam(betRecord.lotteryid) && (
                                    <div className={styles.resultLottery}>
                                        <div className={styles.tableLottery}>
                                            <ResultLotteryMienNam resultList={betRecord.winnumber} typeInputId={typeInputId} />
                                        </div>
                                        <TableLotteryDauDuoi resultList={betRecord.winnumber} methodid={betRecord.methodid} />
                                    </div>
                                )}
                                {checkIsTypeLotteryMienBac(betRecord.lotteryid) && (
                                    <div className={styles.resultLottery}>
                                        <div className={styles.tableLottery}>
                                            <ResultLotteryMienBac resultList={betRecord.winnumber} typeInputId={typeInputId} />
                                        </div>
                                        <TableLotteryDauDuoi resultList={betRecord.winnumber} methodid={betRecord.methodid} />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Cấp số nhân</p>
                        <p>{betRecord.multiple} lần</p>
                    </div>
                    <div className={styles.col}>
                        <p>Tiền cược</p>
                        <p className="text-error">{new Intl.NumberFormat().format(betRecord.amount)}</p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Lần thắng</p>
                        <p>{betRecord.wintimes}</p>
                    </div>
                    <div className={styles.col}>
                        <p>Tiền thắng</p>
                        <p className="text-success">{new Intl.NumberFormat().format(betRecord.bonus)}</p>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <p>Tiền hoàn trả</p>
                        <p>{new Intl.NumberFormat().format(betRecord.amount * betRecord.rebate)}</p>
                    </div>
                    <div className={styles.col}></div>
                </div>
            </div>
            {betRecord.status === 0 && (
                <div className={styles.betAction}>
                    <button type="button" className={globalStyle.btnError} onClick={cancelBet}>
                        Huỷ Cược
                    </button>
                </div>
            )}
        </div>
    );
}

const styles = {
    betContent: css`
        flex: 1;
        overflow-y: auto;
        font-size: 14px;
    `,
    row: css`
        display: flex;
        border: 1px solid ${colors.borderTable};
        border-bottom: none;
        &:last-child {
            border-bottom: 1px solid ${colors.borderTable};
        }
    `,
    col: css`
        flex: 0 0 50%;
        display: flex;
        p {
            padding: 6px;
            &:first-child {
                flex: 0 0 135px;
                color: #7f88c0;
                border-right: 1px solid ${colors.borderTable};
                background: rgba(0, 0, 0, 0.3);
            }
        }
    `,
    wrap: css`
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    rowResult: css`
        align-items: center;
        > p {
            align-self: stretch;
            display: flex;
            align-items: center;

            flex: 0 0 135px;
            padding: 6px;

            color: #7f88c0;
            border-right: 1px solid ${colors.borderTable};
            background: rgba(0, 0, 0, 0.3);
        }
    `,
    resultContent: css`
        flex: 1;
        padding: 8px;
    `,
    betSelectDetail: css`
        word-wrap: break-word;
    `,
    betSelectWrap: css`
        flex: 0 0 calc(100% - 135px);
        padding: 8px;
        word-wrap: break-word;
        max-width: calc(100% - 135px);
        span {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 12; /* max line number */
            -webkit-box-orient: vertical;
        }
    `,
    resultLottery: css`
        display: flex;
        gap: 16px;
    `,
    tableLottery: css`
        flex: 1;
    `,
    betAction: css`
        text-align: center;
        margin: 16px auto 0;
        width: 150px;
    `,
};

export default BetRecord;
