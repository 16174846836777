import classNames from 'classnames';
import { useState } from 'react';
import styles from './account-option.style';
import PersonPWTab from './personal-tab/PersonPWTab';
import PersonPhoneTab from './personal-tab/PersonPhoneTab';
import Persontab from './personal-tab/PersonTab';

interface TabDeposit {
    label: string;
    value: 'INFO' | 'PASSWORD' | 'PHONEVERIFY';
}
const tabList: TabDeposit[] = [
    {
        label: 'THÔNG TIN',
        value: 'INFO',
    },
    {
        label: 'MẬT KHẨU',
        value: 'PASSWORD',
    },
    {
        label: 'XÁC MINH SĐT',
        value: 'PHONEVERIFY',
    },
];

function PersonalInfo() {
    const [tabSelect, setTabSelect] = useState<'INFO' | 'PASSWORD' | 'PHONEVERIFY'>('INFO');

    const changeTabBank = (tab: 'INFO' | 'PASSWORD' | 'PHONEVERIFY') => {
        setTabSelect(tab);
    };
    return (
        <>
            <div className={`${styles.wrap} ${styles.mw800} `}>
                <h6 className={styles.title}>Thông Tin Cá Nhân</h6>

                <div className={styles.tabList}>
                    {tabList.map((tab) => (
                        <div className={classNames(styles.tabItem, { active: tabSelect === tab.value })} key={tab.value} onClick={() => changeTabBank(tab.value)}>
                            {tab.label}
                        </div>
                    ))}
                </div>
                <div style={{ paddingTop: '24px', flex: 1 }}>
                    {tabSelect === 'INFO' && <Persontab />}
                    {tabSelect === 'PASSWORD' && <PersonPWTab />}
                    {tabSelect === 'PHONEVERIFY' && <PersonPhoneTab />}
                </div>
            </div>
        </>
    );
}

export default PersonalInfo;
