import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const paginationStyles = {
    wrap: css`
        display: flex;
        align-items: center;
        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            padding-inline-start: 0;
            margin-bottom: 0;

            li {
                padding: 3px;

                a {
                    cursor: pointer;
                    height: 30px;
                    width: 30px;
                    font-size: 11px;
                    border-radius: 6px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: transparent;
                    color: ${colors.white};
                    border: 1px solid #ccc;
                    line-height: 1;

                    &:focus {
                        outline: none;
                    }
                    &:hover {
                        background: ${colors.primary};
                        border: 1px solid ${colors.primary};
                    }
                }

                &.previous,
                &.next {
                    display: none;
                }

                &.selected {
                    a {
                        background: ${colors.primary};
                        border: 1px solid ${colors.primary};
                        color: ${colors.white};
                    }
                }
            }
        }
    `,
    lightNum: css`
        ul {
            li {
                a {
                    color: ${colors.blackText};
                    &:hover {
                        color: ${colors.white};
                    }
                }
            }
        }
    `,

    totalRecord: css`
        margin-left: 16px;
        font-size: 14px;
    `,
};
export default paginationStyles;
