import { css } from '@emotion/css';
import classNames from 'classnames';
import { colors } from 'constants/theme';
import { LotterySub, TypeInputLottery } from 'models/lotteryModel';
import React, { useEffect, useState } from 'react';

let intervalId: any;
let timeoutId: any;
interface Props {
    quickPickInput: TypeInputLottery;
    onChangeQuickPick: (quickData: TypeInputLottery, amount: number, isValid: boolean) => void;
    subTypeBet: LotterySub;
}
function QuickPickLotteryInput(props: Props) {
    const { quickPickInput, onChangeQuickPick, subTypeBet } = props;
    const [selectedRandom, setSelectedRandom] = useState(0);
    const [rangeGroupActive, setRangeGroupActive] = useState(quickPickInput.layout[0].no);
    const [isRandomLoading, setIsRandomLoading] = useState(false);
    const [fakeGroupRandom, setFakeGroupRandom] = useState<string[]>([]);
    const [checkQucikPick, setCheckQuickPick] = useState(true);

    useEffect(() => {
        if (quickPickInput.layout[0].selectedNums.length === 0) {
            if (checkQucikPick) {
                setSelectedRandom(0);
            }
        }
    }, [quickPickInput]);

    const randomQuickNum = (quantity: number) => {
        setCheckQuickPick(false);
        if (isRandomLoading) return;
        timeoutId = null;
        setFakeGroupRandom([]);
        updateResultIntoLayout([], true);

        setSelectedRandom(quantity);

        const activeLayoutNums = genQickPickRange(rangeGroupActive);
        let allLayoutNums: string[] = [];

        quickPickInput.layout.forEach((range) => {
            const groupNum = genQickPickRange(range.no);

            allLayoutNums = [...allLayoutNums, ...groupNum];
        });

        let arrayNumberRandomResult: string[] = []; // mảng số kết quả
        setIsRandomLoading(true);

        intervalId = setInterval(() => {
            const randomNumFake = activeLayoutNums[Math.floor(Math.random() * activeLayoutNums.length - 1) + 1];
            const randomNumResult = allLayoutNums[Math.floor(Math.random() * allLayoutNums.length - 1) + 1];

            //hiệu ứng chọn số trên layout được chọn
            fakeRandom(randomNumFake);

            if (arrayNumberRandomResult.length < quantity) {
                //kiểm tra số random đã tồn tại trong mảng
                if (!arrayNumberRandomResult.includes(randomNumResult)) {
                    arrayNumberRandomResult.push(randomNumResult);
                }
            } else {
                if (!timeoutId) {
                    timeoutId = setTimeout(() => {
                        clearInterval(intervalId);
                        setIsRandomLoading(false);
                        updateResultIntoLayout(arrayNumberRandomResult, false);
                        setFakeGroupRandom([]);
                        setCheckQuickPick(true);
                    }, 500);
                }
            }
        }, 1);
    };

    const updateResultIntoLayout = (listResult: string[], isReset: boolean) => {
        const newLayout = quickPickInput.layout.map((item) => {
            const rangeNumLayout = genQickPickRange(item.no);
            const selectedNums: string[] = [];
            listResult.forEach((num) => {
                if (rangeNumLayout.includes(num)) {
                    selectedNums.push(num);
                }
            });
            return {
                ...item,
                selectedNums,
            };
        });
        let newQuickPickInput = { ...quickPickInput };
        if (isReset) {
            newQuickPickInput = { ...quickPickInput, layout: quickPickInput.layout.map((item) => ({ ...item, selectedNums: [] })) };
        } else {
            newQuickPickInput = { ...quickPickInput, layout: newLayout };
        }
        checkValidQuickPick(newQuickPickInput);
    };

    const fakeRandom = (num: string) => {
        setFakeGroupRandom((currentList) => {
            if (currentList.length > 5) return [];
            return [...currentList, num];
        });
    };

    const genQickPickRange = (range: string) => {
        const numLength = range.split('|')[0].length;

        const rangeNum: number[] = range.split('|').map((item) => parseInt(item, 10));
        const result: string[] = [];
        for (let num = rangeNum[0]; num <= rangeNum[1]; num++) {
            result.push(`00${num.toString(10)}`.slice(-numLength));
        }
        return result;
    };

    const onSelectRemoveRandom = () => {
        setSelectedRandom(0);
        updateResultIntoLayout([], true);
    };

    const selectPickNum = (num: string, layoutIdx: number) => {
        const newLayout = quickPickInput.layout.map((item, idx) => {
            if (idx === layoutIdx) {
                let newSelectedNums = item.selectedNums;
                //unselect num
                if (newSelectedNums.includes(num)) {
                    newSelectedNums = newSelectedNums.filter((numSelect) => numSelect !== num);
                } else {
                    newSelectedNums.push(num);
                }
                return { ...item, selectedNums: newSelectedNums };
            }
            return item;
        });
        const newQuickPickInput = { ...quickPickInput, layout: newLayout };
        checkValidQuickPick(newQuickPickInput);
    };

    const checkValidQuickPick = (newPickInput: TypeInputLottery) => {
        const isXienTruot = subTypeBet.isXienTruot;
        const len = parseInt(subTypeBet.digits.split(',')[1], 10);
        let isValid = false;
        let amount = 0;
        if (isXienTruot) {
            if (getTotalQuickPickNum(newPickInput) === len) {
                isValid = true;
                amount = 1;
            } else {
                isValid = false;
            }
        } else {
            for (const row of newPickInput.layout) {
                if (row.selectedNums.length > 0) {
                    isValid = true;
                    break;
                } else {
                    isValid = false;
                }
            }
            newPickInput.layout.forEach((row) => {
                amount += row.selectedNums.length;
            });
        }
        onChangeQuickPick(newPickInput, amount, isValid);
    };

    const getTotalQuickPickNum = (newPickInput: TypeInputLottery) => {
        let totalNum = 0;
        newPickInput.layout.forEach((layout) => {
            totalNum += layout.selectedNums.length;
        });
        return totalNum;
    };
    return (
        <div className={styles.wrap}>
            <div className={styles.quickTabRandom}>
                <div className={styles.quickTabList}>
                    <p className={styles.quickTabTitle}>Ngẫu nhiên</p>
                    {quickPickInput.randomPickLayout &&
                        quickPickInput.randomPickLayout.layout.map((item) => (
                            <button
                                type="button"
                                className={classNames(styles.quickTabItem, { active: item.randomAmount === selectedRandom })}
                                key={item.randomAmount}
                                onClick={() => randomQuickNum(item.randomAmount)}
                            >
                                {item.title}
                            </button>
                        ))}
                </div>

                <button type="button" className={styles.btnClearRandom} onClick={onSelectRemoveRandom}>
                    Xoá
                </button>
            </div>
            <ul className={styles.blockRangeList}>
                {quickPickInput.layout.map((item) => (
                    <li className={classNames(styles.blockRangeItem, { active: rangeGroupActive === item.no })} key={item.no} onClick={() => setRangeGroupActive(item.no)}>
                        {item.selectedNums.length > 0 && <small className={styles.noticeNum}>{item.selectedNums.length}</small>}

                        <p>{item.title}</p>
                    </li>
                ))}
            </ul>
            <div className={styles.listNum}>
                {quickPickInput.layout.map((item, layoutIdx) => (
                    <React.Fragment key={item.no}>
                        {rangeGroupActive === item.no && (
                            <div className={styles.listNumBlock}>
                                {genQickPickRange(item.no).map((num, index) => (
                                    <span
                                        className={classNames(styles.numItem, { active: fakeGroupRandom.includes(num) || item.selectedNums.includes(num) })}
                                        key={index}
                                        onClick={() => selectPickNum(num, layoutIdx)}
                                    >
                                        {num}
                                    </span>
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

const styles = {
    wrap: css`
        padding-top: 16px;
    `,
    quickTabRandom: css`
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #bce1e1;
    `,
    quickTabTitle: css`
        padding: 12px;
    `,
    quickTabList: css`
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        gap: 12px;
        padding-right: 6px;
    `,
    quickTabItem: css`
        padding: 6px 8px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #bce1e1;
        &.active {
            background: ${colors.darkPrimary};
            color: ${colors.white};
        }
    `,

    btnClearRandom: css`
        border: 1px solid ${colors.red};
        padding: 6px 8px;

        border-radius: 8px;
        margin-left: 12px;
        color: ${colors.red};
        cursor: pointer;
        font-size: 14px;
        &:active {
            background: ${colors.red};
            color: ${colors.white};
        }
    `,
    blockRangeList: css`
        display: flex;
        list-style-type: none;
        gap: 12px;
    `,
    blockRangeItem: css`
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid #bce1e1;
        font-size: 14px;
        cursor: pointer;
        position: relative;

        &.active {
            background: ${colors.darkPrimary};
            border-color: ${colors.darkPrimary};
            color: ${colors.white};
        }
    `,
    noticeNum: css`
        position: absolute;
        top: -10px;
        right: -8px;
        background: ${colors.secondary};
        border-radius: 40px;
        color: ${colors.white};
        text-shadow: 1px 1px 2px #797979;
        padding: 4px;
        min-width: 20px;
        font-size: 10px;
        text-align: center;
    `,
    listNum: css`
        margin-top: 16px;
    `,
    listNumBlock: css`
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    `,
    numItem: css`
        font-size: 14px;
        border: 1px solid #bce1e1;
        padding: 4px 8px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
            color: ${colors.darkPrimary};
            border-color: ${colors.darkPrimary};
        }
        &.active {
            background: ${colors.darkPrimary};
            color: ${colors.white};
            border-color: ${colors.darkPrimary};
        }
    `,
};

export default QuickPickLotteryInput;
