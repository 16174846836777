import { css } from '@emotion/css';
import { colors } from 'constants/theme';
const bgTitleLinear = `linear-gradient(269deg, rgba(67, 47, 92, 0.66) 23.94%, rgba(21, 41, 111, 0.61) 83.16%), ${colors.bgTitle}`;

const promotionPageStyles = {
    wrap: css`
        min-height: 50vh;
    `,
    header: css`
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 30px 0;
    `,
    title: css`
        display: flex;
        align-items: center;
        gap: 10px;
        img {
            width: 36px;
        }
        h6 {
            font-size: 18px;
        }
    `,
    tabList: css`
        flex: 1;
        display: flex;
        list-style-type: none;
        background: #0c1818;
        gap: 8px;
        border-radius: 6px;
        padding: 0 16px;
    `,
    tabItem: css`
        padding: 12px;

        text-align: center;
        color: ${colors.white};
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            width: 100%;
            height: calc(100% - 4px);
            background: #082626;
            border-radius: 8px 8px 0 0;
            opacity: 0;
            pointer-events: none;
            z-index: 0;
        }
        &.active {
            span {
                color: #ffbf61;
            }
            &::before {
                opacity: 1;
            }
        }
        span {
            position: relative;
            z-index: 1;
            font-weight: 500;
        }
    `,
    promoContent: css`
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 16px;
    `,
    promoItem: css`
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
        padding: 8px;
    `,
    promoItemInner: css`
        background: #025656;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        height: 100%;
        position: relative;
        &:hover {
            box-shadow: 0 0 12px ${colors.secondary};
        }
        .wrap-img {
            display: flex;
            overflow: hidden;
            img {
                width: 100%;
                transition: 0.25s;
            }
        }
        .btn-detail {
            position: absolute;
            top: 6px;
            right: 6px;
            background: #c20001;

            font-weight: 600;
            padding: 8px 12px;
            border-radius: 8px;
            border: 1px solid #ff3434;
            span {
                color: ${colors.white};
                font-size: 12px;
                text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.6);
            }
        }

        p {
            flex: 1;
            padding: 8px;
        }
    `,
    noPromo: css`
        padding: 60px 0;
        text-align: center;
    `,
};
export default promotionPageStyles;
