import { createSlice } from '@reduxjs/toolkit';
import { UserInfoModel } from 'models/userInfoModel';

interface TokenData {
    accessToken: string | null;
    refreshToken: string | null;
}

interface PropsState {
    userInfo: UserInfoModel;
    token: TokenData;
    isLogged: boolean;
    agentType: any;
    rememberData: {
        isRemember: boolean | null;
        account: string | null;
    };
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: {},
        isLogged: false,
        agentType: null,
        userInfo: {},
        rememberData: {
            isRemember: false,
            account: null,
        },
    } as PropsState,
    reducers: {
        login(state, action) {
            state.isLogged = true;
            state.token = action.payload;
        },
        logout(state) {
            state.isLogged = false;
            state.token = {} as TokenData;
            state.userInfo = {} as UserInfoModel;
        },
        cacheUserInfo(state, action) {
            state.userInfo = action.payload;
        },
        updateAgentType(state, action) {
            state.agentType = action.payload;
        },
        rememberAccountAction(state, action) {
            state.rememberData = action.payload;
        },
    },
});

const { actions, reducer } = authSlice;

export const { logout, login, cacheUserInfo, rememberAccountAction, updateAgentType } = actions;
export default reducer;
